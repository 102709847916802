import React from "react";
import { connect } from "react-redux";
import { APP } from "../actions/actionTypes";
import { loadCalls } from "../actions/historyActions";
import CallButton from "../components/buttons/CallButton";

class History extends React.Component {
  componentDidMount() {
    this.props.loadCalls();
    this.props.setPageTitle(this.props.text("history.history"));
  }

  findContactnameFromSip = sip => {
    for (var i = 0; i < this.props.contacts.length; i++) {
      if (this.props.contacts[i].sipaddress === sip) {
        if (this.props.contacts[i].fname && this.props.contacts[i].sname) {
          return this.props.contacts[i].fname + " " + this.props.contacts[i].sname;
        } else if (this.props.contacts[i].fname) {
          return this.props.contacts[i].fname;
        } else if (this.props.contacts[i].sname) {
          return this.props.contacts[i].sname;
        }
      }
    }
    return undefined;
  };

  render() {
    var history = [];
    var tempList = {}; //To save the result from thefindContactnameFromSip function so the contact list will not be iterated every time

    for (var i = 0; i < this.props.calls.length; i++) {
      var contactName = tempList[this.props.calls[i].sipAddress];
      var sip = this.props.calls[i].sipAddress;

      if (contactName === undefined) {
        contactName = this.findContactnameFromSip(sip);
        tempList[sip] = contactName;
      }

      history.push({
        contactName: contactName,
        sipAddress: sip,
      });
    }

    return (
      <div>
        <h1 id="h1id">{this.props.text("history.history")}</h1>
        <div className="row">
          <div className="col">
            <ul className="list-group">
              {history.map((call, index) => (
                <li className="list-group-item" key={index}>
                  <div className="d-flex justify-content-between">
                    <div className="text-box">
                      <div>
                        <div>
                          <strong>{call.contactName}</strong>
                        </div>
                        <div>{call.sipAddress}</div>
                      </div>
                    </div>
                    <div className="float-right">
                      <CallButton sipaddress={call.sipAddress} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    calls: state.history.calls,
    contacts: state.contact.contacts,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCalls: () => {
      return dispatch(loadCalls());
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
