import { Session, URI } from "sip.js";
import { Body } from "sip.js/lib/core";

export class SafetextSender {
  private sendIntervalInMs: number = 250; // Max time interval in "standard"
  private maxCharsToSend: number = 250; // Max chars to send in each SIP message
  private seq: number = 0;
  private buffer: Array<string> = [];
  private fromURI: URI;
  private looping: boolean = false;

  public uri: URI;
  public session: Session;

  constructor(fromURI: URI) {
    this.fromURI = fromURI;
  }

  public reset() {
    this.buffer = [];
    this.seq = 0;
  }

  public send(message: string) {
    if (message.length > this.maxCharsToSend * 4) {
      console.error("Too many characters entered, size=" + message.length);
      return;
    }

    try {
      if (!message) return;
      if (message.length < 1) return;

      for (let i = 0; i < message.length; i++) {
        const c = message.charAt(i);
        this.buffer.unshift(c);
      }

      // Start the loop if we need to
      if (!this.looping && this.buffer.length > 0) {
        this.looping = true;
        this.loop();
      }
    } catch (e) {
      console.error(e);
    }
  }

  private loop() {
    try {
      if (!this.session) {
        // console.error("session=" + this.session);
        this.looping = false;
        throw new Error("session is not set, so we don't know who to send to");
      }

      if (this.buffer.length > 0) {
        try {
          let str = "";

          while (this.buffer.length > 0 && str.length < this.maxCharsToSend) {
            const c = this.buffer.pop();

            if (c) {
              str += c;
            }
          }

          if (str.length > 0) {
            this.sendSipMessage(str);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        this.looping = false;
      }
    } catch (e) {
      console.error(e, "session=" + this.session);
    } finally {
      if (this.looping) {
        setTimeout(() => this.loop(), this.sendIntervalInMs);
      }
    }
  }

  private sendSipMessage(message: string) {
    this.seq++;

    const extraHeaders: string[] = [
      "P-MMX-Text-Offer: " + this.seq,
      "P-Safe-Text: " + this.seq,
      "P-Call-ID: " + this.session.dialog.callId,
    ];

    const body: Body = {
      contentDisposition: "session",
      contentType: "text/plain;charset=UTF-8",
      content: message,
    };

    this.session.message({
      requestOptions: {
        extraHeaders,
        body,
      },
    });
  }
}
