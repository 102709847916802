import React from "react";
import { connect } from "react-redux";
import IAlert from "../models/IAlert";
import { IRootState } from "../models/reducerStates";

type AlertsProps = {
  alerts: IAlert[];
};

class Alerts extends React.Component<AlertsProps> {
  render() {
    const alerts = this.props.alerts.map((alert, i) => (
      <div key={alert.id} className={"alert alert-" + alert.type} role="alert">
        <div className="d-flex align-items-center">
          {alert.showSpinner && (
            <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
          )}
          {alert.message}
        </div>
      </div>
    ));

    return alerts.length > 0 ? <div className="alerts">{alerts}</div> : null;
  }
}

const mapStateToProps = (state: Readonly<IRootState>) => {
  return {
    alerts: state.alerts,
  };
};

export default connect(mapStateToProps)(Alerts);
