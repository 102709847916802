import { CALLS } from "../actions/actionTypes";
import { IHistoryState } from "../models/reducerStates";

const initialState: IHistoryState = {
  calls: [],
};

const historyReducer = (state: IHistoryState = initialState, action: any) => {
  switch (action.type) {
    case CALLS.LOADED:
      state = {
        calls: action.payload.calls,
      };
      break;
    default:
      break;
  }
  return state;
};

export default historyReducer;
