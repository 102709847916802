import React from "react";
import VerifyAccount from "../containers/VerifyAccount";
import NoNavLayout from "../layouts/NoNavLayout";

const VerifyAccountPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <VerifyAccount />
    </NoNavLayout>
  );
};

export default VerifyAccountPage;
