import React from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import Dialer from "../components/Dialer";
import Button from "../components/buttons/Button";
import CallButton from "../components/buttons/CallButton";
import { loadServices } from "../actions/serviceActions";
import { call } from "../actions/sipActions";
import { bindLocalStream, unBindLocalStream } from "../actions/configActions";
import "./Call.css";
import { APP } from "../actions/actionTypes";
import { addAlert } from "../actions/alertActions";

/* The Call page when you can dial anyone you want */
class Call extends React.Component {
  cameraActive = false;
  mediaElementRef = React.createRef();

  startSelfViewStream() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        this.props.bindLocalStream(stream);
        this.mediaElementRef.current.srcObject = stream;
      })
      .catch(err => {
        switch (err.name) {
          case "NotFoundError":
            this.props.addAlert(this.props.text("error.requestedDeviceNotFound"));
            break;
          default:
            break;
        }
        console.log("Error when trying to access camera, ", err);
      });
  }

  componentDidMount() {
    this.props.setPageTitle(this.props.text("common.call"));

    var el = document.getElementById("number");
    if (el !== null) {
      el.focus();
    }

    this.props.loadServices();
  }

  componentWillUnmount() {
    this.props.unBindLocalStream();
  }

  onDialer = c => {
    this.number.value = this.number.value + c;
  };

  onDelete = () => {
    if (this.number.value.length <= 0) return;
    this.number.value = this.number.value.substr(0, this.number.value.length - 1);
  };

  onCall = () => {
    if (this.number.value.length <= 0) return false;
    this.props.call(this.number.value);
  };

  getSIP = () => {
    if (this.number.value.length <= 0) return "";
    return this.number.value;
  };

  submit = e => {
    e.preventDefault();
    this.onCall();
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (this.props.useVideo && !this.cameraActive) {
      this.cameraActive = true;
      this.startSelfViewStream();
    } else if (!this.props.useVideo && this.cameraActive) {
      this.cameraActive = false;
      this.props.unBindLocalStream();
    }

    return (
      <div className="call-container">
        <div className={"dialer-container" + (this.props.videoavailable !== true ? " margin-auto" : "")}>
          <h1 id="h1id">{this.props.text("common.call")}</h1>

          <form className="call-form" onSubmit={this.submit}>
            <div className="form-row">
              <div className="col-9">
                <input
                  required={true}
                  type="text"
                  className="form-control height-100-procent"
                  id="number"
                  name="number"
                  placeholder={this.props.text("call.placeholder")}
                  ref={obj => {
                    this.number = obj;
                  }}
                  aria-label={this.props.text("call.placeholder")}
                />
              </div>
              <div className="col-3">
                <Button
                  type="button"
                  icon="backspace"
                  className="width-100-procent"
                  onClick={() => this.onDelete()}
                  ariaLabelText={this.props.text("aria.back_button")}
                />
              </div>
            </div>
          </form>

          <Dialer
            onClick={obj => {
              this.onDialer(obj);
            }}
            inCall={false}
          />

          <div className="text-align-center dialer-call">
            <CallButton
              size="large"
              onClick={() => {
                this.onCall();
              }}
            />
          </div>
        </div>

        {this.props.useVideo && (
          <video
            id="local-video"
            className="before-call-video"
            playsInline={true}
            autoPlay={true}
            aria-label={this.props.text("aria.selfview")}
            ref={this.mediaElementRef}
          >
            <track kind="captions" src="noCaptions.vtt" />
            <track kind="descriptions" src="noDescription.vtt" srcLang="en" label="english_description"></track>
          </video>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: state.service.services,
    useVideo: state.config.call.video && state.setting.user.WEB_VIDEO === "true" && !state.accessibility.screenIsSmall,
    loading: false,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadServices: () => {
      return dispatch(loadServices());
    },
    call: callTo => {
      return dispatch(call(callTo));
    },
    bindLocalStream: stream => {
      return dispatch(bindLocalStream(stream));
    },
    unBindLocalStream: () => {
      return dispatch(unBindLocalStream());
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
    addAlert: message => {
      dispatch(
        addAlert({
          id: "dialer-video-error",
          type: "danger",
          message,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Call);
