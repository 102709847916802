import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import Button from "../components/buttons/Button";
import ContactList from "../components/ContactList";
import SectionHeader from "../components/SectionHeader";
import { call } from "../actions/sipActions";
import { APP } from "../actions/actionTypes";

class Contacts extends React.Component {
  //Consists of tuples with values 'letter' and 'contacts'
  contactLists = [];

  componentDidMount() {
    this.props.setPageTitle(this.props.text("contacts.contacts"));
  }

  onClick = sipAddress => {
    this.props.call(sipAddress);
  };

  findRelevantContactName = contact => {
    var firstName =
      contact.fname !== null && contact.fname !== undefined && contact.fname !== "" ? contact.fname : null;
    var lastName = contact.sname !== null && contact.sname !== undefined && contact.sname !== "" ? contact.sname : null;
    var sip =
      contact.sipaddress !== null && contact.sipaddress !== undefined && contact.sipaddress !== ""
        ? contact.sipaddress
        : null;

    if (firstName && lastName) {
      return firstName + " " + lastName;
    } else if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    } else if (sip) {
      return sip;
    } else {
      return "#";
    }
  };

  separateContactListByLetters = contactList => {
    var contactsByLetters = [];
    var usedLetters = [];

    for (var i = 0; i < contactList.length; i++) {
      var firstLetter = this.findRelevantContactName(contactList[i])
        .charAt(0)
        .toUpperCase();
      if (contactsByLetters[firstLetter] === undefined) {
        contactsByLetters[firstLetter] = [contactList[i]];
        usedLetters.push(firstLetter);
      } else {
        contactsByLetters[firstLetter].push(contactList[i]);
      }
    }
    return { usedLetters: usedLetters, contactsByLetters: contactsByLetters };
  };

  indexForLetter = (letter, list) => {
    for (var i = 0; i < list.length; i++) {
      if (letter < list[i].letter) {
        return i;
      }
    }
    return list.length;
  };
  indexForContact = (name, list) => {
    for (var i = 0; i < list.length; i++) {
      if (name.toUpperCase() < this.findRelevantContactName(list[i]).toUpperCase()) {
        return i;
      }
    }
    return list.length;
  };

  sortContactsInList = list => {
    if (list.length === 1) {
      return list;
    }
    var contactList = [];
    for (var i = 0; i < list.length; i++) {
      var currentName = this.findRelevantContactName(list[i]);
      var indexForContact = this.indexForContact(currentName, contactList);
      contactList.splice(indexForContact, 0, list[i]);
    }
    return contactList;
  };

  sortContactList = contactListByLettersTuples => {
    var contactLists = [];

    for (var i = 0; i < contactListByLettersTuples.usedLetters.length; i++) {
      var currentLetter = contactListByLettersTuples.usedLetters[i];
      var indexForLetter = this.indexForLetter(currentLetter, contactLists);
      var sortedContactsForLetter = this.sortContactsInList(
        contactListByLettersTuples.contactsByLetters[currentLetter]
      );
      contactLists.splice(indexForLetter, 0, {
        letter: currentLetter,
        contacts: sortedContactsForLetter,
      });
    }

    return contactLists;
  };

  render() {
    if (this.props.isLoadingContacts) {
      return <Loading />;
    }

    if (this.contactLists && this.contactLists.length === 0) {
      //The page renders so many times
      var contactListByLettersTuples = this.separateContactListByLetters(this.props.contacts);
      this.contactLists = this.sortContactList(contactListByLettersTuples);
    }

    const contactListItems = this.contactLists.map((item, index) => (
      <ContactList
        key={index}
        letter={item.letter}
        contacts={item.contacts}
        onClick={this.onClick}
        text={this.props.text}
      />
    ));

    return (
      <div>
        <SectionHeader text={this.props.text("contacts.contacts")}>
          <Button to={"/contacts/add"} icon="note_add" text={this.props.text("common.add")} />
        </SectionHeader>

        <ul className="no-ul-css list-group">{contactListItems}</ul>
      </div>
    );
  }
}

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  isLoadingContacts: PropTypes.bool.isRequired,
  text: PropTypes.func.isRequired,
  call: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    contacts: state.contact.contacts,
    isLoadingContacts: state.contact.loading,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    call: callTo => {
      return dispatch(call(callTo));
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
