import React from "react";
import { Route, withRouter } from "react-router-dom";
import Button from "./Button";

class EditButton extends React.Component {
  btn = props => {
    var classes = props.margin_auto === true ? " margin-auto" : "";
    var to = props.to + (props.id !== undefined || props.id !== "" ? props.id : "");

    return (
      <Route
        render={({ history }) => (
          <Button
            className={classes}
            title=" "
            type="button"
            icon="edit"
            text={this.props.text("common.edit")}
            onClick={() => {
              history.push(to);
            }}
          />
        )}
      />
    );
  };

  render() {
    return this.btn(this.props);
  }
}

export default withRouter(EditButton);
