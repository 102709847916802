import React from "react";
import { connect } from "react-redux";
import { loadServices } from "../actions/serviceActions";
import { CALLS } from "../actions/actionTypes";
import { call } from "../actions/sipActions";
import parseQueryParams from "../modules/parse-query-params";
import { IRootState } from "../models/reducerStates";
import IService from "../models/IService";
import { RouteComponentProps, withRouter } from "react-router-dom";

type Props = {
  loadServices?: Function;
  call?: Function;
  dispatch?: Function;
  audio?: boolean;
  video?: boolean;
  text?: boolean;
  restrictNumbers?: boolean;
  callableNumbers?: IService[];
};

export class Ready extends React.Component<Props & RouteComponentProps> {
  componentDidMount() {
    const token = this.props.location.search.substr(1);
    const tokenValue = parseQueryParams(token);

    if (this.props.restrictNumbers) {
      if (this.props.callableNumbers.length === 0) {
        this.props.loadServices().then(() => {
          this.makeCall(tokenValue);
        });
      } else {
        this.makeCall(tokenValue);
      }
    } else {
      this.makeCall(tokenValue);
    }
  }

  makeCall(values: { to: string; username: string; ivr: string; ref: string }) {
    if (!this.props.restrictNumbers || this.props.callableNumbers.some(e => e.sipaddress === values.to)) {
      this.props.call(values.to, this.props.audio, this.props.video, this.props.text, values.ivr, values.ref);
    } else {
      this.props.dispatch({ type: CALLS.CALL_TO_UNREGISTERED });
    }
  }

  render(): JSX.Element {
    return null;
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    restrictNumbers: state.config.features.kiosk.onlyAllowRegisteredContacts,
    callableNumbers: state.service.services,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  dispatch,
  loadServices: () => dispatch(loadServices()),
  call: (to: string, audio: boolean, video: boolean, text: boolean, ivr: string, ref: string) =>
    dispatch(call(to, audio, video, text, ivr, ref)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ready));
