import isEmpty from "lodash/isEmpty";
import MmxApi from "../api/mmxApi";
import moment from "moment";
import config from "../config/config";

const message = {
  fromDisplayName: null,
  fromSipaddress: null,
  messageNumber: null,
  read: null,
  text: null,
  timestamp: null,
  date: null,
};

function mapFromApi(apiMessage) {
  var ret = { ...message };
  ret.fromDisplayName = isEmpty(apiMessage.fromDisplayName)
    ? config.text("error.unknownsender")
    : apiMessage.fromDisplayName;
  ret.fromSipaddress = isEmpty(apiMessage.fromSipaddress) ? config.text("error.unknownsip") : apiMessage.fromSipaddress;
  ret.messageNumber = apiMessage.messageNumber;
  ret.read = apiMessage.read;
  ret.text = isEmpty(apiMessage.text) ? config.text("error.notextsent") : apiMessage.text;
  ret.timestamp = apiMessage.timestamp;
  ret.date = moment(apiMessage.timestamp).format("YYYY-MM-DD HH:mm");
  return ret;
}

export function mapToApi(message) {
  var ret = {};
  ret.fromDisplayName = message.fromDisplayName;
  ret.fromSipaddress = message.fromSipaddress;
  ret.messageNumber = message.messageNumber;
  ret.read = message.read;
  ret.text = message.text;
  ret.timestamp = message.timestamp;
  return ret;
}

const messagesService = {
  load: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.messages
        .messages()
        .done(data => {
          const messages = Array.from(data.messages, mapFromApi);
          resolve(messages);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },

  markAsRead: (messageNumber, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.messages
        .markAsRead(messageNumber)
        .done(data => {
          resolve(data);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },

  delete: (messageNumber, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.messages
        .delete(messageNumber)
        .done(data => {
          resolve(data);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },

  deleteReadMessages: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.messages
        .deleteReadMessages()
        .done(data => {
          resolve(data);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },
};

export default messagesService;
