import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FourOFour from "../containers/FourOFour";
import Alerts from "../components/Alerts";
import "./NoNavLayout.css";
import { inIframe } from "../misc/inIframe";
import { IRootState } from "../models/reducerStates";
import Footer from "../containers/Footer";
import Modal from "../components/Modal";
import DownloadApp from "../components/DownloadApp";
import IRelease from "../models/IRelease";

type PathParams = {};
type Props = RouteComponentProps<PathParams> & {
  text?: Function;
  allowIframe?: boolean;
  kiosk?: boolean;
  forceIframe?: boolean;
  logo?: string;
  release?: IRelease;
  browserIsSupported?: boolean;
  browserErrorMessage?: string;
};

class NoNav extends React.Component<Props> {
  render() {
    if (this.props.kiosk || (!this.props.allowIframe && inIframe()) || (this.props.forceIframe && !inIframe())) {
      return <FourOFour />;
    }

    return (
      <>
        <DownloadApp />
        <Modal />
        <div className="footer-visible">
          <header className="branding-header-login" role="banner">
            <a href={this.props.text("login.logo.href")} className="branding-header-login-link">
              <div className="nonav-focus-border">
                <img className="noNav-img" src={this.props.logo} alt={this.props.text("menu.logo")} />
              </div>
            </a>
          </header>
          <div className="main-container">
            <>
              <Alerts />
              <div className="row">
                <div
                  className={
                    this.props.location.pathname === "/registration" && this.props.text("registration.info") !== " "
                      ? "col-md-0 col-lg-1 col-xl-1"
                      : "col-md-2 col-lg-3 col-xl-3"
                  }
                ></div>
                <div
                  className={
                    this.props.location.pathname === "/registration" && this.props.text("registration.info") !== " "
                      ? "col-md-12 col-lg-10 col-xl-10"
                      : "col-md-8 col-lg-6 col-xl-6"
                  }
                >
                  <div className="margin-login-box"></div>
                  {this.props.children}
                </div>
                <div
                  className={
                    this.props.location.pathname === "/registration" && this.props.text("registration.info") !== " "
                      ? "col-md-0 col-lg-1 col-xl-1"
                      : "col-md-2 col-lg-3 col-xl-3"
                  }
                ></div>
              </div>
            </>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  text: state.config.text,
  kiosk: state.config.features.kiosk.enabled,
  allowIframe: state.config.iframe.allow,
  forceIframe: state.config.iframe.force,
  logo: state.accessibility.isHighContrast ? state.config.branding.logoDarkUrl : state.config.branding.logoUrl,
  release: state.config.release,
  browserIsSupported: state.config.browser.isSupported,
  browserErrorMessage: state.config.browser.errorMessage,
});

const mapDispatchToProps = (dispatch: Function) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoNav));
