import { text } from "../language";
import release from "../release";
import { detect } from "detect-browser";
import { WebChatLayout } from "../types";
import IConfig from "../models/IConfig";

import smartphone from "../assets/audio/ringtones/smartphone.mp3";
import bell from "../assets/audio/ringtones/bell.mp3";
import marimba from "../assets/audio/ringtones/marimba.mp3";
import classic from "../assets/audio/ringtones/classic.mp3";
import glas from "../assets/audio/ringtones/glas.mp3";

const config: IConfig = {
  release,

  dev: ["localhost"].indexOf(window.location.hostname) >= 0 || process.env.NODE_ENV === "development",

  sentry: "https://3c9237dda7884abeb0acd1e19bdfe219@sentry.nwise.se/2",

  /**
   * SERVER
   */
  server: {
    url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    organisationId: 1,
  },

  /**
   * Settings for the call
   */
  call: {
    registerWithUserpartFromSip: false,
    textVisibleByDefault: false,
    dialpad: false,
    audio: false,
    video: false,
    safetext: false,
    fullscreen: false,
  },

  /**
   * Debug
   */
  debug: {
    enabled: false,
    showInMenu: false,
  },

  /**
   * Layout
   */
  layout: {
    home: "services",
    showLogoutInMenu: false,
    showSettingsInMenu: false,
    showThemeToggleInMenu: true,
    showName: false,
    showOnlineStatus: false,
    textInFooter: false,
    useDoubleAudioIconsInFooter: false,
    mediaButtonsInFooter: false,
    linkToSettingsInFooter: false,
  },

  /**
   * Features
   */
  features: {
    anonymous: {
      enabled: false,
      autologin: false,
      icon: "",
      user: "anonymous",
      password: "xxxxx",
    },
    authentication: {
      enabled: false,
    },
    call: {
      enabled: false,
    },
    changeUser: {
      enabled: false,
    },
    contacts: {
      enabled: false,
    },
    directory: {
      enabled: false,
    },
    history: {
      enabled: false,
    },
    kiosk: {
      enabled: false,
      useDefaultCallScreen: false,
      onlyAllowRegisteredContacts: false,
      logoutAfterCall: true,
    },
    messages: {
      enabled: false,
    },
    registration: {
      enabled: false,
      recaptcha: false,
      autogeneratePassword: false,
      license: true,
      sendRegistrationMail: true,
      fieldOrder: [
        "organisation",
        "username",
        "password",
        "firstname",
        "lastname",
        "email",
        "address",
        "postcode",
        "city",
        "phonenumber",
        "mobilenumber",
        "isdnnumber",
        "sipaddress",
        "gggnumber",
        "faxnumber",
      ],
    },
    resetPassword: {
      enabled: false,
      recaptcha: false,
    },
    services: {
      enabled: false,
    },
    settings: {
      enabled: false,
      allowChangeText: false,
      allowChangeRingtone: false,
      allowChangeMedia: false,
      allowChangeMessage: false,
    },
  },

  /**
   * Native app download links.
   */
  download: {
    enabled: false,
    links: {
      googlePlay: null,
      appStore: null,
    },
  },

  /**
   * Language
   */
  text,

  /**
   * Validation for form fields.
   */
  validation: {
    username: /^[a-z0-9\-.]{5,100}$/,
    password: /^.{6,20}$/,
    email: /^[A-Za-z0-9._\-+]+@[A-Za-z0-9\-.]+\.[A-Za-z]{2,}$/,
    default: /^[\u0600-\u06FFA-Za-zŠŒŽšœžŸÀ-ÿ0-9 ,.;:\-_!"@%&/\\()=+]+$/,
  },

  /**
   * reCAPTCHA
   */
  recaptchaKey: "6LfTEDEUAAAAANAa1e7bMZPeWKozvrEMtgUlBGrA",

  /**
   * IFrame
   */
  iframe: {
    allow: false,
    force: false,
  },

  /**
   * Default Chat Layout
   */
  webChatLayout: WebChatLayout.OVER_UNDER,

  /**
   * Font and Font Size
   */
  fonts: {
    colorsDefaultSentTextForeground: "black",
    colorsDefaultSentTextBackground: "white",
    colorsDefaultRecievedTextForeground: "black",
    colorsDefaultRecievedTextBackground: "white",
    colors: [
      { text: "settings.white", value: "white" },
      { text: "settings.lightgrey", value: "lightgrey" },
      { text: "settings.darkgrey", value: "darkgrey" },
      { text: "settings.black", value: "black" },
      { text: "settings.blue", value: "blue" },
      { text: "settings.green", value: "green" },
      { text: "settings.yellow", value: "yellow" },
      { text: "settings.orange", value: "orange" },
      { text: "settings.red", value: "red" },
      { text: "settings.pink", value: "pink" },
      { text: "settings.purple", value: "purple" },
    ],
    fontsDefault: "times",
    fonts: [
      { text: "settings.times", value: "times" },
      { text: "settings.arial", value: "arial" },
      { text: "settings.trebuchet", value: "trebuchet" },
      { text: "settings.lucida", value: "lucida" },
      { text: "settings.verdana", value: "verdana" },
      { text: "settings.comicsans", value: "comicsans" },
      { text: "settings.couriernew", value: "couriernew" },
    ],
    sizesDefault: 18,
    sizes: [
      { text: "8", value: 8 },
      { text: "10", value: 10 },
      { text: "12", value: 12 },
      { text: "14", value: 14 },
      { text: "16", value: 16 },
      { text: "18", value: 18 },
      { text: "20", value: 20 },
      { text: "22", value: 22 },
      { text: "24", value: 24 },
      { text: "36", value: 36 },
      { text: "48", value: 48 },
      { text: "60", value: 60 },
      { text: "72", value: 72 },
      { text: "84", value: 84 },
      { text: "96", value: 96 },
      { text: "120", value: 120 },
      { text: "140", value: 140 },
    ],
  },

  /**
   * Audio
   */
  ringtonesDefault: "BELL",
  ringtones: {
    SMARTPHONE: {
      text: "settings.smartphone",
      audio: smartphone,
    },
    BELL: {
      text: "settings.bell",
      audio: bell,
    },
    MARIMBA: {
      text: "settings.marimba",
      audio: marimba,
    },
    CLASSIC: {
      text: "settings.classic",
      audio: classic,
    },
    GLAS: {
      text: "settings.glas",
      audio: glas,
    },
  },
  browser: detect(),

  branding: {
    name: "",
    logoUrl: "string",
    logoDarkUrl: "",
    icon: "string",
    favicon: false,
    manifest: false,
    css: false,
  },
};

//
// It is possible to change which MMX server to use in development mode
// by setting the environment variable REACT_APP_MMX_SERVER.
//
// On macos and linux it can be set in the shell with:
// > export REACT_APP_MMX_SERVER="10.0.75.30.xip.io"
//
// On windows it can be set with:
// ????
//
/**
 * Development Options
 */
if (config.dev) {
  let server = "rt-userservice.nwise.se";

  if (process.env.REACT_APP_MMX_SERVER && process.env.REACT_APP_MMX_SERVER.length !== 0) {
    server = process.env.REACT_APP_MMX_SERVER;
  }

  config.server.url = `https://${server}`;
  config.recaptchaKey = "6LeLny8UAAAAAHPnnLuFMzpwGFi2sjnOHaQwNIMv";
  config.sentry = null;
}

export default config;
