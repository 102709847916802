import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import { reset } from "../actions/resetPasswordActions";
import { PASSWORD } from "../actions/actionTypes";

import ErrorAlert from "../components/ErrorAlert";
import { Link, withRouter } from "react-router-dom";

class ResetPasswordPage extends Component {
  componentDidMount() {
    this.props.recaptchaInvalid();
  }

  componentWillUnmount() {
    this.props.clearResetPassword();
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.reset(this.emailOrUsername.value);
  };

  onRecaptchaChange = event => {
    this.props.recaptchaValid();
  };

  onEmailOrUsernameChange = event => {
    if (isEmpty(event.target.value)) {
      this.props.emailUsernameInvalid();
    } else {
      this.props.emailUsernameValid();
    }
  };

  render() {
    if (this.props.loading) {
      return <div></div>;
    }

    if (!this.props.enabled) {
      return <div>Reset password not enabled</div>;
    }

    if (this.props.resetPasswordInProgress) {
      return (
        <main>
          <h1>{this.props.text("resetpassword.processing")}</h1>
          <br />
          <br />
          <div>
            <div className="loader" />
          </div>
        </main>
      );
    }

    if (this.props.resetPasswordComplete) {
      return (
        <main>
          <h1>{this.props.text("resetpassword.completed")}</h1>
          <p>{this.props.text("resetpassword.explanation_complete")}</p>
        </main>
      );
    }

    var errorText = "";
    if (this.props.serverError === true) {
      errorText = this.props.text("network.networkError");
    } else if (this.props.resetPasswordErrorText === "Not Found") {
      errorText = this.props.text("error.usernameNotExists");
    } else {
      errorText = this.props.text("resetpassword.error");
      if (this.props.resetPasswordErrorText.length > 0) {
        errorText += " (" + this.props.resetPasswordErrorText + ")";
      }
    }

    return (
      <main>
        <h1>{this.props.text("resetpassword.title")}</h1>

        {this.props.resetPasswordError && <ErrorAlert text={errorText} />}

        <form onSubmit={this.onsubmit}>
          <div className="form-group">
            <p>{this.props.text("resetpassword.explanation")}</p>
            <label htmlFor="emailOrUsername" className="font-weight-bold">
              {this.props.text("resetpassword.email_or_username")}
            </label>
            <div>
              <input
                type="text"
                onChange={this.onEmailOrUsernameChange}
                className="form-control"
                id="emailOrUsername"
                placeholder={this.props.text("resetpassword.email_or_username")}
                value={this.props.emailOrUsername}
                required
                ref={obj => {
                  this.emailOrUsername = obj;
                }}
              />
            </div>
          </div>

          {this.props.config.resetPassword.recaptcha && (
            <div>
              <br />
              <ReCAPTCHA sitekey={this.props.recaptchaKey} onChange={this.props.recaptchaValid} />
              <br />
            </div>
          )}

          <div className="form-group">
            <div>
              <button disabled={this.props.disabled} type="submit" className="btn button" onClick={this.onSubmit}>
                {this.props.text("common.reset")}
              </button>
            </div>
          </div>
        </form>
        {this.props.location.search.indexOf("hideBack=true") === -1 && (
          <div className="mt-4">
            <Link className="underlined" to="/">
              <strong>{this.props.text("common.back")}</strong>
            </Link>
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.config.text == null ? true : false,
    resetPasswordInProgress: state.resetPassword.resetPasswordInProgress,
    resetPasswordComplete: state.resetPassword.resetPasswordComplete,
    serverError: state.network.serverError,
    resetPasswordError: state.resetPassword.resetPasswordError,
    resetPasswordErrorText: state.resetPassword.resetPasswordErrorText,
    disabled:
      (state.config.resetPassword &&
        state.config.resetPassword.recaptcha === true &&
        state.resetPassword.recaptchavalid === false) ||
      state.resetPassword.emailusernamevalid === false
        ? true
        : false,
    text: str => state.config.text(str),
    enabled: state.config.features.resetPassword.enabled,
    recaptchaKey: state.config.recaptchaKey,
    config: {
      registration: state.config.features.registration,
      resetPassword: state.config.features.resetPassword,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    recaptchaValid: () => {
      dispatch({ type: PASSWORD.RESET_RECAPTCHA_VALID });
    },
    recaptchaInvalid: () => {
      dispatch({ type: PASSWORD.RESET_RECAPTCHA_INVALID });
    },
    emailUsernameValid: () => {
      dispatch({ type: PASSWORD.RESET_EMAIL_USERNAME_VALID });
    },
    emailUsernameInvalid: () => {
      dispatch({ type: PASSWORD.RESET_EMAIL_USERNAME_INVALID });
    },
    clearResetPassword: () => {
      dispatch({ type: PASSWORD.RESET_CLEAR });
    },
    reset: emailOrUsername => {
      dispatch(reset(emailOrUsername));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));
