function askPermission() {
  return new Promise(function(resolve, reject) {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}

const notificationService = {
  initializeNotifications: (kiosk, isAndroid) => {
    if (window.Notification && !kiosk && !isAndroid) {
      if (Notification.permission !== "granted") {
        askPermission();
      }
    }
  },
};

export default notificationService;
