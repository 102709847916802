import { CALLS, SIP_ACTION, USER } from "../actions/actionTypes";
import { SIP_STATE } from "../constants/sipState";
import { CALL_STATE } from "../constants/callState";
import { ISipState } from "../models/reducerStates";
import INegotiatedChannels from "../models/INegotiatedChannels";

let initialState: ISipState = {
  phone: null,
  sipState: SIP_STATE.UNREGISTERED,
  callState: null,
  MMXTEXTSEQ: 0,
  online: true,
  negotiated: {} as INegotiatedChannels,
};

const sipReducer = (state: ISipState = initialState, action: any) => {
  switch (action.type) {
    case SIP_ACTION.ONLINE:
      state = {
        ...state,
        online: action.payload,
      };
      break;
    case SIP_ACTION.INVITE_RECIEVED:
      //Remote peer making call (WE ARE BEING CALLED)
      state = {
        ...state,
        sipState: SIP_STATE.IN_CALL,
        callState: CALL_STATE.INCOMING_CALL,
      };
      break;
    case SIP_ACTION.CALLING:
      //Local peer making call (WE ARE CALLING)
      state = {
        ...state,
        sipState: SIP_STATE.IN_CALL,
        callState: CALL_STATE.CALLING,
      };
      break;
    case SIP_ACTION.CALL_ACCEPTED_LOCAL:
      state = {
        ...state,
        callState: CALL_STATE.ESTABLISHING,
        sipState: SIP_STATE.IN_CALL,
        negotiated: action.payload.negotiated,
      };
      break;
    case SIP_ACTION.CALL_ACCEPTED_REMOTE:
      state = {
        ...state,
        callState: CALL_STATE.ACCEPTED,
        sipState: SIP_STATE.IN_CALL,
        negotiated: action.payload.negotiated,
      };
      break;
    case SIP_ACTION.CALL_SET_NEGOTIATED:
      state = {
        ...state,
        callState: CALL_STATE.ACCEPTED,
        negotiated: action.payload.negotiated,
      };
      break;
    case SIP_ACTION.CALL_CANCELED_LOCAL:
      //Outgoing call was canceled by local peer (WE HUNG UP)
      state = {
        ...state,
        callState: CALL_STATE.CANCELED_LOCAL,
        sipState: SIP_STATE.IDLE,
      };
      break;
    case SIP_ACTION.CALL_CANCELED_REMOTE:
      //incoming call was canceled by remote peer (THEY HUNG UP)
      state = {
        ...state,
        callState: CALL_STATE.CANCELED_REMOTE,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case SIP_ACTION.CALL_REJECTED_LOCAL:
      //Call was rejected by local peer (unsucessful final response) (WE REJECTED THE CALL)
      state = {
        ...state,
        callState: CALL_STATE.REJECTED_LOCAL,
        sipState: SIP_STATE.IDLE,
      };
      break;
    case SIP_ACTION.REJECTED_REMOTE:
      //Call was rejected by remote peer (unsucessful final response) (THEY REJECTED THE CALL)
      state = {
        ...state,
        callState: CALL_STATE.REJECTED_REMOTE,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case SIP_ACTION.CALL_FAILED_REMOTE:
      //Call failed, due to unsucessful final response or timeout (CALL WAS NOT SUCCESSFUL)
      state = {
        ...state,
        callState: CALL_STATE.FAILED,
        sipState: SIP_STATE.IDLE,
      };
      break;
    case SIP_ACTION.CALL_FAILED:
      state = {
        ...state,
        callState: CALL_STATE.FAILED,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case SIP_ACTION.CALL_TERMINATED_LOCAL:
      //Call was terminated (CALL WAS ENDED WITH A BYE)
      state = {
        ...state,
        callState: CALL_STATE.TERMINATED_LOCAL,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case SIP_ACTION.CALL_TERMINATED_REMOTE:
      //Call was terminated (CALL WAS ENDED WITH A BYE)
      state = {
        ...state,
        callState: CALL_STATE.TERMINATED_REMOTE,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case SIP_ACTION.CALL_COMPLETED:
      //Call was completed
      state = {
        ...state,
        sipState: SIP_STATE.IDLE,
        callState: CALL_STATE.COMPLETED,
      };
      break;
    case SIP_ACTION.MESSAGE_SENT:
      let textSeq = state.MMXTEXTSEQ++;
      state = {
        ...state,
        MMXTEXTSEQ: textSeq,
      };
      break;
    case SIP_ACTION.REGISTER_STARTED:
      state = {
        ...state,
        phone: action.payload.phone,
        sipState: SIP_STATE.REGISTERING,
      };
      break;
    case SIP_ACTION.REGISTER_SUCCESS:
      state = {
        ...state,
        phone: action.payload.phone,
        sipState: SIP_STATE.IDLE,
        online: true,
      };
      break;
    case SIP_ACTION.REGISTER_FAILED:
      state = {
        ...state,
        sipState: SIP_STATE.UNREGISTERED,
      };
      break;
    case SIP_ACTION.UNREGISTERED:
      state = {
        ...state,
        sipState: SIP_STATE.UNREGISTERED,
      };
      break;
    case CALLS.CALL_TO_UNREGISTERED:
      state = {
        ...state,
        callState: CALL_STATE.UNREGISTERED_NUMBER,
        sipState: SIP_STATE.IN_CALL,
      };
      break;
    case USER.LOGIN_FAILED:
      state = {
        ...state,
        callState: CALL_STATE.AUTHENTICATION_ERROR,
      };
      break;
    case USER.LOGIN_FAILED_TOKEN_INVALID:
      state = {
        ...state,
        callState: CALL_STATE.TOKEN_INVALID,
      };
      break;
    case USER.LOGIN_FAILED_TOKEN_EXPIRED:
      state = {
        ...state,
        callState: CALL_STATE.TOKEN_EXPIRED,
      };
      break;
    case USER.LOGOUT_SUCCESS:
      state = {
        ...state,
        phone: null,
        online: false,
      };
      break;
    default:
      break;
  }
  return state;
};

export default sipReducer;
