import Storage from "./storage";
import config from "../config/config";
import MessageSignal from "../assets/audio/message/select-click.wav";
import { detect } from "detect-browser";
import release from "../release";

const date = new Date();

export const year = date.getFullYear();

export const isMobileDevice = () => {
  const os = detect().os.toLowerCase();

  if (["ios", "android os"].indexOf(os) !== -1) {
    return true;
  }

  // iPad Pro identifies its os as macOS for some reason
  if (os === "mac os" && navigator.maxTouchPoints > 0) {
    return true;
  }

  return false;
};

/**
 * Generate a UUID (version 4).
 */
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Create a random string of set length.
 *
 * @param length The length of the string. Default = 8
 */
export const randomString = (length: number = 8) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let result = "";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

/**
 * Get the session ID. If none exists, a new one will be generated by default.
 *
 * @param generateIfMissing - If this is set to false, no session ID will be generated if it doesn't already exist.
 */
export const getSessionId = (generateIfMissing: boolean = true): string | null => {
  let sessionId = Storage.session.get("sessionId");

  if (!sessionId && generateIfMissing) {
    sessionId = uuidv4();
    Storage.session.set("sessionId", sessionId);
  }

  return sessionId;
};

/**
 * Check if an IP is ipv6.
 *
 * @param ip
 */
export const checkIfIPv6 = (ip: string): boolean => /:+/.test(ip);

/**
 * Set the document title.
 *
 * @param parts String or array of parts to make up the page title. Parts are separated by "-".
 * @param appendAppOwner Whether or not the last part of the page title should be the "common.appOwner" value.
 */
export const setPageTitle = (parts: string | Array<string>, appendAppOwner = true) => {
  parts = typeof parts === "object" ? parts : [parts];
  if (appendAppOwner) {
    parts.push(config.text("common.appOwner"));
  }
  document.title = parts.join(" - ");
};

/**
 * Play the message alert clip.
 */
export const playMessageAlert = () => {
  const audioElement = document.createElement("audio");
  audioElement.setAttribute("src", MessageSignal);
  audioElement.load();
  audioElement.play();
};

/**
 * Get the User Agent string.
 */
export const getUserAgentString = (): string => {
  const browser = detect();

  let userAgentString = "MMX Webtc/" + release.version.split("-")[0];

  if (browser && browser.os) {
    userAgentString = `${userAgentString} (${browser.os}; ${navigator.language.toLowerCase()})`;
  }
  if (browser && browser.name) {
    userAgentString = `${userAgentString} ${browser.name}`;
  }
  if (browser && browser.version) {
    userAgentString = `${userAgentString}/${browser.version}`;
  }

  return userAgentString;
};
