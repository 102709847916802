import React from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import { call } from "../actions/sipActions";
import FilterSelect from "../components/businessDirectory/FilterSelect";
import List from "../components/businessDirectory/List";
import Search from "../components/businessDirectory/Search";
import "./BusinessDirectory.css";
import { APP, DIRECTORY } from "../actions/actionTypes";
import ContactsService from "../services/contactsService";
import { AnyAction } from "redux";
import { IRootState, IUserState } from "../models/reducerStates";
import BusinessDirectoryItem from "../models/IBusinessDirectoryItem";
import IBusinessDirectoryType from "../models/IBusinessDirectoryType";
import IBusinessDirectoryCategory from "../models/IBusinessDirectoryCategory";

type BusinessDirectoryProps = {
  call?: Function;
  dispatch?: Function;
  text?: Function;
  user?: IUserState;
  serverUrl?: string;
  setPageTitle: Function;
};

type BusinessDirectoryState = {
  loaded: boolean;
  types: IBusinessDirectoryType[];
  categories: IBusinessDirectoryCategory[];
  businessDirectory: { [key: string]: BusinessDirectoryItem[] };
  filteredBusinessDirectory: { [key: string]: BusinessDirectoryItem[] };
  featuredBusinessDirectoryItems: BusinessDirectoryItem[];
  selectedType: number;
  selectedCategory: number;
  searchTerm: string;
};

class BusinessDirectory extends React.Component<BusinessDirectoryProps, BusinessDirectoryState> {
  constructor(props: BusinessDirectoryProps) {
    super(props);

    this.state = {
      loaded: false,
      types: [],
      categories: [],
      businessDirectory: {},
      filteredBusinessDirectory: {},
      featuredBusinessDirectoryItems: [],
      selectedType: null,
      selectedCategory: null,
      searchTerm: "",
    };
  }

  onClick = (sipAddress: string) => {
    this.props.call(sipAddress);
  };

  search = (value: string) => {
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        const { businesses, featured } = this.filterBusinessDirectory();
        this.setState({
          filteredBusinessDirectory: businesses,
          featuredBusinessDirectoryItems: featured,
        });
      }
    );
  };

  selectType = (typeId: number) => (e: Error) => {
    this.setState(
      {
        selectedType: typeId === this.state.selectedType ? null : typeId,
      },
      () => {
        const { businesses, featured } = this.filterBusinessDirectory();
        this.setState({
          filteredBusinessDirectory: businesses,
          featuredBusinessDirectoryItems: featured,
        });
      }
    );
  };

  selectCategory = (categoryId: number) => (e: Error) => {
    this.setState(
      {
        selectedCategory: categoryId === this.state.selectedCategory ? null : categoryId,
      },
      () => {
        const { businesses, featured } = this.filterBusinessDirectory();
        this.setState({
          filteredBusinessDirectory: businesses,
          featuredBusinessDirectoryItems: featured,
        });
      }
    );
  };

  // searchBusinessDirectory = () => {
  //   const api = new MmxApi(this.props.serverUrl, null, this.props.dispatch, {
  //     username: this.props.user.username,
  //     password: this.props.user.password,
  //   });
  //   return api.rue.searchBusinessDirectory({
  //     type: this.state.selectedType,
  //     category: this.state.selectedCategory,
  //   });
  // };

  sortBusinessDirectory = (directory: Array<BusinessDirectoryItem>) => {
    directory.sort((a, b) => (a.name > b.name ? 1 : -1));
    const businessDirectory: { [key: string]: BusinessDirectoryItem[] } = {};
    for (let index = 0; index < Object.keys(directory).length; index++) {
      const business = directory[index];
      const firstLetter: string = business.name.charAt(0).toUpperCase();
      if (typeof businessDirectory[firstLetter] === "undefined") {
        businessDirectory[firstLetter] = [business];
      } else {
        businessDirectory[firstLetter].push(business);
      }
    }

    return businessDirectory;
  };

  filterBusinessDirectory = () => {
    const businessDirectory = this.state.businessDirectory;
    const usedLetters = Object.keys(businessDirectory);
    const visibleBusinesses: { [key: string]: BusinessDirectoryItem[] } = {};
    const featuredBusinesses = [];

    for (let i = 0; i < usedLetters.length; i++) {
      const letter = usedLetters[i];
      const businesses = businessDirectory[usedLetters[i]];

      for (let y = 0; y < businesses.length; y++) {
        const business = businesses[y];

        if (business.hasChildren) {
          continue;
        }

        if (
          this.state.searchTerm.length > 0 &&
          business.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) === -1
        ) {
          continue;
        }

        if (this.state.selectedType && business.type.id !== this.state.selectedType) {
          continue;
        }

        if (this.state.selectedCategory && business.category.id !== this.state.selectedCategory) {
          continue;
        }

        if (typeof visibleBusinesses[letter] === "undefined") {
          visibleBusinesses[letter] = [business];
        } else {
          visibleBusinesses[letter].push(business);
        }

        if (business.featured) {
          featuredBusinesses.push(business);
        }
      }
    }

    return { businesses: visibleBusinesses, featured: featuredBusinesses };
  };

  componentDidMount() {
    if (this.state.loaded) {
      return;
    }

    this.props.setPageTitle(this.props.text("titles.directory"));

    ContactsService.getBusinessDirectory(this.props.serverUrl, this.props.user.securityToken, this.props.dispatch)
      .then(data => {
        this.props.dispatch({
          type: DIRECTORY.LOADED,
          payload: data,
        });

        const businessDirectory = this.sortBusinessDirectory(data.directory);

        this.setState(
          {
            types: data.types,
            categories: data.categories,
            businessDirectory: businessDirectory,
          },
          () => {
            const { businesses, featured } = this.filterBusinessDirectory();
            this.setState({
              filteredBusinessDirectory: businesses,
              featuredBusinessDirectoryItems: featured,
              loaded: true,
            });
          }
        );
      })
      .catch((e: Error) => {
        console.log(e);
        this.setState({
          loaded: true,
        });
      });
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }

    const list = Object.keys(this.state.filteredBusinessDirectory).map(letter => {
      return <List key={letter} items={this.state.filteredBusinessDirectory[letter]} letter={letter} />;
    });

    return (
      <div>
        {this.props.text("directory.title") !== "?directory.title" && (
          <h1 id="h1id">{this.props.text("directory.title")}</h1>
        )}
        <div className="d-flex flex-wrap justify-content-center business-directory-types mb-1">
          <FilterSelect onSelect={this.selectType} items={this.state.types} selected={this.state.selectedType} />
        </div>
        <div className="d-flex flex-wrap justify-content-center business-directory-categories mb-3">
          <div className="col-md-8 col-xl-6 text-center">
            <FilterSelect
              onSelect={this.selectCategory}
              items={this.state.categories}
              selected={this.state.selectedCategory}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center business-directory-search mb-2">
          <Search onSearch={this.search} placeholder={this.props.text("directory.search")} />
        </div>
        {this.state.featuredBusinessDirectoryItems.length > 0 && (
          <ul className="no-ul-css list-group business-directory-list">
            <List
              key="featured"
              items={this.state.featuredBusinessDirectoryItems}
              letter={this.props.text("directory.featured")}
            />
          </ul>
        )}
        <ul className="no-ul-css list-group business-directory-list">{list}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    types: state.businessDirectory.types,
    categories: state.businessDirectory.categories,
    directory: state.businessDirectory.directory,
    serverUrl: state.config.server.url,
    user: state.user,
    text: state.config.text,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    call: (callTo: string) => {
      return dispatch(call(callTo));
    },
    dispatch: (action: AnyAction) => {
      return dispatch(action);
    },
    setPageTitle: (title: string) => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDirectory);
