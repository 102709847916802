import config from "../config/config";
import { SETTINGS } from "../actions/actionTypes";
import { ISettingState } from "../models/reducerStates";

const initialState: ISettingState = {
  loading: true,
  user_loading: true,
  user: {
    AM_ENABLED: "0",
    AM_MESSAGE: "",
    WEB_CHAT_LAYOUT: config.webChatLayout,
    WEB_ONEBOX_TEXT_BACKGROUND_COLOR: "white",
    WEB_ONEBOX_TEXT_COLOR: "black",
    WEB_TEXTAREA_IN_BACKGROUND: config.fonts.colorsDefaultRecievedTextBackground,
    WEB_TEXTAREA_OUT_BACKGROUND: config.fonts.colorsDefaultSentTextBackground,
    WEB_TEXT_IN_COLOR: config.fonts.colorsDefaultRecievedTextForeground,
    WEB_TEXT_OUT_COLOR: config.fonts.colorsDefaultSentTextForeground,
    WEB_TEXT_SIZE: config.fonts.sizesDefault,
    WEB_RINGTONE: config.ringtonesDefault,
    WEB_FONT: config.fonts.fontsDefault,
    WEB_AUDIO: "true",
    WEB_VIDEO: "true",
    WEB_BANDWIDTH: "high",
    GEO_EMERGENCY_NUMBERS: [],
    GEO_TRACK_USER_LOCATION: false,
    GEO_SEND_ON_EMERGENCY_CALL: false,
    GEO_UPDATE_LIMIT: 0,
  },
  system: {},
  settingsSaved: undefined,
};

const settingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SETTINGS.USER_LOADING:
      state = {
        ...state,
        user_loading: true,
      };
      break;
    case SETTINGS.USER_LOADED:
      state = {
        ...state,
        user_loading: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
      break;
    case SETTINGS.USER_LOADED_DEFAULT:
      state = {
        ...initialState,
        user_loading: false,
      };
      break;
    case SETTINGS.SAVE:
      state = {
        ...state,
        settingsSaved: true,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
      break;
    case SETTINGS.SAVED_RESET:
      state = {
        ...state,
        settingsSaved: false,
      };
      break;
    default:
      break;
  }

  state.loading = state.user_loading;

  return state;
};

export default settingReducer;
