import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Webapp",
  "common.loading": "Laden",
  "common.call": "Anrufen",
  "common.answer": "Annehmen",
  "common.reject": "Ablehnen",
  "common.callback": "Rückruf",
  "common.callagain": "Wiederwahl",
  "common.yes": "Ja",
  "common.no": "Nein",
  "common.on": "AN",
  "common.off": "AUS",
  "common.text": "Text",
  "common.abort": "Beenden",
  "common.delete": "Löschen",
  "common.cancel": "Abbrechen",
  "common.missedcall": "Verpasster Anruf von",
  "common.save": "Speichern",
  "common.hangup": "Auflegen",
  "common.add": "Hinzufügen",
  "common.firstname": "Vorname",
  "common.lastname": "Nachname",
  "common.sipaddress": "SIP Adresse",
  "common.email": "E-Mail",
  "common.address": "Adresse",
  "common.city": "Stadt",
  "common.postcode": "Postleitzahl",
  "common.calling": "Anruf wird aufgebaut",
  "common.dialer": "Tastenfeld",
  "common.back": "Zurück",
  "common.hide": "Verbergen",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Audio",
  "common.reset": "Zurücksetzen",
  "common.here": "Hier",
  "common.username": "Benutzername",
  "common.password": "Passwort",
  "common.send": "Senden",
  "common.password1": "Passwort",
  "common.password2": "Passwort wiederholen",
  "common.phonenumber": "Telefon",
  "common.sip": "SIP Adresse",
  "common.mobilenumber": "Mobil",
  "common.isdn": "ISDN Nummer",
  "common.3gnumber": "3G Nummer",
  "common.fax": "Fax",
  "common.username_helptext":
    "Zwischen 5 und 100 Zeichen. Nur 0-9, .(Punkt), -(Strich) und a-z(Kleinbuchstaben) sind gestattet.",
  "common.email_helptext": "Ungültige E-Mail",
  "common.password1_helptext": "Zwischen 6 und 20 Zeichen",
  "common.password2_helptext": "Passwörter stimmen nicht überein",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Das Feld enthält ein oder mehrere ungültige Zeichen",
  "common.link": "link",
  "common.Link": "Link",
  "common.anonymous": "Anonym",
  "common.edit": "Bearbeiten",
  "common.contact_information": "Kontaktinformationen",
  "common.view_message": "Nachricht anschauen",
  "common.close_message": "Nachricht schliessen",
  "common.required": "* Angabe erforderlich",
  "common.to_startpage": "Zur Startseite",
  "common.organisation": "Organisation",
  "common.permissions.heading": "Der Dienst benötigt die Erlaubnis, Ihre Kamera und / oder Ihr Mikrofon zu verwenden.",
  "common.permissions.subheading":
    "Sie können später vor jedem Anruf entscheiden, ob Sie Video / Audio für den Anruf verwenden möchten oder nicht.<br>Firefox-Benutzern wird empfohlen, sich an diese Entscheidung zu erinnern, um ähnliche Fragen später zu vermeiden.<br>Wenn Sie die Berechtigungen akzeptieren, wird die Kamera / das Mikrofon einige Sekunden lang aktiviert und dann ausgeschaltet.",
  "common.app_for_os": "%APPNAME% für %OS%",

  /** Titles */
  "titles.login": "Anmelden",
  "titles.services": "Dienste",
  "titles.contacts": "Kontakte",
  "titles.call": "Anrufen",
  "titles.directory": "Business Directory",
  "titles.history": "Verlauf",
  "titles.messages": "Nachrichten",
  "titles.settings": "Einstellungen",
  "titles.userinformation": "Mein Profil",
  "titles.log": "Debug",
  "titles.about": "Systeminformationen",
  "titles.registration": "Registration",
  "titles.newpassword": "Neues Passwort beantragen",
  "titles.resetpassword": "Passwort zurücksetzen",
  "titles.verifyaccount": "Konto aktivieren",

  /** Toolbar */
  "toolbar.hidetext": "Text ist AN",
  "toolbar.showtext": "Text ist AUS",
  "toolbar.micoff": "Mikrofon ist AUS",
  "toolbar.micon": "Mikrofon ist AN",
  "toolbar.audiooff": "Ton ist AUS",
  "toolbar.audioon": "Ton ist AN",
  "toolbar.videooff": "Eingehendes Bild ist AUS",
  "toolbar.videoon": "Eingehendes Bild ist AN",
  "toolbar.cameraoff": "Mein Bild ist AUS",
  "toolbar.cameraon": "Mein Bild ist AN",
  "toolbar.fullscreenoff": "Vollbild ist AUS",
  "toolbar.fullscreenon": "Vollbild ist AN",
  "toolbar.selfviewoff": "Kontrollbild ist AUS",
  "toolbar.selfviewon": "Kontrollbild ist AN",
  "toolbar.hidedialpad": "Tastenfeld ist AN",
  "toolbar.showdialpad": "Tastenfeld ist AUS",
  "toolbar.useaudioon": "Anruf mit Ton ist AN",
  "toolbar.useaudiooff": "Anruf mit Ton ist AUS",
  "toolbar.usevideoon": "Anruf mit Video ist AN",
  "toolbar.usevideooff": "Anruf mit Video ist AUS",
  "toolbar.usetexton": "Anruf mit Text ist AN",
  "toolbar.usetextoff": "Anruf mit Text ist AUS",
  "toolbar.more": "Mehr anzeigen",
  "toolbar.hide": "Verbergen",
  "toolbar.togglevideo": "Video anzeigen",
  "toolbar.toggletext": "Text anzeigen",
  "toolbar.toSettings": "Zu % Einstellungen gehen",

  /** Menu */
  "menu.logo": "Startseite",
  "menu.services": "Dienste",
  "menu.servicesHeadline": "Dienste",
  "menu.contacts": "Kontakte",
  "menu.mycontacts": "Meine Kontakte",
  "menu.call": "Anrufen",
  "menu.directory": "Business Directory",
  "menu.history": "Verlauf",
  "menu.messages": "Nachrichten",
  "menu.settings": "Einstellungen",
  "menu.logout": "Abmelden",
  "menu.userinformation": "Konto",
  "menu.userprofile": "Mein Profil",
  "menu.log": "Debug",
  "menu.about": "Systeminformationen",

  /** Login screen */
  "login.loginanonymous.headline": "Für anonyme Anrufe hier klicken",
  "login.loginanonymous": "Anonyme Anmeldung",
  "login.headline": "Anmeldung",
  "login.login": "Anmeldung",
  "login.loginfailed": "Anmeldung fehlgeschlagen",
  "login.resetpassword": "Passwort vergessen?",
  "login.registration": "Neues Konto erstellen",
  "login.nowebrtc": "Ihr Browser unterstützt keine Audio-/Videoübertragung in Echtzeit (WebRTC).",
  "login.debug": "Debug mode",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Ungültiger Benutzername / Passwort",
  "error.usernameNotExists": "Benutzername existiert nicht",
  "error.noservercontact": "Keine Verbindung zum Server",
  "error.accountnotactive": "Konto nicht aktiviert",
  "error.accountexpired": "Konto abgelaufen",
  "error.tokenexpired": "Die Sitzung ist abgelaufen, bitte melden Sie sich erneut an",
  "error.tokeninvalid": "Der Authentifizierungstoken ist ungültig",
  "error.unknown": "Unbekannter Fehler",
  "error.unknownsender": "Unbekannter Absender",
  "error.unknownsip": "Unbekannter SIP",
  "error.notextsent": "Kein Text gesendet",
  "error.acquiremedia":
    "Sie können keine Audio-/Videoanrufe tätigen, ohne den Zugriff auf Ihr Mikrofon/ Ihre Kamera freizugeben. Wenn Sie einen Anruf ohne diesen Zugriff tätigen möchten, deaktivieren Sie bitte Audio / Video.",
  "error.mediaNotReadable":
    "Auf das Audio- oder Videogerät kann nicht zugegriffen werden. Stellen Sie sicher, dass sie nicht von anderen Anwendungen verwendet werden, und aktualisieren Sie die Seite.",
  "error.requestedDeviceNotFound":
    "Das angeforderte Mediengerät wurde nicht gefunden. Bitte stellen Sie sicher, dass es richtig angeschlossen ist.",
  "error.cantcall": "Es können keine Anrufe getätigt werden, solange keine Verbindung zum Server besteht",
  "error.cantcall_without_media":
    "Es können keine Anrufe ohne mindestens einen aktivierten Medientyp (Audio, Video oder Text) getätigt werden.",
  "error.404": "404 - Die gewünschte Seite konnte nicht gefunden werden.",
  "error.unsupportedbrowser":
    "Ihr Webbrowser wird momentan nicht unterstützt, bitte verwenden Sie einen anderen Browser.",
  "error.browseroutofdate":
    "Die Version Ihres Webbrowsers ist veraltet, bitte führen Sie zuerst ein Update auf die neuste Version durch.",
  "error.browseroutofdate.ios.15.1":
    "Diese iOS-Version wird nicht unterstützt, bitte aktualisieren Sie auf mindestens iOS 15.2.",
  "error.browserwarning.closeAndDontShowAgain": "Schließen und nicht mehr anzeigen",
  "error.browserwarning.macos":
    "Wenn Sie Probleme beim Telefonieren von Ihrem iPad haben und derzeit iOS Version 15.1 verwenden, aktualisieren Sie bitte auf iOS 15.2 oder höher.",

  /** User settings */
  "settings.white": "Weiss",
  "settings.lightgrey": "Hellgrau",
  "settings.darkgrey": "Dunkelgrau",
  "settings.black": "Schwarz",
  "settings.yellow": "Gelb",
  "settings.blue": "Blau",
  "settings.red": "Rot",
  "settings.green": "Grün",
  "settings.purple": "Violett",
  "settings.orange": "Orange",
  "settings.pink": "Pink",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Nebeneinander",
  "settings.overunder": "Übereinander",
  "settings.one": "Geteiltes Fenster",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Glocke",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Klassisch",
  "settings.settings": "Einstellungen",
  "settings.text": "Text",
  "settings.font": "Schriftart",
  "settings.fontsize": "Schriftgrösse",
  "settings.textboxlayout": "Textfensteranordnung",
  "settings.mycolors": "Mein Textfenster",
  "settings.incomingcolors": "Eingehendes Textfenster",
  "settings.sharedcolors": "Geteiltes Textfenster",
  "settings.textcolors": "Textfarbe",
  "settings.backgroundcolors": "Hintergrundfarbe",
  "settings.preview": "Vorschau",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "settings.previewmytext": "Mein Text",
  "settings.previewincomingtext": "Eingehender Text",
  "settings.previewbothtext": "Mein Text, Eingehender Text",
  "settings.incomingcalls": "Eingehende Anrufe",
  "settings.messagebox": "Anrufbeantworter",
  "settings.autoanswer": "Verwenden Sie eine Textnachricht bei Abwesenheit",
  "settings.autoanswermessage": "Nachricht",
  "settings.duringcalls": "Anruf",
  "settings.colors": "Farben",
  "settings.textformat": "Text",
  "settings.media": "Medien",
  "settings.audio": "Audio",
  "settings.video": "Video",
  "settings.ringtone": "Klingelton",
  "settings.mediamail": "Nachrichten",
  "settings.about": "Systeminformationen",
  "settings.logout": "Abmelden",
  "settings.logout.anonymous": "Verlassen",
  "settings.saved": "Einstellungen wurden gespeichert",
  "settings.media_explanation":
    "Wählen Sie die Art des Anrufs. Wenn eine Einstellung aktiviert ist, kann sie während eines Anrufs deaktiviert werden. Sie kann allerdings während des Anrufs nicht reaktiviert werden.",
  "settings.useaudio": "Audioanruf",
  "settings.usevideo": "Videoanruf",
  "settings.bandwidth.label": "Bandbreite",
  "settings.bandwidth.high": "Hoch",
  "settings.bandwidth.medium": "Mittel",
  "settings.bandwidth.low": "Niedrig",

  /** Messages */
  "messages.messages": "Nachrichten",
  "messages.unreadmessages": "Ungelesen",
  "messages.readmessages": "Gelesen",
  "messages.nomessages": "Sie haben keine neuen Nachrichten",
  "messages.deleteallread": "Alle gelesenen Nachrichten löschen",
  "messages.deleteallreadconfirm": "Alle gelesenen Nachrichten löschen?",
  "messages.messageAlert": "Nachricht gelöscht",

  /** Contacts */
  "contacts.contacts": "Meine Kontakte",
  "contacts.addcontact": "Kontakte hinzufügen",
  "contacts.deletecontact": "Kontakte löschen",
  "contacts.nosipgiven": "Bitte vergeben Sie eine SIP-Adresse",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Name / Nummer hier einfügen",
  "call.incomingtext": "Eingehender Text",
  "call.outgoingtext": "Ausgehender Text",
  "call.incoming": "Eingehender Anruf von ",
  "call.unknownnumber": "Unbekannte Nummer",
  "call.unknownnumber_uppercase": "Unbekannte Nummer",
  "call.endedcall": "hat den Anruf beendet",
  "call.yourejected": "Sie haben den Anruf abgelehnt",
  "call.noanswer": "Keine Antwort",
  "call.rejected": "Konnte den Anruf nicht entgegennehmen",
  "call.notfound": "ist nicht erreichbar",
  "call.toolongtext": "\n<Zu viele Zeichen. Maximal 1000 Zeichen.>\n",
  "call.notificationbody": "Hier klicken um zu antworten",
  "call.videoTextDisabled": "Text und eingehendes Bild ist Aus",
  "call.writeHere": "Hier eintippen...",
  "call.receiveHere": "Hier wird der eingehende Text erscheinen",
  "call.establishing": "Anruf wird aufgebaut...",

  /** History screen */
  "history.history": "Verlauf",

  /** Reset password */
  "resetpassword.processing": "Verarbeiten...",
  "resetpassword.completed": "Passwortzurücksetzung wurde erfolgreich abgeschlossen",
  "resetpassword.email_or_username": "E-Mail oder Benutzername",
  "resetpassword.title": "Neues Passwort",
  "resetpassword.explanation":
    "Geben Sie Ihre E-Mail / Ihren Benutzernamen ein. Sie erhalten einen Link mit weiteren Angaben. Folgen Sie dem Link um Ihr Passwort zurückzusetzen.",
  "resetpassword.explanation_complete": "In Kürze erhalten Sie eine E-Mail mit weiteren Angaben",
  "resetpassword.invalid_link": "Ungültiger Zurücksetzungslink",
  "resetpassword.password_changed": "Ihr Passwort wurde geändert.",
  "resetpassword.enter_new_password": "Neues Passwort angeben",
  "resetpassword.button_reset": "Zurücksetzen",
  "resetpassword.password": "Passwort",
  "resetpassword.password_repeat": "Passwort wiederholen",
  "resetpassword.password_requirement": "Das Passwort muss zwischen 6 und 20 Zeichen lang sein.",
  "resetpassword.error":
    "Passwortzurücksetzung fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Administrator.",
  "resetpassword.link_too_old": "Link veraltet",

  /** Registration */
  "registration.error":
    "Registrierung nicht möglich. Bitte versuchen Sie es erneut oder kontaktieren Sie den Administrator.",
  "registration.title": "Registrierung",
  "registration.processing": "Verarbeitung...",
  "registration.not_enabled": "Registrierung nicht verfügbar",
  "registration.completed_headline": "Sie wurden registriert",
  "registration.completed": "Registrierung abgeschlossen",
  "registration.completed_admin_approval":
    "Ihr Konto muss von einem Administrator genehmigt werden, bevor Sie sich anmelden können.",
  "registration.completed_verification":
    "Ihr Konto muss bestätigt werden, bevor Sie sich anmelden können. Sie erhalten in Kürze eine E-Mail mit Anweisungen.",
  "registration.completed_link": "/",
  "registration.username_exists": "Benutzername existiert bereits. Bitte wählen Sie einen anderen Benutzernamen.",
  "registration.email_exists": "E-Mailadresse existiert bereits. Bitte wählen Sie eine andere E-Mailadresse.",
  "registration.sip_exists": "SIP Adresse existiert bereits. Wählen Sie eine andere SIP Adresse.",
  "registration.license1": "Ja, Ich habe",
  "registration.license_link_text": "die Informationen über die Handhabung persönlicher Informationen",
  "registration.license2": "gelesen und akzeptiere die Bedingungen.",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Bitte akzeptieren Sie die Bedingungen, um mit der Anmeldung fortzufahren.",
  "registration.register": "Registrieren",

  /** Verify Account */
  "verifyaccount.completed.title": "Ihr Konto ist aktiviert!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Etwas ist schief gelaufen! Bitte versuchen Sie erneut, das Konto zu registrieren.",
  "verifyaccount.error.expired":
    "Der Bestätigungslink ist abgelaufen. Bitte versuchen Sie erneut, das Konto zu registrieren.",
  "verifyaccount.registration": "Neues Konto erstellen",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Konto anpassen",
  "changeuser.titlePassword": "Passwort ändern",
  "changeuser.newPassword": "Neues Passwort",
  "changeuser.error": "Update fehlgeschlagen. Bitte probieren Sie es erneut oder kontaktieren Sie den Administrator.",
  "changeuser.complete": "Konto aktualisiert",
  "changeuser.processing": "Verarbeitung...",
  "changeuser.username_exists": "Benutzername existiert bereits. Bitte wählen Sie einen anderen Benutzernamen.",
  "changeuser.email_exists": "E-Mailadresse existiert bereits. Bitte wählen Sie eine andere E-Mailadresse.",
  "changeuser.email_empty": "E-Mailadresse ist erforderlich. Bitte geben Sie eine E-Mailadresse an.",
  "changeuser.sip_exists": "SIP Adresse existiert bereits. Wählen Sie eine andere SIP Adresse.",

  /** Network */
  "network.networkError": "Beim Verbindungsversuch ist ein Fehler aufgetreten.",
  "network.establishing": "Versuch, eine Verbindung zum Server herzustellen...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Dunkle Ansicht",
  "accessibility.originaltheme": "Standardansicht",

  /** About */
  "about.user": "Benutzername",
  "about.sip": "SIP address",
  "about.server": "Server IP",
  "about.version": "Version",
  "about.browser": "Browser",
  "about.browserversion": "Browserversion",
  "about.os": "OS",

  /** Aria */
  "aria.mainmenu": "Hauptmenü",
  "aria.submenu_contacts": "Kontaktmenü",
  "aria.submenu_account": "Kontomenü",
  "aria.selected_page": "Ausgewählte Seite",
  "aria.back_button": "Backspace",
  "aria.accept_terms": "Bedingungen akzeptieren",
  "aria.link_sound_video": "Zu Audio-/Videoeinstellungen wechseln",
  "aria.selfview": "Kontrollbild",
  "aria.in-call": "Im Gespräch",

  /** Kiosk */
  "kiosk.call_ended": "Der Anruf wurde beendet. Sie können diese Seite jetzt schließen.",

  /** Modal */
  "modal.close": "Schließen",
  "modal.cancel": "Abbrechen",
  "modal.confirm": "OK",
  "modal.logout_confirmation.title": "Möchten Sie sich wirklich abmelden?",
  "modal.logout_confirmation.confirm": "Ja",
  "modal.logout_confirmation.cancel": "Nein, abbrechen",
};

export default translations;
