import React from "react";
import { Link } from "react-router-dom";
import "./NavItemMaterial.css";

const navItemMaterial = props => {
  var chosenElemClass = props.chosen === true ? "activeNavElem" : "";
  var showNumberIndication =
    props.amountUnreadMessages !== undefined &&
    props.amountUnreadMessages > 0; /* TODO: Add missed calls here later when that feature works */
  var notificationNumber = props.amountUnreadMessages; /* TODO: Add missed calls here later when that feature works */

  return (
    <li className={"nav-item " + chosenElemClass} aria-current={chosenElemClass !== "" ? "page" : "false"}>
      <Link className={"nav-link " + chosenElemClass} to={props.route ? "/" + props.route : ""} onClick={props.onClick}>
        <div className="text-align-center nav-focus-border nav-content-container">
          <div className="nav-content">
            {showNumberIndication && <div className="notification">{notificationNumber}</div>}
            <i aria-hidden="true" className="material-icons md-60 vertical-align-middle nav-icon">
              {props.icon}
            </i>
            <div className="text-align-center font-size-medium nav-text">{props.text}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default navItemMaterial;
