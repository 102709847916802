import { ALERT } from "./actionTypes";
import { uuidv4 } from "../misc/helpers";

export const Types = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "danger",
};

export const addAlert = ({ message, type = "info", showSpinner = false, append = false, id = null, flash = true }) => {
  id = id || uuidv4();
  return {
    type: ALERT.ADD,
    payload: {
      id,
      type,
      message,
      showSpinner,
      append,
      flash,
    },
  };
};

export const removeAlert = id => ({
  type: ALERT.REMOVE,
  payload: {
    id,
  },
});

export const clearAlerts = () => ({
  type: ALERT.CLEAR_ALL,
});
