import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "التطبيق على شبكة الإنترنت",
  "common.loading": "جار التحميل",
  "common.call": "مكالمة",
  "common.answer": "إجابة",
  "common.reject": "رفض",
  "common.callback": "أتصل مرة أخرى",
  "common.callagain": "اتصل مرة اخرى",
  "common.yes": "نعم فعلا",
  "common.no": "لا",
  "common.on": "على",
  "common.off": "إيقاف",
  "common.text": "نص",
  "common.abort": "الإجهاض",
  "common.delete": "حذف",
  "common.cancel": "إلغاء",
  "common.missedcall": "مكالمة فائتة من",
  "common.save": "حفظ",
  "common.hangup": "يشنق",
  "common.add": "إضافة",
  "common.firstname": "الاسم الاول",
  "common.lastname": "الكنية",
  "common.sipaddress": "عنوان SIP",
  "common.email": "البريد الإلكتروني",
  "common.address": "عنوان",
  "common.city": "مدينة",
  "common.postcode": "الرمز البريدي",
  "common.calling": "دعوة",
  "common.dialer": "طالب",
  "common.back": "الى الخلف",
  "common.hide": "إخفاء",
  "common.online": "عبر الانترنت",
  "common.video": "فيديو",
  "common.audio": "سمعي",
  "common.reset": "إعادة تعيين",
  "common.here": "هنا",
  "common.username": "اسم المستخدم",
  "common.password": "كلمه السر",
  "common.send": "إرسال",
  "common.password1": "كلمه السر",
  "common.password2": "اعد كلمة السر",
  "common.phonenumber": "هاتف",
  "common.sip": "عنوان SIP",
  "common.mobilenumber": "التليفون المحمول",
  "common.isdn": "رقم ISDN",
  "common.3gnumber": "رقم الجيل الثالث 3G",
  "common.fax": "فاكس",
  "common.username_helptext": "بين 5 و 100 حرف. يُسمح فقط بـ 0-9 و (نقطة) و - (شرطة) و a-z (أحرف صغيرة).",
  "common.email_helptext": "بريد إلكتروني خاطئ",
  "common.password1_helptext": "بين 6 و 20 حرفًا",
  "common.password2_helptext": "كلمات المرور غير متساوية",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "يحتوي الحقل على حرف غير صالح أو أكثر",
  "common.link": "حلقة الوصل",
  "common.Link": "حلقة الوصل",
  "common.anonymous": "مجهول",
  "common.edit": "تصحيح",
  "common.contact_information": "معلومات التواصل",
  "common.view_message": "شاهد الرساله",
  "common.close_message": "إغلاق الرسالة",
  "common.required": "* يتطلب حقلا",
  "common.to_startpage": "لبدء الصفحة",
  "common.organisation": "منظمة",
  "common.permissions.heading": "تحتاج الخدمة إلى إذن لاستخدام الكاميرا و / أو الميكروفون",
  "common.permissions.subheading":
    'يمكنك لاحقًا قبل كل مكالمة تحديد ما إذا كنت تريد استخدام الفيديو / الصوت في المكالمة أم لا. <br> يُنصح مستخدمو Firefox بـ "تذكر هذا القرار" لتجنب أسئلة مماثلة لاحقًا. <br> عند قبول الأذونات ، سيتم تنشيط الكاميرا / الميكروفون لبضع ثوان ثم إيقاف تشغيله.',
  "common.app_for_os": "%APPNAME% for %OS%",

  /** Titles */
  "titles.login": "تسجيل الدخول",
  "titles.services": "خدمات",
  "titles.contacts": "جهات الاتصال الخاصة بي",
  "titles.call": "مكالمة",
  "titles.directory": "Business Directory",
  "titles.history": "التاريخ",
  "titles.messages": "رسائل",
  "titles.settings": "الإعدادات",
  "titles.userinformation": "ملفي",
  "titles.log": "التصحيح",
  "titles.about": "معلومات حول النظم",
  "titles.registration": "التسجيل",
  "titles.newpassword": "احصل على كلمة سر جديدة",
  "titles.resetpassword": "إعادة تعيين كلمة المرور",
  "titles.verifyaccount": "تفعيل حساب",

  /** Toolbar */
  "toolbar.hidetext": "النص مغلق",
  "toolbar.showtext": "النص قيد التشغيل",
  "toolbar.micoff": "الميكروفون مغلق",
  "toolbar.micon": "الميكروفون قيد التشغيل",
  "toolbar.audiooff": "الصوت مغلق",
  "toolbar.audioon": "الصوت قيد التشغيل",
  "toolbar.videooff": "الفيديو الوارد مغلق",
  "toolbar.videoon": "الفيديو الوارد قيد التشغيل",
  "toolbar.cameraoff": "الفيديو الخاص بي مغلق",
  "toolbar.cameraon": "الفيديو الخاص بي قيد التشغيل",
  "toolbar.fullscreenoff": "وضع ملء الشاشة مغلق",
  "toolbar.fullscreenon": "وضع ملء الشاشة قيد التشغيل",
  "toolbar.selfviewoff": "Selfview هو خارج",
  "toolbar.selfviewon": "Selfview قيد التشغيل",
  "toolbar.hidedialpad": "Dialpad في وضع التشغيل",
  "toolbar.showdialpad": "Dialpad هو خارج",
  "toolbar.useaudioon": "الاتصال مع الصوت قيد التشغيل",
  "toolbar.useaudiooff": "الاتصال مع الصوت هو OFF",
  "toolbar.usevideoon": "الاتصال مع الفيديو قيد التشغيل",
  "toolbar.usevideooff": "الاتصال مع الفيديو هو OFF",
  "toolbar.usetexton": "الاتصال مع النص قيد التشغيل",
  "toolbar.usetextoff": "الاتصال مع النص هو OFF",
  "toolbar.more": "أكثر من",
  "toolbar.hide": "إخفاء",
  "toolbar.togglevideo": "عرض الفيديو",
  "toolbar.toggletext": "عرض النص",
  "toolbar.toSettings": "اذهب للاعدادات",

  /** Menu */
  "menu.logo": "إلى الصفحة الرئيسية",
  "menu.services": "خدمات",
  "menu.servicesHeadline": "خدمات",
  "menu.contacts": "جهات الاتصال",
  "menu.mycontacts": "جهات الاتصال الخاصة بي",
  "menu.call": "مكالمة",
  "menu.directory": "Business Directory",
  "menu.history": "التاريخ",
  "menu.messages": "رسائل",
  "menu.settings": "الإعدادات",
  "menu.logout": "الخروج",
  "menu.userinformation": "الحساب",
  "menu.userprofile": "ملفي",
  "menu.log": "التصحيح",
  "menu.about": "معلومات حول النظم",

  /** Login screen */
  "login.loginanonymous.headline": "انقر هنا للاتصال مجهول",
  "login.loginanonymous": "تسجيل دخول مجهول",
  "login.headline": "تسجيل الدخول",
  "login.login": "تسجيل الدخول",
  "login.loginfailed": "فشل عملية الدخول",
  "login.resetpassword": "هل نسيت كلمة المرور الخاصة بك؟",
  "login.registration": "هل تريد التسجيل؟",
  "login.nowebrtc": "لا يدعم متصفحك الاتصال في الوقت الفعلي باستخدام الفيديو والصوت (WebRTC).",
  "login.debug": "وضع التصحيح",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "خطأ في اسم المستخدم أو كلمة مرور",
  "error.usernameNotExists": "اسم المستخدم غير موجود",
  "error.noservercontact": "لا يوجد اتصال مع الخادم",
  "error.accountnotactive": "الحساب غير نشط",
  "error.accountexpired": "صلاحية الحساب",
  "error.tokenexpired": "انتهت صلاحية جلسة المصادقة ، يرجى تسجيل الدخول مرة أخرى",
  "error.tokeninvalid": "رمز المصادقة غير صالح",
  "error.unknown": "خطأ غير معروف",
  "error.unknownsender": "مرسل غير معروف",
  "error.unknownsip": "SIP غير معروف",
  "error.notextsent": "لم يتم إرسال نص",
  "error.acquiremedia":
    "لا يمكنك إجراء مكالمة صوتية / مرئية إذا لم تسمح بالوصول إلى الوسائط. إذا كنت تريد الاتصال بدون صوت / فيديو ، فيرجى إلغاء تحديد هذه الإعدادات.",
  "error.mediaNotReadable":
    "لا يمكن الوصول إلى جهاز الصوت أو الفيديو. يرجى التأكد من عدم استخدامها من قبل أي تطبيق آخر وتحديث الصفحة.",
  "error.requestedDeviceNotFound": "لم يتم العثور على جهاز الوسائط المطلوب. يرجى التأكد من توصيله بشكل صحيح.",
  "error.cantcall": "لا يمكنك إجراء مكالمات عندما لا تكون على اتصال بالنظام",
  "error.cantcall_without_media": "لا يمكنك إجراء مكالمات بدون صوت أو فيديو أو نص",
  "error.404": "404 - الصفحة التي تبحث عنها غير موجودة",
  "error.unsupportedbrowser": "متصفح الويب الخاص بك غير مدعوم حاليًا ، يرجى استخدام متصفح ويب آخر.",
  "error.browseroutofdate": "متصفحك غير محدثة. يرجى تحديثه إلى أحدث إصدار لاستخدام التطبيق.",
  "error.browseroutofdate.ios.15.1": "إصدار iOS هذا غير مدعوم ، يرجى التحديث إلى iOS 15.2 على الأقل.",
  "error.browserwarning.closeAndDontShowAgain": "أغلق ولا تظهر مرة أخرى",
  "error.browserwarning.macos":
    "إذا كنت تواجه مشكلات في إجراء مكالمات من جهاز iPad الخاص بك وتستخدم حاليًا الإصدار 15.1 من نظام التشغيل iOS ، فالرجاء الترقية إلى iOS 15.2 أو إصدار أحدث.",

  /** User settings */
  "settings.white": "أبيض",
  "settings.lightgrey": "رمادي فاتح",
  "settings.darkgrey": "الرمادي الداكن",
  "settings.black": "أسود",
  "settings.yellow": "الأصفر",
  "settings.blue": "أزرق",
  "settings.red": "أحمر",
  "settings.green": "أخضر",
  "settings.purple": "أرجواني",
  "settings.orange": "البرتقالي",
  "settings.pink": "زهري",
  "settings.helvetica": "هلفتيكا",
  "settings.times": "تايمز الرومانية الجديدة",
  "settings.arial": "اريال",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "لوسيدا بدون يونيكود",
  "settings.verdana": "فيردانا",
  "settings.comicsans": "بلا هزلية",
  "settings.couriernew": "ساعي جديد",
  "settings.sidebyside": "جنباألى جنب",
  "settings.overunder": "فوق وتحت",
  "settings.one": "نافذة النص المشترك",
  "settings.smartphone": "الهاتف الذكي",
  "settings.bell": "جرس",
  "settings.marimba": "الماريمبا",
  "settings.glas": "زجاج",
  "settings.classic": "كلاسيكي",
  "settings.settings": "الإعدادات",
  "settings.text": "نص",
  "settings.font": "الخط",
  "settings.fontsize": "حجم الخط",
  "settings.textboxlayout": "تخطيط نافذة النص",
  "settings.mycolors": "نافذة النص الخاص بي",
  "settings.incomingcolors": "نافذة النص الوارد",
  "settings.sharedcolors": "نافذة النص المشترك",
  "settings.textcolors": "لون الخط",
  "settings.backgroundcolors": "لون الخلفية",
  "settings.preview": "معاينة",
  "settings.loremipsum":
    "أبجد هوز دولور الجلوس امات، consectetur adipiscing إيليت، tempor الحوار الاقتصادي الاستراتيجي والحيوية، بحيث تعبا وحزنا، وبعض الأشياء الهامة التي يجب القيام eiusmod.",
  "settings.previewmytext": "النص الخاص بي",
  "settings.previewincomingtext": "النص الوارد",
  "settings.previewbothtext": "النص الخاص بي ، النص الوارد",
  "settings.incomingcalls": "المكالمات الواردة",
  "settings.messagebox": "صندوق الرسائل",
  "settings.autoanswer": "استخدم رسالة نصية عندما لا تجيب",
  "settings.autoanswermessage": "الرسالة",
  "settings.duringcalls": "أثناء المكالمات",
  "settings.colors": "الألوان",
  "settings.textformat": "نص",
  "settings.media": "وسائل الإعلام",
  "settings.audio": "سمعي",
  "settings.video": "فيديو",
  "settings.ringtone": "نغمة",
  "settings.mediamail": "رسائل",
  "settings.about": "معلومات النظام",
  "settings.logout": "الخروج",
  "settings.logout.anonymous": "ىخرج",
  "settings.saved": "تم حفظ الإعدادات",
  "settings.media_explanation":
    "اختر كيف تريد إجراء مكالمات. إذا كان الإعداد قيد التشغيل ، فيمكنك دائمًا إيقاف تشغيله مؤقتًا أثناء المكالمة ، ولكن إذا كان في وضع إيقاف التشغيل ، فلا يمكن تشغيله أثناء المكالمة.",
  "settings.useaudio": "الاتصال باستخدام الصوت",
  "settings.usevideo": "الاتصال باستخدام الفيديو",
  "settings.bandwidth.label": "عرض النطاق",
  "settings.bandwidth.high": "عالي",
  "settings.bandwidth.medium": "متوسط",
  "settings.bandwidth.low": "قليل",

  /** Messages */
  "messages.messages": "رسائل",
  "messages.unreadmessages": "غير مقروء",
  "messages.readmessages": "اقرأ",
  "messages.nomessages": "ليس لديك رسائل",
  "messages.deleteallread": "احذف جميع الرسائل المقروءة",
  "messages.deleteallreadconfirm": "هل تريد حذف جميع الرسائل المقروءة؟",
  "messages.messageAlert": "الرسالة مسحت",

  /** Contacts */
  "contacts.contacts": "جهات الاتصال الخاصة بي",
  "contacts.addcontact": "إضافة جهة اتصال",
  "contacts.deletecontact": "حذف اتصال",
  "contacts.nosipgiven": "يرجى إعطاء جهة الاتصال عنوان SIP",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "اكتب الرقم / الاسم هنا",
  "call.incomingtext": "النص الوارد",
  "call.outgoingtext": "النص المنتهية ولايته",
  "call.incoming": "مكالمة واردة من",
  "call.unknownnumber": "رقم مجهول",
  "call.unknownnumber_uppercase": "رقم مجهول",
  "call.endedcall": "أنهى المكالمة",
  "call.yourejected": "لقد رفضت المكالمة",
  "call.noanswer": "لا اجابة",
  "call.rejected": "لا يمكن الإجابة في الوقت الراهن",
  "call.notfound": "لا يمكن الوصول إليها",
  "call.toolongtext": "\n <تم إدخال عدد كبير جدًا من الأحرف. الحد الأقصى 1000.> \n",
  "call.notificationbody": "انقر هنا للإجابة",
  "call.videoTextDisabled": "النص والفيديو الوارد هو OFF",
  "call.writeHere": "اكتب هنا...",
  "call.receiveHere": "هذا هو المكان الذي سيظهر فيه النص الوارد",
  "call.establishing": "إنشاء اتصال ...",

  /** History screen */
  "history.history": "التاريخ",

  /** Reset password */
  "resetpassword.processing": "معالجة ...",
  "resetpassword.completed": "اكتمال إعادة تعيين كلمة المرور",
  "resetpassword.email_or_username": "البريد الإلكتروني أو اسم المستخدم",
  "resetpassword.title": "كلمة مرور جديدة",
  "resetpassword.explanation":
    "أدخل بريدك الإلكتروني أو اسم المستخدم وسيتم إرسال رابط إلى البريد الإلكتروني أو البريد الإلكتروني المرتبط باسم المستخدم. اتبع الرابط لإعادة تعيين كلمة المرور الخاصة بك.",
  "resetpassword.explanation_complete": "سوف تتلقى رسالة بريد إلكتروني مع تعليمات فورتر قريبا.",
  "resetpassword.invalid_link": "رابط إعادة تعيين كلمة المرور غير صالح",
  "resetpassword.password_changed": "لقد تغيرت كلمة المرور الخاصة بك",
  "resetpassword.enter_new_password": "أدخل كلمة مرور جديدة",
  "resetpassword.button_reset": "إعادة تعيين",
  "resetpassword.password": "كلمه السر",
  "resetpassword.password_repeat": "اعد كلمة السر",
  "resetpassword.password_requirement": "يجب أن يتراوح طول كلمة المرور بين 6 و 20 حرفًا",
  "resetpassword.error": "لم يكن من الممكن إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى أو الاتصال بالمسؤول",
  "resetpassword.link_too_old": "الرابط قديم جدا",

  /** Registration */
  "registration.error": "لم يكن من الممكن إكمال التسجيل. يرجى المحاولة مرة أخرى أو الاتصال بالمسؤول.",
  "registration.title": "التسجيل",
  "registration.processing": "معالجة ...",
  "registration.not_enabled": "لم يتم تمكين التسجيل.",
  "registration.completed_headline": "انت تملك حساب مصرفي",
  "registration.completed": "تم الانتهاء من التسجيل الخاص بك.",
  "registration.completed_admin_approval": "يجب أن يوافق المسؤول على حسابك قبل أن تتمكن من تسجيل الدخول.",
  "registration.completed_verification":
    "يجب تأكيد حسابك قبل أن تتمكن من تسجيل الدخول. سوف تتلقى رسالة بريد إلكتروني مع التعليمات قريبا.",
  "registration.completed_link": "/",
  "registration.username_exists": "اسم المستخدم موجود يرجى اختيار اسم مستخدم آخر.",
  "registration.email_exists": "يوجد بريد الكتروني. يرجى اختيار بريد الكتروني آخر",
  "registration.sip_exists": "عنوان SIP موجود. يرجى تحديد عنوان رشفة آخر.",
  "registration.license1": "نعم لقد قرأت",
  "registration.license_link_text": "المعلومات حول كيفية تعاملنا مع المعلومات الشخصية",
  "registration.license2": "وقبول الشروط",
  "registration.licenselink": "https://nwise.se/",
  "registration.info": " ",
  "registration.check_checkbox": "اقبل الشروط إذا كنت تريد التسجيل",
  "registration.register": "يسجل",

  /** Verify Account */
  "verifyaccount.completed.title": "تم تنشيط حسابك",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "هناك خطأ ما! يرجى محاولة تسجيل الحساب مرة أخرى.",
  "verifyaccount.error.expired": "رابط التفعيل انتهت مدته. يرجى محاولة تسجيل الحساب مرة أخرى.",
  "verifyaccount.registration": "هل تريد التسجيل؟",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "تحرير الحساب",
  "changeuser.titlePassword": "تغيير كلمة المرور",
  "changeuser.newPassword": "كلمة مرور جديدة",
  "changeuser.error": "لم يكن من الممكن التحديث. يرجى المحاولة مرة أخرى أو الاتصال بالمسؤول.",
  "changeuser.complete": "تم تحديث الحساب",
  "changeuser.processing": "معالجة ...",
  "changeuser.username_exists": "اسم المستخدم موجود يرجى اختيار اسم مستخدم آخر.",
  "changeuser.email_exists": "يوجد بريد الكتروني. يرجى اختيار بريد الكتروني آخر",
  "changeuser.email_empty": "البريد الالكتروني مطلوب. يرجى كتابة بريد الكتروني.",
  "changeuser.sip_exists": "عنوان SIP موجود. يرجى تحديد عنوان رشفة آخر.",

  /** Network */
  "network.networkError": "حدثت مشكلة عند الاتصال بالخادم",
  "network.establishing": "محاولة إنشاء اتصال بالخادم",
  "network.offline": "غير متصل على الانترنت",

  /** Accessibility */
  "accessibility.darktheme": "موضوع الظلام",
  "accessibility.originaltheme": "الموضوع الأصلي",

  /** About */
  "about.user": "اسم المستخدم",
  "about.sip": "عنوان SIP",
  "about.server": "خادم IP",
  "about.version": "الإصدار",
  "about.browser": "المتصفح",
  "about.browserversion": "نسخة المتصفح",
  "about.os": "نظام التشغيل",

  /** Aria */
  "aria.mainmenu": "القائمة الرئيسية",
  "aria.submenu_contacts": "القائمة الفرعية للاتصالات",
  "aria.submenu_account": "القائمة الفرعية للحساب",
  "aria.selected_page": "الصفحة المختارة",
  "aria.back_button": "إزالة الشخصية الأخيرة",
  "aria.accept_terms": "قبول الشروط",
  "aria.link_sound_video": "انتقل إلى إعدادات الصوت والفيديو",
  "aria.selfview": "فيديو Selfview",
  "aria.in-call": "في الدعوة",

  /** Kiosk */
  "kiosk.call_ended": "انتهت المكالمة ، يمكنك الآن إغلاق هذه الصفحة",

  /** Modal */
  "modal.close": "يغلق",
  "modal.cancel": "يلغي",
  "modal.confirm": "نعم",
  "modal.logout_confirmation.title": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  "modal.logout_confirmation.confirm": "نعم",
  "modal.logout_confirmation.cancel": "لا ، إلغاء",
};

export default translations;
