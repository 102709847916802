import React from "react";
import { connect } from "react-redux";
import { showModal } from "../actions/modalActions";
import { logout } from "../actions/userActions";
import NavItemMaterial from "../components/nav/NavItemMaterial";
import { IRootState } from "../models/reducerStates";

type Props = {
  anonymousAutologin: boolean;
  isAnonymous: boolean;
  text: Function;
  logout: Function;
  dispatch: Function;
};

class Logout extends React.Component<Props> {
  render() {
    const showConfirmationModal = () => {
      this.props.dispatch(
        showModal({
          title: this.props.text("modal.logout_confirmation.title"),
          showCancel: true,
          showConfirm: true,
          showClose: false,
          onConfirm: () => {
            this.props.dispatch(logout());
          },
          confirmLabel: this.props.text("modal.logout_confirmation.confirm"),
          cancelLabel: this.props.text("modal.logout_confirmation.cancel"),
        })
      );
    };

    return (
      !this.props.anonymousAutologin && (
        <NavItemMaterial
          icon="exit_to_app"
          text={
            this.props.isAnonymous === false
              ? this.props.text("settings.logout")
              : this.props.text("settings.logout.anonymous")
          }
          onClick={showConfirmationModal}
        />
      )
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isAnonymous: state.user.anonymous,
    anonymousAutologin: state.config.features.anonymous.autologin,
    text: state.config.text,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
