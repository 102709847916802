import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Webbapp",
  "common.loading": "Laddar",
  "common.call": "Ring",
  "common.answer": "Svara",
  "common.reject": "Avvisa",
  "common.callback": "Ring tillbaka",
  "common.callagain": "Ring igen",
  "common.yes": "Ja",
  "common.no": "Nej",
  "common.on": "PÅ",
  "common.off": "AV",
  "common.text": "Text",
  "common.abort": "Avbryt",
  "common.delete": "Ta bort",
  "common.cancel": "Avbryt",
  "common.missedcall": "Missat samtal från",
  "common.save": "Spara",
  "common.hangup": "Lägg på",
  "common.add": "Lägg till",
  "common.firstname": "Förnamn",
  "common.lastname": "Efternamn",
  "common.sipaddress": "SIP adress",
  "common.email": "E-post",
  "common.address": "Adress",
  "common.city": "Stad",
  "common.postcode": "Postnummer",
  "common.calling": "Ringer",
  "common.dialer": "Tonval",
  "common.back": "Tillbaka",
  "common.hide": "Dölj",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Ljud",
  "common.reset": "Återställ",
  "common.here": "här",
  "common.username": "Användarnamn",
  "common.password": "Lösenord",
  "common.send": "Skicka",
  "common.password1": "Lösenord",
  "common.password2": "Upprepa lösenord",
  "common.phonenumber": "Telefon",
  "common.sip": "SIP adress",
  "common.mobilenumber": "Mobil",
  "common.isdn": "ISDN nummer",
  "common.3gnumber": "3G nummer",
  "common.fax": "Fax",
  "common.username_helptext":
    "Mellan 5 och 100 tecken. Endast 0-9, .(punkt), -(bindestreck) och a-z(små bokstäver) är tillåtna.",
  "common.email_helptext": "Ogiltig e-post",
  "common.password1_helptext": "Mellan 6 och 20 tecken",
  "common.password2_helptext": "Lösenorden är inte lika",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Fältet innehåller ett eller flera ogiltiga tecken",
  "common.link": "länk",
  "common.Link": "Länk",
  "common.anonymous": "Anonym",
  "common.edit": "Redigera",
  "common.contact_information": "Detaljer",
  "common.view_message": "Se meddelandet",
  "common.close_message": "Stäng meddelandet",
  "common.required": "* Obligatoriskt fält",
  "common.to_startpage": "Till startsidan",
  "common.organisation": "Organisation",
  "common.permissions.heading": "Tjänsten behöver rättighet att använda din kamera och/eller mikrofon.",
  "common.permissions.subheading":
    'Du kan senare inför varje samtal bestämma om du vill ha video/ljud i samtalet eller inte. <br>Firefoxanvändare rekommenderas att "Kom ihåg detta beslut" för att undvika liknande frågor senare. <br>När du godkänner rättigheterna kommer kamera/mikrofon aktiveras i några sekunder för att sedan stängas av.',
  "common.app_for_os": "%APPNAME% för %OS%",

  /** Toolbar */
  "toolbar.hidetext": "Text är AV",
  "toolbar.showtext": "Text är PÅ",
  "toolbar.micoff": "Mikrofon är AV",
  "toolbar.micon": "Mikrofon är PÅ",
  "toolbar.audiooff": "Ljud är AV",
  "toolbar.audioon": "Ljud är PÅ",
  "toolbar.videooff": "Inkommande video är AV",
  "toolbar.videoon": "Inkommande video är PÅ",
  "toolbar.cameraoff": "Min video är AV",
  "toolbar.cameraon": "Min video är PÅ",
  "toolbar.fullscreenoff": "Helskärm är AV",
  "toolbar.fullscreenon": "Helskärm är PÅ",
  "toolbar.selfviewoff": "Egenbild är AV",
  "toolbar.selfviewon": "Egenbild är PÅ",
  "toolbar.hidedialpad": "Knappsats är PÅ",
  "toolbar.showdialpad": "Knappsats är AV",
  "toolbar.useaudioon": "Ring med ljud är PÅ",
  "toolbar.useaudiooff": "Ring med ljud är AV",
  "toolbar.usevideoon": "Ring med video är PÅ",
  "toolbar.usevideooff": "Ring med video är AV",
  "toolbar.usetexton": "Ring med text är PÅ",
  "toolbar.usetextoff": "Ring med text är AV",
  "toolbar.more": "Mer",
  "toolbar.hide": "Göm",
  "toolbar.togglevideo": "Visa video",
  "toolbar.toggletext": "Visa text",
  "toolbar.toSettings": "Gå till inställningningar för %",

  /** Titles */
  "titles.login": "Logga in",
  "titles.services": "Tjänster",
  "titles.contacts": "Mina kontakter",
  "titles.call": "Ring",
  "titles.directory": "Business Directory",
  "titles.history": "Historik",
  "titles.messages": "Meddelanden",
  "titles.settings": "Inställningar",
  "titles.userinformation": "Min profil",
  "titles.log": "Debug",
  "titles.about": "Information om system",
  "titles.registration": "Registrering",
  "titles.newpassword": "Få nytt lösenord",
  "titles.resetpassword": "Återställ lösenord",
  "titles.verifyaccount": "Aktivera konto",

  /** Menu */
  "menu.logo": "Till startsida",
  "menu.services": "Tjänster",
  "menu.servicesHeadline": "Tjänster",
  "menu.contacts": "Kontakter",
  "menu.mycontacts": "Mina kontakter",
  "menu.call": "Ring",
  "menu.directory": "Business Directory",
  "menu.history": "Historik",
  "menu.messages": "Meddelanden",
  "menu.settings": "Inställningar",
  "menu.logout": "Logga ut",
  "menu.userinformation": "Konto",
  "menu.userprofile": "Min profil",
  "menu.log": "Debug",
  "menu.about": "Information om system",

  /** Login screen */
  "login.loginanonymous.headline": "Ring utan inloggning",
  "login.loginanonymous": "Logga in anonymt",
  "login.headline": "Ring med inloggning",
  "login.login": "Logga in",
  "login.loginfailed": "Inloggning misslyckades",
  "login.resetpassword": "Har du glömt ditt lösenord?",
  "login.registration": "Vill du registrera dig?",
  "login.nowebrtc": "Din webbläsare stödjer inte realtidskommunikation med ljud och bild (WebRTC).",
  "login.debug": "Felsökningsläge",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Fel användarnamn eller lösenord",
  "error.usernameNotExists": "Användarnamnet existerar inte",
  "error.noservercontact": "Ingen kontakt med servern",
  "error.accountnotactive": "Kontot är inte aktiverat",
  "error.accountexpired": "Kontots giltighetstid har passerats",
  "error.tokenexpired": "Sessionen har gått ut, vänligen logga in igen",
  "error.tokeninvalid": "Säkerhetsnyckeln är ogiltig",
  "error.unknown": "Okänt fel",
  "error.unknownsender": "Okänd avsändare",
  "error.unknownsip": "Okänd SIP",
  "error.notextsent": "Ingen text skickades",
  "error.acquiremedia":
    "Tjänsten behöver rättighet att använda din kamera och/eller mikrofon. Du kan inför varje samtal bestämma om du vill ha video/ljud i samtalet.",
  "error.mediaNotReadable":
    "Kamera eller mikrofon är upptagna. Vänligen se till att de inte används av någon annan applikation och ladda om sidan.",
  "error.requestedDeviceNotFound":
    "Den begärda medieenheten hittades inte. Vänligen kontrollera att den är korrekt ansluten.",
  "error.cantcall": "Du kan inte genomföra samtal när du inte har kontakt med systemet",
  "error.cantcall_without_media": "Du kan inte genomföra samtal utan ljud, video eller text",
  "error.404": "404 - Sidan du försöker visa existerar inte",
  "error.unsupportedbrowser": "Din webbläsare stöds inte för tillfället. Vänligen använd en annan webbläsare.",
  "error.browseroutofdate":
    "Din webbläsare är inaktuell. Uppdatera den till den senaste versionen för att använda applikationen.",
  "error.browseroutofdate.ios.15.1": "Den här iOS-versionen stöds inte, uppdatera till iOS 15.2 eller senare.",
  "error.browserwarning.closeAndDontShowAgain": "Stäng och visa inte igen",
  "error.browserwarning.macos":
    "Om du upplever problem med att ringa samtal från din iPad och använder iOS version 15.1, uppgradera till iOS 15.2 eller senare.",

  /** User settings */
  "settings.white": "Vit",
  "settings.lightgrey": "Ljusgrå",
  "settings.darkgrey": "Mörkgrå",
  "settings.black": "Svart",
  "settings.yellow": "Gul",
  "settings.blue": "Blå",
  "settings.red": "Röd",
  "settings.green": "Grön",
  "settings.purple": "Lila",
  "settings.orange": "Orange",
  "settings.pink": "Rosa",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Sida vid sida",
  "settings.overunder": "Över och under",
  "settings.one": "Delat textfält",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Bell",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Classic",
  "settings.settings": "Inställningar",
  "settings.text": "Text",
  "settings.font": "Teckensnitt",
  "settings.fontsize": "Storlek",
  "settings.textboxlayout": "Layout för textfält",
  "settings.mycolors": "Mitt textfält",
  "settings.incomingcolors": "Inkommande textfält",
  "settings.sharedcolors": "Delat textfält",
  "settings.textcolors": "Textfärg",
  "settings.backgroundcolors": "Bakgrundsfärg",
  "settings.preview": "Förhandsvisning",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "settings.previewmytext": "Min text",
  "settings.previewincomingtext": "Inkommande text",
  "settings.previewbothtext": "Min text, Inkommande text",
  "settings.incomingcalls": "Inkommande samtal",
  "settings.messagebox": "Meddelande",
  "settings.autoanswer": "Använd textmeddelande för när du inte svarar",
  "settings.autoanswermessage": "Meddelandet",
  "settings.duringcalls": "I samtal",
  "settings.colors": "Färger",
  "settings.textformat": "Text",
  "settings.media": "Media",
  "settings.audio": "Ljud",
  "settings.video": "Video",
  "settings.ringtone": "Ringsignal",
  "settings.mediamail": "Meddelanden",
  "settings.about": "Systeminformation",
  "settings.logout": "Logga ut",
  "settings.logout.anonymous": "Avsluta",
  "settings.saved": "Inställningarna har sparats",
  "settings.media_explanation":
    "Bestäm vilket sätt du vill ringa på. Ifall en inställning är PÅ kan du alltid tillfälligt stänga av den i ett samtal, men ifall den är AV kan du inte sätta på den i ett samtal.",
  "settings.useaudio": "Ring med ljud",
  "settings.usevideo": "Ring med video",
  "settings.bandwidth.label": "Bandbredd",
  "settings.bandwidth.high": "Hög",
  "settings.bandwidth.medium": "Mellan",
  "settings.bandwidth.low": "Låg",

  /** Messages */
  "messages.messages": "Meddelanden",
  "messages.unreadmessages": "Olästa",
  "messages.readmessages": "Lästa",
  "messages.nomessages": "Du har inga meddelanden",
  "messages.deleteallread": "Ta bort alla lästa meddelanden",
  "messages.deleteallreadconfirm": "Ta bort alla lästa meddelanden?",
  "messages.messageAlert": "Meddelandet är raderat",

  /** Contacts */
  "contacts.contacts": "Mina kontakter",
  "contacts.addcontact": "Lägg till kontakt",
  "contacts.deletecontact": "Ta bort kontakt",
  "contacts.nosipgiven": "Vänligen fyll i en SIP-adress till kontakten",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Skriv nummer/namn här",
  "call.incomingtext": "Inkommande text",
  "call.outgoingtext": "Utgående text",
  "call.incoming": "Inkommande samtal från ",
  "call.unknownnumber": "okänt nummer",
  "call.unknownnumber_uppercase": "Okänt nummer",
  "call.endedcall": "avslutade samtalet",
  "call.yourejected": "Du avböjde samtalet",
  "call.noanswer": "Inget svar",
  "call.rejected": "kunde inte svara för tillfället",
  "call.notfound": "är inte nåbar",
  "call.toolongtext": "\n<Texten är för lång. Max 1000 tecken>\n",
  "call.notificationbody": "Klicka här för att svara",
  "call.videoTextDisabled": "Text och inkommande video är AV",
  "call.writeHere": "Skriv här...",
  "call.receiveHere": "Här visas inkommande text",
  "call.establishing": "Samtalet etableras...",

  /** History screen */
  "history.history": "Historik",

  /** Reset password */
  "resetpassword.processing": "Bearbetar ...",
  "resetpassword.completed": "Återställning av lösenord är slutfört",
  "resetpassword.email_or_username": "E-post eller användarnamn",
  "resetpassword.title": "Återställ lösenord",
  "resetpassword.explanation":
    "Skriv in din e-post eller ditt användarnamn så skickas en länk till den givna e-posten eller den e-post kopplad till användarnamnet. Genom länken kan du återstalla ditt lösenord.",
  "resetpassword.explanation_complete": "Du kommer inom kort att få ett e-postmeddelande med vidare instruktioner.",
  "resetpassword.invalid_link": "Ogiltigt återställningslänk",
  "resetpassword.password_changed": "Ditt lösenord har ändrats",
  "resetpassword.enter_new_password": "Ange nytt lösenord",
  "resetpassword.button_reset": "Spara",
  "resetpassword.password": "Lösenord",
  "resetpassword.password_repeat": "Upprepa lösenord",
  "resetpassword.password_requirement": "Lösenordet måste vara mellan 6 och 20 tecken",
  "resetpassword.error": "Det var inte möjligt att återställa lösenordet. Försök igen eller kontakta din administratör",
  "resetpassword.link_too_old": "länken är för gammal",

  /** Registration */
  "registration.error":
    "Det var inte möjligt att slutföra registreringen. Försök igen eller kontakta din administratör. ",
  "registration.title": "Registrering",
  "registration.processing": "Bearbetar ...",
  "registration.not_enabled": "Registrering är inte tillgängligt.",
  "registration.completed_headline": "Du har blivit registrerad",
  "registration.completed": "Din registrering är klar.",
  "registration.completed_admin_approval": "Ditt konto behöver godkännas av en administratör innan du kan logga in.",
  "registration.completed_verification":
    "Ditt konto behöver verifieras innan du kan logga in. Du kommer inom kort att få ett mail med instruktioner.",
  "registration.completed_link": "/",
  "registration.username_exists": "Användarnamnet finns redan registrerat. Var vänlig och ange ett annat användarnamn.",
  "registration.email_exists": "E-posten finns redan registrerad. Var vänlig och ange en annan e-post.",
  "registration.sip_exists": "SIP adressen finns redan registrerad. Var vänlig och ange en annan SIP adress.",
  "registration.license1": "Ja, jag har läst ",
  "registration.license_link_text": "informationen om behandling av personuppgifter",
  "registration.license2": " och accepterar villkoren för detta",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Acceptera villkoren ifall du vill registrera dig",
  "registration.register": "Registrera",

  /** Verify Account */
  "verifyaccount.completed.title": "Ditt konto är aktiverat!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Något gick fel! Försök att registrera kontot igen.",
  "verifyaccount.error.expired": "Verifieringslänken har upphört att gälla. Försök att registrera kontot igen.",
  "verifyaccount.registration": "Vill du registrera dig?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Redigera konto",
  "changeuser.titlePassword": "Ändra lösenord",
  "changeuser.newPassword": "Nytt lösenord",
  "changeuser.error":
    "Det var inte möjligt att uppdatera kontot. Var vänligen försök igen eller kontakta din administratör.",
  "changeuser.complete": "Konto uppdaterat",
  "changeuser.processing": "Bearbetar ...",
  "changeuser.username_exists": "Användarnamnet finns redan registrerat. Var vänlig och ange ett annat användarnamn.",
  "changeuser.email_exists": "E-posten finns redan registrerad. Var vänlig och ange en annan e-post.",
  "changeuser.email_empty": "E-post är obligatorisk. Var vänlig och ange en e-post.",
  "changeuser.sip_exists": "SIP adressen finns redan registrerad. Var vänlig och ange en annan SIP adress.",

  /** Network */
  "network.networkError": "Får ingen kontakt med servern",
  "network.establishing": "Försöker upprätta kontakt med servern...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Mörkt tema",
  "accessibility.originaltheme": "Originaltema",

  /** About */
  "about.user": "Användarnamn",
  "about.sip": "SIP-adress",
  "about.server": "Server IP",
  "about.version": "Version",
  "about.browser": "Webbläsare",
  "about.browserversion": "Webbläsare version",
  "about.os": "Operativsystem",

  /** Aria */
  "aria.mainmenu": "Huvudmeny",
  "aria.submenu_contacts": "Undermeny för kontakter",
  "aria.submenu_account": "Undermeny för konto",
  "aria.selected_page": "Välj sida",
  "aria.back_button": "Ta bort sista tecknet",
  "aria.accept_terms": "Acceptera villkoren",
  "aria.link_sound_video": "Gå till inställningarna för ljud och video",
  "aria.selfview": "Egenbild video",
  "aria.in-call": "I samtal",

  /** Kiosk */
  "kiosk.call_ended": "Samtalet har avslutats. Du kan nu stänga ner den här sidan.",

  /** Modal */
  "modal.close": "Stäng",
  "modal.cancel": "Avbryt",
  "modal.confirm": "OK",
  "modal.logout_confirmation.title": "Vill du verkligen logga ut?",
  "modal.logout_confirmation.confirm": "Ja",
  "modal.logout_confirmation.cancel": "Nej, avbryt",
};

export default translations;
