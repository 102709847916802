import React from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userActions";
import { withRouter, Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import { SettingsNavItemMaterial } from "../components/settingsNav/SettingsNavItemMaterial";
import Colors from "./settings/Colors";
import TextFormat from "./settings/TextFormat";
import Ringtone from "../containers/settings/Ringtone";
import Media from "../containers/settings/Media";
import Mediamail from "../containers/settings/Mediamail";
import About from "../containers/settings/About";
import ErrorAlert from "../components/ErrorAlert";
import "./Settings.css";
import { toggleContrast } from "../actions/accessibilityActions";
import { APP } from "../actions/actionTypes";

class Settings extends React.Component {
  hasJustRotated = false; /* If the device has just rotated then width and height is switched, maybe will be changed in future */

  availableRoutes = ["duringcalls", "ringtone", "mediamail", "about"];
  availableSubRoutes = ["colors", "textformat", "media"];

  renderNewOrientation = () => {
    this.hasJustRotated = true;
    this.forceUpdate();
  };

  componentDidMount() {
    window.addEventListener("orientationchange", this.renderNewOrientation);
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.renderNewOrientation);
  }

  logout = event => {
    event.preventDefault();
    //
    // Ensure that the user is redirected to the default location when loging out.
    // (the SecureRoute component will otherwise redirect to the last visisted location)
    //
    this.props.history.push("/");
    this.props.logout();
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    var smallScreenMode = false;
    if ((!this.hasJustRotated && window.innerWidth < 992) || (this.hasJustRotated && window.innerHeight < 992)) {
      smallScreenMode = true;
    } else if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      smallScreenMode = true;
    }
    this.hasJustRotated = false;

    var settingFor = this.props.match.params.settingFor;
    var settingForFor = this.props.match.params.settingForFor;

    if (settingFor !== "" && settingFor !== undefined && this.availableRoutes.indexOf(settingFor) === -1) {
      return <Redirect to={"/settings"} />;
    } else if (settingFor === "duringcalls" && this.availableSubRoutes.indexOf(settingForFor) === -1) {
      return <Redirect to={"/settings"} />;
    }

    if (this.props.location.pathname === "/settings" || this.props.location.pathname === "/settings/duringcalls") {
      settingFor =
        this.props.text_enabled || (this.props.media_enabled && this.props.configuredMedia > 0)
          ? "duringcalls"
          : "about";
      if (this.props.text_enabled || (this.props.media_enabled && this.props.configuredMedia > 0)) {
        settingForFor = this.props.text_enabled ? "colors" : "media";
      }
    }

    var showMenu =
      this.props.location.pathname === "/settings" || this.props.location.pathname === "/settings/duringcalls";
    var h1text = "settings." + (settingForFor !== undefined ? settingForFor : settingFor);

    var media = "";
    if (this.props.configuredMedia === 3) {
      media = this.props.text("settings.audio") + " / " + this.props.text("settings.video");
    } else if (this.props.configuredMedia === 2) {
      media = this.props.text("settings.video");
    } else if (this.props.configuredMedia === 1) {
      media = this.props.text("settings.audio");
    }

    var heading = settingForFor === "media" ? media : this.props.text(h1text);
    var pageTitle = [heading];
    if (settingFor) {
      pageTitle.push(this.props.text("settings.settings"));
    }

    const currentPageTitle =
      typeof this.props.currentPageTitle === "string" ? [this.props.currentPageTitle] : this.props.currentPageTitle;

    if (currentPageTitle.join(" - ") !== pageTitle.join(" - ") + " - " + this.props.text("common.appOwner")) {
      this.props.setPageTitle(pageTitle);
    }

    var screenClass = smallScreenMode ? "settings-menu-small" : "settings-menu-larger";

    var showErrorAlert = this.props.showErrorAlert;
    return (
      <div className="display-flex height-100-vh">
        <ul className={"no-ul-css " + screenClass + (!showMenu && smallScreenMode ? " display-none" : "")}>
          {(this.props.text_enabled || (this.props.media_enabled && this.props.configuredMedia > 0)) && (
            <SettingsNavItemMaterial
              icon="call"
              text={this.props.text("settings.duringcalls")}
              textEnabled={this.props.text_enabled}
              mediaConfigured={this.props.media_enabled && this.props.configuredMedia > 0}
              mediaText={media}
              alltext={this.props.text}
              route="duringcalls"
              chosenRoute={settingForFor ? settingFor + "/" + settingForFor : settingFor}
              haveSubmeny={true}
            />
          )}
          {this.props.audio_enabled && (
            <SettingsNavItemMaterial
              icon="audiotrack"
              text={this.props.text("settings.ringtone")}
              route="ringtone"
              chosenRoute={this.props.match.params.settingFor}
            />
          )}

          {this.props.message_enabled && (
            <SettingsNavItemMaterial
              icon="mail_outline"
              text={this.props.text("settings.mediamail")}
              route="mediamail"
              chosenRoute={this.props.match.params.settingFor}
            />
          )}

          <SettingsNavItemMaterial
            icon="info"
            text={this.props.text("settings.about")}
            route="about"
            chosenRoute={this.props.match.params.settingFor}
          />

          {!this.props.showThemeToggleInMenu && (
            <SettingsNavItemMaterial
              icon="settings_brightness"
              text={
                this.props.isHighContrast
                  ? this.props.text("accessibility.originaltheme")
                  : this.props.text("accessibility.darktheme")
              }
              route={settingForFor ? settingFor + "/" + settingForFor : settingFor}
              onClick={() => {
                this.props.toggleContrast();
              }}
            />
          )}
          {!this.props.isLogoutLinkEnabled && !this.props.anonymousAutologin && (
            <SettingsNavItemMaterial
              icon="exit_to_app"
              text={
                this.props.isAnonymous === false
                  ? this.props.text("settings.logout")
                  : this.props.text("settings.logout.anonymous")
              }
              onClick={this.logout}
            />
          )}
        </ul>

        <div className={"settings-content" + (showMenu && smallScreenMode ? " display-none" : "")}>
          {showErrorAlert && <ErrorAlert text={this.props.offlineText} />}
          <h1>{heading}</h1>

          {this.props.settingsSaved && (
            <div className="alert alert-success col-md-12 col-lg-12" role="alert">
              {this.props.text("settings.saved")}
            </div>
          )}

          {(settingForFor === "colors" || settingFor === "") && <Colors smallScreenMode={smallScreenMode} />}

          {settingForFor === "textformat" && <TextFormat smallScreenMode={smallScreenMode} />}

          {settingForFor === "media" && <Media smallScreenMode={smallScreenMode} />}

          {settingFor === "ringtone" && <Ringtone smallScreenMode={smallScreenMode} />}

          {settingFor === "mediamail" && <Mediamail smallScreenMode={smallScreenMode} />}

          {settingFor === "about" && <About smallScreenMode={smallScreenMode} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loading: false,
    isLogoutLinkEnabled: state.config.layout.showLogoutInMenu,
    anonymousAutologin: state.config.features.anonymous.autologin,
    isAnonymous: state.user.anonymous,
    text: state.config.text,
    settingsSaved: state.setting.settingsSaved,
    showThemeToggleInMenu: state.config.layout.showThemeToggleInMenu,
    isHighContrast: state.accessibility.isHighContrast,
    message_enabled: state.config.features.settings.allowChangeMessage,
    text_enabled: state.config.features.settings.allowChangeText,
    audio_enabled: state.config.features.settings.allowChangeRingtone,
    media_enabled: state.config.features.settings.allowChangeMedia,
    configuredMedia: state.config.call.audio * 1 + state.config.call.video * 2,
    currentPageTitle: state.accessibility.pageTitle,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleContrast: () => {
      return dispatch(toggleContrast());
    },
    logout: () => {
      dispatch(logout());
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
