import ResetPasswordService from "../services/resetPasswordService";
import { PASSWORD } from "./actionTypes";

export function reset(emailOrUsername) {
  return (dispatch, getstate) => {
    dispatch({ type: PASSWORD.RESET_INIT });

    var params = {
      lang: encodeURIComponent(navigator.language),
      orgid: encodeURIComponent(getstate().config.server.organisationId),
    };
    if (emailOrUsername.indexOf("@") < 0) {
      params = {
        ...params,
        username: encodeURIComponent(emailOrUsername),
      };
    } else {
      params = {
        ...params,
        email: encodeURIComponent(emailOrUsername),
      };
    }

    ResetPasswordService.requestResetPassword(params, getstate().config.server.url, dispatch)
      .done(() => {
        dispatch({ type: PASSWORD.RESET_SUCCESS });
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        if (xhr.status === 404) {
          thrownError = "Not Found";
        }

        dispatch({
          type: PASSWORD.RESET_ERROR,
          payload: thrownError,
        });
        dispatch({ type: PASSWORD.RESET_RECAPTCHA_INVALID });
      });
  };
}

export function setNewPassword(uid, key, password) {
  return (dispatch, getstate) => {
    dispatch({ type: PASSWORD.NEW_INIT });

    var params = {
      key: encodeURIComponent(key),
      password: encodeURIComponent(password),
      uid: encodeURIComponent(uid),
    };

    ResetPasswordService.setNewPassword(params, getstate().config.server.url, dispatch)
      .done(() => {
        dispatch({ type: PASSWORD.NEW_SUCCESS });
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        dispatch({ type: PASSWORD.NEW_ERROR, payload: thrownError });
      });
  };
}
