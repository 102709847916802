export enum CALL_STATE {
  CALLING = "CALLING",
  INCOMING_CALL = "INCOMING_CALL",
  ACCEPTED = "ACCEPTED",
  ESTABLISHING = "ESTABLISHING",
  COMPLETED = "COMPLETED",
  BUSY = "BUSY",
  REJECTED_LOCAL = "REJECTED_LOCAL",
  REJECTED_REMOTE = "REJECTED_REMOTE",
  CANCELED_LOCAL = "CANCELED_LOCAL",
  CANCELED_REMOTE = "CANCELED_REMOTE",
  FAILED = "FAILED",
  TERMINATED_LOCAL = "TERMINATED_LOCAL",
  TERMINATED_REMOTE = "TERMINATED_REMOTE",
  UNREGISTERED_NUMBER = "UNREGISTERED_NUMBER",
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  TOKEN_INVALID = "TOKEN_INVALID",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  UNKNOWN_NUMBER = "UNKNOWN_NUMBER",
}
