import { ALERT } from "../actions/actionTypes";
import IAlert from "../models/IAlert";

const initialState: IAlert[] = [];

const alertReducer = (state: IAlert[] = initialState, action: any) => {
  switch (action.type) {
    case ALERT.ADD:
      const alert: IAlert = {
        id: action.payload.id,
        type: action.payload.type,
        message: action.payload.message,
        showSpinner: action.payload.showSpinner,
        flash: action.payload.flash,
      };

      if (action.payload.append) {
        const existingAlertIndex = state.findIndex((alert: IAlert) => alert.id === action.payload.id);

        if (existingAlertIndex !== -1) {
          state[existingAlertIndex] = alert;
          state = [...state];
        } else {
          state = [...state, alert];
        }
      } else {
        state = [...initialState, alert];
      }
      break;

    case ALERT.REMOVE:
      state = state.filter((alert: IAlert) => alert.id !== action.payload.id);
      break;

    case ALERT.CLEAR_ALL:
      state = initialState;
      break;

    case "@@router/LOCATION_CHANGE":
      state = state.filter(alert => !alert.flash);
      break;

    default:
      break;
  }

  return state;
};

export default alertReducer;
