import $ from "jquery";
import { refreshProperties } from "./userActions";
import UserService from "../services/userService";
import { CHANGE_USER } from "./actionTypes";

export function init() {
  return (dispatch, getstate) => {
    dispatch({
      type: CHANGE_USER.LOADING,
      payload: {
        loading: true,
      },
    });
  };
}

export function save(values) {
  return (dispatch, getstate) => {
    if (getstate().user.anonymous.enabled) {
      return;
    }

    dispatch({ type: CHANGE_USER.INIT });

    var posts = [];
    var server = getstate().config.server.url;
    var securityToken = getstate().user.securityToken;

    /*
     * There exists a 'user/setProperties' in the server which can set all properties at once but
     * it has unclear parameters/payload. TODO: switch to that when there is time for optimazation
     */

    var changeUser = getstate().changeUser;
    var properties = [
      {
        key: "PASSWORD",
        value: values.password1,
        formerValue: changeUser.password.value,
      },
      {
        key: "FORENAME",
        value: values.firstname,
        formerValue: changeUser.firstname.value,
      },
      {
        key: "SURNAME",
        value: values.lastname,
        formerValue: changeUser.lastname.value,
      },
      {
        key: "EMAIL",
        value: values.email,
        formerValue: changeUser.email.value,
      },
      {
        key: "ADDRESS",
        value: values.address,
        formerValue: changeUser.address.value,
      },
      {
        key: "POSTCODE",
        value: values.postcode,
        formerValue: changeUser.postcode.value,
      },
      { key: "CITY", value: values.city, formerValue: changeUser.city.value },
      {
        key: "PHONE_NUMBER",
        value: values.mobilenumber,
        formerValue: changeUser.mobilenumber.value,
      },
      {
        key: "ISDN_NUMBER",
        value: values.isdnnumber,
        formerValue: changeUser.isdnnumber.value,
      },
      {
        key: "GGG_NUMBER",
        value: values.gggnumber,
        formerValue: changeUser.gggnumber.value,
      },
      {
        key: "FAX_NUMBER",
        value: values.faxnumber,
        formerValue: changeUser.faxnumber.value,
      },
    ];

    for (var i = 0; i < properties.length; i++) {
      if (changed(properties[i].value, properties[i].formerValue)) {
        posts.push(
          UserService.setUserProperty(properties[i].key, properties[i].value, server, securityToken, dispatch)
        );
      }
    }

    $.when(...posts)
      .then(() => {
        dispatch({ type: CHANGE_USER.SUCCESS });
        dispatch(refreshProperties());
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        dispatch(refreshProperties());
        dispatch({
          type: CHANGE_USER.ERROR,
          payload: thrownError,
        });
      });
  };
}

function changed(val1, val2) {
  // eslint-disable-next-line
  return val1 == val2 || val1 === val2 ? false : true;
}
