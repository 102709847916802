import { MESSAGES } from "./actionTypes";
import MessageService from "../services/messagesService";

const messagesLoading = () => ({
  type: MESSAGES.LOADING,
  payload: {},
});

const messagesLoaded = messages => ({
  type: MESSAGES.LOADED,
  payload: {
    messages: messages,
  },
});

export function loadMessages(silent = false) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    if (!silent) {
      dispatch(messagesLoading());
    }

    MessageService.load(getstate().config.server.url, securityToken, dispatch).then(messages => {
      dispatch(messagesLoaded(messages));
    });
  };
}

export function readMessage(messageNumber) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    MessageService.markAsRead(messageNumber, getstate().config.server.url, securityToken, dispatch).then(() => {
      dispatch(loadMessages(securityToken, true));
    });
  };
}

export function deleteMessage(messageNumber) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    MessageService.delete(messageNumber, getstate().config.server.url, securityToken, dispatch)
      .then(() => {
        dispatch(loadMessages(securityToken, true));
      })
      .catch(({ xhr, ajaxOptions, thrownError }) => {
        //
        // jQuery ajax will throw an exception when the data received from the server
        // is not valid json (the dataType of the ajax request is set to json).
        // In the case of this delete message, no json is returned in the response from
        // the server. This is expected behaviour.
        //
        if (xhr && xhr.status === 200) {
          dispatch(loadMessages(securityToken, true));
        }
      });
  };
}

export function deleteReadMessages(securityToken) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    MessageService.deleteReadMessages(getstate().config.server.url, securityToken, dispatch)
      .then(() => {
        dispatch(loadMessages(securityToken, true));
      })
      .catch(({ xhr, ajaxOptions, thrownError }) => {
        //
        // jQuery ajax will throw an exception when the data received from the server
        // is not valid json (the dataType of the ajax request is set to json).
        // In the case of this delete message, no json is returned in the response from
        // the server. This is expected behaviour.
        //
        if (xhr && xhr.status === 200) {
          dispatch(loadMessages(securityToken, true));
        }
      });
  };
}

export const deletedMessageAlert = () => ({
  type: MESSAGES.DELETED_ALERT,
});

export const clearMessageAlert = () => ({
  type: MESSAGES.CLEAR_ALERT,
});
