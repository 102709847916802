import ContactsService from "../services/contactsService";
import { SERVICES } from "./actionTypes";

/**
 * Loads the service/persistant/sticky contacts from the server.
 *
 * @param {String}  securityToken   The security token for the user.
 */
export function loadServices(securityToken = null) {
  return (dispatch, getstate) => {
    return new Promise((resolve, reject) => {
      if (!securityToken) {
        securityToken = getstate().user.securityToken;
      }

      dispatch({
        type: SERVICES.LOADING,
        payload: {},
      });

      ContactsService.getPersistantContacts(getstate().config.server.url, securityToken, dispatch)
        .done(data => {
          var services = data.contactList.map((item, index) => {
            return {
              id: item.id,
              name: item.fname,
              imageUrl: item.imageUrl,
              sipaddress: item.sipaddress,
            };
          });

          dispatch({
            type: SERVICES.LOADED,
            payload: { services: services },
          });

          resolve({ services: services });
        })
        .fail(e => {
          console.warn("Could not get persistant contacts from server");
          reject(e);
        });
    });
  };
}
