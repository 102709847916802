import React from "react";
import { connect } from "react-redux";
import { APP } from "../actions/actionTypes";
import "./Log.css";

class Log extends React.Component {
  componentDidMount() {}

  debug = () => {
    var items = [];

    for (var key in this.props.state) {
      if (this.props.state.hasOwnProperty(key)) {
        var name = key;
        var value = this.props.state[key];
        items.push(
          <tr key={name}>
            <td>{name}</td>
            <td>{value}</td>
          </tr>
        );
      }
    }

    return (
      <table className="table table-condensed table-striped table-bordered table-sm thead-light font-size-smaller">
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      // eslint-disable-next-line
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  log = () => {
    var items = [];

    this.props.log.forEach(element => {
      items.push(
        <tr key={this.uuidv4()}>
          <td>{element.time}</td>
          <td>{element.msg}</td>
        </tr>
      );
    });

    items.reverse();

    return (
      <table className="table table-condensed table-striped table-bordered table-sm thead-light font-size-smaller">
        <thead>
          <tr>
            <th>Time</th>
            <th className="width-100-procent">Value</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  };

  logconsole = () => {
    var items = [];

    if (!window.log) return;

    window.log.forEach(element => {
      items.push(
        <tr key={this.uuidv4()}>
          <td className={element.color + "-color"}>{element.time}</td>
          <td>
            <pre className={element.color + "-color"}>{element.msg}</pre>
          </td>
        </tr>
      );
    });

    items.reverse();

    return (
      <table className="table table-condensed table-striped table-bordered table-sm thead-light font-size-smaller">
        <thead>
          <tr>
            <th>Time</th>
            <th className="width-100-procent">Value</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  };

  render() {
    var debugClass = "nav-link";
    var logClass = "nav-link";
    var consoleClass = "nav-link";

    if (this.props.view === 1) debugClass = debugClass + " active";
    if (this.props.view === 2) logClass = logClass + " active";
    if (this.props.view === 3) consoleClass = consoleClass + " active";

    return (
      <div className="log-container">
        <ul className="nav nav-tabs margin-bottom-0_1em">
          <li className="nav-item">
            <button className={consoleClass} onClick={() => this.props.changeview(3)}>
              Console
            </button>
          </li>
          <li className="nav-item">
            <button className={logClass} onClick={() => this.props.changeview(2)}>
              Log
            </button>
          </li>
          <li className="nav-item">
            <button className={debugClass} onClick={() => this.props.changeview(1)}>
              Debug
            </button>
          </li>
        </ul>

        {this.props.view === 1 && this.debug()}

        {this.props.view === 2 && this.log()}

        {this.props.view === 3 && this.logconsole()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    state: state.debug.state,
    log: state.debug.log,
    view: state.debug.view,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeview: view => {
      dispatch({
        type: APP.DEBUGVIEW,
        payload: view,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Log);
