import { CONFIG, APP, USER } from "./actionTypes";
import UserSession from "../misc/usersession";
import UserService from "../services/userService";
import { loadSettings } from "./settingActions";
import { localIpAquired, checkMediaPermissions } from "./userActions";
import { addAlert, clearAlerts, Types as alertTypes } from "./alertActions";
import { register } from "./sipActions";
import Browser from "../misc/browser";

const configSystemLoaded = (systemConfig, config) => ({
  type: CONFIG.SYSTEM_LOADED,
  payload: {
    systemConfig: systemConfig,
    config: config,
  },
});

const configInitCompleted = () => ({
  type: CONFIG.INIT_COMPLETED,
  payload: {},
});

export const loginSuccess = user => ({
  type: USER.LOGIN_SUCCESS,
  payload: {
    user: user,
  },
});

const loginFailed = (error, errorCode = -1) => {
  let type;

  switch (errorCode) {
    case 1006:
      type = USER.LOGIN_FAILED_TOKEN_INVALID;
      break;
    case 1007:
      type = USER.LOGIN_FAILED_TOKEN_EXPIRED;
      break;
    default:
      type = USER.LOGIN_FAILED;
      break;
  }

  return {
    type,
    payload: {
      loginerror: error,
      loginerrorCode: errorCode,
    },
  };
};

const configDebugEnable = () => ({
  type: CONFIG.DEBUG_ENABLE,
  payload: {},
});

export function bootApplication() {
  return async (dispatch, getState) => {
    dispatch({
      type: APP.SET_LOADING,
      payload: true,
    });

    document.documentElement.lang = getState().accessibility.locale;

    Browser.isSupported()
      .then(() => {
        dispatch({
          type: CONFIG.SET_BROWSER_SUPPORT,
          payload: {
            webrtc: true,
            supported: true,
            message: null,
          },
        });
      })
      .catch(e => {
        dispatch({
          type: CONFIG.SET_BROWSER_SUPPORT,
          payload: {
            webrtc: !e.error || e.error === "IN_GREYLIST",
            supported: !e.error || e.error === "IN_GREYLIST",
            message: e.message,
          },
        });
      })
      .finally(() => {
        if (UserSession.isEstablished()) {
          const user = UserSession.getUser();
          const systemConfig = UserSession.getSystemConfig();
          const localIpAddress = UserSession.getLocalIpAddress();
          dispatch(loginSuccess(user));
          dispatch(configSystemLoaded(systemConfig, getState().config));
          dispatch(loadSettings());
          dispatch(localIpAquired(localIpAddress));

          // Check if we already have permission to the devices we required in the config
          dispatch(
            checkMediaPermissions(getState().config.call.audio, getState().config.call.video, true, systemConfig, user)
          )
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              dispatch(configInitCompleted());
              dispatch({
                type: APP.SET_LOADING,
                payload: false,
              });
            });
        } else {
          const anonymous = getState().config.features.anonymous;

          if (anonymous.autologin) {
            dispatch(login(anonymous.user, anonymous.password));
          }

          dispatch({
            type: APP.SET_LOADING,
            payload: false,
          });
        }
      });
  };
}

export function login(username, password, token) {
  return (dispatch, getState) => {
    dispatch(clearAlerts());

    const success = ({ user, systemConfig, localIpAddress }) => {
      user.anonymous =
        getState().config.features.anonymous.enabled && username === getState().config.features.anonymous.user;
      systemConfig.SYS_ALL_WEBRTC_SERVER_URI = systemConfig.SYS_WEBRTC_SERVER_URI.split(",");
      systemConfig.SYS_WEBRTC_SERVER_URI = loadBalance(systemConfig.SYS_WEBRTC_SERVER_URI.split(","));
      UserSession.setUser(user);
      UserSession.setSystemConfig(systemConfig);
      UserSession.setLocalIpAddress(localIpAddress);
      dispatch(configSystemLoaded(systemConfig, getState().config));
      dispatch(loginSuccess(user));
      dispatch(loadSettings());
      dispatch(localIpAquired(localIpAddress));
      dispatch(
        checkMediaPermissions(getState().config.call.audio, getState().config.call.video, true, systemConfig, user)
      ).finally(() => {
        dispatch(configInitCompleted());
      });
    };

    const failure = ({ errorCode, xhr }) => {
      dispatch(
        addAlert({
          message: `${getState().config.text("login.loginfailed")} (${errorMessage(
            errorCode,
            getState().config.text
          )})`,
          type: alertTypes.ERROR,
          id: "userError",
        })
      );

      UserSession.removeUser();
      dispatch(loginFailed(errorMessage(errorCode, getState().config.text), errorCode));
    };

    UserService.login(username, password, token, getState().config.server.url, dispatch)
      .then(success)
      .catch(failure);
  };
}

export function enableDebug() {
  return (dispatch, getstate) => {
    dispatch(configDebugEnable());
  };
}

const loadBalance = servers => {
  //sipjs loadbalancing doesnt work with freeswitch, because uri
  //depends on which wsserver that is used. Just another example of
  //Freeswitch problems ...
  let index = Math.floor(Math.random() * servers.length);
  return servers[index];
};

export function switchFreeswitch() {
  return (dispatch, getstate) => {
    const systemConfig = UserSession.getSystemConfig();
    const currentIndex = systemConfig.SYS_ALL_WEBRTC_SERVER_URI.indexOf(systemConfig.SYS_WEBRTC_SERVER_URI);
    systemConfig.SYS_WEBRTC_SERVER_URI =
      currentIndex < systemConfig.SYS_ALL_WEBRTC_SERVER_URI.length - 1
        ? systemConfig.SYS_ALL_WEBRTC_SERVER_URI[currentIndex + 1]
        : systemConfig.SYS_ALL_WEBRTC_SERVER_URI[0];
    UserSession.setSystemConfig(systemConfig);
    dispatch(configSystemLoaded(systemConfig, getstate().config));
    dispatch(register());
  };
}

const errorMessage = (errorCode, text) => {
  switch (errorCode) {
    case -7:
      return text("error.accountexpired");
    case -1:
    case 1001:
      return text("error.usernameorpassword");
    case -2:
      return text("error.noservercontact");
    case 1002:
      return text("error.accountnotactive");
    case 1006:
      return text("error.tokeninvalid");
    case 1007:
      return text("error.tokenexpired");
    default:
      return text("error.unknown") + " " + errorCode;
  }
};

export const bindLocalStream = stream => ({
  type: CONFIG.BIND_LOCAL_STREAM,
  payload: {
    stream: stream,
  },
});

export function unBindLocalStream() {
  return (dispatch, getstate) => {
    let localStream = getstate().config.localStream;
    if (localStream) {
      if (localStream.stop) {
        localStream.stop();
      } else {
        localStream.getTracks().forEach(function(track) {
          if (track && track.stop) {
            track.stop();
          }
        });
      }
      localStream = null;
    }
    dispatch({
      type: CONFIG.UNBIND_LOCAL_STREAM,
    });
  };
}
