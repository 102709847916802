import { APP } from "../actions/actionTypes";
import { playMessageAlert, setPageTitle } from "../misc/helpers";
import { IAccessibilityState } from "../models/reducerStates";
var FaviconNotification = require("favicon-notification");

let initialState: IAccessibilityState = {
  locale: "en",
  screenIsSmall: false,
  isInBackground: false,
  isHighContrast: false,
  navbarIsExpanded: false,
  pageTitle: "",
  showPageTitleBell: false,
};

const accessibilityReducer = (state: IAccessibilityState = initialState, action: any) => {
  switch (action.type) {
    case APP.SET_LOCALE:
      state = {
        ...state,
        locale: action.payload,
      };
      break;
    case APP.SCREEN_IS_SMALL:
      state = {
        ...state,
        screenIsSmall: action.payload,
      };
      break;
    case APP.IS_IN_BACKGROUND:
      state = {
        ...state,
        isInBackground: action.payload,
      };
      break;
    case APP.UPDATE_PAGE_TITLE:
      if (action.payload.title) {
        state.pageTitle = action.payload.title;
      }

      let title = state.pageTitle;
      if (action.payload.showBell) {
        playMessageAlert();
        FaviconNotification.add();
      } else {
        FaviconNotification.remove();
      }

      setPageTitle(title);
      break;
    case APP.TOGGLE_CONTRAST:
      state = {
        ...state,
        isHighContrast: !state.isHighContrast,
      };
      break;
    case APP.TOGGLE_NAVBAR:
      state = {
        ...state,
        navbarIsExpanded: !state.navbarIsExpanded,
      };
      break;
    case "@@router/LOCATION_CHANGE":
      state = {
        ...state,
        navbarIsExpanded: false,
      };
      break;
    default:
      break;
  }
  return state;
};

export default accessibilityReducer;
