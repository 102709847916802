import jwtDecode from "jwt-decode";
import qs from "querystringify";

const hasQueryParam = (name, params) => {
  if (Object.keys(params).includes) {
    return Object.keys(params).includes(name);
  } else {
    if (name.length > Object.keys(params).length) {
      return false;
    } else {
      return Object.keys(params).indexOf(name) !== -1;
    }
  }
};

const validateRequiredParam = (name, params) => {
  if (hasQueryParam(name, params)) {
    return;
  } else {
    throw new Error(`Missing required paramter '${name}'`);
  }
};

const parseQueryParams = token => {
  try {
    // Attempt to decode the querystring as a JWT
    const { to, username, ivr = null, ref = null } = jwtDecode(token);
    return { to, username, ivr, ref };
  } catch (e) {
    // Not a valid JWT... Try to decode it as a Base64 encoded string
    try {
      const queryString = atob(token);
      const params = qs.parse(queryString);
      validateRequiredParam("t", params);

      return {
        to: params.t,
        username: hasQueryParam("u", params) ? params.u : null,
        password: hasQueryParam("p", params) ? params.p : null,
        ivr: hasQueryParam("i", params) ? params.i : null,
        ref: hasQueryParam("r", params) ? params.r : null,
      };
    } catch (e) {
      throw new Error("Invalid Token");
    }
  }
};

export default parseQueryParams;
