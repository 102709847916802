import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { APP, CHANGE_USER } from "../actions/actionTypes";
import { save } from "../actions/changeUserActions";
import UserService from "../services/userService";

import Button from "../components/buttons/Button";
import InputText from "../components/InputText";
import Loading from "../components/Loading";

const validate = (values, props) => {
  const errors = {};
  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    var emailExists = props.text("changeuser.email_exists");

    if (values.email !== props.email.value && !(values.email === "" || values.email === undefined)) {
      UserService.validateByKey("email", values.email, props.server, dispatch)
        .done(() => {
          resolve();
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          if (xhr.status !== 0) {
            reject({
              email: emailExists,
            });
          } else {
            resolve();
          }
        });
    } else {
      resolve();
    }
  });
};

class ChangeUser extends React.Component {
  componentDidMount() {
    this.props.clear();
    this.props.setPageTitle(this.props.text("changeuser.title"));
  }

  onSubmit = values => {
    this.props.save(values);
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    var sip = this.props.sip.replace("|", ", ");

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          <h1 id="h1id">{this.props.text("changeuser.title")}</h1>

          {this.props.changeUserError && (
            <div className="alert alert-danger col-md-12 col-lg-12" role="alert">
              {this.props.text("changeuser.error") +
                " (" +
                (this.props.changeUserErrorText === ""
                  ? this.props.text("error.noservercontact")
                  : this.props.changeUserErrorText) +
                ")"}
            </div>
          )}

          {this.props.changeUserComplete && (
            <div className="alert alert-success col-md-12 col-lg-12" role="alert">
              {this.props.text("changeuser.complete")}
            </div>
          )}

          <p>{this.props.text("common.required")}</p>

          <div>
            <h2 className="h6 font-weight-bold my-0">{this.props.text("common.username")}</h2>
            <div id="username">{this.props.username.value}</div>
          </div>
          <div className="margin-top-1em">
            <h2 className="h6 font-weight-bold my-0">{this.props.text("common.sipaddress")}</h2>
            <div id="sipaddress">{sip}</div>
          </div>

          {this.props.password.visible && (
            <Button
              title={this.props.text("changeuser.titlePassword")}
              className="btn button margin-top-2em"
              to={"/userinformation/changePassword"}
              text={this.props.text("changeuser.titlePassword")}
            />
          )}

          {!this.props.changeUserInProgress && (
            <div className="margin-top-2em">
              <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <InputText
                  name="firstname"
                  autoComplete="given-name"
                  text={this.props.text("common.firstname")}
                  field={this.props.firstname}
                />
                <InputText
                  name="lastname"
                  autoComplete="family-name"
                  text={this.props.text("common.lastname")}
                  field={this.props.lastname}
                />
                <InputText
                  name="email"
                  autoComplete="email"
                  text={this.props.text("common.email")}
                  field={this.props.email}
                />
                <InputText
                  name="address"
                  autoComplete="street-address"
                  text={this.props.text("common.address")}
                  field={this.props.address}
                />
                <InputText
                  name="postcode"
                  autoComplete="postal-code"
                  text={this.props.text("common.postcode")}
                  field={this.props.postcode}
                />
                <InputText
                  name="city"
                  autoComplete="address-level2"
                  text={this.props.text("common.city")}
                  field={this.props.city}
                />
                <InputText
                  name="phonenumber"
                  text={this.props.text("common.phonenumber")}
                  field={this.props.phonenumber}
                />
                <InputText
                  name="mobilenumber"
                  text={this.props.text("common.mobilenumber")}
                  field={this.props.mobilenumber}
                />
                <InputText name="isdnnumber" text={this.props.text("common.isdn")} field={this.props.isdnnumber} />
                <InputText name="gggnumber" text={this.props.text("common.3gnumber")} field={this.props.gggnumber} />
                <InputText name="faxnumber" text={this.props.text("common.fax")} field={this.props.faxnumber} />

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="float-right">
                    <Button
                      title=" "
                      type="submit"
                      icon="save"
                      text={this.props.text("common.save")}
                      className="mr-3"
                    />
                    <Button title=" " to={"/userinformation"} text={this.props.text("common.cancel")} />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    changeUserInProgress: state.changeUser.changeUserInProgress,
    changeUserComplete: state.changeUser.changeUserComplete,
    changeUserError: state.changeUser.changeUserError,
    changeUserErrorText: state.changeUser.changeUserErrorText,
    text: str => state.config.text(str),
    loading: state.config.features.registration == null ? true : false,
    sip: state.user.properties.SIP_ADDRESS,
    server: state.config.server.url,
    initialValues: {
      username: state.user.properties.USERNAME,
      firstname: state.user.properties.FORENAME,
      lastname: state.user.properties.SURNAME,
      sipaddress: state.user.properties.SIP_ADDRESS,
      address: state.user.properties.ADDRESS,
      postcode: state.user.properties.POSTCODE,
      city: state.user.properties.CITY,
      email: state.user.properties.EMAIL,
    },

    ...state.changeUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    save: values => {
      dispatch(save(values));
    },
    clear: () => {
      dispatch({ type: CHANGE_USER.CLEAR });
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

ChangeUser = reduxForm({
  form: "changeuser",
  validate,
  asyncValidate,
  asyncBlurFields: ["username", "email"],
})(ChangeUser);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUser);
