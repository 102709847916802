import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Standard from "./Standard";

export class Init extends React.Component {
  establishing = () => {
    return (
      <div className={"text-align-center margin-auto"}>
        <h1>{this.props.l10n("common.loading")}</h1>
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
          <div className="rect6"></div>
          <div className="rect7"></div>
        </div>
      </div>
    );
  };

  render() {
    const { logo, logoDark, contrast, l10n } = this.props;
    return <Standard logo={logo} logoDark={logoDark} contrast={contrast} l10n={l10n} include={this.establishing()} />;
  }
}

Init.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
});

export default connect(mapStateToProps)(Init);
