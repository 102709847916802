import React from "react";
import { Field } from "redux-form";

class InputSelect extends React.Component {
  renderField = ({ input, value, meta: { error } }) => {
    var className = "form-control";
    if (error) {
      className = "form-control is-invalid";
    } else {
      if (this.props.field.required) {
        className = "form-control is-valid";
      }
    }

    var type = "select";

    return (
      <div>
        <label htmlFor={this.props.name} className="font-weight-bold">
          {this.props.text + (this.props.field.required ? " *" : "")}
        </label>
        <select
          aria-hidden="true"
          id={this.props.name}
          type={type}
          className={className}
          required={this.props.field.required}
          {...input}
        >
          {this.props.field.organisations.map(opt => (
            <option value={opt.id} key={opt.id}>
              {this.props.texts(opt.name)}
            </option>
          ))}
        </select>
        {true && error && <span className="color-red">{error}</span>}
      </div>
    );
  };

  required = value => (value || !this.props.field.required ? undefined : true);

  render() {
    return (
      <div className="form-group">
        <Field component={this.renderField} name={this.props.name} />
      </div>
    );
  }
}

export default InputSelect;
