import React from "react";
import { connect } from "react-redux";
import Standard from "../containers/callStates/Standard";
import { setPageTitle } from "../misc/helpers";
import { IRootState } from "../models/reducerStates";

type CallCompletedProps = {
  l10n: Function;
  logo: string;
  logoDark: string;
  contrast: boolean;
  pageTitle: string;
};

export class CallCompleted extends React.Component<CallCompletedProps> {
  componentDidMount() {
    setPageTitle(this.props.pageTitle, false);
  }

  callCompleted = () => {
    return (
      <div className={"text-align-center margin-auto col-9"}>
        <h1>{this.props.l10n("kiosk.call_ended")}</h1>
      </div>
    );
  };

  render(): JSX.Element {
    const { logo, logoDark, contrast, l10n } = this.props;
    return <Standard logo={logo} logoDark={logoDark} contrast={contrast} l10n={l10n} include={this.callCompleted()} />;
  }
}

const mapStateToProps = (state: IRootState) => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
  pageTitle: state.config.text("common.appOwner"),
});

export default connect(mapStateToProps)(CallCompleted);
