import React, { useEffect } from "react";
import { connect } from "react-redux";
import { hideModal } from "../actions/modalActions";
import { IRootState } from "../models/reducerStates";
import "./Modal.css";

type Props = {
  id?: string;
  show?: boolean;
  title?: string;
  body?: string;
  showCancel?: boolean;
  showClose?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  showConfirm?: boolean;
  text: Function;
  handleCancel?: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm?: Function;
  onConfirm?: Function;
};

const Modal = (props: Props) => {
  const id = props.id || "main-modal";

  useEffect(() => {
    const trapTab = (event: FocusEvent) => {
      const modal = document.getElementById(id);
      if (event.target instanceof Node && props.show && !modal.contains(event.target)) {
        event.stopPropagation();
        modal.focus();
      }
    };

    document.addEventListener("focus", trapTab, true);
    return () => {
      document.removeEventListener("focus", trapTab, true);
    };
  }, [props.show, id]);

  return (
    <React.Fragment>
      <div
        className={"modal fade" + (props.show ? " show" : "")}
        aria-labelledby="modal-title"
        tabIndex={-1}
        role="dialog"
        aria-hidden={!props.show}
        id={id}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-title">
                {props.title}
              </h5>
              {props.showClose && (
                <button
                  type="button"
                  className="close"
                  onClick={props.handleCancel}
                  aria-label={props.text("modal.close")}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            {props.body && <div className="modal-body">{props.body}</div>}
            <div className="modal-footer">
              {props.showCancel && (
                <button type="button" className="btn button" onClick={props.handleCancel}>
                  {props.cancelLabel || props.text("modal.cancel")}
                </button>
              )}
              {props.showConfirm && (
                <button
                  type="button"
                  className="btn button"
                  onClick={() => {
                    props.handleConfirm(props.onConfirm);
                  }}
                >
                  {props.confirmLabel || props.text("modal.confirm")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.show && <div className="modal-backdrop fade show"></div>}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    text: state.config.text,
    show: state.modal.show,
    title: state.modal.title,
    body: state.modal.body,
    confirmLabel: state.modal.confirmLabel,
    onConfirm: state.modal.onConfirm,
    showClose: state.modal.showClose,
    showConfirm: state.modal.showConfirm,
    showCancel: state.modal.showCancel,
    cancelLabel: state.modal.cancelLabel,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    handleConfirm(action: Function = null) {
      if (typeof action === "function") {
        action();
      }
      dispatch(hideModal());
    },

    handleCancel() {
      dispatch(hideModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
