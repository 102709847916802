import { CONTACTS } from "../actions/actionTypes";
import { IContactState } from "../models/reducerStates";

const initialState: IContactState = {
  loading: false,
  contacts: [],
  noSipGivenAlert: false,
};

const contactReducer = (state: IContactState = initialState, action: any) => {
  switch (action.type) {
    case CONTACTS.LOADED:
      state = {
        contacts: action.payload.contacts,
        loading: false,
        noSipGivenAlert: undefined,
      };
      break;
    case CONTACTS.LOADING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS.UPDATE_LOCALLY:
      if (action.payload.data.id === undefined) {
        return;
      }
      let idUpdate = parseInt(action.payload.data.id, 10);

      for (let i = 0; i < state.contacts.length; i++) {
        if (state.contacts[i].id === idUpdate) {
          state.contacts[i] = {
            ...action.payload.data,
            id: idUpdate,
          };
          state.loading = false;
          break;
        }
      }
      break;
    case CONTACTS.DELETE_LOCALLY:
      let idDelete = parseInt(action.payload.id, 10);
      for (let j = 0; j < state.contacts.length; j++) {
        if (state.contacts[j].id === idDelete) {
          state.contacts.splice(j, 1);
          state.loading = false;
          break;
        }
      }
      break;
    case CONTACTS.REMOVE_LOADING:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CONTACTS.NO_SIP_GIVEN:
      state.noSipGivenAlert = true;
      break;
    case CONTACTS.CLEAR_ALERT:
      state.noSipGivenAlert = false;
      break;
    default:
      break;
  }
  return state;
};

export default contactReducer;
