import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Standard from "./Standard";

export class CallCompleted extends React.Component {
  completed = () => {
    return null;
  };

  render() {
    const { logo, logoDark, contrast, l10n } = this.props;
    return <Standard logo={logo} logoDark={logoDark} contrast={contrast} l10n={l10n} include={this.completed()} />;
  }
}

CallCompleted.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
});

export default connect(mapStateToProps)(CallCompleted);
