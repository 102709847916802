import React from "react";
import { Link } from "react-router-dom";
import "./SettingsNav.css";

export const SettingsSubNavItemMaterial = props => {
  var route = props.route === undefined ? "" : props.route;

  return (
    <li
      className={
        "settings-submenu-item" + (props.chosen ? " activeSettingsTab" : "") + (props.last ? " last-sub-item" : "")
      }
      aria-current={props.chosen ? "true" : "false"}
    >
      <Link className="settings-menu-link settings-submenu-link" to={route}>
        <div className={"nav-item-focus-border" + (props.chosen ? " activeSettingsTab" : "")}>
          <div className="settings-submenu-text font-size-large">{props.text}</div>
        </div>
      </Link>
    </li>
  );
};
