// Bundle size before cleanup: 448.26 KB

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/variables.css";
import "./assets/styles/index.css";
import "./components/buttons/LinkButtons.css";
import "@material-design-icons/font/filled.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import store from "./store";
import App from "./App";
import { bootApplication } from "./actions/configActions";
import release from "./release";
import "./misc/accessibilityChecks";
// import "./misc/logHijack";
import "./misc/ieFix";
import "./misc/bootstrapFix";
import "./misc/putCursorAtEnd";
import axios from "axios";
import { APP, CONFIG } from "./actions/actionTypes";
import defaultConfig from "./config/config";
import FaviconNotification from "favicon-notification";
import Storage from "./misc/storage";

console.log(`${release.version} (${release.shortHash})`);

let app = null;
const brandBaseUrl = `./branding`;
const brandingConfigUrl = `${brandBaseUrl}/brand.json`;
const cacheBustToken = +Date.now();

axios
  .request({
    method: "get",
    url: `${brandingConfigUrl}?_=${cacheBustToken}`,
  })
  .then(response => {
    store.dispatch({
      type: APP.SET_LOCALE,
      payload: navigator.language.substring(0, 2) || "en",
    });

    store.dispatch({
      type: CONFIG.SET,
      payload: response.data,
    });

    const config = store.getState().config;
    if (config.sentry) {
      var history = createBrowserHistory();
      Sentry.init({
        dsn: config.sentry,
        release: `mmx-web-tc@${release.version}`,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
          }),
        ],
        normalizeDepth: 5,
        tracesSampleRate: 1.0,
        beforeBreadcrumb(breadcrumb, hint) {
          return breadcrumb.category === "console" ? null : breadcrumb;
        },
      });

      Sentry.configureScope(function(scope) {
        Sentry.setTag("brand", config.branding && config.branding.name ? config.branding.name : "undefined");
        Sentry.setTag("commit", release.hash);
      });
    }

    const head = document.head || document.getElementsByTagName("head")[0];

    if (response.data.branding && response.data.branding.css) {
      const style = document.createElement("link");
      style.rel = "stylesheet";
      style.type = "text/css";
      style.setAttribute("href", `${brandBaseUrl}/branding.css?_=${cacheBustToken}`);
      head.appendChild(style);
    }

    let faviconUrl = "./favicon.ico";
    if (response.data.branding && response.data.branding.favicon) {
      faviconUrl = `${brandBaseUrl}/favicon.ico`;
    }
    FaviconNotification.init({
      url: faviconUrl,
      color: "#ffc700",
      lineColor: "#feecad",
    });

    if (response.data.branding && response.data.branding.manifest) {
      const manifest = document.createElement("link");
      manifest.setAttribute("rel", "manifest");
      manifest.setAttribute("href", `${brandBaseUrl}/manifest.json?_=${cacheBustToken}`);
      head.appendChild(manifest);
    }

    Storage._prefix = response.data.branding.name;

    store.dispatch(bootApplication());

    app = (
      <Provider store={store}>
        <ConnectedRouter history={createBrowserHistory()}>
          <App />
        </ConnectedRouter>
      </Provider>
    );
  })
  .catch(e => {
    Sentry.init({
      dsn: defaultConfig.sentry,
      release: `mmx-web-tc@${release.version}`,
    });
    Sentry.addBreadcrumb({
      category: "info",
      message: `Trying to fetch branding from ${brandingConfigUrl}`,
      level: Sentry.Severity.Info,
    });
    Sentry.captureMessage("Configuration Not Found", "error");
    Sentry.close();

    console.error(e);

    app = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            borderRight: "2px solid",
            fontSize: "26px",
            padding: "0 15px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Error
        </div>
        <div
          style={{
            fontSize: "18px",
            padding: "0 15px",
            textAlign: "center",
          }}
        >
          Configuration Not Found
        </div>
      </div>
    );
  })
  .finally(() => {
    ReactDOM.render(app, document.getElementById("root"));
  });
