import React from "react";
import { connect } from "react-redux";
import Button from "../../components/buttons/Button";

class About extends React.Component {
  render() {
    return (
      <div>
        {!this.props.user.anonymous && (
          <div>
            <h2 className="h6 font-weight-bold my-0">{this.props.text("about.user")}</h2>
            <div>{this.props.user.properties.USERNAME}</div>

            <h2 className="h6 font-weight-bold my-0 margin-top-1em">{this.props.text("about.sip")}</h2>
            <div>{this.props.user.properties.SIP_ADDRESS}</div>
          </div>
        )}
        <h2 className="h6 font-weight-bold my-0 margin-top-1em">{this.props.text("about.version")}</h2>
        <div>
          {this.props.config.release.version} (<code>{this.props.config.release.shortHash}</code>)
        </div>

        <h2 className="h6 font-weight-bold my-0 margin-top-1em">{this.props.text("about.browser")}</h2>
        <div>{this.props.config.browser.name}</div>

        <h2 className="h6 font-weight-bold my-0 margin-top-1em">{this.props.text("about.browserversion")}</h2>
        <div>{this.props.config.browser.version}</div>

        <h2 className="h6 font-weight-bold my-0 margin-top-1em">{this.props.text("about.os")}</h2>
        <div>{this.props.config.browser.os}</div>
        {this.props.smallScreenMode && (
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="float-right">
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    user: state.user,
    text: state.config.text,
  };
};

export default connect(mapStateToProps)(About);
