import React from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { detect } from "detect-browser";
import MainPage from "./pages/MainPage";
import { LoginPage } from "./pages/LoginPage";
import { toggleContrast, updateScreenSize, updateAppIsInBackground } from "./actions/accessibilityActions";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import RegistrationPage from "./pages/RegistrationPage";
import SecureRoute from "./components/SecureRoute";
import KioskPage from "./kiosk/KioskPage";
import NotificationService from "./services/notificationService";
import Loading from "./containers/Loading";
import PermissionsOverlay from "./containers/PermissionsOverlay";
import { CALL_STATE } from "./constants/callState";
import InCall from "./containers/InCall";

import * as Sentry from "@sentry/react";
import WrongUnamePwd from "./containers/callStates/WrongUsernamePassword";
import TokenInvalid from "./containers/callStates/TokenInvalid";
import TokenExpired from "./containers/callStates/TokenExpired";
import VerifyAccountPage from "./pages/VerifyAccountPage";
import debounce from "lodash/debounce";
import { APP } from "./actions/actionTypes";

const App = props => {
  NotificationService.initializeNotifications(props.kiosk, detect().os.indexOf("Android") !== -1);

  var savedDarkTheme = JSON.parse(window.localStorage.getItem("darkTheme"));
  if (savedDarkTheme && savedDarkTheme !== props.isHighContrast) {
    props.toggleContrast();
  }

  var text = props.text;
  var titles = {
    login: text("titles.login"),
    resetpassword: text("titles.resetpassword"),
    newpassword: text("titles.newpassword"),
    registration: text("titles.registration"),
    about: text("titles.about"),
  };

  props.updateScreenSize();
  window.addEventListener(
    "resize",
    debounce(() => {
      props.updateScreenSize();
    }, 300)
  );

  document.addEventListener(
    "visibilitychange",
    () => {
      if (document.visibilityState === "hidden") {
        props.updateAppIsInBackground(true);
      } else {
        props.updateAppIsInBackground(false);
        props.showPageTitleBell(false);
      }
    },
    false
  );

  if (props.showPermissionsOverlay) {
    return <PermissionsOverlay />;
  }

  if (props.isLoading) {
    return <Loading />;
  }

  if (props.kiosk) {
    switch (props.callState) {
      case CALL_STATE.AUTHENTICATION_ERROR:
        return <WrongUnamePwd />;
      case CALL_STATE.TOKEN_INVALID:
        return <TokenInvalid />;
      case CALL_STATE.TOKEN_EXPIRED:
        return <TokenExpired />;
      default:
        break;
    }
  }

  if (
    props.callState &&
    props.callState !== CALL_STATE.COMPLETED &&
    props.callState !== CALL_STATE.AUTHENTICATION_ERROR &&
    props.callState !== CALL_STATE.TOKEN_INVALID &&
    props.callState !== CALL_STATE.TOKEN_EXPIRED
  ) {
    return <InCall />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          render={() => <LoginPage title={titles["login"]} updatePageTitle={props.updatePageTitle} />}
        />
        <Route
          path="/resetpassword"
          render={() => <ResetPasswordPage title={titles["resetpassword"]} updatePageTitle={props.updatePageTitle} />}
        />
        <Route
          path="/newpassword"
          render={() => <NewPasswordPage title={titles["newpassword"]} updatePageTitle={props.updatePageTitle} />}
        />
        <Route
          path="/registration/verify/:uid/:key"
          render={() => <VerifyAccountPage title={titles["verifyaccount"]} updatePageTitle={props.updatePageTitle} />}
        />
        <Route
          path="/registration"
          render={() => <RegistrationPage title={titles["registration"]} updatePageTitle={props.updatePageTitle} />}
        />
        <Route path="/oneclickcall" component={KioskPage} />
        <SecureRoute path="/" component={MainPage} isAuthenticated={props.isAuthenticated} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state, props) => {
  Sentry.setTag("brand", state.config.brand);

  return {
    text: state.config.text,
    isHighContrast: state.accessibility.isHighContrast,
    isAuthenticated: state.user.authenticated,
    kiosk: state.config.features.kiosk.enabled,
    isLoading: state.config.loading,
    showPermissionsOverlay: state.config.showPermissionsOverlay,
    callState: state.sip.callState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreenSize: () => {
      return dispatch(updateScreenSize());
    },
    updateAppIsInBackground: isInBackground => {
      return dispatch(updateAppIsInBackground(isInBackground));
    },
    showPageTitleBell: showBell => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          showBell,
        },
      });
    },
    updatePageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
    toggleContrast: () => {
      return dispatch(toggleContrast());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));
