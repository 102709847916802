import { IRootState } from "../models/reducerStates";
import { online, register } from "./sipActions";

export function heartbeat(interval: number) {
  return (dispatch: Function, getState: Function) => {
    const state: IRootState = getState();

    // 1 sec
    if (interval === 1) {
      if (state.user.authenticated) {
        dispatch(online());
      }
    }

    // 5 sec
    if (interval === 5) {
      //
    }

    // 20 sec
    if (interval === 20) {
      //
    }

    // 60 sec
    if (interval === 60) {
      if (state.user.authenticated) {
        // Try to re-register with the Freeswitch if there has been a communications error
        if (state.network.freeswitchError) {
          dispatch(register());
        }
      }
    }
  };
}
