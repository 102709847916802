import { DIRECTORY } from "../actions/actionTypes";
import { IBusinessDirectoryState } from "../models/reducerStates";

const initialState: IBusinessDirectoryState = {
  types: [],
  categories: [],
  directory: [],
};

const businessDirectoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DIRECTORY.LOADED:
      state = action.payload;
      break;
    default:
      break;
  }
  return state;
};

export default businessDirectoryReducer;
