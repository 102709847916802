import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { saveSettings, loadSettings } from "../../actions/settingActions";
import { SETTINGS } from "../../actions/actionTypes";
import Loading from "../../components/Loading";
import Button from "../../components/buttons/Button";

class Colors extends React.Component {
  componentDidMount() {
    this.props.loadSettings();
  }

  componentWillUnmount() {
    this.props.clearSettingsSaved();
  }

  onSubmit = values => {
    this.props.saveSettings(values);
  };

  colorPicker = (text, name) => {
    return (
      <div className="form-group font-weight-bold">
        <label htmlFor={"settings-" + name}>{text}</label>
        <Field id={"settings-" + name} component="select" className="form-control" name={name}>
          {this.props.colors.map(color => (
            <option value={color.value} key={color.value}>
              {this.props.text(color.text)}
            </option>
          ))}
        </Field>
      </div>
    );
  };

  preview = (htext, previewtext, id, textcolor, backgroundcolor) => {
    return (
      <div className="form-group font-weight-bold">
        <label htmlFor={"settings-" + id}>{htext}</label>
        <div id={id} className={"colorpreview " + this.props[backgroundcolor] + "-background-color "}>
          <p
            className={
              "margin-zero font-family-" +
              this.props.currentfont +
              " font-size-" +
              this.props.currentfontsize +
              " " +
              this.props[textcolor] +
              "-color"
            }
          >
            {previewtext}
          </p>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.config.features.settings.allowChangeText) {
      return <Redirect to={"/settings"} />;
    }

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div>
          <h2>{this.props.text("settings.incomingcolors")}</h2>
          {this.colorPicker(this.props.text("settings.backgroundcolors"), "rectextbackground")}
          {this.colorPicker(this.props.text("settings.textcolors"), "rectextforeground")}
          {this.preview(
            this.props.text("settings.preview"),
            this.props.text("settings.loremipsum"),
            "incomingpreview",
            "rectextforeground",
            "rectextbackground"
          )}

          <h2 className="margin-top-2em">{this.props.text("settings.mycolors")}</h2>
          {this.colorPicker(this.props.text("settings.backgroundcolors"), "senttextbackground")}
          {this.colorPicker(this.props.text("settings.textcolors"), "senttextforeground")}
          {this.preview(
            this.props.text("settings.preview"),
            this.props.text("settings.loremipsum"),
            "mypreview",
            "senttextforeground",
            "senttextbackground"
          )}

          <h2 className="margin-top-2em">{this.props.text("settings.sharedcolors")}</h2>
          {this.colorPicker(this.props.text("settings.backgroundcolors"), "onetextbackground")}
          {this.colorPicker(this.props.text("settings.textcolors"), "onetextforeground")}
          {this.preview(
            this.props.text("settings.preview"),
            this.props.text("settings.loremipsum"),
            "sharedpreview",
            "onetextforeground",
            "onetextbackground"
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div className="float-right">
            <Button title=" " type="submit" icon="save" text={this.props.text("common.save")} />
            {this.props.smallScreenMode && (
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            )}
          </div>
        </div>
        <div></div>
      </form>
    );
  }
}

const selector = formValueSelector("colors");
const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      senttextforeground: state.setting.user.WEB_TEXT_OUT_COLOR,
      senttextbackground: state.setting.user.WEB_TEXTAREA_OUT_BACKGROUND,
      rectextforeground: state.setting.user.WEB_TEXT_IN_COLOR,
      rectextbackground: state.setting.user.WEB_TEXTAREA_IN_BACKGROUND,
      onetextforeground: state.setting.user.WEB_ONEBOX_TEXT_COLOR,
      onetextbackground: state.setting.user.WEB_ONEBOX_TEXT_BACKGROUND_COLOR,
      textbox: state.setting.user.WEB_CHAT_LAYOUT,
    },
    enableReinitialize: true,
    senttextforeground: selector(state, "senttextforeground"),
    senttextbackground: selector(state, "senttextbackground"),
    rectextforeground: selector(state, "rectextforeground"),
    rectextbackground: selector(state, "rectextbackground"),
    onetextbackground: selector(state, "onetextbackground"),
    onetextforeground: selector(state, "onetextforeground"),
    colors: state.config.fonts.colors,
    currentfont: state.setting.user.WEB_FONT,
    currentfontsize: state.setting.user.WEB_TEXT_SIZE,
    loading: state.setting.loading,
    text: state.config.text,
    config: state.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: values => {
      return dispatch(loadSettings(values));
    },
    clearSettingsSaved: () => {
      dispatch({ type: SETTINGS.SAVED_RESET });
    },
  };
};

Colors = reduxForm({
  form: "colors",
})(Colors);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Colors));
