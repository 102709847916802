import React from "react";
import { Field } from "redux-form";

class InputText extends React.Component {
  renderField = ({ input, meta: { error } }) => {
    var className = "";
    if (error) {
      className = "outline-red";
    }

    return (
      <div>
        <input id={this.props.name} name={this.props.name} type={"checkbox"} className={className} {...input} />
        {this.props.name === "license" && (
          <label htmlFor={this.props.name} className="font-size-1_1em display-inline font-weight-bold">
            &nbsp;{this.props.text("registration.license1")}
            <a target="_blank" rel="noopener noreferrer" href={this.props.text("registration.licenselink")}>
              {this.props.text("registration.license_link_text")}
            </a>
            {this.props.text("registration.license2")}
          </label>
        )}
        {error && <p className="color-red">{error}</p>}
      </div>
    );
  };

  render() {
    return (
      <div className="form-group">
        <Field component={this.renderField} name={this.props.name} />
      </div>
    );
  }
}

export default InputText;
