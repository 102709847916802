import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { saveSettings, loadSettings } from "../../actions/settingActions";
import { SETTINGS } from "../../actions/actionTypes";
import Loading from "../../components/Loading";
import Button from "../../components/buttons/Button";

class Mediamail extends React.Component {
  componentDidMount() {
    this.props.loadSettings();
  }

  componentWillUnmount() {
    this.props.clearSettingsSaved();
  }

  onSubmit = values => {
    this.props.saveSettings(values);
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.config.features.settings.allowChangeMessage) {
      return <Redirect to={"/settings"} />;
    }

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div>
          <div className="form-check">
            <label htmlFor="settings-autoanswer" className="form-check-label">
              <Field
                id="settings-autoanswer"
                component="input"
                type="checkbox"
                className="form-check-input"
                name="autoAnswerMessageEnabled"
              />
              {this.props.text("settings.autoanswer")}
            </label>
          </div>
          <div className="form-group font-weight-bold margin-top-1em">
            <label htmlFor="settings-autoanswermessage">{this.props.text("settings.autoanswermessage")}</label>
            <Field
              id="settings-autoanswermessage"
              component="textarea"
              className="form-control"
              name="autoAnswerMessage"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div className="float-right">
            <Button title=" " type="submit" icon="save" text={this.props.text("common.save")} />
            {this.props.smallScreenMode && (
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            )}
          </div>
        </div>
        <div></div>
      </form>
    );
  }
}

const selector = formValueSelector("mediamail");
const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      autoAnswerMessageEnabled: state.setting.user.AM_ENABLED === "1",
      autoAnswerMessage: state.setting.user.AM_MESSAGE,
      textbox: state.setting.user.WEB_CHAT_LAYOUT,
    },
    enableReinitialize: true,
    loading: state.setting.loading,
    textbox: selector(state, "textbox"),
    text: state.config.text,
    config: state.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: values => {
      return dispatch(loadSettings(values));
    },
    clearSettingsSaved: () => {
      dispatch({ type: SETTINGS.SAVED_RESET });
    },
  };
};

Mediamail = reduxForm({
  form: "mediamail",
})(Mediamail);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mediamail));
