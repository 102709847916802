import { CALLS } from "./actionTypes";

export function loadCalls(securityToken) {
  return (dispatch, getstate) => {
    if (!securityToken) {
      securityToken = getstate().user.securityToken;
    }

    dispatch({
      type: CALLS.LOADING,
      payload: {},
    });

    var calls = [];
    calls.push({
      type: "sip",
      answered: true,
      sipAddress: "kalle@sip.nwise.se",
      contactName: "kalle",
      receivedText: "hej",
      sentText: "hej kalle",
      timestamp: Date.now(),
    });
    calls.push({
      type: "sip",
      answered: false,
      sipAddress: "lisa.newton@sip.nwise.se",
      contactName: null,
      receivedText: "hej",
      sentText: "hej lisa",
      timestamp: Date.now(),
    });

    dispatch(callsLoaded(calls));
  };
}

function callsLoaded(calls) {
  return {
    type: CALLS.LOADED,
    payload: {
      calls: calls,
    },
  };
}
