import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";

import { validate } from "../actions/userActions";
import { login } from "../actions/configActions";
import { enableDebug } from "../actions/configActions";
import Button from "../components/buttons/Button";
import "./Login.css";
import { IRootState } from "../models/reducerStates";
import IConfig from "../models/IConfig";
import { text } from "../language/index";

type Props = {
  login: Function;
  config: IConfig;
  isAuthenticated: boolean;
  debug: boolean;
  enableDebug: Function;
  text: typeof text;
  anonymous: { user: string; password: string; icon?: string };
  error: string;
  errorCode: number;
  redirectTo: string;
  webrtc: boolean;
};

class Login extends React.Component<Props> {
  username: HTMLInputElement;
  password: HTMLInputElement;

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.login(this.username.value, this.password.value);
  };

  componentDidMount() {
    if (this.username) {
      this.username.focus();
    }
  }

  render() {
    // TODO: If user is anonymously auto logged in and then logs out
    // he will be stuck at "Logging in...". The system should automatically
    // issue a new login request.
    if (this.props.config.features.anonymous.autologin && !this.props.isAuthenticated) {
      return <div>Logging in...</div>;
    }

    if (this.props.isAuthenticated) {
      return <Redirect to={this.props.redirectTo} />;
    }

    if (this.props.debug === true) {
      this.props.enableDebug();
    }

    return (
      <main>
        {this.props.debug && (
          <div className="alert alert-success" role="alert">
            {this.props.text("login.debug")}
          </div>
        )}

        <h1>{this.props.text("common.appName")}</h1>

        <div className="login-form d-flex flex-column">
          {this.props.config.features.anonymous.enabled && (
            <div className="login-form-section">
              {this.props.text("login.loginanonymous.headline") !== "?login.loginanonymous.headline" && (
                <h2>{this.props.text("login.loginanonymous.headline")}</h2>
              )}
              <Button
                type="button"
                className="login-button"
                onClick={() => this.props.login(this.props.anonymous.user, this.props.anonymous.password)}
                text={this.props.text("login.loginanonymous")}
                icon={this.props.anonymous.icon}
              />
            </div>
          )}

          <div className="login-form-section">
            {this.props.config.features.authentication.enabled && (
              <form onSubmit={this.onSubmit} className="margin-top-2em">
                <h2 id="h1id">{this.props.text("login.headline")}</h2>

                {this.props.text("login.optionalLoginInfo") !== "?login.optionalLoginInfo" && (
                  <div className="margin-bottom-1em">
                    <strong>
                      <a className="underlined" href={this.props.text("login.optionalLoginInfoLink")}>
                        {this.props.text("login.optionalLoginInfo")}
                      </a>
                    </strong>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="username" className="font-weight-bold">
                    {this.props.text("login.username", "common.username")}
                  </label>
                  <input
                    required={true}
                    aria-required="true"
                    type="username"
                    autoCapitalize="off"
                    className="form-control"
                    id="username"
                    ref={obj => {
                      this.username = obj;
                    }}
                    placeholder={this.props.text("login.username", "common.username")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="font-weight-bold">
                    {this.props.text("common.password")}
                  </label>
                  <input
                    required={true}
                    aria-required="true"
                    type="password"
                    autoComplete="off"
                    className="form-control"
                    id="password"
                    ref={obj => {
                      this.password = obj;
                    }}
                    placeholder={this.props.text("common.password")}
                  />
                </div>

                <Button type="submit" className="login-button" text={this.props.text("login.login")} />
              </form>
            )}

            <div className="login-additional-links">
              {this.props.text("login.optionalLogin") !== "?login.optionalLogin" && (
                <div className="login-additional-link">
                  <a className="underlined" href={this.props.text("login.optionalLoginLink")}>
                    {this.props.text("login.optionalLogin")}
                  </a>
                </div>
              )}

              {this.props.config.features.registration.enabled && (
                <div className="login-additional-link">
                  <Link className="underlined" to={"/registration"}>
                    {this.props.text("login.registration")}
                  </Link>
                </div>
              )}

              {this.props.text("login.optionalLogin2") !== "?login.optionalLogin2" && (
                <div className="login-additional-link">
                  <a className="underlined" href={this.props.text("login.optionalLoginLink2")}>
                    {this.props.text("login.optionalLogin2")}
                  </a>
                </div>
              )}

              {this.props.text("login.optionalLogin3") !== "?login.optionalLogin3" && (
                <div className="login-additional-link">
                  <a className="underlined" href={this.props.text("login.optionalLoginLink3")}>
                    {this.props.text("login.optionalLogin3")}
                  </a>
                </div>
              )}

              {this.props.text("login.handleData") !== "?login.handleData" && (
                <div className="login-additional-link">
                  <a className="underlined" href={this.props.text("login.handleDataLink")}>
                    {this.props.text("login.handleData")}
                  </a>
                </div>
              )}

              {this.props.config.features.resetPassword.enabled && (
                <div className="login-additional-link">
                  <Link className="underlined" to={"/resetpassword"}>
                    {this.props.text("login.resetpassword")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: IRootState, props: any) => {
  const redirectTo = props.location.state ? props.location.state.from : "/";

  return {
    config: state.config,
    error: state.user.loginerror,
    errorCode: state.user.loginerrorCode,
    loginform: state.user.loginform,
    text: state.config.text,
    anonymous: state.config.features.anonymous,
    webrtc: state.config.browser.webrtc,
    isAuthenticated: state.user.authenticated,
    redirectTo,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    login: (username: string, password: string) => {
      dispatch(login(username, password));
    },
    validate: (username: string, password: string) => {
      dispatch(validate(username, password));
    },
    enableDebug: () => {
      dispatch(enableDebug());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
