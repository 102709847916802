import { CHANGE_USER, USER, CONFIG } from "../actions/actionTypes";
import IConfig from "../models/IConfig";
import { IChangeUserState } from "../models/reducerStates";

const initialState: IChangeUserState = {
  usefirstnamername: undefined,
  username: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  password: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  firstname: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  lastname: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  address: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  postcode: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  city: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  email: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  phonenumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  mobilenumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  textphonenumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  isdnnumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  gggnumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  faxnumber: {
    value: null,
    visible: false,
    required: false,
    regex: null,
    regexText: null,
    dontmatch: null,
    autoComplete: "off",
  },
  changeUserInProgress: false,
  changeUserComplete: false,
  changeUserError: false,
  changeUserErrorText: null,
};

const changeUserReducer = (state: IChangeUserState = initialState, action: any) => {
  switch (action.type) {
    case USER.REFRESH:
    case USER.LOGIN_SUCCESS:
      state = {
        ...state,
        username: {
          ...state.username,
          value: action.payload.user.properties.USERNAME,
        },
        password: {
          ...state.password,
          value: null,
        },
        firstname: {
          ...state.firstname,
          value: action.payload.user.properties.FORENAME,
        },
        lastname: {
          ...state.lastname,
          value: action.payload.user.properties.SURNAME,
        },
        address: {
          ...state.address,
          value: action.payload.user.properties.ADDRESS,
        },
        postcode: {
          ...state.postcode,
          value: action.payload.user.properties.POSTCODE,
        },
        city: {
          ...state.city,
          value: action.payload.user.properties.CITY,
        },
        email: {
          ...state.email,
          value: action.payload.user.properties.EMAIL,
        },
        phonenumber: {
          ...state.phonenumber,
          value: action.payload.user.properties.PHONE_NUMBER,
        },
        mobilenumber: {
          ...state.mobilenumber,
          value: action.payload.user.properties.MOBILE_NUMBER,
        },
        textphonenumber: {
          ...state.textphonenumber,
          value: action.payload.user.properties.TEXT_PHONE_NUMBER,
        },
        isdnnumber: {
          ...state.isdnnumber,
          value: action.payload.user.properties.ISDN_NUMBER,
        },
        gggnumber: {
          ...state.gggnumber,
          value: action.payload.user.properties.GGG_NUMBER,
        },
        faxnumber: {
          ...state.faxnumber,
          value: action.payload.user.properties.FAX_NUMBER,
        },
      };
      break;
    case CONFIG.SYSTEM_LOADED:
      const config: IConfig = action.payload.config;

      state = {
        ...state,
        username: {
          ...state.username,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "username"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "username"),
          regex: config.validation.username,
          regexText: action.payload.config.text("common.username_helptext"),
        },
        password: {
          ...state.password,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "password"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "password"),
          regex: config.validation.password,
          regexText: action.payload.config.text("common.password1_helptext"),
          dontmatch: action.payload.config.text("common.password2_helptext"),
        },
        firstname: {
          ...state.usefirstnamername,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "firstname"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "firstname"),
        },
        lastname: {
          ...state.lastname,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "lastname"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "lastname"),
        },
        address: {
          ...state.address,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "street address"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "street address"),
        },
        postcode: {
          ...state.postcode,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "post code"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "post code"),
        },
        city: {
          ...state.city,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "city"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "city"),
        },
        email: {
          ...state.email,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "email address"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "email address"),
          regex: config.validation.email,
          regexText: action.payload.config.text("common.email_helptext"),
        },
        phonenumber: {
          ...state.phonenumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "phone number"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "phone number"),
        },
        mobilenumber: {
          ...state.mobilenumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "mobile number"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "mobile number"),
        },
        textphonenumber: {
          ...state.textphonenumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "textphone"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "textphone"),
        },
        isdnnumber: {
          ...state.isdnnumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "isdn number"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "isdn number"),
        },
        gggnumber: {
          ...state.gggnumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "3g number"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "3g number"),
        },
        faxnumber: {
          ...state.faxnumber,
          visible: contains(action.payload.systemConfig.WEB_CHANGE_OWN_DATA_VISIBLE_FIELDS, "fax number"),
          regex: config.validation.default,
          regexText: action.payload.config.text("common.invalid_characters"),
          required: contains(action.payload.systemConfig.WEB_REQUIRED_FIELDS, "fax number"),
        },
      };
      break;
    case CHANGE_USER.INIT:
      state = {
        ...state,
        changeUserInProgress: true,
        changeUserComplete: false,
        changeUserError: false,
      };
      break;
    case CHANGE_USER.SUCCESS:
      state = {
        ...state,
        changeUserInProgress: false,
        changeUserComplete: true,
        changeUserError: false,
      };
      break;
    case CHANGE_USER.ERROR:
      state = {
        ...state,
        changeUserInProgress: false,
        changeUserComplete: false,
        changeUserError: true,
        changeUserErrorText: action.payload,
      };
      break;
    case CHANGE_USER.CLEAR:
      state = {
        ...state,
        changeUserInProgress: false,
        changeUserComplete: false,
        changeUserError: false,
        changeUserErrorText: null,
      };
      break;
    default:
      break;
  }
  return state;
};

function contains(str: string, substr: string) {
  return str.indexOf(substr) !== -1;
}

export default changeUserReducer;
