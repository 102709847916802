import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { call } from "../../actions/sipActions";
import { SIP_ACTION } from "../../actions/actionTypes";
import Standard from "./Standard";
import RoundRejectButton from "../../components/buttons/RoundRejectButton";
import RoundAnswerButton from "../../components/buttons/RoundAnswerButton";

export class TerminatedRemote extends React.Component {
  terminated_remote = () => {
    var contactName = this.findContactnameFromSip(this.props.callB);

    return (
      <div className={"text-align-center margin-auto"}>
        <h1>
          {contactName} {this.props.l10n("call.endedcall")}
        </h1>
        <br />
        <div className={"row text-align-center margin-auto"}>
          <div className={"col"}>
            <RoundAnswerButton
              text={this.props.l10n("common.callagain")}
              onClick={() => this.props.call(this.props.callB)}
            />
          </div>
          <div className={"col"}>
            <RoundRejectButton
              text={this.props.l10n("common.cancel")}
              onClick={() => this.props.complete()}
              kioskmode={false}
            />
          </div>
        </div>
      </div>
    );
  };

  findContactnameFromSip = sip => {
    for (var i = 0; i < this.props.contacts.length; i++) {
      if (this.props.contacts[i].sipaddress === sip) {
        if (this.props.contacts[i].fname && this.props.contacts[i].sname) {
          return this.props.contacts[i].fname + " " + this.props.contacts[i].sname;
        } else if (this.props.contacts[i].fname) {
          return this.props.contacts[i].fname;
        } else if (this.props.contacts[i].sname) {
          return this.props.contacts[i].sname;
        }
      }
    }
    return sip;
  };

  render() {
    const { logo, logoDark, contrast, l10n } = this.props;
    return (
      <Standard logo={logo} logoDark={logoDark} contrast={contrast} l10n={l10n} include={this.terminated_remote()} />
    );
  }
}

TerminatedRemote.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
  callB: PropTypes.string.isRequired,
  contacts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  callB: state.call.callB,
  contacts: state.contact.contacts,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
});

const mapDispatchToProps = dispatch => ({
  call: to => {
    return dispatch(call(to));
  },
  complete: () => {
    return dispatch({
      type: SIP_ACTION.CALL_COMPLETED,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminatedRemote);
