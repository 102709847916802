import React from "react";
import PropTypes from "prop-types";
import CallButton from "../components/buttons/CallButton";
import EditButton from "../components/buttons/EditButton";
import InformationButton from "../components/buttons/InformationButton";
import DeleteButton from "../components/buttons/DeleteButton";

const ContactItem = props => (
  <li className="list-group-item">
    <div className="d-flex justify-content-between flex-wrap-wrap">
      <div className="text-box">
        <div>
          <div>
            <strong>
              {props.item.fname} {props.item.sname}
            </strong>
          </div>
          <div>{props.item.sipaddress}</div>
        </div>
      </div>
      <div className="float-right list-link-buttons d-flex justify-content-between">
        <CallButton sipaddress={props.item.sipaddress} />
        <EditButton to="/contacts/edit/" id={props.item.id} text={props.text} />
        <InformationButton to="/contacts/information/" id={props.item.id} text={props.text} />
        <DeleteButton to="/contacts/delete/" id={props.item.id} text={props.text} />
      </div>
    </div>
  </li>
);

ContactItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
};

const ContactList = props => {
  const contactItems = props.contacts.map((item, index) => (
    <ContactItem key={index} item={item} onClick={props.onClick} text={props.text} />
  ));

  return (
    <li className="margin-bottom-4em">
      <h2>{props.letter}</h2>
      <ul className="list-group">{contactItems}</ul>
    </li>
  );
};

ContactList.propTypes = {
  onClick: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  text: PropTypes.func.isRequired,
};

export default ContactList;
