import React from "react";
import Login from "../containers/Login";
import NoNavLayout from "../layouts/NoNavLayout";

export const LoginPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <Login />
    </NoNavLayout>
  );
};

export const LoginDebugPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <Login debug={true} />
    </NoNavLayout>
  );
};

export default LoginPage;
