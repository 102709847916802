import React from "react";
import { connect } from "react-redux";
import { CALL_STATE } from "../constants/callState";
// import "./InCall.css";

import Calling from "./callStates/Calling";
import IncomingCall from "./callStates/IncomingCall";
import CanceledRemote from "./callStates/CanceledRemote";
import CallCompleted from "./callStates/CallCompleted";
import RejectedRemote from "./callStates/RejectedRemote";
import TerminatedRemote from "./callStates/TerminatedRemote";
import UnknownNumber from "./callStates/UnknownNumber";
import UnknownError from "./callStates/UnknownError";
import Establishing from "./callStates/Establishing";

import Default from "./callStates/InCall/Default";
import Kiosk from "./callStates/InCall/Kiosk";

class InCall extends React.Component {
  stillInCall = e => {
    e.preventDefault();
    e.returnValue = "You are in a call. Are you sure that you want to exit the page?"; //there is a default dialog text for new browsers that is shown instead
    return "You are in a call. Are you sure that you want to exit the page?"; //there is a default dialog text for new browsers that is shown instead
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.stillInCall);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.stillInCall);
  }

  callScreen() {
    if (!this.props.kiosk || (this.props.kiosk && this.props.kiosk_main_layout)) {
      return <Default />;
    }

    return <Kiosk />;
  }

  render() {
    switch (this.props.callState) {
      case CALL_STATE.INCOMING_CALL:
        return <IncomingCall />;
      case CALL_STATE.ESTABLISHING:
        return <Establishing />;
      case CALL_STATE.ACCEPTED:
        return this.callScreen();
      case CALL_STATE.CALLING:
        return <Calling />;
      case CALL_STATE.REJECTED_REMOTE:
        return <RejectedRemote />;
      case CALL_STATE.TERMINATED_REMOTE:
        return <TerminatedRemote />;
      case CALL_STATE.CANCELED_REMOTE:
        return <CanceledRemote />;
      case CALL_STATE.UNREGISTERED_NUMBER:
      case CALL_STATE.UNKNOWN_NUMBER:
        return <UnknownNumber />;
      case CALL_STATE.TERMINATED_LOCAL:
      case CALL_STATE.COMPLETED:
      case CALL_STATE.REJECTED_LOCAL:
        return <CallCompleted />;
      case CALL_STATE.FAILED:
        return <UnknownError />;
      default:
        break;
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    callState: state.sip.callState,
    kiosk: state.config.features.kiosk.enabled,
    kiosk_main_layout: state.config.features.kiosk.useDefaultCallScreen,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InCall);
