import React from "react";
import { connect } from "react-redux";
import { deleteContact } from "../actions/contactActions";
import Loading from "../components/Loading";
import Button from "../components/buttons/Button";
import "./ContactDelete.css";
import { APP } from "../actions/actionTypes";

class ContactDelete extends React.Component {
  componentDidMount() {
    this.props.setPageTitle(this.props.text("contacts.deletecontact"));
  }

  delete = event => {
    this.props.deleteContact(this.props.match.params.id);
    this.props.history.push("/contacts");
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          <h1 id="h1id" className="text-align-center">
            {this.props.text("contacts.deletecontact")} {this.props.contact.name}?
          </h1>
          <br />
          <div className="remove-buttons-container">
            <Button onClick={this.delete} text={this.props.text("common.yes")} className={"remove-contact-button"} />
            <Button
              to={"/contacts/" + this.props.match.params.id}
              text={this.props.text("common.no")}
              className={"remove-contact-button"}
            />
          </div>
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
      </div>
    );
  }
}

function getContact(state, props) {
  if (!props.match.params.id) return {};
  var contact = {};
  state.contact.contacts.forEach(item => {
    if (item.id.toString() === props.match.params.id) {
      contact = item;
      var name = item.fname;
      if (item.fname && item.sname) {
        name = item.fname + " " + item.sname;
      } else if (item.fname) {
        name = item.fname;
      } else if (item.sname) {
        name = item.sname;
      } else {
        name = item.sipaddress;
      }
      contact.name = name;
    }
  });
  return contact;
}

const mapStateToProps = (state, props) => {
  return {
    contacts: state.contacts,
    contact: getContact(state, props),
    loading: state.contact.loading,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteContact: id => {
      return dispatch(deleteContact(id));
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDelete);
