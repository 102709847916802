import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import ReduxThunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import { reducer as formReducer } from "redux-form";
import heartbeats from "heartbeats";
import userReducer from "./reducers/userReducer";
import configReducer from "./reducers/configReducer";
import serviceReducer from "./reducers/serviceReducer";
import contactReducer from "./reducers/contactReducer";
import businessDirectoryReducer from "./reducers/businessDirectoryReducer";
import historyReducer from "./reducers/historyReducer";
import settingReducer from "./reducers/settingReducer";
import { messageReducer } from "./reducers/messageReducer";
import connectionReducer from "./reducers/connectionReducer";
import sipReducer from "./reducers/sipReducer";
import resetPasswordReducer from "./reducers/resetPasswordReducer";
import registrationReducer from "./reducers/registrationReducer";
import changeUserReducer from "./reducers/changeUserReducer";
import debugReducer from "./reducers/debugReducer";
import accessibilityReducer from "./reducers/accessibilityReducer";
import callReducer from "./reducers/callReducer";
import { networkReducer } from "./reducers/networkReducer";
import { heartbeat } from "./actions/heartbeatActions";
import kioskReducer from "./reducers/kioskReducer";
import alertReducer from "./reducers/alertReducer";
import modalReducer from "./reducers/modalReducer";
import notificationReducer from "./reducers/notificationReducer";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

const rootReducer = combineReducers({
  user: userReducer,
  config: configReducer,
  service: serviceReducer,
  contact: contactReducer,
  businessDirectory: businessDirectoryReducer,
  history: historyReducer,
  setting: settingReducer,
  form: formReducer,
  message: messageReducer,
  connection: connectionReducer,
  sip: sipReducer,
  resetPassword: resetPasswordReducer,
  registration: registrationReducer,
  changeUser: changeUserReducer,
  accessibility: accessibilityReducer,
  debug: debugReducer,
  call: callReducer,
  network: networkReducer,
  kiosk: kioskReducer,
  alerts: alertReducer,
  modal: modalReducer,
  notification: notificationReducer,
});

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: state => {
    const transformedState = {
      ...state,
      changeUser: null,
      config: {
        mode: state.config.mode,
        audio: state.config.call.audio,
        video: state.config.call.video,
        hasPermissions: state.config.hasPermissions,
        webrtc: state.config.browser.webrtc,
      },
      contact: null,
      debug: null,
      form: null,
      history: null,
      kiosk: null,
      message: null,
      registration: null,
      resetPassword: null,
      service: null,
      setting: null,
      sip: {
        ...state.sip,
        safetextSender: null,
      },
      user: {
        ...state.user,
        loginform: null,
      },
    };

    return transformedState;
  },
});

const store = createStore(
  connectRouter(history)(rootReducer),
  {},
  composeEnhancers(
    applyMiddleware(
      ReduxThunk,
      routerMiddleware(history),
      createLogger({
        predicate: (getState, action) => {
          if (!getState().config.debug.enabled) return false;
          if (("" + action.type).indexOf("@@redux-form") !== -1) return false;
          return true;
        },
        collapsed: (getState, action) => true,
      })
    ),
    sentryReduxEnhancer
  )
);

//Heartbeats
var heart = heartbeats.createHeart(1000);

heart.createEvent(1, function(count, last) {
  store.dispatch(heartbeat(1));
});

//5sec
heart.createEvent(5, function(count, last) {
  store.dispatch(heartbeat(5));
});

//20sec
heart.createEvent(20, function(count, last) {
  store.dispatch(heartbeat(20));
});

//60sec
heart.createEvent(60, function(count, last) {
  store.dispatch(heartbeat(60));
});

export default store;
