import React from "react";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

import Button from "../components/buttons/Button";
import InputText from "../components/InputText";
import InputSelect from "../components/InputSelect";
import InputCheckbox from "../components/InputCheckbox";

import { registration, init } from "../actions/registrationActions";
import { REGISTRATION } from "../actions/actionTypes";
import UserService from "../services/userService";
import ErrorAlert from "../components/ErrorAlert";

/**
 * Checks if the given values in the form are valid.
 *
 * @param {Object}      values      The values of the written username and email.
 * @param {Object}      props       The props from the object with the class Registration.
 * @return {Object}                 An object with the errors.
 */
const validate = (values, props) => {
  const errors = {};
  const passwordField = find(props.fields, field => field.name === "password");

  var regEx = null;
  if (!isEmpty(values.password1) && passwordField.regex && passwordField.regexText) {
    regEx = new RegExp(passwordField.regex);
    errors.password1 = regEx.test(values.password1) ? undefined : passwordField.regexText;
  }

  if (
    !isEmpty(values.password2) &&
    passwordField.dontmatch &&
    (isEmpty(values.password1) || values.password1 !== values.password2)
  ) {
    errors.password2 = passwordField.dontmatch;
  }

  return errors;
};

/**
 * Checks from the server if either the written username and email is unique and returns a resolved promise
 * if both are unique and otherwise a rejected promise with the value of what is not unique.
 *
 * @param {Object}      values      The values of the written username and email.
 * @param {Function}    dispatch    Redux's dispatch.
 * @param {Object}      props       The props from the object with the class Registration.
 * @return {Promise}                A rejected or resolved promised.
 */
const asyncValidate = (values, dispatch, props) => {
  return new Promise(async (resolve, reject) => {
    const usernameExists = values.username !== null && values.username !== undefined && values.username !== "";
    const emailExists = values.email !== null && values.email !== undefined && values.email !== "";

    const validations = [];
    if (usernameExists) validations.push("username");
    if (emailExists) validations.push("email");

    const errors = {};
    for (const field of validations) {
      try {
        await UserService.validateByKey(field, values[field], props.server, dispatch);
      } catch (e) {
        if (field === "username") {
          errors[field] = props.text("registration.username_exists");
        }

        if (field === "email") {
          errors[field] = props.text("registration.email_exists");
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      reject(errors);
      return;
    }

    resolve();
  });
};

class Registration extends React.Component {
  componentDidMount() {
    this.props.recaptchaInvalid();
    this.props.init();
  }

  componentWillUnmount() {
    this.props.clearRegistration();
  }

  onSubmit = values => {
    if (this.props.config.features.registration.license && values.license !== true) {
      throw new SubmissionError({
        license: this.props.text("registration.check_checkbox"),
      });
    }

    if (this.props.config.features.registration.recaptcha && !this.props.recaptchavalid) {
      return;
    }

    this.props.registration(values);
  };

  password = field => {
    if (!field.visible) {
      return null;
    }

    return (
      <div key="password">
        <InputText name="password1" text={this.props.text("common.password1")} field={field} autoComplete="off" />
        <InputText name="password2" text={this.props.text("common.password2")} field={field} autoComplete="off" />
      </div>
    );
  };

  organisation = (organisations, texts) => {
    if (!Array.isArray(organisations)) {
      return null;
    }

    return (
      <div key="organisation">
        <InputSelect
          name="orgID"
          text={this.props.text("common.organisation")}
          texts={texts}
          field={{ organisations, required: true }}
        />
      </div>
    );
  };

  getFormField = field => {
    switch (field.name) {
      case "organisation":
        return this.organisation(this.props.config.features.registration.organisations, this.props.text);
      case "password":
        return this.password(field);
      default:
        return (
          <InputText
            key={field.name}
            name={field.name}
            text={this.props.text(`registration.${field.name}`, `common.${field.name}`)}
            field={field}
            autoComplete={field.autoComplete || "off"}
            autoCapitalize={field.autoCapitalize || "on"}
          />
        );
    }
  };

  getFormFields = () => {
    return this.props.fields.map(field => {
      return this.getFormField(field);
    });
  };

  render() {
    if (this.props.loading) {
      return <div></div>;
    }

    if (!this.props.enabled) {
      return <div>{this.props.text("registration.not_enabled")}</div>;
    }

    if (this.props.registrationComplete) {
      let message = this.props.text("registration.completed");
      if (this.props.accountRequiresVerification) {
        message = this.props.text("registration.completed_verification");
      } else if (this.props.accountRequiresAdminApproval) {
        message = this.props.text("registration.completed_admin_approval");
      }

      if (this.props.location.search.indexOf("hideLogin=true") !== -1) {
        return (
          <main>
            <h1>{this.props.text("registration.completed_headline")}</h1>
            <p>{message}</p>
          </main>
        );
      } else {
        return (
          <main>
            <h1>{this.props.text("registration.completed_headline")}</h1>
            <p>{message}</p>
            <Link className="underlined" to={"/"}>
              {this.props.text("common.to_startpage")}
            </Link>
          </main>
        );
      }
    }

    let errorText = "";
    if (this.props.serverError === true) {
      errorText = this.props.text("network.networkError");
    } else if (this.props.registrationError === true) {
      errorText = this.props.text("registration.error") + " (" + this.props.registrationErrorText + ")";
    }

    return (
      <main>
        {!this.props.registrationInProgress && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <h1 id="h1id">{this.props.text("registration.title")}</h1>
              </div>
            </div>
            <div className="row">
              <div className={this.props.text("registration.info") !== " " ? "col-md-7" : "col"}>
                {errorText !== "" && <ErrorAlert text={errorText} />}

                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <p>{this.props.text("common.required")}</p>

                  {this.getFormFields()}

                  {this.props.config.features.registration.license && (
                    <InputCheckbox
                      name="license"
                      text={this.props.text}
                      field={{ visible: true }}
                      autoComplete="off"
                      type={"checkbox"}
                    />
                  )}

                  {this.props.config.features.registration.recaptcha && (
                    <div>
                      <ReCAPTCHA sitekey={this.props.config.recaptchaKey} onChange={this.props.recaptchaValid} />
                      <br />
                    </div>
                  )}

                  <Button type="submit" text={this.props.text("registration.register")} />
                </form>
              </div>
              {this.props.text("registration.info") !== " " && (
                <div className="col mt-5">
                  {this.props
                    .text("registration.info")
                    .split("\n")
                    .map(element => {
                      return <p>{element}</p>;
                    })}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {this.props.location.search.indexOf("hideBack=true") === -1 && (
          <div className="row mt-4">
            <div className="col">
              <Link className="underlined" to="/">
                <strong>{this.props.text("common.back")}</strong>
              </Link>
            </div>
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = state => {
  const fields = Object.keys(state.registration)
    .map(field => {
      return state.registration[field] ? { name: field, ...state.registration[field] } : false;
    })
    .filter(field => field.visible)
    .sort((a, b) => {
      a.weight = a.weight === -1 ? 999 : a.weight;
      return a.weight > b.weight ? 1 : -1;
    });

  return {
    config: state.config,
    registrationInProgress: state.registration.registrationInProgress,
    registrationComplete: state.registration.registrationComplete,
    accountRequiresVerification: state.registration.accountRequiresVerification,
    accountRequiresAdminApproval: state.registration.accountRequiresAdminApproval,
    registrationError: state.registration.registrationError,
    registrationErrorText: state.registration.registrationErrorText,
    serverError: state.network.serverError,
    text: state.config.text,
    server: state.config.server.url,
    loading: state.config.text == null ? true : false,
    recaptchavalid: state.registration.recaptchavalid,
    fields,
    enabled: state.config.features.registration.enabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    recaptchaValid: () => {
      dispatch({ type: REGISTRATION.REGISTRATION_RECAPTCHA_VALID });
    },
    recaptchaInvalid: () => {
      dispatch({ type: REGISTRATION.REGISTRATION_RECAPTCHA_INVALID });
    },
    clearRegistration: () => {
      dispatch({ type: REGISTRATION.REGISTRATION_CLEAR });
    },
    registration: values => {
      dispatch(registration(values));
    },
    init: () => {
      dispatch(init());
    },
  };
};

Registration = reduxForm({
  form: "registration",
  validate,
  asyncValidate,
  asyncBlurFields: ["username", "email"],
})(Registration);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration));
