interface StorageInteface {
  _prefix: string;
  session: {
    get: (key: string) => any;
    set: (key: string, value: any) => void;
    clear: () => void;
  };
  local: {
    get: (key: string) => any;
    set: (key: string, value: any) => void;
    clear: () => void;
  };
}

//Detect if in iframe
var webStorage = true;
try {
  webStorage = window.self === window.top;
} catch (e) {}

var local: Record<string, any> = {};
var session: Record<string, any> = {};

const storage = {} as StorageInteface;

if (webStorage) {
  storage.session = {
    get: key => {
      key = `${storage._prefix}.${key}`;
      var ret = sessionStorage[key];
      if (!ret || ret === "null") return null;
      return sessionStorage[key];
    },
    set: (key, data) => {
      key = `${storage._prefix}.${key}`;
      sessionStorage.setItem(key, data);
    },
    clear: () => {
      sessionStorage.clear();
    },
  };

  storage.local = {
    get: key => {
      key = `${storage._prefix}.${key}`;
      var ret = localStorage[key];
      if (!ret || ret === "null") return null;
      return localStorage[key];
    },

    set: (key, data) => {
      key = `${storage._prefix}.${key}`;
      localStorage.setItem(key, data);
    },

    clear: () => {
      localStorage.clear();
    },
  };
} else {
  storage.session = {
    get: key => {
      return session[key];
    },

    set: (key, data) => {
      session[key] = data;
    },

    clear: () => {
      session = {};
    },
  };

  storage.local = {
    get: key => {
      return local[key];
    },

    set: (key, data) => {
      local[key] = data;
    },

    clear: () => {
      local = {};
    },
  };
}

export default storage;
