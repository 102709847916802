import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../components/Loading";
import {
  loadMessages,
  readMessage,
  deleteMessage,
  deletedMessageAlert,
  clearMessageAlert,
} from "../actions/messageActions";
import { withRouter } from "react-router-dom";
import { call } from "../actions/sipActions";
import SectionHeader from "../components/SectionHeader";
import MessageList from "../components/MessageList";
import Button from "../components/buttons/Button";
import { APP } from "../actions/actionTypes";

class Messages extends React.Component {
  componentDidMount() {
    this.props.loadMessages();
    this.props.setPageTitle(this.props.text("messages.messages"));
  }

  componentWillUnmount() {
    this.props.clearMessageAlert();
  }

  getViewLink = item => {
    // eslint-disable-next-line
    if (item.messageNumber == this.props.messageNumber) {
      return "/messages";
    } else {
      return "/messages/view/" + item.messageNumber;
    }
  };

  deleteMessage = item => {
    this.props.deleteMessage(item.messageNumber);
    this.props.deletedMessageAlert();
  };

  viewMessage = item => {
    if (!item.read) {
      this.props.readMessage(item.messageNumber);
    }
    item.read = true;
    this.props.history.push("/messages/view/" + item.messageNumber);
  };

  call = address => {
    this.props.call(address);
  };

  findContactnameFromSip = sip => {
    for (var i = 0; i < this.props.contacts.length; i++) {
      if (this.props.contacts[i].sipaddress === sip) {
        if (this.props.contacts[i].fname && this.props.contacts[i].sname) {
          return this.props.contacts[i].fname + " " + this.props.contacts[i].sname;
        } else if (this.props.contacts[i].fname) {
          return this.props.contacts[i].fname;
        } else if (this.props.contacts[i].sname) {
          return this.props.contacts[i].sname;
        }
      }
    }
    return sip;
  };

  render() {
    if (this.props.isLoadingMessages) {
      return <Loading />;
    }

    var tempList = {}; //To save the result from thefindContactnameFromSip function so the contact list will not be iterated every time

    var unreadMessages = [];
    var readMessages = [];
    for (var i = 0; i < this.props.messages.length; i++) {
      var contactName = tempList[this.props.messages[i].fromSipaddress];
      if (contactName === undefined) {
        contactName = this.findContactnameFromSip(this.props.messages[i].fromSipaddress);
        tempList[this.props.messages[i].fromSipaddress] = contactName;
      }

      var message = {
        ...this.props.messages[i],
        name: contactName,
      };

      if (this.props.messages[i].read === false) {
        unreadMessages.push(message);
      } else {
        readMessages.push(message);
      }
    }

    return (
      <div>
        <SectionHeader text={this.props.text("messages.messages")} />

        {this.props.messageAlert && (
          <div className="alert alert-info col-md-12 col-lg-12" role="alert">
            {this.props.text("messages.messageAlert")}
          </div>
        )}

        {unreadMessages.length > 0 && (
          <div>
            <h2>{this.props.text("messages.unreadmessages")}</h2>
            <MessageList
              messages={unreadMessages}
              onRead={this.viewMessage}
              onDelete={this.deleteMessage}
              onCall={this.call}
              text={this.props.text}
            />
          </div>
        )}

        {readMessages.length > 0 && (
          <div className={unreadMessages.length > 0 ? "margin-top-5em" : ""}>
            <div className="text-align-center">
              <Button to={"/messages/delete"} icon="delete" text={this.props.text("messages.deleteallread")} />
            </div>
            <h2 className="margin-top-1em">{this.props.text("messages.readmessages")}</h2>
            <MessageList
              messages={readMessages}
              onRead={this.viewMessage}
              onDelete={this.deleteMessage}
              onCall={this.call}
              text={this.props.text}
            />
          </div>
        )}

        {unreadMessages.length === 0 && readMessages.length === 0 && <p>{this.props.text("messages.nomessages")}</p>}
      </div>
    );
  }
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      fromDisplayName: PropTypes.string.isRequired,
      fromSipaddress: PropTypes.string.isRequired,
      messageNumber: PropTypes.number.isRequired,
      read: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  messageNumber: PropTypes.number,
  text: PropTypes.func.isRequired,
  loadMessages: PropTypes.func.isRequired,
  readMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  call: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    messages: state.message.messages,
    isLoadingMessages: state.message.loading,
    messageNumber:
      props.match && props.match.params && props.match.params.messageNumber
        ? parseInt(props.match.params.messageNumber, 10)
        : 0,
    contacts: state.contact.contacts,
    messageAlert: state.message.messageAlert,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadMessages: () => {
      return dispatch(loadMessages());
    },
    readMessage: messageNumber => {
      return dispatch(readMessage(messageNumber));
    },
    deleteMessage: messageNumber => {
      return dispatch(deleteMessage(messageNumber));
    },
    call: callTo => {
      return dispatch(call(callTo));
    },
    deletedMessageAlert: () => {
      dispatch(deletedMessageAlert());
    },
    clearMessageAlert: () => {
      dispatch(clearMessageAlert());
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
