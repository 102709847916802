import { MODAL } from "../actions/actionTypes";
import { IModalState } from "../models/reducerStates";

const initialState: IModalState = {
  show: false,
  title: null,
  body: null,
  showClose: false,
  showConfirm: false,
  showCancel: false,
  confirmLabel: null,
  cancelLabel: null,
  onConfirm: null,
};

const modalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MODAL.SHOW:
      state = {
        show: true,
        title: action.payload.title,
        body: action.payload.body,
        showClose: action.payload.showClose,
        showConfirm: action.payload.showConfirm,
        showCancel: action.payload.showCancel,
        confirmLabel: action.payload.confirmLabel,
        cancelLabel: action.payload.cancelLabel,
        onConfirm: action.payload.onConfirm,
      };
      break;
    case MODAL.HIDE:
      state = initialState;
      break;
    default:
      break;
  }
  return state;
};

export default modalReducer;
