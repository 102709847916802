import React from "react";
import { connect } from "react-redux";
import { login } from "../actions/configActions";
import { logout } from "../actions/userActions";
import { unregister } from "../actions/sipActions";
import FourOFour from "../containers/FourOFour";
import ConnectionStatus from "./ConnectionStatus";
import parseQueryParams from "../modules/parse-query-params";
import { inIframe } from "../misc/inIframe";
import Init from "../containers/callStates/Init";
import Ready from "./Ready";
import CallCompleted from "./CallCompleted";
import { IRootState } from "../models/reducerStates";
import { RouteComponentProps } from "react-router";
import { USER } from "../actions/actionTypes";
import { CALL_STATE } from "../constants/callState";
import { KIOSK_STATE } from "../constants/kioskState";
import { setPageTitle } from "../misc/helpers";

type KioskPageProps = {
  login: Function;
  logout: Function;
  dispatch: Function;
  location: Location;
  kiosk: boolean;
  kioskState: KIOSK_STATE;
  callState: CALL_STATE;
  logoutAfterCall: boolean;
  anonymous_enabled: boolean;
  isAuthenticated: boolean;
  anonymous: {
    user: string;
    password: string;
  };
  allowIframe: boolean;
  forceIframe: boolean;
  pageTitle: string;
};

class KioskPage extends React.Component<KioskPageProps> {
  componentDidMount() {
    setPageTitle(this.props.pageTitle, false);
    try {
      const token = this.props.location.search.substr(1);
      const tokenValue = parseQueryParams(token);

      // Make sure to log out so that we always have a fresh user
      // TODO Send an unregister request for the saved user if we have one.
      this.props.login(
        this.props.anonymous_enabled ? this.props.anonymous.user : tokenValue.username,
        this.props.anonymous_enabled ? this.props.anonymous.password : tokenValue.password,
        token
      );
    } catch (e) {
      this.props.dispatch({
        type: USER.LOGIN_FAILED_TOKEN_INVALID,
        payload: {
          loginerror: -1,
        },
      });
    }
  }

  render() {
    if (!this.props.kiosk || (!this.props.allowIframe && inIframe()) || (this.props.forceIframe && !inIframe())) {
      return <FourOFour />;
    }

    const { kioskState } = this.props;

    switch (kioskState) {
      case KIOSK_STATE.INIT:
        return <Init />;
      case KIOSK_STATE.READY:
        return <Ready />;
      case KIOSK_STATE.CALL_COMPLETED:
        if (this.props.logoutAfterCall) {
          this.props.logout();
        }
        return <CallCompleted />;
      default:
        break;
    }

    return <ConnectionStatus status={kioskState} />;
  }
}

const dispatchToProps = (dispatch: Function) => {
  return {
    dispatch,
    login: (username: string, password: string, token: string) => dispatch(login(username, password, token)),
    logout: () => {
      dispatch(unregister());
      dispatch(logout());
    },
  };
};

const stateToProps = (state: IRootState, router: RouteComponentProps) => {
  return {
    logoutAfterCall: state.config.features.kiosk.logoutAfterCall,
    anonymous_enabled: state.config.features.anonymous.enabled,
    anonymous: state.config.features.anonymous,
    isAuthenticated: state.user.authenticated,
    kiosk: state.config.features.kiosk.enabled,
    kioskState: state.kiosk.state,
    callState: state.sip.callState,
    router,
    allowIframe: state.config.iframe.allow,
    forceIframe: state.config.iframe.force,
    audio: state.config.audio,
    video: state.config.video,
    text: state.config.call.safetext,
    pageTitle: state.config.text("common.appOwner"),
  };
};

export default connect(stateToProps, dispatchToProps)(KioskPage);
