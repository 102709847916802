import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Verkkosovellus",
  "common.loading": "Ladataan",
  "common.call": "Soita",
  "common.answer": "Vastaa",
  "common.reject": "Hylkää",
  "common.callback": "Soita takaisin",
  "common.callagain": "Soita uudelleen",
  "common.yes": "Kyllä",
  "common.no": "Nro",
  "common.on": "Päällä",
  "common.off": "Pois",
  "common.text": "Teksti",
  "common.abort": "Peruuttaa",
  "common.delete": "Poistaa",
  "common.cancel": "Peruuttaa",
  "common.missedcall": "Vastaamaton puhelu",
  "common.save": "Tallenna",
  "common.hangup": "Sulje",
  "common.add": "Lisää",
  "common.firstname": "Etunimi",
  "common.lastname": "Sukunimi",
  "common.sipaddress": "SIP-osoite",
  "common.email": "E-post",
  "common.address": "Osoite",
  "common.city": "Kaupunki",
  "common.postcode": "Postinumero",
  "common.calling": "Soittoääni",
  "common.dialer": "Numerovalitsin",
  "common.back": "Takaisin",
  "common.hide": "Piilota",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Ääni",
  "common.reset": "Nollaa",
  "common.here": "Täällä",
  "common.username": "Käyttäjätunnus",
  "common.password": "Salasana",
  "common.send": "Lähetä",
  "common.password1": "Salasana",
  "common.password2": "Toista salasana",
  "common.phonenumber": "Puhelin",
  "common.sip": "SIP-osoite",
  "common.mobilenumber": "Matkapuhelin",
  "common.isdn": "ISDN-numero",
  "common.3gnumber": "3G-numero",
  "common.fax": "Faksi",
  "common.username_helptext": "5 - 100 merkkiä. Vain 0-9, (piste), - (tavu) ja a-z (pienet kirjaimet) ovat sallittuja.",
  "common.email_helptext": "",
  "common.password1_helptext": "6 - 20 merkkiä",
  "common.password2_helptext": "Salasanat eivät ole täsmää",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Kentässä on yksi tai useampi virheellinen merkki",
  "common.link": "linkki",
  "common.Link": "Linkki",
  "common.anonymous": "Anonyymi",
  "common.edit": "Muokata",
  "common.contact_information": "Tiedot",
  "common.view_message": "Katso viesti",
  "common.close_message": "Sulje viesti",
  "common.required": "* Pakollinen kenttä",
  "common.to_startpage": "Aloitussivulle",
  "common.organisation": "Organisaatio",
  "common.permissions.heading": "Palvelu tarvitsee kameran ja / tai mikrofonin käyttöluvan.",
  "common.permissions.subheading":
    'Voit myöhemmin ennen jokaista puhelua päättää, haluatko käyttää videota / ääntä puhelussa vai ei.<br>Firefoxin käyttäjiä kehotetaan "Muistamaan tämä päätös" välttämään vastaavia kysymyksiä myöhemmin.<br>Kun hyväksyt käyttöoikeudet, kamera / mikrofoni aktivoituu muutamaksi sekunniksi ja sammuu sitten.',
  "common.app_for_os": "%APPNAME% for %OS%",

  /** Toolbar */
  "toolbar.hidetext": "Teksti on pois",
  "toolbar.showtext": "Teksti on päälle",
  "toolbar.micoff": "Mikrofoni on pois päältä",
  "toolbar.micon": "Mikrofoni on päällä",
  "toolbar.audiooff": "Ääni on pois",
  "toolbar.audioon": "Ääni on päällä",
  "toolbar.videooff": "Saapuva video on pois",
  "toolbar.videoon": "Saapuva video on päällä",
  "toolbar.cameraoff": "Videoni on pois",
  "toolbar.cameraon": "Videoni on päällä",
  "toolbar.fullscreenoff": "Koko näyttö on pois",
  "toolbar.fullscreenon": "Koko näyttö on päällä",
  "toolbar.selfviewoff": "Omakuva on pois",
  "toolbar.selfviewon": "Omakuva on päällä",
  "toolbar.hidedialpad": "Näppäimistö on päällä",
  "toolbar.showdialpad": "Näppäimistö on pois",
  "toolbar.useaudioon": "Äänimerkki on päällä",
  "toolbar.useaudiooff": "Äänimerkki on pois",
  "toolbar.usevideoon": "Puhelu videolla on päällä",
  "toolbar.usevideooff": "Puhelu videolla on pois",
  "toolbar.usetexton": "Soitto tekstillä on päällä",
  "toolbar.usetextoff": "Soitto tekstillä on pois",
  "toolbar.more": "Lisää",
  "toolbar.hide": "Piilota",
  "toolbar.togglevideo": "Näytä video",
  "toolbar.toggletext": "Näytä teksti",
  "toolbar.toSettings": "Siirry % asetuksiin",

  /** Titles */
  "titles.login": "Kirjaudu sisään",
  "titles.services": "Palvelut",
  "titles.contacts": "Omat yhteystiedot",
  "titles.call": "Soita",
  "titles.directory": "Business Directory",
  "titles.history": "Historia",
  "titles.messages": "Viestit",
  "titles.settings": "Asetukset",
  "titles.userinformation": "Oma profiili",
  "titles.log": "Debug",
  "titles.about": "Tietoja järjestelmistä",
  "titles.registration": "Rekisteröinti",
  "titles.newpassword": "Hanki uusi salasana",
  "titles.resetpassword": "Nollaa salasana",
  "titles.verifyaccount": "Aktivoi tili",

  /** Menu */
  "menu.logo": "Aloita sivu",
  "menu.services": "Palvelut",
  "menu.servicesHeadline": "Palvelut",
  "menu.contacts": "Yhteydet",
  "menu.mycontacts": "Omat yhteystiedot",
  "menu.call": "Soita",
  "menu.directory": "Business Directory",
  "menu.history": "Historia",
  "menu.messages": "Viestit",
  "menu.settings": "Asetukset",
  "menu.logout": "Kirjaudu ulos",
  "menu.userinformation": "Tili",
  "menu.userprofile": "Oma profiili",
  "menu.log": "Debug",
  "menu.about": "Tietoja järjestelmistä",

  /** Login screen */
  "login.loginanonymous.headline": "Soita ilman kirjautumista",
  "login.loginanonymous": "Kirjaudu sisään anonyymisti",
  "login.headline": "Soita kirjautumalla",
  "login.login": "Kirjaudu sisään",
  "login.loginfailed": "Kirjautuminen epäonnistui",
  "login.resetpassword": "Unohditko salasanasi?",
  "login.registration": "Haluatko rekisteröityä?",
  "login.nowebrtc": "Selaimesi ei tue reaaliaikaista viestintää äänen ja videon kanssa (WebRTC).",
  "login.debug": "Virheenkorjaus tilassa",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Väärä käyttäjätunnus tai salasana",
  "error.usernameNotExists": "Käyttäjätunnusta ei ole",
  "error.noservercontact": "Ei yhteyttä palvelimeen",
  "error.accountnotactive": "Tili ei ole aktiivinen",
  "error.accountexpired": "Tilin voimassaolo on päättynyt",
  "error.tokenexpired": "Todennusistunto on vanhentunut, kirjaudu sisään uudelleen",
  "error.tokeninvalid": "Todennustunnus on virheellinen",
  "error.unknown": "Tuntematon virhe",
  "error.unknownsender": "Tuntematon lähettäjä",
  "error.unknownsip": "Tuntematon SIP",
  "error.notextsent": "Tekstiä ei lähetetty",
  "error.acquiremedia": "Et voi soittaa puheluja äänen / videon kanssa, ellet ole valinnut tätä asetussivulla.",
  "error.mediaNotReadable":
    "Ääni- tai videolaitetta ei voi käyttää. Varmista, että mikään muu sovellus ei käytä niitä, ja päivitä sivu.",
  "error.requestedDeviceNotFound": "Pyydettyä medialaitetta ei löytynyt. Varmista, että se on kytketty oikein.",
  "error.cantcall": "Et voi soittaa puheluita, kun et ole yhteydessä järjestelmään",
  "error.cantcall_without_media": "Et voi soittaa puheluita ilman ääntä, videota tai tekstiä",
  "error.404": "404 - Etsimääsi sivua ei ole",
  "error.unsupportedbrowser": "Selainta ei tällä hetkellä tueta. Käytä toista selainta.",
  "error.browseroutofdate": "Selaimesi on vanhentunut. Päivitä se uusimpaan versioon, jotta voit käyttää sovellusta.",
  "error.browseroutofdate.ios.15.1": "Tätä iOS-versiota ei tueta. Päivitä vähintään iOS 15.2:een.",
  "error.browserwarning.closeAndDontShowAgain": "Sulje ja älä näytä uudelleen",
  "error.browserwarning.macos":
    "Jos sinulla on ongelmia puheluiden soittamisessa iPadistasi ja käytät tällä hetkellä iOS-versiota 15.1, päivitä iOS 15.2:een tai uudempaan.",

  /** User settings */
  "settings.white": "Vuosi",
  "settings.lightgrey": "Vaaleanharmaa",
  "settings.darkgrey": "Tummanharmaa",
  "settings.black": "Musta",
  "settings.yellow": "Gul",
  "settings.blue": "Sininen",
  "settings.red": "Punainen",
  "settings.green": "Vihreä",
  "settings.purple": "Violetti",
  "settings.orange": "Oranssi",
  "settings.pink": "Rosa",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida ilman Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Sarjakuvat",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Vierekkäin",
  "settings.overunder": "Päällekkäin",
  "settings.one": "Jaettu teksti -kenttä",
  "settings.smartphone": "Älypuhelin",
  "settings.bell": "Soittokello",
  "settings.marimba": "Marimban",
  "settings.glas": "Lasi",
  "settings.classic": "Klassikko",
  "settings.settings": "Asetukset",
  "settings.text": "Teksti",
  "settings.font": "Kirjasimet",
  "settings.fontsize": "Koko",
  "settings.textboxlayout": "Tekstikenttien asettelu",
  "settings.mycolors": "Oma tekstikenttä",
  "settings.incomingcolors": "Saapuvan tekstikentän",
  "settings.sharedcolors": "Jaettu teksti -kenttä",
  "settings.textcolors": "Tekstin väri",
  "settings.backgroundcolors": "Taustaväri",
  "settings.preview": "Esikatselu",
  "settings.loremipsum": "Tässä esimerkki tekstiä.",
  "settings.previewmytext": "Oma teksti",
  "settings.previewincomingtext": "Saapuva teksti",
  "settings.previewbothtext": "Oma teksti, Saapuva teksti",
  "settings.incomingcalls": "Saapuvat puhelut",
  "settings.messagebox": "Viesti",
  "settings.autoanswer": "Käytä tekstiviestiä, kun et vastaa",
  "settings.autoanswermessage": "Viesti",
  "settings.duringcalls": "Keskustelussa",
  "settings.colors": "Värit",
  "settings.textformat": "Teksti",
  "settings.media": "Media",
  "settings.audio": "Kuulostaa",
  "settings.video": "Video",
  "settings.ringtone": "Soittoääni",
  "settings.mediamail": "Viestit",
  "settings.about": "Järjestelmän tiedot",
  "settings.logout": "Kirjaudu ulos",
  "settings.logout.anonymous": "Kirjaudu ulos",
  "settings.saved": "Asetukset on tallennettu",
  "settings.media_explanation":
    "Näiden asetusten avulla voit päättää, mihin suuntaan soitat. Jos jokin näistä asetuksista on PÄÄLLÄ, voit aina sammuttaa sen puhelun aikana, mutta jos se on pois, et voi kytkeä sitä päälle puhelun aikana.",
  "settings.useaudio": "Soita äänellä",
  "settings.usevideo": "Soita videolla",
  "settings.bandwidth.label": "Kaistanleveys",
  "settings.bandwidth.high": "Korkea",
  "settings.bandwidth.medium": "Keskitaso",
  "settings.bandwidth.low": "Matala",

  /** Messages */
  "messages.messages": "Viestit",
  "messages.unreadmessages": "Lukematon",
  "messages.readmessages": "Luettu",
  "messages.nomessages": "Sinulla ei ole viestejä",
  "messages.deleteallread": "Poista kaikki luetut viestit",
  "messages.deleteallreadconfirm": "Poista kaikki luetut viestit?",
  "messages.messageAlert": "Viesti on poistettu",

  /** Contacts */
  "contacts.contacts": "Omat yhteystiedot",
  "contacts.addcontact": "Lisää yhteystieto",
  "contacts.deletecontact": "Poista yhteystieto",
  "contacts.nosipgiven": "Täytä yhteyshenkilön SIP-osoite",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Kirjoita tähän numero / nimi",
  "call.incomingtext": "Saapuva teksti",
  "call.outgoingtext": "Lähtevä teksti",
  "call.incoming": "Saapuvat puhelut",
  "call.unknownnumber": "Tuntematon numero",
  "call.unknownnumber_uppercase": "Tuntematon numero",
  "call.endedcall": "'Lopetti keskustelun",
  "call.yourejected": "Olet hylännyt puhelun",
  "call.noanswer": "Ei vastausta",
  "call.rejected": "Ei voinut vastata tällä hetkellä",
  "call.notfound": "Ei ole tavoitettavissa",
  "call.toolongtext": "N <Teksti on liian pitkä. Enintään 1000 merkkiä> n",
  "call.notificationbody": "Vastaa napsauttamalla tätä",
  "call.videoTextDisabled": "Teksti ja saapuva video ovat pois päältä",
  "call.writeHere": "Kirjoita tähän ...",
  "call.receiveHere": "Tässä on saapuva teksti",
  "call.establishing": "Puhelun luominen...",

  /** History screen */
  "history.history": "Historia",

  /** Reset password */
  "resetpassword.processing": "Käsittely ...",
  "resetpassword.completed": "Salasanan nollaus on valmis",
  "resetpassword.email_or_username": "Sähköposti tai käyttäjätunnus",
  "resetpassword.title": "Nollaa salasana",
  "resetpassword.explanation":
    "Syötä sähköpostiosoitteesi tai käyttäjätunnuksesi ja lähetä linkki tiettyyn sähköpostiviestiin tai käyttäjätunnukseen liittyvään sähköpostiviestiin. Linkin avulla voit palauttaa salasanasi.",
  "resetpassword.explanation_complete": "Saat pian sähköpostiviestin, jossa on lisäohjeita.",
  "resetpassword.invalid_link": "Virheellinen palautuslinkki",
  "resetpassword.password_changed": "Salasanasi on muuttunut",
  "resetpassword.enter_new_password": "Anna uusi salasana",
  "resetpassword.button_reset": "Tallenna",
  "resetpassword.password": "Salasana",
  "resetpassword.password_repeat": "Toista salasana",
  "resetpassword.password_requirement": "Salasanan on oltava 6 - 20 merkkiä",
  "resetpassword.error": "Salasanaa ei voitu palauttaa. Yritä uudelleen tai ota yhteys järjestelmänvalvojaan",
  "resetpassword.link_too_old": "linkki on liian vanha",

  /** Registration */
  "registration.error":
    "Rekisteröintiä ei ollut mahdollista suorittaa. Yritä uudelleen tai ota yhteys järjestelmänvalvojaan.",
  "registration.title": "Rekisteröinti",
  "registration.processing": "Käsittely ...",
  "registration.not_enabled": "Rekisteröinti ei ole käytettävissä.",
  "registration.completed_headline": "Olet rekisteröitynyt",
  "registration.completed": "Rekisteröinti on valmis.",
  "registration.completed_admin_approval":
    "Järjestelmänvalvojan on hyväksyttävä tilisi, ennen kuin voit kirjautua sisään.",
  "registration.completed_verification":
    "Tilisi on vahvistettava, ennen kuin voit kirjautua sisään. Saat pian sähköpostin ohjeineen.",
  "registration.completed_link": "/",
  "registration.username_exists": "Käyttäjätunnus on jo rekisteröity. Anna toinen käyttäjätunnus.",
  "registration.email_exists": "Sähköposti on jo rekisteröity. Anna toinen sähköpostiosoite.",
  "registration.sip_exists": "SIP-osoite on jo rekisteröity. Anna toinen SIP-osoite.",
  "registration.license1": "Kyllä, olen lukenut",
  "registration.license_link_text": "Tietoja henkilötietojen käsittelystä",
  "registration.license2": "ja hyväksyä tämän ehdot",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Hyväksy ehdot, jos haluat rekisteröityä",
  "registration.register": "Rekisteröidy",

  /** Verify Account */
  "verifyaccount.completed.title": "Tilisi on aktivoitu!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Jotain meni pieleen! Yritä rekisteröidä tili uudelleen.",
  "verifyaccount.error.expired": "Vahvistuslinkki on vanhentunut. Yritä rekisteröidä tili uudelleen.",
  "verifyaccount.registration": "Haluatko rekisteröityä?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Muokkaa tiliä",
  "changeuser.titlePassword": "Vaihda salasana",
  "changeuser.newPassword": "Uusi salasana",
  "changeuser.error":
    "Tilin päivittämistä ei ollut mahdollista. Yritä uudelleen tai ota yhteyttä järjestelmänvalvojaan.",
  "changeuser.complete": "Tilin päivitys",
  "changeuser.processing": "Käsittely ...",
  "changeuser.username_exists": "Käyttäjätunnus on jo rekisteröity. Anna toinen käyttäjätunnus.",
  "changeuser.email_exists": "Sähköposti on jo rekisteröity. Anna toinen sähköpostiosoite.",
  "changeuser.email_empty": "Sähköposti on pakollinen. Anna sähköpostiosoite.",
  "changeuser.sip_exists": "SIP-osoite on jo rekisteröity. Anna toinen SIP-osoite.",

  /** Network */
  "network.networkError": "Ei yhteyttä palvelimeen",
  "network.establishing": "Yritetään muodostaa yhteys palvelimeen...",
  "network.offline": "Poissa",

  /** Accessibility */
  "accessibility.darktheme": "Tumma Teema",
  "accessibility.originaltheme": "Alkuperäinen Teema",

  /** About */
  "about.user": "Käyttäjätunnus",
  "about.sip": "SIP-osoitetta",
  "about.server": "Palvelimen IP",
  "about.version": "Versio",
  "about.browser": "Selain",
  "about.browserversion": "Selainversio",
  "about.os": "Käyttöjärjestelmä",

  /** Aria */
  "aria.mainmenu": "Päävalikko",
  "aria.submenu_contacts": "Yhteystiedot-alivalikko",
  "aria.submenu_account": "Tilin alivalikko",
  "aria.selected_page": "Sinun sid",
  "aria.back_button": "Poista viimeinen merkki",
  "aria.accept_terms": "Hyväksy ehdot",
  "aria.link_sound_video": "Siirry äänen ja videon asetuksiin",
  "aria.selfview": "Selfview video",
  "aria.in-call": "Puhelun aikana",

  /** Kiosk */
  "kiosk.call_ended": "Puhelu on päättynyt, voit nyt sulkea tämän sivun.",

  /** Modal */
  "modal.close": "Sulkea",
  "modal.cancel": "Peruuttaa",
  "modal.confirm": "Hyvä",
  "modal.logout_confirmation.title": "Haluatko varmasti kirjautua ulos?",
  "modal.logout_confirmation.confirm": "Joo",
  "modal.logout_confirmation.cancel": "Ei, peruuta",
};

export default translations;
