import $ from "jquery";
import isNumber from "lodash/isNumber";
import isEmpty from "lodash/isEmpty";
import { SETTINGS } from "./actionTypes";
import Storage from "../misc/storage";
import SettingsService from "../services/settingsService";

export function loadSettings() {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    dispatch({
      type: SETTINGS.USER_LOADING,
      payload: {},
    });

    if (getstate().user.anonymous === true && Storage.local.get("settings") != null) {
      var values = JSON.parse(Storage.local.get("settings"));
      var current = toServerSettings(values);

      dispatch({
        type: SETTINGS.USER_LOADED,
        payload: {
          ...current,
        },
      });
    } else if (getstate().user.anonymous === true) {
      dispatch({
        type: SETTINGS.USER_LOADED_DEFAULT,
      });
    } else {
      SettingsService.webSettings(getstate().config.server.url, securityToken, dispatch)
        .done(data => {
          var payload = {};
          data.forEach(function(element) {
            payload[element.key] = element.value;
          });

          dispatch({
            type: SETTINGS.USER_LOADED,
            payload: payload,
          });
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          console.error(xhr);
        });
    }
  };
}

export function saveSettings(values) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;
    var oldValues = getstate().setting.user;
    var current = toServerSettings(values);

    // if (getstate().network.sipError) {
    //   dispatch({
    //     type: ActionTypes.SIP_CLEAR_ALERT,
    //     payload: {},
    //   });
    // }

    //If anonymous the settings should only be save locally
    if (getstate().user.anonymous === true) {
      Storage.local.set("settings", JSON.stringify(values));
      dispatch({
        type: SETTINGS.SAVE,
        payload: {
          ...current,
        },
      });
      return;
    } else {
      var posts = [];
      for (var property in current) {
        if (current.hasOwnProperty(property)) {
          if (!oldValues[property] || oldValues[property] !== current[property]) {
            if (!(isEmpty(oldValues[property]) && isEmpty(current[property]))) {
              posts.push(update(property, current[property], getstate().config.server.url, securityToken, dispatch));
            }
          }
        }
      }

      $.when(...posts).then(() => {
        dispatch({
          type: SETTINGS.SAVE,
          payload: {
            ...current,
          },
        });
      });
    }
  };
}

function update(name, value, server, securityToken, dispatch) {
  var data = isNumber(value) ? value.toString() : value;
  return SettingsService.updateSetting(name, data, server, securityToken, dispatch).fail(
    (xhr, ajaxOptions, thrownError) => {
      console.error(xhr);
    }
  );
}

function toServerSettings(values, ret = {}) {
  if (values.autoAnswerMessage) ret.AM_MESSAGE = values.autoAnswerMessage;
  if (values.autoAnswerMessageEnabled != null) ret.AM_ENABLED = values.autoAnswerMessageEnabled ? "1" : "0";
  if (values.font) ret.WEB_FONT = values.font;
  if (values.fontsize) ret.WEB_TEXT_SIZE = values.fontsize;
  if (values.rectextbackground) ret.WEB_TEXTAREA_IN_BACKGROUND = values.rectextbackground;
  if (values.rectextforeground) ret.WEB_TEXT_IN_COLOR = values.rectextforeground;
  if (values.ringtone) ret.WEB_RINGTONE = values.ringtone;
  if (values.senttextbackground) ret.WEB_TEXTAREA_OUT_BACKGROUND = values.senttextbackground;
  if (values.senttextforeground) ret.WEB_TEXT_OUT_COLOR = values.senttextforeground;
  if (values.onetextbackground) ret.WEB_ONEBOX_TEXT_BACKGROUND_COLOR = values.onetextbackground;
  if (values.onetextforeground) ret.WEB_ONEBOX_TEXT_COLOR = values.onetextforeground;
  if (values.textbox) ret.WEB_CHAT_LAYOUT = values.textbox;
  if (values.audio) ret.WEB_AUDIO = values.audio;
  if (values.video) ret.WEB_VIDEO = values.video;
  if (values.bandwidth) ret.WEB_BANDWIDTH = values.bandwidth;

  return ret;
}
