import React from "react";
import { withRouter } from "react-router-dom";

class ErrorAlert extends React.Component {
  errorAlert = React.createRef();

  componentDidMount() {
    this.errorAlert.current.focus();
  }

  render() {
    return (
      <div
        ref={this.errorAlert}
        className={"alert" + (this.props.severity ? " alert-" + this.props.severity : " alert-danger")}
        role="alert"
      >
        <div>{this.props.text}</div>
        {this.props.severity === "info" && this.props.dismissText && this.props.onDismiss && (
          <button className="btn btn-link alert-link p-0 m-0" onClick={this.props.onDismiss}>
            {this.props.dismissText}
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(ErrorAlert);
