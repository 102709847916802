import MmxApi from "../api/mmxApi";

const settingsService = {
  webSettings: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.settings.webSettings();
  },

  updateSetting: (key, payload, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.settings.updateSetting(key, payload);
  },
};

export default settingsService;
