import React from "react";
import { connect } from "react-redux";
import { loadContacts } from "../actions/contactActions";

import { logout } from "../actions/userActions";
import UserSession from "../misc/usersession";

import DefaultLayout from "../layouts/DefaultLayout";
import Storage from "../misc/storage";

class MainPage extends React.Component {
  logoutEvent(event) {
    if (event.key === `${Storage._prefix}.logout-event`) {
      const user = UserSession.getUser();
      if (user !== null && user !== undefined) {
        event.preventDefault();
        this.props.history.push("/");
        this.props.logout();
        window.removeEventListener("storage", this.logoutEvent);
      }
    }
  }

  componentDidMount() {
    this.props.loadContacts();
    window.addEventListener("storage", this.logoutEvent.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.logoutEvent);
  }

  render() {
    return <DefaultLayout unsupportedBrowser={this.props.unsupportedBrowser} />;
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.contact.contacts,
    authenticated: state.user.authenticated,
    unsupportedBrowser: state.config.unsupportedBrowser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadContacts: () => {
      return dispatch(loadContacts());
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
