import React from "react";
import PropTypes from "prop-types";
import CallButton from "../components/buttons/CallButton";
import "./MessageList.css";

const MessageItem = props => {
  const read = () => (
    <div className="messages-message-container">
      <div className="messages-message-text">{props.item.name}</div>
      <div>
        <small>{props.item.date}</small>
      </div>
    </div>
  );

  const unread = () => (
    <strong>
      <div className="messages-message-container">
        <div className="messages-message-text">{props.item.name}</div>
        <div>{props.item.date}</div>
      </div>
    </strong>
  );

  const messageItem = props.item.read ? read() : unread();

  return (
    <li className={`list-group-item`}>
      <div className="d-flex justify-content-between flex-wrap-wrap">
        <div className="text-box">
          <div className="padding-3px">{messageItem}</div>
        </div>
        <div className="float-right list-link-buttons d-flex justify-content-between">
          <button
            className="link-button no-link-button"
            text={props.text("common.view_message")}
            onClick={() => props.onRead(props.item)}
          >
            <i aria-hidden="true" className="material-icons icon-link-button">
              mail_outline
            </i>
            <div className="font-size-smaller text-link-button">{props.text("common.view_message")}</div>
          </button>
          <CallButton sipaddress={props.item.fromDisplayName} />
          <button
            className="link-button no-link-button"
            text={props.text("common.delete")}
            onClick={() => props.onDelete(props.item)}
          >
            <i aria-hidden="true" className="material-icons icon-link-button">
              delete
            </i>
            <div className="font-size-smaller text-link-button">{props.text("common.delete")}</div>
          </button>
        </div>
      </div>
    </li>
  );
};
MessageItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  onCall: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
};

const MessageList = props => {
  const messageItems = props.messages.map((item, index) => (
    <MessageItem
      key={index}
      item={item}
      onDelete={props.onDelete}
      onRead={props.onRead}
      onCall={props.onCall}
      text={props.text}
    />
  ));
  return (
    <div className="row">
      <div className="col">
        <ul className="list-group">{messageItems}</ul>
      </div>
    </div>
  );
};
MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  onCall: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
};

export default MessageList;
