import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { saveSettings, loadSettings } from "../../actions/settingActions";
import { SETTINGS } from "../../actions/actionTypes";
import Loading from "../../components/Loading";
import Button from "../../components/buttons/Button";
import "../Settings.css";

class Sound extends React.Component {
  /* The currently focused ringtone */
  audio = null;

  componentDidMount() {
    this.props.loadSettings();
  }

  componentWillUnmount() {
    this.props.clearSettingsSaved();
    if (this.audio !== null) {
      this.audio.pause();
    }
  }

  onSubmit = values => {
    this.props.saveSettings(values);
  };

  playRingtone(event) {
    if (this.audio !== null) {
      this.audio.pause();
    }

    if (!(event.target.value in this.props.ringtones)) {
      return;
    }

    this.audio = new Audio(this.props.ringtones[event.target.value].audio);
    this.audio.play();
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.config.features.settings.allowChangeRingtone) {
      return <Redirect to={"/settings"} />;
    }

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        {this.props.config.features.settings.allowChangeRingtone && (
          <div>
            <div className="form-group font-weight-bold">
              <label htmlFor="settings-ringtone">{this.props.text("settings.ringtone")}</label>
              <br />
              {this.props.ringtone}
              <Field
                id="settings-ringtone"
                component="select"
                className="form-control"
                name="ringtone"
                onChange={this.playRingtone.bind(this)}
              >
                {Object.keys(this.props.ringtones).map(key => (
                  <option value={key} key={key}>
                    {this.props.text(this.props.ringtones[key].text)}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div />
          <div className="float-right">
            <Button title=" " type="submit" icon="save" text={this.props.text("common.save")} />
            {this.props.smallScreenMode && (
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            )}
          </div>
        </div>
        <div />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      ringtone: state.setting.user.WEB_RINGTONE,
    },
    enableReinitialize: true,
    ringtones: state.config.ringtones,
    loading: state.setting.loading,
    text: state.config.text,
    config: state.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: () => {
      return dispatch(loadSettings());
    },
    clearSettingsSaved: () => {
      dispatch({ type: SETTINGS.SAVED_RESET });
    },
  };
};

Sound = reduxForm({
  form: "sound",
})(Sound);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sound));
