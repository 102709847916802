import { NOTIFICATION } from "./actionTypes";

function createNotificationCalling(title: string, body?: string, action?: Function, icon?: string) {
  if (window.Notification && window.Notification.permission === "granted") {
    const notification = new Notification(title, {
      body,
      icon,
      requireInteraction: true,
      vibrate: [200, 100, 200, 100, 200, 100, 400],
    });

    notification.onclick = function(event) {
      if (action) {
        action();
      }
      window.focus();
      this.close();
    };

    return notification;
  }

  return null;
}

export function triggerNotification(title: string, body?: string, action?: Function, icon?: string) {
  return (dispatch: any, getState: any) => {
    let notification = getState().notification;
    if (notification) {
      notification.close();
    }

    icon = icon || getState().config.branding.icon;
    notification = createNotificationCalling(title, body, action, icon);

    dispatch({
      type: NOTIFICATION.TRIGGER,
      payload: notification,
    });
  };
}

export function removeNotification() {
  return (dispatch: any, getState: any) => {
    const notification = getState().notification;
    if (notification) {
      notification.close();
      dispatch({ type: NOTIFICATION.REMOVE });
    }
  };
}
