import React from "react";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import { Web } from "sip.js";
import { IRootState } from "../models/reducerStates";
import { ISipState } from "../models/reducerStates";

type AudioVideoProps = {
  isVisible?: boolean;
  isVideoAvailable?: boolean;
  direction?: string;
  kioskmode?: boolean;
  textIsVisible?: boolean;
  browser?: string;
  sip?: ISipState;
  muted?: boolean;
};

class AudioVideo extends React.Component<AudioVideoProps> {
  mediaElement: JSX.Element | undefined;
  mediaElementRef = React.createRef<HTMLAudioElement | HTMLVideoElement>();
  id: string;
  flexbox: string;
  textBox: string;
  visibleClass: string;

  constructor(props: AudioVideoProps) {
    super(props);

    this.id = this.props.direction === "local" ? "local-video-container" : "remote-video-container";
    this.flexbox = this.props.kioskmode === true ? "display-flex" : "";
    this.textBox = this.props.textIsVisible === true ? " video-container-width" : "";
  }

  audio(): JSX.Element {
    return (
      <audio
        ref={this.mediaElementRef as React.RefObject<HTMLAudioElement>}
        className="min-width-height-100-procent"
        id="remote-audio"
        muted={this.props.muted}
      >
        <track kind="captions" src="noCaptions.vtt" />
      </audio>
    );
  }

  video(direction: string): JSX.Element {
    const id = direction === "local" ? "local-video" : "remote-video";

    let classes = "";
    if (this.props.kioskmode === true) {
      classes = direction === "local" ? "kiosk-local-video" : "kiosk-remote-video";
    } else {
      classes =
        direction === "local" ? "local-video" : this.props.textIsVisible ? "remote-video video-width" : "remote-video";
    }

    return (
      <video
        ref={this.mediaElementRef as React.RefObject<HTMLVideoElement>}
        id={id}
        playsInline={true}
        muted={direction === "local" ? true : this.props.muted}
        className={classes}
      >
        <track kind="captions" src="noCaptions.vtt" />
        <track kind="descriptions" src="noDescription.vtt" srcLang="en" label="english_description"></track>
      </video>
    );
  }

  componentDidMount() {
    const sessionDescriptionHandler = this.props.sip.phone.session.sessionDescriptionHandler;

    if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Invalid session description handler.");
    }

    const stream =
      this.props.direction === "local"
        ? sessionDescriptionHandler.localMediaStream
        : sessionDescriptionHandler.remoteMediaStream;

    const element = this.mediaElementRef.current as HTMLMediaElement;
    element.autoplay = true; // Safari does not allow calling .play() from a non user action
    element.srcObject = stream;

    element.play().catch(e => {
      console.error("Failed to play media");
      console.error(e);
    });

    // If a track is added, load and restart playback of media.
    stream.onaddtrack = () => {
      element.load(); // Safari does not work otheriwse
      element.play().catch(e => {
        console.error("Failed to play remote media on add track");
        console.error(e);
      });
    };

    // If a track is removed, load and restart playback of media.
    stream.onremovetrack = () => {
      element.load(); // Safari does not work otheriwse
      element.play().catch(e => {
        console.error("Failed to play remote media on remove track");
        console.error(e);
      });
    };
  }

  render() {
    this.mediaElement = this.props.isVideoAvailable ? this.video(this.props.direction) : this.audio();
    this.visibleClass = this.props.isVisible ? "" : " display-none";

    if (this.props.direction === "local" && !this.props.kioskmode) {
      return (
        <Rnd
          className={this.visibleClass}
          bounds={".callViewContainer"}
          lockAspectRatio={true}
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: this.props.browser === "ie" ? false : true,
            bottomLeft: false,
            topLeft: false,
          }}
          default={{
            x: 0,
            y: 0,
            height: "auto",
            width: "15%",
          }}
        >
          {this.mediaElement}
        </Rnd>
      );
    }

    return (
      <div
        id={this.id}
        className={
          this.flexbox +
          this.visibleClass +
          this.textBox +
          (this.props.kioskmode === true ? " local-video-container-kiosk" : "")
        }
      >
        {this.mediaElement}
      </div>
    );
  }
}

const mapStateToProps = (state: Readonly<IRootState>) => ({
  sip: state.sip,
  muted: !state.call.soundOn,
  browser: state.config.browser.name.toLowerCase(),
});

export default connect(mapStateToProps)(AudioVideo);
