/**
 * The base for this is copied from SIP.js (sip.js/lib/platform/web/modifiers/modifiers.js).
 * It contains the stripMediaDescription and stripVideo, but we need a stripAudio.
 * Unfortunately they only export stripVideo, not the helper function stripMediaDescription.
 */

const stripMediaDescription = (sdp: string, description: "audio" | "video") => {
  const descriptionRegExp = new RegExp("m=" + description + ".*$", "gm");
  const groupRegExp = new RegExp("^a=group:.*$", "gm");
  if (descriptionRegExp.test(sdp)) {
    let midLineToRemove;
    sdp = sdp
      .split(/^m=/gm)
      .filter((section: string) => {
        if (section.substr(0, description.length) === description) {
          midLineToRemove = section.match(/^a=mid:.*$/gm);
          if (midLineToRemove) {
            const step = midLineToRemove[0].match(/:.+$/g);
            if (step) {
              midLineToRemove = step[0].substr(1);
            }
          }
          return false;
        }
        return true;
      })
      .join("m=");
    const groupLine = sdp.match(groupRegExp);
    if (groupLine && groupLine.length === 1) {
      let groupLinePortion = groupLine[0];
      // eslint-disable-next-line no-useless-escape
      const groupRegExpReplace = new RegExp(" *" + midLineToRemove + "[^ ]*", "g");
      groupLinePortion = groupLinePortion.replace(groupRegExpReplace, "");
      sdp = sdp.split(groupRegExp).join(groupLinePortion);
    }
  }
  return sdp;
};

export function stripAudio(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
  description.sdp = stripMediaDescription(description.sdp || "", "audio");
  return Promise.resolve(description);
}

export function stripVideo(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
  description.sdp = stripMediaDescription(description.sdp || "", "video");
  return Promise.resolve(description);
}
