import React from "react";
import isEmpty from "lodash/isEmpty";
import { Field } from "redux-form";

class InputText extends React.Component {
  renderField = ({ input, value, meta: { error } }) => {
    let className = "form-control";

    if (input.value.length > 0) {
      if (error) {
        className = "form-control is-invalid";
      } else {
        if (this.props.field.required) {
          className = "form-control is-valid";
        }

        if (this.props.field.regex && !isEmpty(input.value)) {
          className = "form-control is-valid";
        }
      }
    }

    let type;
    switch (this.props.name) {
      case "email":
        type = "email";
        break;
      case "password1":
      case "password2":
        type = "password";
        break;
      default:
        type = "text";
        break;
    }

    return (
      <div>
        <label htmlFor={this.props.name} className="font-weight-bold">
          {this.props.text + (this.props.field.required ? " *" : "")}
        </label>
        <input
          id={this.props.name}
          type={type}
          autoComplete={this.props.autoComplete}
          autoCapitalize={this.props.autoCapitalize}
          className={className}
          required={this.props.field.required}
          placeholder={this.props.name === "email" ? "me@example.org" : ""}
          {...input}
        />
        {error && typeof error === "string" && (
          <span className="color-red" dangerouslySetInnerHTML={{ __html: error }}></span>
        )}
      </div>
    );
  };

  required = value => (value || !this.props.field.required ? undefined : true);

  regex = value => {
    if (value && this.props.field.regex && !(this.props.name === "password1" || this.props.name === "password2")) {
      const regEx = new RegExp(this.props.field.regex);

      if (regEx.test(value)) {
        return undefined;
      }

      if (this.props.field.regexText) {
        return this.props.field.regexText;
      }

      return true;
    }

    return undefined;
  };

  render() {
    if (!this.props.field.visible) {
      return null;
    }

    return (
      <div className="form-group">
        <Field
          component={this.renderField}
          name={this.props.name}
          validate={[this.required, this.regex]}
          autoComplete={this.props.autoComplete}
          autoCapitalize={this.props.autoCapitalize}
        />
      </div>
    );
  }
}

export default InputText;
