const detectLocalIpAddress = server => {
  if (typeof server === "string") {
    server = new URL(server);
  }

  const promise = new Promise(resolve => {
    const configuration = {
      iceServers: [{ urls: `stun:${server.host}:40000` }],
    };

    const pc = new RTCPeerConnection(configuration);

    pc.onicecandidate = event => {
      const candidate = event.candidate;

      if (candidate) {
        if (candidate.type === "srflx") {
          resolve(candidate.ip);
        }
      } else {
        pc.close();
      }
    };
  });

  return promise;
};

export default detectLocalIpAddress;
