import React from "react";
import { Link } from "react-router-dom";
import "./NavItemMaterial.css";

const subNavItemMaterial = props => {
  var chosenElemClass = props.chosen === true ? "activeNavElem" : "";

  return (
    <li className={"navbar-sub-item " + chosenElemClass} aria-current={chosenElemClass !== "" ? "page" : "false"}>
      <Link className={"nav-link " + chosenElemClass} to={props.route ? "/" + props.route : ""} onClick={props.onClick}>
        <div className="text-align-center nav-focus-border nav-content-container">
          <div className="text-align-center font-size-large nav-text">{props.text}</div>
        </div>
      </Link>
    </li>
  );
};

export default subNavItemMaterial;
