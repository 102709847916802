import { APP, CALLS, SIP_ACTION } from "../actions/actionTypes";
import { CallStatus } from "../types";
import { ICallState } from "../models/reducerStates";

const initialState: ICallState = {
  callTo: null,
  callStatus: null,
  calling: false,
  text_in: "",
  text_out: "",
  text_combined: "",
  text_dtmf: "",
  text_unread: false,
  videoVisible: true,
  textVisible: true,
  soundOn: true,
  dialerVisible: false,
  selfViewVisible: true,
  showMore: false,
  audio: true,
  video: true,
  knockknock: false,
  fullscreenEnabled: false,
  audioavailable: null,
  callB: null,
  callBName: null,
  textavailable: null,
  videoavailable: null,
  view: null,
  shouldAlertOnIncomingMessage: false,
};

const callReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CALLS.ANSWER:
      state = {
        ...state,
        calling: true,
        callStatus: CallStatus.INCALL,
        text_in: "",
        text_out: "",
        text_combined: "",
        text_dtmf: "",
        text_unread: false,
        videoVisible: true,
        textVisible: true,
        soundOn: true,
        dialerVisible: false,
        selfViewVisible: true,
        showMore: false,
        knockknock: false,
        fullscreenEnabled: false,
      };
      break;
    case CALLS.CHANGE_VIEW:
      state = {
        ...state,
        view: action.view,
      };
      break;
    case CALLS.CALL:
      state = {
        ...state,
        calling: true,
        callStatus: CallStatus.OUTGOING,
        callB: action.payload,
        callBName: null,
        knockknock: false,
      };
      break;
    case CALLS.HANGUP:
      state = {
        ...state,
        calling: false,
        callStatus: CallStatus.HANGUPA,
      };
      break;
    case CALLS.DTMF:
      state = {
        ...state,
        text_dtmf: notMaskDtmf(state.text_dtmf, action.payload),
      };
      break;
    case CALLS.REJECT:
      state = {
        ...state,
        calling: false,
        callStatus: CallStatus.REJECTEDB,
      };
      break;
    case APP.IS_IN_BACKGROUND:
      state = {
        ...state,
        shouldAlertOnIncomingMessage: action.payload,
      };
      break;
    case CALLS.TEXT_MSG_IN:
      state = {
        ...state,
        text_in: state.text_in + action.payload,
        text_combined: state.text_combined + action.payload,
        text_unread: state.textVisible ? false : true,
        shouldAlertOnIncomingMessage: false,
      };
      break;
    case CALLS.TEXT_MSG_IN_CLEAR:
      state = {
        ...state,
        text_in: "",
      };
      break;
    case CALLS.TEXT_MSG_IN_DEL:
      let text = state.text_in;

      if (text.length > 0) {
        state = {
          ...state,
          text_in: text.substring(0, text.length - 1),
          text_combined: state.text_combined.substring(0, state.text_combined.length - 1),
        };
      } else {
        state = {
          ...state,
        };
      }
      break;
    case CALLS.TEXT_MSG_OUT:
      state = {
        ...state,
        text_out: state.text_out + action.payload,
        text_combined: state.text_combined + action.payload,
      };
      break;
    case CALLS.TEXT_MSG_OUT_CLEAR:
      state = {
        ...state,
        text_out: "",
      };
      break;
    case CALLS.TEXT_MSG_OUT_DEL:
      let text2 = state.text_out;

      if (text2.length > 0) {
        state = {
          ...state,
          text_out: text2.substring(0, text2.length - 1),
          text_combined: state.text_combined.substring(0, state.text_combined.length - 1),
        };
      } else {
        state = {
          ...state,
        };
      }
      break;
    case SIP_ACTION.INVITE_RECIEVED:
      state = {
        ...state,
        callB: action.payload.callB,
      };
      break;
    case SIP_ACTION.CALL_ACCEPTED_LOCAL:
      state = {
        ...state,
        audioavailable: action.payload.audio,
        textavailable: action.payload.text,
        videoavailable: action.payload.video,
        text_in: "",
        text_out: "",
        text_combined: "",
        text_dtmf: "",
        text_unread: false,
        videoVisible: action.payload.video,
        textVisible: action.payload.text,
        soundOn: true,
        dialerVisible: false,
        selfViewVisible: action.payload.video,
        showMore: false,
        audio: true,
        video: true,
        knockknock: false,
        fullscreenEnabled: false,
      };
      break;
    case SIP_ACTION.CALLING:
      state = {
        ...initialState,
        audioavailable: action.payload.audio,
        textavailable: action.payload.text,
        videoavailable: action.payload.video,
        callB: action.payload.callB,
        callBName: action.payload.callBName,
      };

      if (action.payload.video) {
        state.videoVisible = true;
      }

      if (action.payload.text) {
        state.textVisible =
          typeof action.payload.options.textVisible === "undefined" ? true : action.payload.options.textVisible;
      }
      break;
    case CALLS.MUTE_AUDIO:
      state = {
        ...state,
        audio: !state.audio,
      };
      break;
    case CALLS.MUTE_REMOTE_AUDIO:
      state = {
        ...state,
        soundOn: !state.soundOn,
      };
      break;
    case CALLS.MUTE_VIDEO:
      state = {
        ...state,
        video: !state.video,
      };
      break;
    case CALLS.HIDE_VIDEO:
      state = {
        ...state,
        videoVisible: !state.videoVisible,
      };
      break;
    case CALLS.TOGGLE_TEXT:
      state = {
        ...state,
        textVisible: typeof action.payload !== "undefined" ? action.payload : !state.textVisible,
      };

      if (state.textVisible) {
        state.text_unread = false;
      }
      break;
    case CALLS.HIDE_DIALER:
      state = {
        ...state,
        dialerVisible: !state.dialerVisible,
      };
      break;
    case CALLS.HIDE_SELFVIEW:
      state = {
        ...state,
        selfViewVisible: !state.selfViewVisible,
      };
      break;
    case CALLS.SHOW_MORE:
      state = {
        ...state,
        showMore: !state.showMore,
      };
      break;
    case CALLS.KNOCK_KNOCK:
      state = {
        ...state,
        knockknock: action.payload,
      };
      break;
    case CALLS.TOGGLE_FULLSCREEN:
      state = {
        ...state,
        fullscreenEnabled: typeof action.payload !== "undefined" ? action.payload : !state.fullscreenEnabled,
      };
      break;
    default:
      break;
  }
  return state;
};

// eslint-disable-next-line
function notMaskDtmf(current: any, dtmf: any) {
  console.log("Current", current);
  console.log("DTMF", dtmf);
  return current + dtmf;
}

export default callReducer;
