import { SIP_ACTION } from "../actions/actionTypes";
import { KIOSK_STATE } from "../constants/kioskState";
import { IKioskState } from "../models/reducerStates";

const initialState: IKioskState = {
  state: KIOSK_STATE.INIT,
  callB: null,
  externalReference: null,
};

const kioskReducer = (state: IKioskState = initialState, action: any) => {
  switch (action.type) {
    case SIP_ACTION.REGISTER_SUCCESS:
      return {
        ...state,
        state: KIOSK_STATE.READY,
      };

    case SIP_ACTION.CALL_COMPLETED:
      return {
        ...state,
        state: KIOSK_STATE.CALL_COMPLETED,
      };

    default:
      break;
  }

  return state;
};

export default kioskReducer;
