import React from "react";
import { connect } from "react-redux";
import { dtmf } from "../actions/dtmfActions";
import Dialer from "../components/Dialer";
import { Rnd } from "react-rnd";
import "./InCall.css";
import "./DialerView.css";
import { CALLS } from "../actions/actionTypes";

class DialerViewI extends React.Component {
  constructor(props) {
    super(props);
    this.textInputRef = null;
    this.minimumFontSize = 24;
    this.maximumFontSize = 70;
    this.state = {
      fontSize: this.minimumFontSize,
    };
  }

  componentDidUpdate() {
    // TODO: Ask Nils about this ref
    if (this.textInputRef) this.textInputRef.scrollLeft = this.textInputRef.scrollWidth;
  }

  onInputDtmf = event => {
    var textOld = this.props.textDtmf;
    var textNew = this.textInputRef.value;
    var lengthDiff = textNew.length - textOld.length;

    // No change
    if (lengthDiff !== 1) {
      return;
    }

    var msg = textNew.substring(textOld.length, textNew.length);

    this.props.dtmf(msg);
  };

  render() {
    const { isVisible, textDtmf, dtmf } = this.props;
    const visibleClass = isVisible ? "" : " display-none";
    return (
      <div className={"modal dialer-container incall-dialer-container" + (isVisible ? "" : " display-none")}>
        <Rnd
          className={"modal-dialog dialer-view " + visibleClass}
          role="dialog"
          bounds={"parent"}
          lockAspectRatio={3 / 4}
          resizeHandleStyles={{
            top: {
              pointerEvents: "all",
            },
            right: {
              pointerEvents: "all",
            },
            bottom: {
              pointerEvents: "all",
            },
            left: {
              pointerEvents: "all",
            },
            topRight: {
              pointerEvents: "all",
            },
            bottomRight: {
              pointerEvents: "all",
            },
            bottomLeft: {
              pointerEvents: "all",
            },
            topLeft: {
              pointerEvents: "all",
            },
          }}
          enableResizing={{
            top: true,
            right: true,
            bottom: true,
            left: true,
            topRight: true,
            bottomRight: true,
            bottomLeft: true,
            topLeft: true,
          }}
          default={{
            x: 0,
            y: 0,
            width: 250,
            height: 333,
          }}
          minWidth={250}
          minHeight={333}
          onResize={(e, direction, ref, delta, position) => {
            let fontSize = parseInt(ref.offsetHeight / 5, 10) - this.minimumFontSize * 2;
            fontSize = fontSize >= this.minimumFontSize ? fontSize : this.minimumFontSize;
            fontSize = fontSize <= this.maximumFontSize ? fontSize : this.maximumFontSize;
            this.setState({
              fontSize: fontSize,
            });
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.props.hideDialer}
                aria-label={this.props.text("modal.close")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                value={textDtmf}
                className="form-control form-control-lg inCall-dialer"
                disabled
                type="text"
                style={{ fontSize: this.state.fontSize }}
                ref={o => (this.textInputRef = o)}
                onInput={this.onInputDtmf}
              />
              <Dialer
                className="margin-top-1em"
                fontSize={this.state.fontSize}
                onClick={str => {
                  dtmf(str);
                }}
                inCall={true}
              />
            </div>
          </div>
        </Rnd>
      </div>
    );
  }
}

const mstp = state => {
  return {
    isVisible: state.call.dialerVisible,
    textDtmf: state.call.text_dtmf,
    text: state.config.text,
  };
};

const mdtp = dispatch => {
  return {
    hideDialer: () => {
      return dispatch({
        type: CALLS.HIDE_DIALER,
      });
    },
    dtmf: str => {
      return dispatch(dtmf(str));
    },
  };
};

export default connect(mstp, mdtp)(DialerViewI);
