import { CONFIG, APP } from "../actions/actionTypes";
import logo from "../assets/images/logo.png";
import logoDark from "../assets/images/logo_dark.png";
import icon from "../assets/images/icon.png";
import merge from "lodash/merge";
import defaultConfig from "../config/config";
import { IConfigState } from "../models/reducerStates";
import { setTexts } from "../language";

export const isKiosk = (state: any) => state.config.features.kiosk.enabled;

const configReducer = (state: IConfigState = defaultConfig, action: any) => {
  switch (action.type) {
    case CONFIG.LOCAL_IP_AQUIRED:
      state = {
        ...state,
        localIp: action.ip,
      };
      break;
    case CONFIG.SET_BROWSER_SUPPORT:
      state = {
        ...state,
        browser: {
          ...state.browser,
          webrtc: action.payload.webrtc,
          isSupported: action.payload.supported,
          errorMessage: action.payload.message || null,
        },
      };
      break;
    case CONFIG.SET:
      const newState = merge(state, action.payload);
      setTexts(newState.texts);
      state = newState;

      state.branding.logoUrl = logo;
      if (action.payload.branding && action.payload.branding.logo) {
        if (typeof action.payload.branding.logo === "string") {
          state.branding.logoUrl = `./branding/${action.payload.branding.logo}`;
        } else {
          state.branding.logoUrl = "./branding/logo.png";
        }
      }

      state.branding.logoDarkUrl = logoDark;
      if (action.payload.branding && action.payload.branding.logo) {
        if (action.payload.branding.logoDark && typeof action.payload.branding.logoDark === "string") {
          state.branding.logoDarkUrl = `./branding/${action.payload.branding.logoDark}`;
        } else {
          state.branding.logoDarkUrl = "./branding/logo_dark.png";
        }
      }

      state.branding.icon = icon;
      if (action.payload.branding && action.payload.branding.icon) {
        if (typeof action.payload.branding.icon === "string") {
          state.branding.icon = `./branding/${action.payload.branding.icon}`;
        } else {
          state.branding.icon = "./branding/icon.png";
        }
      }
      break;
    case CONFIG.INIT_COMPLETED:
      state = {
        ...state,
        init: true,
      };
      break;
    // case ActionTypes.CONFIG_REQUIREMEDIA_STARTED:
    case CONFIG.REQUIREMEDIA_SUCCESS:
      state = {
        ...state,
        audio: action.payload.audio,
        video: action.payload.video,
        hasPermissions: true,
      };
      break;
    case CONFIG.REQUIREMEDIA_FAILURE:
      state = {
        ...state,
        audio: action.payload.audio,
        video: action.payload.video,
        hasPermissions: false,
      };
      break;
    case CONFIG.BIND_LOCAL_STREAM:
      state = {
        ...state,
        localStream: action.payload.stream,
      };
      break;
    case CONFIG.UNBIND_LOCAL_STREAM:
      state = {
        ...state,
        localStream: null,
      };
      break;
    case CONFIG.SYSTEM_LOADED:
      state = {
        ...state,
        loading: false,
        system: {
          ...state.system,
          ...action.payload.systemConfig,
        },
      };
      break;
    case CONFIG.SYSTEM_LOADING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIG.DEBUG_ENABLE:
      state.debug.enabled = true;
      state.debug.showInMenu = true;
      window.logEnabled = true;
      break;
    case APP.UNSUPPORTED_BROWSER:
      state = {
        ...state,
        unsupportedBrowser: action.payload,
      };
      break;
    case APP.SET_LOADING:
      state = {
        ...state,
        loading: action.payload || false,
      };
      break;
    case APP.SHOW_PERMISSIONS_OVERLAY:
      state = {
        ...state,
        showPermissionsOverlay: true,
      };
      break;
    case APP.HIDE_PERMISSIONS_OVERLAY:
      state = {
        ...state,
        showPermissionsOverlay: false,
      };
      break;

    default:
      break;
  }
  return state;
};

export default configReducer;
