import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class Loading extends React.Component {
  render() {
    return (
      <div className="callFullscreen">
        <div className="text-align-center margin-auto">
          <h1>{this.props.l10n("common.loading")}</h1>
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
            <div className="rect6"></div>
            <div className="rect7"></div>
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
});

export default connect(mapStateToProps)(Loading);
