import { detect } from "detect-browser";

const whitelist = [
  "edge",
  "edge-ios",
  "samsung",
  "edge-chromium",
  "chrome",
  "crios",
  "firefox",
  "fxios",
  "opera-mini",
  "opera",
  "bb10",
  "android",
  "ios",
  "safari",
];

// Browser format to match against: {os}.{osVersion}.{browser}.{browserVersion}
const blacklist = [
  { match: ".ios.7", message: "error.browseroutofdate" },
  { match: ".ios.8", message: "error.browseroutofdate" },
  { match: ".ios.9", message: "error.browseroutofdate" },
  { match: ".ios.10", message: "error.browseroutofdate" },
  { match: "ios.15.1", message: "error.browseroutofdate.ios.15.1" },
  { match: "edge.2", message: "error.browseroutofdate" },
  { match: "edge.3", message: "error.browseroutofdate" },
  { match: "edge.4", message: "error.browseroutofdate" },
];

// Browser format to match against: {os}.{osVersion}.{browser}.{browserVersion}
const greylist = [{ match: "macos", message: "error.browserwarning.macos" }];

const browser = {
  isSupported: () => {
    return new Promise((resolve, reject) => {
      let errorMessage = null;

      try {
        const browser = detect();
        const osVersion = navigator.userAgent.match(/\(\D+([\d+_]+).*\)/)[1].replaceAll("_", ".");
        const browserKey = [browser.os, osVersion, browser.name, browser.version]
          .join(".")
          .toLowerCase()
          .replace(" ", "");

        if (whitelist.indexOf(browser.name.toLowerCase()) === -1) {
          errorMessage = "error.unsupportedbrowser";
          throw new Error("NOT_IN_WHITELIST");
        }

        blacklist.forEach(item => {
          if (browserKey.indexOf(item.match) !== -1) {
            errorMessage = item.message;
            throw new Error("IN_BLACKLIST");
          }
        });

        greylist.forEach(item => {
          if (browserKey.indexOf(item.match) !== -1) {
            errorMessage = item.message;
            throw new Error("IN_GREYLIST");
          }
        });
      } catch (e) {
        reject({
          error: e.message,
          message: errorMessage,
        });
      }

      resolve();
    });
  },
};

export default browser;
