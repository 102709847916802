import React from "react";
import PropTypes from "prop-types";
import { call } from "../../actions/sipActions";
import { connect } from "react-redux";

/**
 * A button which is used for calling a sip adress. Needs to have either a props.onClick
 * which has a call function of its own or props.sip which is sipadress that will be
 * called.
 */
class CallButton extends React.Component {
  onCall(sip) {
    if (sip !== null && sip !== " " && sip !== undefined) {
      this.props.call(sip);
    }
  }

  btn = props => {
    var onClickFunc = props.onClick
      ? props.onClick
      : () => {
          this.onCall(props.sipaddress);
        };
    var buttonText = props.buttonText ? props.buttonText : props.text("common.call");

    return (
      <button className="link-button" onClick={onClickFunc} text={buttonText}>
        <div className={"round-button call-button" + (props.size === "large" ? " round-button-large" : "")}>
          <i aria-hidden="true" className="material-icons call-icon-link-button">
            call
          </i>
        </div>
        <div className={"text-link-button" + (props.size === "large" ? " text-link-button-large" : "")}>
          {buttonText}
        </div>
      </button>
    );
  };

  render() {
    return this.btn(this.props);
  }
}

CallButton.propTypes = {
  text: PropTypes.func.isRequired,
  call: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    call: callTo => {
      return dispatch(call(callTo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallButton);
