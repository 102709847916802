import { USER } from "../actions/actionTypes";
import * as Sentry from "@sentry/react";
import { IUserState } from "../models/reducerStates";

const initialState: IUserState = {
  username: null,
  password: null,
  loginerror: null,
  authenticated: false,
  loginform: {
    usernameValid: false,
    passwordValid: false,
    valid: false,
  },
};

const userReducer = (state: IUserState = initialState, action: any) => {
  switch (action.type) {
    case USER.LOGIN_SUCCESS:
      // Make sure to use the real username, not the one that was used to log in.
      // This is to get around any lowercase/uppercase issues.
      action.payload.user.username = action.payload.user.properties.USERNAME;
      Sentry.setUser({
        username: action.payload.user.username,
        email: action.payload.user.properties.EMAIL,
        "SIP Address": action.payload.user.properties.SIP_ADDRESS,
      });

      state = {
        ...action.payload.user,
      };
      break;
    case USER.LOGIN_FAILED:
      state = {
        ...state,
        authenticated: false,
        loginerror: action.payload.loginerror,
        loginerrorCode: action.payload.loginerrorCode,
      };
      break;
    case USER.LOGIN_FAILED_TOKEN_INVALID:
      Sentry.captureMessage("User attempted to login using an invalid token");

      state = {
        ...state,
        authenticated: false,
        loginerror: action.payload.loginerror,
        loginerrorCode: action.payload.loginerrorCode,
      };
      break;
    case USER.LOGIN_FAILED_TOKEN_EXPIRED:
      Sentry.captureMessage("User attempted to login using an expired token");

      state = {
        ...state,
        authenticated: false,
        loginerror: action.payload.loginerror,
        loginerrorCode: action.payload.loginerrorCode,
      };
      break;
    case USER.LOGOUT_SUCCESS:
      state = {
        ...initialState,
      };
      break;
    case USER.LOGINFORM_VALIDATE:
      state = {
        ...state,
        loginform: action.payload.loginform,
      };
      break;
    default:
      break;
  }
  return state;
};

export default userReducer;
