import { MODAL } from "./actionTypes";

export function showModal({
  title = null,
  body = null,
  showCancel = false,
  showConfirm = true,
  showClose = true,
  confirmLabel = null,
  cancelLabel = null,
  onConfirm = null,
}: {
  title: string;
  body?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  showClose?: boolean;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: Function;
}) {
  return (dispatch: Function) => {
    window.document.body.classList.add("modal-open");
    dispatch({
      type: MODAL.SHOW,
      payload: {
        title,
        body,
        showConfirm,
        showCancel,
        showClose,
        confirmLabel,
        cancelLabel,
        onConfirm,
      },
    });
  };
}

export function hideModal() {
  return (dispatch: Function) => {
    document.body.classList.remove("modal-open");
    dispatch({ type: MODAL.HIDE });
  };
}
