import { NOTIFICATION } from "../actions/actionTypes";
import INotification from "../models/INotification";

const notificationReducer = (state: INotification[] = null, action: any) => {
  switch (action.type) {
    case NOTIFICATION.TRIGGER:
      state = action.payload;
      break;
    case NOTIFICATION.REMOVE:
      state = null;
      break;
    default:
      break;
  }

  return state;
};

export default notificationReducer;
