import React from "react";
import { connect } from "react-redux";
import { deleteReadMessages } from "../actions/messageActions";
import { withRouter } from "react-router-dom";
import Button from "../components/buttons/Button";

class MessagesDelete extends React.Component {
  delete = event => {
    this.props.deleteReadMessages();
    this.props.history.push("/messages");
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>

        <div className="col-md-10 col-lg-6 col-xl-6">
          <h1 id="h1id" className="text-align-center">
            {this.props.text("messages.deleteallreadconfirm")}
          </h1>
          <br />
          <div className="remove-buttons-container">
            <Button onClick={this.delete} text={this.props.text("common.yes")} className={"remove-contact-button"} />
            <Button
              to={"/messages/" + this.props.match.params.id}
              text={this.props.text("common.no")}
              className={"remove-contact-button"}
            />
          </div>
        </div>

        <div className="col-md-1 col-lg-3 col-xl-3"></div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    messages: state.message.messages,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteReadMessages: () => {
      return dispatch(deleteReadMessages());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesDelete));
