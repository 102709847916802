import { loadSettings } from "./settingActions";
import { register as registerSIP, unregister as unregisterSIP } from "./sipActions";
import { addAlert, clearAlerts, Types as alertTypes } from "./alertActions";
import Storage from "../misc/storage";
import { APP, CONFIG, USER } from "../actions/actionTypes";
import { loginSuccess } from "../actions/configActions";
import detectLocalIpAddress from "../misc/localIpAddress";
import UserService from "../services/userService";
import UserSession from "../misc/usersession";
import MmxApi from "../api/mmxApi";

export function initUser(user, systemConfig) {
  return (dispatch, getState) => {
    if (!user && Storage.session.get("user")) {
      user = JSON.parse(Storage.session.get("user"));
    }
    if (!systemConfig && Storage.session.get("systemConfig")) {
      systemConfig = JSON.parse(Storage.session.get("systemConfig"));
    }

    if (systemConfig) {
      dispatch({
        type: CONFIG.SYSTEM_LOADED,
        payload: {
          systemConfig: systemConfig,
          config: getState().config,
        },
      });
    }
    if (user) {
      dispatch(loginSuccess(user));
      dispatch(loadSettings());
    }
    if (systemConfig && user) {
      dispatch(
        checkMediaPermissions(getState().config.call.audio, getState().config.call.video, true, systemConfig, user)
      );
    }
    if (!user && getState().config.features.anonymous.autologin) {
      dispatch(login(getState().config.features.anonymous.user, getState().config.features.anonymous.password));
    }

    dispatch({
      type: CONFIG.INIT_COMPLETED,
      payload: {},
    });
  };
}

export function checkMediaPermissions(audio, video, collectIfMissing = false, systemConfig, user) {
  return (dispatch, getState) => {
    dispatch(clearAlerts());

    return new Promise((resolve, reject) => {
      if (!getState().config.browser.webrtc) {
        dispatch({
          type: CONFIG.REQUIREMEDIA_SUCCESS,
          payload: { audio: false, video: false },
        });

        if (systemConfig && user) {
          dispatch(registerSIP());
        }

        return resolve();
      }

      if (!audio && !video) {
        dispatch({
          type: CONFIG.REQUIREMEDIA_SUCCESS,
          payload: { audio: false, video: false },
        });
        return resolve();
      }

      dispatch({
        type: CONFIG.REQUIREMEDIA_STARTED,
        payload: { audio: false, video: false },
      });

      var listDevices = { video: false, audio: false };
      var environment = global.window || global;
      environment.navigator.mediaDevices.enumerateDevices().then(function(devices) {
        devices.forEach(function(device) {
          if (video && device.kind.indexOf("videoinput") !== -1 && device.label !== "") {
            listDevices.video = true;
          }

          if (audio && device.kind.indexOf("audioinput") !== -1 && device.label !== "") {
            listDevices.audio = true;
          }
        });

        if (listDevices.audio === audio && listDevices.video === video) {
          dispatch({
            type: CONFIG.REQUIREMEDIA_SUCCESS,
            payload: { audio, video },
          });
          return resolve();
        }

        // No permissions, but we should't try to get them
        if (!collectIfMissing) {
          dispatch({
            type: CONFIG.REQUIREMEDIA_FAILURE,
            payload: { audio: listDevices.audio, video: listDevices.video },
          });
          return reject();
        }

        const showPermissionOverlay = setTimeout(() => {
          dispatch({ type: APP.SHOW_PERMISSIONS_OVERLAY });
        }, 1000);

        // We don't have the required permissions, try to get them...
        var environment = global.window || global;
        environment.navigator.mediaDevices
          .getUserMedia({ audio, video })
          .then(stream => {
            if (stream) {
              if (stream.stop) {
                stream.stop();
              } else {
                stream.getTracks().forEach(track => {
                  if (track && track.stop) {
                    track.stop();
                  }
                });
              }
            }

            // We got all the permissions we need!
            dispatch({
              type: CONFIG.REQUIREMEDIA_SUCCESS,
              payload: { audio, video },
            });
            resolve();
          })
          .catch(e => {
            dispatch({
              type: CONFIG.REQUIREMEDIA_FAILURE,
              payload: { audio: false, video: false },
            });

            let errorMessage = getState().config.text("error.acquiremedia");

            switch (e.name) {
              case "NotReadableError":
              case "AbortError":
                errorMessage = getState().config.text("error.mediaNotReadable");
                break;
              case "NotFoundError":
                errorMessage = getState().config.text("error.requestedDeviceNotFound");
                break;
              default:
                break;
            }

            dispatch(
              addAlert({
                message: errorMessage,
                type: alertTypes.ERROR,
                id: "permissionError",
                flash: false,
              })
            );

            reject(e);
          })
          .finally(() => {
            clearTimeout(showPermissionOverlay);
            dispatch({ type: APP.HIDE_PERMISSIONS_OVERLAY });
          });
      });
    }).finally(() => {
      detectLocalIpAddress(
        systemConfig ? systemConfig.SYS_WEBRTC_SERVER_URI : UserSession.getSystemConfig().SYS_WEBRTC_SERVER_URI
      ).then(localIpAddress => {
        dispatch(localIpAquired(localIpAddress));
      });

      if (systemConfig && user) {
        dispatch(registerSIP());
      }
    });
  };
}

export function refreshProperties() {
  return (dispatch, getState) => {
    var securityToken = getState().user.securityToken;
    if (securityToken) {
      UserService.getUserProperties(getState().config.server.url, securityToken, dispatch)
        .done(data => {
          var properties = {};
          data.propertySet.forEach(function(element) {
            properties[element.key] = element.value;
          }, this);

          var user = {
            ...getState().user,
            properties: properties,
          };

          Storage.session.set("user", JSON.stringify(user));

          dispatch(initUser());
          dispatch({
            type: USER.REFRESH,
            payload: { user: user },
          });
        })
        .fail(() => {
          console.warn("Could not get properties for user from server");
        });
    }
  };
}

export function login(username, password) {
  return (dispatch, getState) => {
    const api = new MmxApi(getState().config.server.url, null, dispatch);
    const params = {
      username: username,
      password: password,
      force: true,
      willUseSipRegistration: true,
    };

    api.user
      .login(params)
      .done(data => {
        var systemConfig = {};
        var properties = {};
        data.properties.propertySet.forEach(function(element) {
          properties[element.key] = element.value;
        }, this);
        data.capabilities.forEach(function(element) {
          systemConfig[element.key] = element.value;
        }, this);

        var user = {
          properties: { ...properties },
          authenticated: true,
          username: username,
          password: password,
          securityToken: data.securityToken,
          uri: data.uri,
          anonymous: getState().config.features.anonymous && username === getState().config.features.anonymous.user,
        };

        Storage.session.set("user", JSON.stringify(user));
        Storage.session.set("systemConfig", JSON.stringify(systemConfig));
        dispatch(initUser(user, systemConfig));
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        var data = xhr.responseText && xhr.status !== 404 ? JSON.parse(xhr.responseText) : {};
        console.error(xhr);
        console.error("Error when login against " + getState().config.server.url + "/userApi/user/api/user/login");

        Storage.session.set("user", null);

        var error = getState().config.text("error.unknown") + " " + data.errorCode;
        switch (data.errorCode) {
          case -7:
            error = getState().config.text("error.accountexpired");
            break;
          case -1:
            error = getState().config.text("error.usernameorpassword");
            break;
          default:
            break;
        }
        dispatch(loginFailed(error, data.errorCode));
      });
  };
}

export function validate(username, password) {
  return dispatch => {
    var action = {
      type: USER.LOGINFORM_VALIDATE,
      payload: {
        loginform: {
          usernameValid: username ? true : false,
          passwordValid: password ? true : false,
          valid: username && password,
        },
      },
    };
    dispatch(action);
  };
}

export function logout() {
  return (dispatch, getState) => {
    const user = getState().user;

    if (!user.authenticated) return;

    var securityToken = user.securityToken;
    UserService.logout(getState().config.server.url, securityToken, dispatch)
      .done(() => {
        dispatch(logoutSuccess());
        dispatch(unregisterSIP());
        Storage.session.clear();
        Storage.local.set("settings", null);
        Storage.local.set("logout-event", "logout" + Math.random());
      })
      .fail(() => {
        console.warn("Could not log out from server");
      });
  };
}

export function localLogout() {
  return dispatch => {
    dispatch(logoutSuccess());
    dispatch(unregisterSIP());
    Storage.session.clear();
    Storage.local.set("settings", null);
    Storage.local.set("logout-event", "logout" + Math.random());
  };
}

export const localIpAquired = localIp => ({
  type: CONFIG.LOCAL_IP_AQUIRED,
  ip: localIp,
});

function logoutSuccess() {
  return {
    type: USER.LOGOUT_SUCCESS,
  };
}

function loginFailed(error, errorCode) {
  return {
    type: USER.LOGIN_FAILED,
    payload: {
      loginerror: error,
      loginerrorCode: errorCode,
    },
  };
}
