import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class FourOFour extends React.Component {
  render() {
    const { logo, l10n } = this.props;
    return (
      <div className="callFullscreen">
        <header className="inCallHeader branding-header">
          <img className="logo" src={logo} alt={l10n("error.404")} />
        </header>
        <div className="box callContainer">
          <main className="row content margin-0_1em call-center">
            <div className={"text-align-center margin-auto"}>
              <h1>{l10n("error.404")}</h1>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

FourOFour.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.accessibility.isHighContrast ? state.config.branding.logoDarkUrl : state.config.branding.logoUrl,
});

export default connect(mapStateToProps)(FourOFour);
