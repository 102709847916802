import React from "react";
import ContrastToggle from "../../containers/ContrastToggle";
import Logout from "../../containers/Logout";
import NavItemMaterial from "./NavItemMaterial";
import SubNavItemMaterial from "./SubNavItemMaterial";
import "./Navbar.css";

const navbar = props => {
  var activeServices = props.activeTabName === "services";
  var activeContacts = props.activeTabName === "contacts";
  var activeDirectory = props.activeTabName === "directory";
  var activeAccount = props.activeTabName === "userinformation";
  var activeSettings = props.activeTabName === "settings";
  var ariaText = props.text("aria.selected_page");
  var contactsSubMenuOpen =
    (activeServices || activeContacts || activeDirectory) &&
    [props.links.services.enabled, props.links.contacts.enabled, props.links.directory.enabled].filter(Boolean).length >
      1;
  var accountSubMenuOpen =
    (activeAccount || activeSettings) && props.links.userinformation.enabled && props.links.settings.enabled;
  var amountUnreadMessages = 0;
  var ua = window.navigator.userAgent;
  var browserIE = ua.indexOf("MSIE ") > 0 || ua.indexOf("Trident/") > 0 ? true : false;

  for (var i = 0; i < props.links.messages.messages.length; i++) {
    if (props.links.messages.messages[i].read === false) {
      amountUnreadMessages++;
    }
  }

  return (
    <div className="navbar navbar-expand-lg navbar-light no-transition">
      <header role="banner">
        <a className="navbar-logo nav-focus-border" href={props.text("login.loggedin.logo.href")}>
          <img className="logo" src={props.logo} alt={props.text("menu.logo")} />
        </a>
      </header>

      <nav className="navbar-toggler-nav">
        <button
          className="navbar-toggler"
          type="button"
          onClick={props.toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={props.navbarIsExpanded}
          aria-label="Toggle navigation"
        >
          <i className="material-icons navbar-toggle-icon">dehaze</i>
        </button>
      </nav>

      <nav
        className={
          "collapse navbar-collapse navbar-tabs" + (browserIE ? " navIE" : "") + (props.navbarIsExpanded ? " show" : "")
        }
        id="navbarSupportedContent"
        aria-label="Huvudmeny"
      >
        <ul className="navbar-nav" aria-label={props.text("aria.mainmenu")}>
          {props.links.services.enabled && !props.links.contacts.enabled && !props.links.directory.enabled && (
            <NavItemMaterial
              icon="view_comfy"
              text={props.links.services.text}
              ariaText={ariaText}
              route="services"
              chosen={activeServices ? true : false}
            />
          )}
          {!props.links.services.enabled && props.links.contacts.enabled && !props.links.directory.enabled && (
            <NavItemMaterial
              icon="contacts"
              text={props.links.contacts.text}
              ariaText={ariaText}
              route="contacts"
              chosen={activeContacts ? true : false}
            />
          )}
          {!props.links.services.enabled && !props.links.contacts.enabled && props.links.directory.enabled && (
            <NavItemMaterial
              icon="perm_contact_calendar"
              text={props.links.directory.text}
              ariaText={ariaText}
              route="directory"
              chosen={activeDirectory ? true : false}
            />
          )}
          {[props.links.services.enabled, props.links.contacts.enabled, props.links.directory.enabled].filter(Boolean)
            .length > 1 && (
            <li className="nav-box">
              <ul className="no-ul-css">
                <NavItemMaterial
                  icon="contacts"
                  text={props.links.contacts.text}
                  ariaText={ariaText}
                  route={() => {
                    if (props.links.directory.enabled) {
                      return "directory";
                    }

                    if (props.links.contacts.enabled) {
                      return "contacts";
                    }

                    return "services";
                  }}
                  chosen={activeServices || activeContacts || activeDirectory ? true : false}
                />
                <li>
                  <ul
                    className="navbar-nav navbar-sub navbar-sub-smallscreen"
                    aria-label={props.text("aria.submenu_contacts")}
                  >
                    {props.links.services.enabled && (
                      <SubNavItemMaterial
                        text={props.links.services.text}
                        ariaText={ariaText}
                        route="services"
                        chosen={activeServices}
                      />
                    )}
                    {props.links.directory.enabled && (
                      <SubNavItemMaterial
                        text={props.links.directory.text}
                        ariaText={ariaText}
                        route="directory"
                        chosen={activeDirectory}
                      />
                    )}
                    {props.links.contacts.enabled && (
                      <SubNavItemMaterial
                        text={props.links.contacts.text}
                        ariaText={ariaText}
                        route="contacts"
                        chosen={activeContacts}
                      />
                    )}
                  </ul>
                </li>
              </ul>
            </li>
          )}

          {props.links.call.enabled && (
            <NavItemMaterial
              icon="dialpad"
              text={props.links.call.text}
              ariaText={ariaText}
              route="call"
              chosen={props.activeTabName === "call" ? true : false}
            />
          )}
          {props.links.history.enabled && (
            <NavItemMaterial
              icon="history"
              text={props.links.history.text}
              ariaText={ariaText}
              route="history"
              chosen={props.activeTabName === "history" ? true : false}
            />
          )}
          {props.links.messages.enabled && (
            <NavItemMaterial
              icon="message"
              text={props.links.messages.text}
              ariaText={ariaText}
              route="messages"
              chosen={props.activeTabName === "messages" ? true : false}
              amountUnreadMessages={amountUnreadMessages}
            />
          )}

          {props.links.userinformation.enabled && !props.links.settings.enabled && (
            <NavItemMaterial
              icon="account_box"
              text={props.links.userinformation.text}
              ariaText={ariaText}
              route="userinformation"
              chosen={activeAccount ? true : false}
            />
          )}
          {!props.links.userinformation.enabled && props.links.settings.enabled && (
            <NavItemMaterial
              icon="settings"
              text={props.links.settings.text}
              ariaText={ariaText}
              route="settings"
              chosen={activeSettings ? true : false}
            />
          )}
          {props.links.userinformation.enabled && props.links.settings.enabled && props.settingsInMenu && (
            <li className="nav-box">
              <ul className="no-ul-css">
                <NavItemMaterial
                  icon="settings"
                  text={props.links.settings.text}
                  ariaText={ariaText}
                  route="settings"
                  chosen={activeAccount || activeSettings ? true : false}
                />
              </ul>
            </li>
          )}
          {props.links.userinformation.enabled && props.links.settings.enabled && !props.settingsInMenu && (
            <li className="nav-box">
              <ul className="no-ul-css">
                <NavItemMaterial
                  icon="account_box"
                  text={props.links.userinformation.text}
                  ariaText={ariaText}
                  route="userinformation"
                  chosen={activeAccount || activeSettings ? true : false}
                />
                <li>
                  <ul
                    className="navbar-nav navbar-sub navbar-sub-smallscreen"
                    aria-label={props.text("aria.submenu_contacts")}
                  >
                    {props.links.services.enabled && (
                      <SubNavItemMaterial
                        icon="settings"
                        text={props.links.settings.text}
                        ariaText={ariaText}
                        route="settings"
                        chosen={activeSettings ? true : false}
                      />
                    )}
                  </ul>
                </li>
              </ul>
            </li>
          )}

          {props.links.contrasttoggle.enabled && <ContrastToggle />}

          {props.logout && <Logout />}
          {props.links.debug.enabled && (
            <NavItemMaterial
              icon="format_align_justify"
              text={props.links.debug.text}
              ariaText={ariaText}
              route="log"
              chosen={props.activeTabName === "log" ? true : false}
            />
          )}
        </ul>

        {contactsSubMenuOpen && (
          <ul className="navbar-nav navbar-sub navbar-sub-bigscreen" aria-label={props.text("aria.submenu_contacts")}>
            {props.links.services.enabled && (
              <SubNavItemMaterial
                text={props.links.services.text}
                ariaText={ariaText}
                route="services"
                chosen={activeServices}
              />
            )}
            {props.links.directory.enabled && (
              <SubNavItemMaterial
                text={props.links.directory.text}
                ariaText={ariaText}
                route="directory"
                chosen={activeDirectory}
              />
            )}
            {props.links.contacts.enabled && (
              <SubNavItemMaterial
                text={props.links.contacts.text}
                ariaText={ariaText}
                route="contacts"
                chosen={activeContacts}
              />
            )}
          </ul>
        )}
        {accountSubMenuOpen && props.settingsInMenu && (
          <ul className="navbar-nav navbar-sub navbar-sub-bigscreen" aria-label={props.text("aria.submenu_account")}>
            <SubNavItemMaterial
              text={props.links.settings.text}
              ariaText={ariaText}
              route="settings"
              chosen={activeSettings}
            />
            <SubNavItemMaterial
              text={props.links.userprofile.text}
              ariaText={ariaText}
              route="userinformation"
              chosen={activeAccount}
            />
          </ul>
        )}
        {accountSubMenuOpen && !props.settingsInMenu && (
          <ul className="navbar-nav navbar-sub navbar-sub-bigscreen" aria-label={props.text("aria.submenu_account")}>
            <SubNavItemMaterial
              text={props.links.userprofile.text}
              ariaText={ariaText}
              route="userinformation"
              chosen={activeAccount}
            />
            <SubNavItemMaterial
              text={props.links.settings.text}
              ariaText={ariaText}
              route="settings"
              chosen={activeSettings}
            />
          </ul>
        )}
      </nav>
    </div>
  );
};

export default navbar;
