import MmxApi from "../api/mmxApi";

const resetPasswordService = {
  requestResetPassword: (params, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    var rtValue = api.user.requestResetPassword(params);
    return rtValue;
  },

  setNewPassword: (params, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.setNewPassword(params);
  },
};

export default resetPasswordService;
