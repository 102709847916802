import React, { useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "../models/reducerStates";
import appStoreBadges from "../misc/appStoreBadges";
import { isMobileDevice } from "../misc/helpers";
import "./DownloadApp.css";
import Storage from "../misc/storage";

type Props = {
  locale?: string;
  os?: string;
  hasNativeApp?: boolean;
  downloadLink?: string;
  text?: Function;
};

const Modal = (props: Props) => {
  const storageKey = "mmx:hide-app-download-link";
  const translatedBadges = appStoreBadges[props.locale] || appStoreBadges["en"];
  const [visible, setVisible] = useState(props.hasNativeApp && !Storage.local.get(storageKey));

  const hide = () => {
    setVisible(false);
  };

  const hideForever = () => {
    Storage.local.set(storageKey, true);
    hide();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="download-app-wrapper">
      <div className="modal-dialog my-0" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal-title">
              {props
                .text("common.app_for_os")
                .replace("%APPNAME%", props.text("common.appName"))
                .replace("%OS%", props.os)}
            </h5>
            <button type="button" className="close" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer justify-content-center">
            <a href={props.downloadLink} className={"download-app-link " + props.os.toLowerCase()}>
              <img src={translatedBadges[props.os]} alt="" />
            </a>
            <button className="btn btn-sm btn-link font-italic" onClick={hideForever}>
              {props.text("error.browserwarning.closeAndDontShowAgain")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const browser = state.config.browser;
  let os = browser.os as string;
  const showDownloadDialog = state.config.download && state.config.download.enabled;
  let downloadLink = null;

  if (browser.os === "Mac OS" && isMobileDevice()) {
    os = "iOS";
  }

  if (browser.os === "Android OS") {
    os = "Android";
  }

  if (os === "Android" && showDownloadDialog && state.config.download.links.googlePlay) {
    downloadLink = state.config.download.links.googlePlay;
  }

  if (os === "iOS" && showDownloadDialog && state.config.download.links.appStore) {
    downloadLink = state.config.download.links.appStore;
  }

  return {
    text: state.config.text,
    locale: state.accessibility.locale,
    hasNativeApp: showDownloadDialog && downloadLink !== null,
    downloadLink,
    os,
  };
};

export default connect(mapStateToProps)(Modal);
