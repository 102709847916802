import React from "react";
import { connect } from "react-redux";
import ErrorAlert from "../components/ErrorAlert";
import Storage from "../misc/storage";

class UnsupportedBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hideBrowserWarning: false };
  }

  handleDismiss = () => {
    Storage.local.set("hideBrowserWarning", true);
    this.setState({ hideBrowserWarning: true });
  };

  componentDidMount() {
    this.setState({
      hideBrowserWarning: (this.props.severity === "info" && Storage.local.get("hideBrowserWarning")) || false,
    });
  }

  render() {
    if (this.state.hideBrowserWarning) {
      return null;
    }

    return (
      <div className="mt-5">
        <ErrorAlert
          text={this.props.message}
          severity={this.props.severity}
          dismissText={this.props.text("error.browserwarning.closeAndDontShowAgain")}
          onDismiss={this.handleDismiss}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let message = state.config.text("error.unsupportedbrowser");

  if (state.config.browser.errorMessage) {
    message = state.config.text(state.config.browser.errorMessage);
  }

  return {
    message,
    text: state.config.text,
    severity: state.config.browser.isSupported ? "info" : "danger",
    hideBrowserWarning: Storage.local.get("hideBrowserWarning"),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsupportedBrowser);
