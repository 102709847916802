import React from "react";
import { connect } from "react-redux";
import { saveContact, noSipGiven, clearContactAlert } from "../actions/contactActions";
import { reduxForm, initialize } from "redux-form";
import Loading from "../components/Loading";
import Button from "../components/buttons/Button";
import InputText from "../components/InputText";
import { APP } from "../actions/actionTypes";

class ContactAddEdit extends React.Component {
  onSubmit = values => {
    if (values.sipaddress === undefined || values.sipaddress === null || values.sipaddress === "") {
      this.props.noSipGiven();
      return;
    }

    this.props.saveContact({
      ...values,
      id: this.props.match.params.id,
    });
    this.props.history.push("/contacts");
  };

  componentDidMount() {
    if (this.props.mode === "add") {
      this.props.setPageTitle(this.props.text("contacts.addcontact"));
    }

    if (this.props.mode === "edit") {
      this.props.setPageTitle([this.props.text("common.edit"), this.props.text("contacts.contacts")]);
    }
  }

  componentWillUnmount() {
    this.props.clearContactAlert();
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    var email = { visible: true, ...this.props.emailRegex };

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          {this.props.mode === "add" && <h1 id="h1id">{this.props.text("contacts.addcontact")}</h1>}

          {this.props.mode === "edit" && (
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <h1 id="h1id">{this.props.text("common.edit")}</h1>
                </div>
              </div>
            </div>
          )}

          {this.props.noSipGivenAlert && (
            <div className="alert alert-danger col-md-12 col-lg-12" role="alert">
              {this.props.text("contacts.nosipgiven")}
            </div>
          )}

          <p>{this.props.text("common.required")}</p>

          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <InputText
              name="fname"
              autoComplete="given-name"
              text={this.props.text("common.firstname")}
              field={{ visible: true }}
            />
            <InputText
              name="sname"
              autoComplete="family-name"
              text={this.props.text("common.lastname")}
              field={{ visible: true }}
            />
            <InputText
              name="sipaddress"
              autoComplete="sipaddress"
              text={this.props.text("common.sipaddress")}
              field={{ required: true, visible: true }}
            />
            <InputText name="email" autoComplete="email" text={this.props.text("common.email")} field={email} />
            <InputText
              name="address"
              autoComplete="street-address"
              text={this.props.text("common.address")}
              field={{ visible: true }}
            />
            <InputText
              name="postNr"
              autoComplete="postal-code"
              text={this.props.text("common.postcode")}
              field={{ visible: true }}
            />
            <InputText
              name="city"
              autoComplete="address-level2"
              text={this.props.text("common.city")}
              field={{ visible: true }}
            />

            <div className="d-flex justify-content-between">
              <div></div>
              <div className="float-right">
                <Button type="submit" icon="save" text={this.props.text("common.save")} />
                <Button to={"/contacts"} text={this.props.text("common.cancel")} />
              </div>
            </div>
            <div></div>
          </form>
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
      </div>
    );
  }
}

function getContact(state, props) {
  if (!props.match.params.id) return {};
  var contact = {};
  state.contact.contacts.forEach(item => {
    if (item.id.toString() === props.match.params.id) {
      contact = item;
      contact.name = item.fname + " ";
      if (item.sname) {
        item.name = item.name + item.sname;
      }
    }
  });
  return contact;
}

const mapStateToProps = (state, props) => {
  return {
    contacts: state.contacts,
    initialValues: getContact(state, props),
    enableReinitialize: true,
    loading: state.contact.loading,
    noSipGivenAlert: state.contact.noSipGivenAlert,
    text: state.config.text,
    emailRegex: {
      regex: state.changeUser.email.regex,
      regexText: state.changeUser.email.regexText,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveContact: values => {
      return dispatch(saveContact(values));
    },
    initialize: values => {
      return dispatch(initialize("contact", values));
    },
    noSipGiven: () => {
      dispatch(noSipGiven());
    },
    clearContactAlert: () => {
      dispatch(clearContactAlert());
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

ContactAddEdit = reduxForm({
  form: "contact",
})(ContactAddEdit);

export default connect(mapStateToProps, mapDispatchToProps)(ContactAddEdit);
