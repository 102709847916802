import React from "react";
import { connect } from "react-redux";
import { toggleContrast } from "../actions/accessibilityActions";
import NavItemMaterial from "../components/nav/NavItemMaterial";

class ContrastToggle extends React.Component {
  render() {
    return <NavItemMaterial icon="settings_brightness" text={this.props.text} onClick={this.props.onToggleContrast} />;
  }
}

const mapStateToProps = state => {
  const text = state.accessibility.isHighContrast
    ? state.config.text("accessibility.originaltheme")
    : state.config.text("accessibility.darktheme");
  return {
    isHighContrast: state.accessibility.isHighContrast,
    text: text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleContrast: () => {
      return dispatch(toggleContrast());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContrastToggle);
