import { PASSWORD } from "../actions/actionTypes";
import { IResetPasswordState } from "../models/reducerStates";

const initialState: IResetPasswordState = {
  recaptchavalid: false,
  emailusernamevalid: false,
  resetPasswordInProgress: false,
  resetPasswordComplete: false,
  resetPasswordError: false,
  resetPasswordErrorText: "",
  newPasswordInProgress: false,
  newPasswordComplete: false,
  newPasswordError: false,
  newPasswordErrorText: "",
};

const resetPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PASSWORD.RESET_RECAPTCHA_VALID:
    case PASSWORD.RESET_RECAPTCHA_INVALID:
      state = {
        ...state,
        recaptchavalid: action.type === PASSWORD.RESETPASSWORD_RECAPTCHA_VALID,
      };
      break;
    case PASSWORD.RESET_EMAIL_USERNAME_VALID:
    case PASSWORD.RESET_EMAIL_USERNAME_INVALID:
      state = {
        ...state,
        emailusernamevalid: action.type === PASSWORD.RESETPASSWORD_EMAILUSERNAME_VALID,
      };
      break;
    case PASSWORD.RESET_INIT:
      state = {
        ...state,
        resetPasswordInProgress: true,
        resetPasswordComplete: false,
        resetPasswordError: false,
      };
      break;
    case PASSWORD.RESET_SUCCESS:
      state = {
        ...state,
        resetPasswordInProgress: false,
        resetPasswordComplete: true,
        resetPasswordError: false,
      };
      break;
    case PASSWORD.RESET_CLEAR:
      state = {
        ...state,
        resetPasswordInProgress: false,
        resetPasswordComplete: false,
        resetPasswordError: false,
      };
      break;
    case PASSWORD.RESET_ERROR:
      state = {
        ...state,
        resetPasswordInProgress: false,
        resetPasswordComplete: false,
        resetPasswordError: true,
        resetPasswordErrorText: action.payload,
      };
      break;
    case PASSWORD.NEW_RESET:
      state = {
        ...state,
        newPasswordInProgress: false,
        newPasswordComplete: false,
        newPasswordError: false,
        newPasswordErrorText: "",
      };
      break;
    case PASSWORD.NEW_INIT:
      state = {
        ...state,
        newPasswordInProgress: true,
        newPasswordComplete: false,
        newPasswordError: false,
      };
      break;
    case PASSWORD.NEW_SUCCESS:
      state = {
        ...state,
        newPasswordInProgress: false,
        newPasswordComplete: true,
        newPasswordError: false,
      };
      break;
    case PASSWORD.NEW_ERROR:
      state = {
        ...state,
        newPasswordInProgress: false,
        newPasswordComplete: false,
        newPasswordError: true,
        newPasswordErrorText: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default resetPasswordReducer;
