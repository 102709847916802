import { Invitation, UserAgent, Web, LogLevel, Inviter } from "sip.js";
import { addAlert, removeAlert, Types as alertTypes } from "./alertActions";
import { SIP_STATE } from "../constants/sipState";
import { CALL_STATE } from "../constants/callState";
import { switchFreeswitch } from "./configActions";
import { checkMediaPermissions, logout } from "./userActions";
import { APP, CALLS, SIP_ACTION } from "./actionTypes";
import findContactFromSip from "../modules/findContactFromSip";
import { checkIfIPv6 } from "../misc/helpers";
import UserSession from "../misc/usersession";
import * as Sentry from "@sentry/react";
import IUserSettings from "../models/IUserSettings";
import { IRootState, ISipState } from "../models/reducerStates";
import { IConfigState } from "../models/reducerStates";
import { IUserState } from "../models/reducerStates";
import { Phone } from "../modules/Phone/Phone";
import { PhoneInviteOptions } from "../modules/Phone";

/** Create and register sip user agent */
export function register() {
  return async (dispatch: Function, getState: Function) => {
    const config: IConfigState = getState().config;
    const user: IUserState = getState().user;
    const sip: ISipState = getState().sip;
    const server = new URL(config.system.SYS_WEBRTC_SERVER_URI);

    if (sip.phone) {
      sip.phone.connect();
      return;
    }

    const phone = new Phone({
      debug: config.debug.enabled,
      supportedMedia: {
        audio: config.call.audio,
        video: config.call.video,
        text: config.call.safetext,
      },
      userAgentOptions: {
        server,
        uri: user.properties.SIP_ADDRESS,
        user: {
          displayName: user.anonymous ? config.text("common.anonymous") : user.properties.SIP_ADDRESS.split("@")[0],
          username: user.properties.USERNAME,
          authorizationPassword: user.password,
          authorizationUsername: user.properties.USERNAME,
        },
        onLog: (level, content) => {
          getSipError(level, content, dispatch, getState);
        },
      },
    });

    phone.delegate = {
      onRegistered: () => {
        dispatch(removeAlert("freeswitchError"));
        if (getState().sip.sipState === SIP_STATE.UNREGISTERED || getState().sip.sipState === SIP_STATE.REGISTERING) {
          dispatch({
            type: SIP_ACTION.REGISTER_SUCCESS,
            payload: {
              phone,
            },
          });
        }
      },

      onServerConnect: () => {
        dispatch(removeAlert("freeswitchError"));
        dispatch(removeAlert("networkInfo"));
      },

      onServerDisconnect: () => {
        dispatch(
          addAlert({
            message: config.text("network.networkError"),
            type: alertTypes.ERROR,
            id: "freeswitchError",
          })
        );
      },

      onServerConnectionFailed: () => {
        dispatch({
          type: SIP_ACTION.REGISTER_FAILED,
          payload: {},
        });

        dispatch(logout());

        dispatch(
          addAlert({
            message: config.text("network.networkError"),
            type: alertTypes.ERROR,
            id: "freeswitchError",
          })
        );
      },

      onServerConnectionInProgress: () => {
        dispatch(
          addAlert({
            message: config.text("network.establishing"),
            type: alertTypes.INFO,
            showSpinner: true,
            id: "networkInfo",
            append: true,
          })
        );
      },

      onUnregistered: () => {
        dispatch({ type: SIP_ACTION.CALL_COMPLETED });
        dispatch({ type: SIP_ACTION.UNREGISTERED });
      },

      onCallAnswered: () => {
        const sessionDescriptionHandler = phone.session.sessionDescriptionHandler;

        if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
          throw new Error("Invalid session description handler.");
        }

        const sessionData: any = phone.session.data;
        const negotiatedMedia = sessionData.negotiatedMedia;

        dispatch({
          type: SIP_ACTION.CALL_ACCEPTED_REMOTE,
          payload: {
            negotiated: negotiatedMedia,
          },
        });
      },

      onReinviteReceived: (): void => {
        // A reinvite might mean that we were transferred from the queue to an agent.
        // Let's reset the position to make sure that the agent gets it.
        phone.lastPosition = null;
      },

      onMessageReceived: (message): void => {
        const incomingMessage = message.request.body;
        let text = "";

        for (let i = 0; i < incomingMessage.length; i++) {
          const character = incomingMessage[i];
          if (character.charCodeAt(0) === 7) {
            dispatch({
              type: CALLS.KNOCK_KNOCK,
              payload: true,
            });
          } else if (character.charCodeAt(0) === 8) {
            if (text && text.length > 0) {
              dispatch({
                type: CALLS.TEXT_MSG_IN,
                payload: text,
              });
            }

            dispatch({
              type: CALLS.TEXT_MSG_IN_DEL,
            });

            text = "";
          } else {
            text += character;
          }
        }

        if (getState().call.shouldAlertOnIncomingMessage) {
          dispatch({
            type: APP.UPDATE_PAGE_TITLE,
            payload: {
              showBell: true,
            },
          });
        }

        dispatch({
          type: CALLS.TEXT_MSG_IN,
          payload: text,
        });
      },

      onCallHangup(): void {
        const sip: ISipState = getState().sip;
        switch (sip.callState) {
          case CALL_STATE.ACCEPTED:
            dispatch({ type: SIP_ACTION.CALL_TERMINATED_REMOTE });
            break;
          case CALL_STATE.CALLING:
            dispatch({ type: SIP_ACTION.REJECTED_REMOTE });
            break;
          case CALL_STATE.CANCELED_LOCAL:
            dispatch({ type: SIP_ACTION.CALL_CANCELED_LOCAL });
            dispatch({ type: SIP_ACTION.CALL_COMPLETED });
            break;
          case CALL_STATE.TERMINATED_LOCAL:
            dispatch({ type: SIP_ACTION.CALL_TERMINATED_LOCAL });
            dispatch({ type: SIP_ACTION.CALL_COMPLETED });
            break;
          case CALL_STATE.INCOMING_CALL:
            dispatch({ type: SIP_ACTION.CALL_CANCELED_REMOTE });
            break;
          case CALL_STATE.COMPLETED:
            break;
          default:
            dispatch({ type: SIP_ACTION.CALL_FAILED });
            break;
        }
      },

      onCallReceived(): void {
        const state: IRootState = getState();
        const sip = state.sip;
        const phone = sip.phone;
        const session = phone.session;
        const config = state.config;
        const userSettings = state.setting.user;

        if (!session || !(session instanceof Invitation)) {
          throw new Error("Invalid session description handler.");
        }

        // Set the Phone media so that the noMedia check can do its thing
        phone.audio = config.audio && userSettings.WEB_AUDIO === "true";
        phone.video = config.video && userSettings.WEB_VIDEO === "true";

        dispatch(checkMediaPermissions(config.call.audio, config.call.video, true))
          .then(() => {
            if (
              sip.sipState === SIP_STATE.IDLE ||
              (sip.sipState === SIP_STATE.IN_CALL && sip.callState === CALL_STATE.TERMINATED_REMOTE)
            ) {
              // Handle incoming session state changes.
              // session.stateChange.addListener((newState: SessionState) => {});

              var remoteIdentity = `${session.remoteIdentity.uri.scheme}:${session.remoteIdentity.uri.aor}`;
              var callBName = findContactFromSip(
                getState().service.services,
                getState().contact.contacts,
                remoteIdentity
              );

              if (callBName === remoteIdentity) {
                callBName = session.remoteIdentity.displayName
                  ? session.remoteIdentity.displayName
                  : session.remoteIdentity.uri.user;
              }

              dispatch({
                type: SIP_ACTION.INVITE_RECIEVED,
                payload: {
                  session,
                  callBName: callBName,
                  callB: session.remoteIdentity.displayName
                    ? session.remoteIdentity.displayName
                    : session.remoteIdentity.uri.user,
                },
              });
            } else {
              session.reject({
                statusCode: 486,
              });
            }
          })
          .catch((e: Error) => {
            session.reject();
            dispatch({ type: SIP_ACTION.CALL_COMPLETED });
          });
      },
    };

    // Connect to server (and register)
    phone.connect();

    dispatch({
      type: SIP_ACTION.REGISTER_STARTED,
      payload: {
        phone,
      },
    });
  };
}

export function acceptCall() {
  return (dispatch: Function, getState: Function) => {
    if (getState().network.sipError) {
      dispatch(removeAlert("freeswitchError"));
    }

    const negotiateAudio = getState().config.audio && getState().setting.user.WEB_AUDIO === "true";
    const negotiateVideo = getState().config.video && getState().setting.user.WEB_VIDEO === "true";

    const sip: ISipState = getState().sip;
    sip.phone.answer({
      audio: negotiateAudio,
      video: negotiateVideo,
      text: getState().config.call.safetext,
    });

    if (sip.phone.session) {
      if (sip.phone.session.sessionDescriptionHandler) {
        dispatch({
          type: SIP_ACTION.CALL_ACCEPTED_LOCAL,
          payload: {
            negotiated: {
              audio: negotiateAudio,
              video: negotiateVideo,
              text: getState().config.call.safetext,
            },
            audio: getState().config.audio,
            video: getState().config.video,
            text: getState().config.call.safetext,
          },
        });
      }
    }
  };
}

export function online() {
  return (dispatch: Function, getState: Function): void => {
    const sip: ISipState = getState().sip;
    const config: IConfigState = getState().config;

    const online = () => {
      dispatch({
        type: SIP_ACTION.ONLINE,
        payload: true,
      });

      removeAlert("freeswitchError");
    };

    const offline = () => {
      if (config.layout.showOnlineStatus) {
        dispatch({
          type: SIP_ACTION.ONLINE,
          payload: false,
        });

        dispatch(
          addAlert({
            message: getState().config.text("network.offline"),
            type: alertTypes.ERROR,
            id: "freeswitchError",
          })
        );
      }
    };

    // window.navigator.onLine only checks if the device is connected to a network.
    // It does not check if that network has internet access or not.
    if (!window.navigator.onLine && sip.online) {
      offline();
      return;
    }

    if (sip.phone !== null) {
      if (sip.phone.isConnected && !sip.online) {
        online();
        return;
      }

      return;
    }

    if (sip.online) {
      offline();
    }
  };
}

export function muteAudio() {
  return (dispatch: Function, getState: Function) => {
    const state: IRootState = getState();
    const phone = state.sip.phone;

    if (state.call.audio) {
      phone.muteAudio("local");
    } else {
      phone.unmuteAudio("local");
    }

    dispatch({
      type: CALLS.MUTE_AUDIO,
      payload: {},
    });
  };
}

export function muteVideo() {
  return (dispatch: Function, getState: Function) => {
    const state: IRootState = getState();
    const phone = state.sip.phone;

    if (state.call.video) {
      phone.muteVideo("local");
    } else {
      phone.unmuteVideo("local");
    }

    dispatch({
      type: CALLS.MUTE_VIDEO,
      payload: {},
    });
  };
}

export function muteRemoteAudio() {
  return (dispatch: Function, getState: Function) => {
    const state: IRootState = getState();
    const phone = state.sip.phone;

    if (state.call.soundOn) {
      phone.muteAudio("remote");
    } else {
      phone.unmuteAudio("remote");
    }

    dispatch({
      type: CALLS.MUTE_REMOTE_AUDIO,
      payload: {},
    });
  };
}

export function muteRemoteVideo() {
  return (dispatch: Function, getState: Function) => {
    const state: IRootState = getState();
    const phone = state.sip.phone;

    if (state.call.videoVisible) {
      phone.muteVideo("remote");
    } else {
      phone.unmuteVideo("remote");
    }

    return dispatch({
      type: CALLS.HIDE_VIDEO,
    });
  };
}

export function stopVideo() {
  return (_dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;
    phone.stopMediaTracks("local", "video");
  };
}

export function stopAudio() {
  return (_dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;
    phone.stopMediaTracks("local", "audio");
  };
}

export function stopRemoteAudio() {
  return (_dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;
    phone.stopMediaTracks("remote", "audio");
  };
}

// function getSessionDescriptionHandlerOptions(
//   getState: Function
// ): SessionDescriptionHandlerOptions {
//   // Completely override the constraints.
//   // I think this is what essentially happened in the old sessionDescriptionHandler.checkAndDefaultConstraints.
//   const audio = getState().config.call.audio;
//   const video = getState().config.call.video;

//   const sessionDescriptionHandlerOptions: SessionDescriptionHandlerOptions = {
//     constraints: {
//       audio,
//       video,
//     },
//   };

//   return sessionDescriptionHandlerOptions;
// }

export function call(
  to: string,
  audio?: boolean,
  video?: boolean,
  text?: boolean,
  ivrInput: string = null,
  externalReference: string = null
) {
  return (dispatch: Function, getState: Function) => {
    const config: IConfigState = getState().config;
    const userSettings: IUserSettings = getState().setting.user;

    audio = config.call.audio && userSettings.WEB_AUDIO === "true";
    video = config.call.video && userSettings.WEB_VIDEO === "true";
    text = config.call.safetext;

    if (to === null || to === "" || to === undefined) {
      console.error('No "To" address is set!');
      return;
    }

    // Must use domain
    if (to.indexOf("@") === -1) {
      let domain = "";

      // Check for multiple SIP-addresses in properties
      if (getState().user.properties.SIP_ADDRESS.indexOf("|") !== -1) {
        domain = getState()
          .user.properties.SIP_ADDRESS.split("|")[0]
          .split("@")[1];
      } else {
        domain = getState().user.properties.SIP_ADDRESS.split("@")[1];
      }

      to = to + "@" + domain;
    }

    // Send an outgoing INVITE request
    const target = UserAgent.makeURI(`sip:${to}`);
    if (!target) {
      throw new Error("Failed to create target URI.");
    }

    dispatch(checkMediaPermissions(audio, video, true))
      .then(() => {
        const phone: Phone = getState().sip.phone;

        // Set the Phone media so that the noMedia check can do its thing
        phone.audio = audio;
        phone.video = video;

        const inviteOptions: PhoneInviteOptions = {
          to: target,
          media: {
            audio,
            video,
            text,
          },
          ivr: ivrInput,
          ref: externalReference,
          extraHeaders: [],
        };

        if (config.features.kiosk.enabled && externalReference !== null) {
          const callInfoHeader = `P-Call-Info: <sip:0.0.0.0>;USER-REFERENCE=${externalReference};purpose=USERINFO`;
          inviteOptions.extraHeaders.push(callInfoHeader);
        } else {
          const localIpAddress = UserSession.getLocalIpAddress();
          if (localIpAddress !== "" && localIpAddress !== null) {
            let ipAddressHeader;

            if (checkIfIPv6(localIpAddress)) {
              ipAddressHeader = `P-Call-Info: <sip:[${localIpAddress}]>; purpose=trs-user-ip`;
            } else {
              ipAddressHeader = `P-Call-Info: <sip:${localIpAddress}>; purpose=trs-user-ip`;
            }

            inviteOptions.extraHeaders.push(ipAddressHeader);
          }
        }

        dispatch({
          type: SIP_ACTION.CALLING,
          payload: {
            audio,
            video,
            text,
            options: {
              textVisible: config.call.textVisibleByDefault,
            },
            callB: to,
            callBName: findContactFromSip(getState().service.services, getState().contact.contacts, to),
          },
        });

        phone.sendInvite(inviteOptions).catch(e => {
          console.error(e);

          dispatch(
            addAlert({
              message: getState().config.text("error.mediaNotReadable"),
              type: alertTypes.ERROR,
              id: "permissionError",
            })
          );

          dispatch({ type: SIP_ACTION.CALL_COMPLETED });
        });
      })
      .catch((e: Error) => {
        const errorMessage =
          typeof e !== "undefined" && e.message
            ? e.message
            : "Can't call, because the the browser does not have the correct permissions.";

        Sentry.setContext("Error", { message: errorMessage });
        Sentry.captureMessage("Outgoing Call Failed", Sentry.Severity.Debug);

        console.error(errorMessage);
      });
  };
}

export function sendMessage(message: string) {
  return (dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;

    if (!phone.safetextSender) {
      return;
    }

    if (message && message.length > 1000) {
      const textIsTooLong = getState().config.text("call.toolongtext");

      dispatch({
        type: CALLS.TEXT_MSG_OUT,
        payload: textIsTooLong,
      });

      phone.safetextSender.send(textIsTooLong);
      return;
    }

    let text = "";
    for (let i = 0; i < message.length; i++) {
      const c = message[i];

      if (c.charCodeAt(0) === 8) {
        if (text && text.length > 0) {
          dispatch({
            type: CALLS.TEXT_MSG_OUT,
            payload: text,
          });
        }

        dispatch({
          type: CALLS.TEXT_MSG_OUT_DEL,
        });

        text = "";
      } else {
        text += c;
      }
    }

    dispatch({
      type: CALLS.TEXT_MSG_OUT,
      payload: text,
    });

    phone.safetextSender.send(message);

    dispatch({ type: SIP_ACTION.MESSAGE_SENT });
  };
}

export function ping() {
  return (dispatch: Function, getState: Function) => {
    //TODO fixed timeout in websockets
    //Nils: Some webbrowser closes the websocket when no data has arrived in 30sec(ie and edge)
    //This ping sends an empty space on the websocket
    //This can mess things up on the server side so it is no good solution :(
    //Chrome/FF seems to work
    //Propably a result to what kind of header information that is sent in the websocket init
    var ua = getState().sip.phone;
    if (ua.transport.ws) {
      ua.transport.ws.send(" ");
      dispatch({
        type: SIP_ACTION.WS_PING,
        payload: getState().config.browser.name,
      });
    }
  };
}

export function rejectCall() {
  return (dispatch: Function, getState: Function) => {
    const sip: ISipState = getState().sip;
    const phone: Phone = sip.phone;

    if (sip.callState === CALL_STATE.INCOMING_CALL) {
      dispatch({ type: SIP_ACTION.CALL_REJECTED_LOCAL });
    }

    dispatch({ type: SIP_ACTION.CALL_COMPLETED });

    if (phone.session) {
      phone.decline();
    }
  };
}

export function cancelCall() {
  return (dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;

    if (phone.session instanceof Inviter) {
      dispatch({ type: SIP_ACTION.CALL_CANCELED_LOCAL });
    }

    dispatch({ type: SIP_ACTION.CALL_COMPLETED });

    if (phone.session) {
      phone.hangup();
    }
  };
}

export function endCall() {
  return (dispatch: Function, getState: Function) => {
    const sip: ISipState = getState().sip;
    const phone = sip.phone;

    if (sip.callState === CALL_STATE.ACCEPTED) {
      dispatch({ type: SIP_ACTION.CALL_TERMINATED_LOCAL });
    }

    dispatch({ type: SIP_ACTION.CALL_COMPLETED });

    if (phone.session) {
      phone.hangup();
    }
  };
}

export function unregister() {
  return (_dispatch: Function, getState: Function) => {
    const phone: Phone = getState().sip.phone;

    if (phone) {
      phone.unregister();
    }
  };
}

function getSipError(level: LogLevel, content: string, dispatch: Function, getState: Function) {
  if (level !== null && content !== null && content !== undefined) {
    if (level === "error" && ["unable to acquire streams"].indexOf(content) !== -1) {
      dispatch(
        addAlert({
          message: errorMessage(content, getState),
          type: alertTypes.ERROR,
          id: "freeswitchError",
        })
      );
    } else if (level === "warn" && ["Transport error: [object Event]"].indexOf(content) !== -1) {
      dispatch(
        addAlert({
          message: getState().config.text("network.networkError"),
          type: alertTypes.ERROR,
          showSpinner: true,
          id: "freeswitchError",
        })
      );
    } else if (content.indexOf) {
      if (level === "log" && content.indexOf("WebSocket") !== -1 && content.indexOf(" connected") !== -1) {
        dispatch(removeAlert("freeswitchError"));
      } else if (content.indexOf("connection state set to 'error'") !== -1) {
        dispatch(switchFreeswitch());
      }
    }
  }
}

const errorMessage = (errorCode: any, getState: Function) => {
  switch (errorCode) {
    case "unable to acquire streams":
      return getState().config.text("error.acquiremedia");
    default:
      return getState().config.text("error.unknown") + " " + errorCode;
  }
};
