import { BandwidthRestrictions } from "../../../types";
import store from "../../../store";

const addBandwidthLine = (
  media: "audio" | "video",
  bandwidthRestriction: keyof typeof BandwidthRestrictions,
  sdp: string
) => {
  if (!BandwidthRestrictions[bandwidthRestriction][media]) {
    return sdp;
  }

  const bLine = `b=AS:${BandwidthRestrictions[bandwidthRestriction][media]}`;

  var lines = sdp.split("\n");
  var line = -1;

  // Attempt to find the line number of the m line for the media
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf(`m=${media}`) === 0) {
      line = i;
      break;
    }
  }

  // No m line found, don't do anything
  if (line === -1) {
    return sdp;
  }

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    lines[line] = bLine;
  } else {
    var newLines = lines.slice(0, line);
    newLines.push(bLine);
    lines = newLines.concat(lines.slice(line, lines.length));
  }

  return lines.join("\n");
};

export default function addBandwidthRestriction(
  description: RTCSessionDescriptionInit
): Promise<RTCSessionDescriptionInit> {
  const bandwidthRestriction = store.getState().setting.user.WEB_BANDWIDTH;
  description.sdp = addBandwidthLine("audio", bandwidthRestriction, description.sdp);
  description.sdp = addBandwidthLine("video", bandwidthRestriction, description.sdp);
  return Promise.resolve(description);
}
