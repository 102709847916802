import ILanguage from "../models/Language/ILanguage";

import en from "./en";
import sv from "./sv";
import da from "./da";
import nl from "./nl";
import fi from "./fi";
import de from "./de";
import th from "./th";
import ar from "./ar";
import fr from "./fr";
import no from "./no";
import merge from "lodash/merge";

let texts: Record<string, ILanguage> = {
  en,
  sv,
  da,
  nl,
  fi,
  de,
  th,
  ar,
  fr,
  no,
};

export function setTexts(overrides: Record<string, ILanguage>): void {
  texts = merge(texts, overrides);
}

export function text(string: keyof ILanguage, fallback?: keyof ILanguage): string {
  const lang = navigator.language.substring(0, 2) || "en";

  // Try to get translations for the browser's langugage
  let translations = texts[lang];

  // Fall back on the first element in languages array
  if (!translations) {
    translations = texts[Object.keys(texts)[0]] as ILanguage;
  }

  let translation = translations[string];

  // Check for a fallback
  if (!translations[string] && fallback) {
    translation = translations[fallback];
  }

  // No translation available
  if (!translation) {
    translation = "?" + string;
    let errorMessage = `translation missing for "${string}" in "${lang}"`;
    errorMessage = lang !== "en" ? errorMessage + ' and "en"' : errorMessage;
    console.debug(errorMessage);
  }

  return translation;
}
