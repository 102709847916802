import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web app",
  "common.loading": "Loading",
  "common.call": "Call",
  "common.answer": "Answer",
  "common.reject": "Reject",
  "common.callback": "Call back",
  "common.callagain": "Call again",
  "common.yes": "Yes",
  "common.no": "No",
  "common.on": "ON",
  "common.off": "OFF",
  "common.text": "Text",
  "common.abort": "Abort",
  "common.delete": "Delete",
  "common.cancel": "Cancel",
  "common.missedcall": "Missed call from",
  "common.save": "Save",
  "common.hangup": "Hang up",
  "common.add": "Add",
  "common.firstname": "First name",
  "common.lastname": "Last name",
  "common.sipaddress": "SIP address",
  "common.email": "Email",
  "common.address": "Address",
  "common.city": "City",
  "common.postcode": "Post code",
  "common.calling": "Calling",
  "common.dialer": "Dialer",
  "common.back": "Back",
  "common.hide": "Hide",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Audio",
  "common.reset": "Reset",
  "common.here": "here",
  "common.username": "Username",
  "common.password": "Password",
  "common.send": "Send",
  "common.password1": "Password",
  "common.password2": "Repeat password",
  "common.phonenumber": "Phone",
  "common.sip": "SIP address",
  "common.mobilenumber": "Mobile",
  "common.isdn": "ISDN number",
  "common.3gnumber": "3G number",
  "common.fax": "Fax",
  "common.username_helptext":
    "Between 5 and 100 characters. Only 0-9, .(dot), -(dash) and a-z(small caps) are permitted.",
  "common.email_helptext": "Invalid email",
  "common.password1_helptext": "Between 6 and 20 characters",
  "common.password2_helptext": "Passwords are not equal",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "The field contains one or more invalid characters",
  "common.link": "link",
  "common.Link": "Link",
  "common.anonymous": "Anonymous",
  "common.edit": "Edit",
  "common.contact_information": "Contact information",
  "common.view_message": "View message",
  "common.close_message": "Close message",
  "common.required": "* Required field",
  "common.to_startpage": "To start page",
  "common.organisation": "Organization",
  "common.permissions.heading": "The service needs permission to use your camera and/or microphone.",
  "common.permissions.subheading":
    'You can later before each call decide whether you want to use video/audio in the call or not. <br>Firefox users are advised to "Remember this decision" to avoid similar questions later. <br>When you accept the permissions, the camera/microphone will be activated for a few seconds and then switched off.',
  "common.app_for_os": "%APPNAME% for %OS%",

  /** Titles */
  "titles.login": "Login",
  "titles.services": "Services",
  "titles.contacts": "My contacts",
  "titles.call": "Call",
  "titles.directory": "Business Directory",
  "titles.history": "History",
  "titles.messages": "Messages",
  "titles.settings": "Settings",
  "titles.userinformation": "My profile",
  "titles.log": "Debug",
  "titles.about": "Information about systems",
  "titles.registration": "Registration",
  "titles.newpassword": "Get new password",
  "titles.resetpassword": "Reset password",
  "titles.verifyaccount": "Activate account",

  /** Toolbar */
  "toolbar.hidetext": "Text is OFF",
  "toolbar.showtext": "Text is ON",
  "toolbar.micoff": "Microphone is OFF",
  "toolbar.micon": "Microphone is ON",
  "toolbar.audiooff": "Audio is OFF",
  "toolbar.audioon": "Audio is ON",
  "toolbar.videooff": "Incoming video is OFF",
  "toolbar.videoon": "Incoming video is ON",
  "toolbar.cameraoff": "My video is OFF",
  "toolbar.cameraon": "My video is ON",
  "toolbar.fullscreenoff": "Fullscreen is OFF",
  "toolbar.fullscreenon": "Fullscreen is ON",
  "toolbar.selfviewoff": "Selfview is OFF",
  "toolbar.selfviewon": "Selfview is ON",
  "toolbar.hidedialpad": "Dialpad is ON",
  "toolbar.showdialpad": "Dialpad is OFF",
  "toolbar.useaudioon": "Call with sound is ON",
  "toolbar.useaudiooff": "Call with sound is OFF",
  "toolbar.usevideoon": "Call with video is ON",
  "toolbar.usevideooff": "Call with video is OFF",
  "toolbar.usetexton": "Call with text is ON",
  "toolbar.usetextoff": "Call with text is OFF",
  "toolbar.more": "More",
  "toolbar.hide": "Hide",
  "toolbar.togglevideo": "Show video",
  "toolbar.toggletext": "Show text",
  "toolbar.toSettings": "Go to % settings",

  /** Menu */
  "menu.logo": "To home page",
  "menu.services": "Services",
  "menu.servicesHeadline": "Services",
  "menu.contacts": "Contacts",
  "menu.mycontacts": "My contacts",
  "menu.call": "Call",
  "menu.directory": "Business Directory",
  "menu.history": "History",
  "menu.messages": "Messages",
  "menu.settings": "Settings",
  "menu.logout": "Logout",
  "menu.userinformation": "Account",
  "menu.userprofile": "My profile",
  "menu.log": "Debug",
  "menu.about": "Information about systems",

  /** Login screen */
  "login.loginanonymous.headline": "Click here for anonymous calling",
  "login.loginanonymous": "Anonymous login",
  "login.headline": "Login",
  "login.login": "Login",
  "login.loginfailed": "Login failed",
  "login.resetpassword": "Did you forget your password?",
  "login.registration": "Want to sign up?",
  "login.nowebrtc": "Your browser doesn't support real-time communication with video and audio (WebRTC).",
  "login.debug": "Debug mode",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Invalid username or password",
  "error.usernameNotExists": "The username does not exist",
  "error.noservercontact": "No contact with server",
  "error.accountnotactive": "Account not active",
  "error.accountexpired": "Account expired",
  "error.tokenexpired": "The authentication session has expired, please sign-in again",
  "error.tokeninvalid": "The authentication token is invalid",
  "error.unknown": "Unknown error",
  "error.unknownsender": "Unknown sender",
  "error.unknownsip": "Unknown SIP",
  "error.notextsent": "No text sent",
  "error.acquiremedia":
    "The service needs the permission to use your camera and/or microphone. You can later before each call decide whether you want to use video/audio in the call or not.",
  "error.mediaNotReadable":
    "Your camera or microphone are busy. Please make sure they are not used by any other application and reload the page.",
  "error.requestedDeviceNotFound":
    "The requested media device was not found. Please make sure it's connected properly.",
  "error.cantcall": "You cannot make calls when you are not in contact with the system",
  "error.cantcall_without_media": "You cannot make calls without sound, video or text",
  "error.404": "404 - The page you're looking for does not exist",
  "error.unsupportedbrowser": "Your web browser is currently not supported, please use another web browser.",
  "error.browseroutofdate":
    "Your web browser is out of date. Please update it to the latest version in order to use the application.",
  "error.browseroutofdate.ios.15.1": "This iOS version is unsupported, please update to iOS 15.2 or later.",
  "error.browserwarning.closeAndDontShowAgain": "Close and don't show again",
  "error.browserwarning.macos":
    "If you experience problems in making calls from your iPad and are currently using iOS version 15.1, please upgrade to iOS 15.2 or later.",

  /** User settings */
  "settings.white": "White",
  "settings.lightgrey": "Light Grey",
  "settings.darkgrey": "Dark Grey",
  "settings.black": "Black",
  "settings.yellow": "Yellow",
  "settings.blue": "Blue",
  "settings.red": "Red",
  "settings.green": "Green",
  "settings.purple": "Purple",
  "settings.orange": "Orange",
  "settings.pink": "Pink",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Side by side",
  "settings.overunder": "Over and under",
  "settings.one": "Shared text window",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Bell",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Classic",
  "settings.settings": "Settings",
  "settings.text": "Text",
  "settings.font": "Font",
  "settings.fontsize": "Fontsize",
  "settings.textboxlayout": "Text window layout",
  "settings.mycolors": "My text window",
  "settings.incomingcolors": "Incoming text window",
  "settings.sharedcolors": "Shared text window",
  "settings.textcolors": "Text color",
  "settings.backgroundcolors": "Background color",
  "settings.preview": "Preview",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "settings.previewmytext": "My text",
  "settings.previewincomingtext": "Incoming text",
  "settings.previewbothtext": "My text, Incoming text",
  "settings.incomingcalls": "Incoming calls",
  "settings.messagebox": "Message box",
  "settings.autoanswer": "Use a text message for when you do not answer",
  "settings.autoanswermessage": "The message",
  "settings.duringcalls": "During calls",
  "settings.colors": "Colors",
  "settings.textformat": "Text",
  "settings.media": "Media",
  "settings.audio": "Audio",
  "settings.video": "Video",
  "settings.ringtone": "Ringtone",
  "settings.mediamail": "Messages",
  "settings.about": "System information",
  "settings.logout": "Log out",
  "settings.logout.anonymous": "Exit",
  "settings.saved": "Settings have been saved",
  "settings.media_explanation":
    "Choose how you want to make you calls. If a setting is ON then you can always temporarily turn it off during a call, however if it is OFF then it cannot be turned on during a call.",
  "settings.useaudio": "Call using audio",
  "settings.usevideo": "Call using video",
  "settings.bandwidth.label": "Bandwidth",
  "settings.bandwidth.high": "High",
  "settings.bandwidth.medium": "Medium",
  "settings.bandwidth.low": "Low",

  /** Messages */
  "messages.messages": "Messages",
  "messages.unreadmessages": "Unread",
  "messages.readmessages": "Read",
  "messages.nomessages": "You have no messages",
  "messages.deleteallread": "Delete all the read messages",
  "messages.deleteallreadconfirm": "Delete all the read messages?",
  "messages.messageAlert": "Message deleted",

  /** Contacts */
  "contacts.contacts": "My contacts",
  "contacts.addcontact": "Add contact",
  "contacts.deletecontact": "Delete contact",
  "contacts.nosipgiven": "Please give the contact a SIP address",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Write number/name here",
  "call.incomingtext": "Incoming text",
  "call.outgoingtext": "Outgoing text",
  "call.incoming": "Incoming call from ",
  "call.unknownnumber": "unknown number",
  "call.unknownnumber_uppercase": "Unknown number",
  "call.endedcall": "ended the call",
  "call.yourejected": "You rejected the call",
  "call.noanswer": "No answer",
  "call.rejected": "could not answer for the moment",
  "call.notfound": "is not reachable",
  "call.toolongtext": "\n<Too many characters entered. Max 1000.>\n",
  "call.notificationbody": "Click here to answer",
  "call.videoTextDisabled": "Text and incoming video is OFF",
  "call.writeHere": "Write here...",
  "call.receiveHere": "This is where the incoming text will appear",
  "call.establishing": "Establishing call...",

  /** History screen */
  "history.history": "History",

  /** Reset password */
  "resetpassword.processing": "Processing ...",
  "resetpassword.completed": "Password reset is completed",
  "resetpassword.email_or_username": "Email or username",
  "resetpassword.title": "New password",
  "resetpassword.explanation":
    "Enter your email or username and a link will be sent to the email or the email associated with the username. Follow the link to reset your password.",
  "resetpassword.explanation_complete": "You will receive an email with further instructions shortly.",
  "resetpassword.invalid_link": "Invalid reset password link",
  "resetpassword.password_changed": "Your password has changed",
  "resetpassword.enter_new_password": "Enter new password",
  "resetpassword.button_reset": "Reset",
  "resetpassword.password": "Password",
  "resetpassword.password_repeat": "Repeat password",
  "resetpassword.password_requirement": "Password must be between 6 and 20 characters long",
  "resetpassword.error": "It was not possible to reset the password. Please try again or contact your administrator",
  "resetpassword.link_too_old": "link is too old",

  /** Registration */
  "registration.error":
    "It was not possible to complete the registration. Please try again or contact your administrator. ",
  "registration.title": "Registration",
  "registration.processing": "Processing ...",
  "registration.not_enabled": "Registration is not enabled.",
  "registration.completed_headline": "You have been registered",
  "registration.completed": "Your registration has been completed.",
  "registration.completed_admin_approval":
    "Your account needs to be approved by an administrator before you can login.",
  "registration.completed_verification":
    "Your account needs to be confirmed before you can login. You will receive an email with instructions shortly.",
  "registration.completed_link": "/",
  "registration.username_exists": "Username exists. Please select another username.",
  "registration.email_exists": "Email exists. Please select another email.",
  "registration.sip_exists": "SIP address exists. Please select another sip address.",
  "registration.license1": "Yes, I have read ",
  "registration.license_link_text": " the information about how we handle personal information",
  "registration.license2": " and accept the terms",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Accept the terms if you want to be registered",
  "registration.register": "Register",

  /** Verify Account */
  "verifyaccount.completed.title": "Your account is activated!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Something went wrong! Please try to register the account again.",
  "verifyaccount.error.expired": "The verification link is expired. Please try to register the account again.",
  "verifyaccount.registration": "Want to sign up?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Edit account",
  "changeuser.titlePassword": "Change password",
  "changeuser.newPassword": "New password",
  "changeuser.error": "It was not possible to update. Please try again or contact your administrator. ",
  "changeuser.complete": "Account updated",
  "changeuser.processing": "Processing ...",
  "changeuser.username_exists": "Username exists. Please select another username.",
  "changeuser.email_exists": "Email exists. Please select another email.",
  "changeuser.email_empty": "Email is required. Please write an email.",
  "changeuser.sip_exists": "SIP address exists. Please select another sip address.",

  /** Network */
  "network.networkError": "A problem occured when connecting to server",
  "network.establishing": "Trying to establish connection to the server...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Dark theme",
  "accessibility.originaltheme": "Original theme",

  /** About */
  "about.user": "Username",
  "about.sip": "SIP address",
  "about.server": "Server IP",
  "about.version": "Version",
  "about.browser": "Browser",
  "about.browserversion": "Browser version",
  "about.os": "OS",

  /** Aria */
  "aria.mainmenu": "Main menu",
  "aria.submenu_contacts": "Submenu of contacts",
  "aria.submenu_account": "Submenu of account",
  "aria.selected_page": "Selected page",
  "aria.back_button": "Remove last character",
  "aria.accept_terms": "Accept terms",
  "aria.link_sound_video": "Go to settings for sound and video",
  "aria.selfview": "Selfview video",
  "aria.in-call": "In call",

  /** Kiosk */
  "kiosk.call_ended": "The call has ended, you may now close this page.",

  /** Modal */
  "modal.close": "Close",
  "modal.cancel": "Cancel",
  "modal.confirm": "OK",
  "modal.logout_confirmation.title": "Are you sure you want to log out?",
  "modal.logout_confirmation.confirm": "Yes",
  "modal.logout_confirmation.cancel": "No, cancel",
};

export default translations;
