import React from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import Button from "../components/buttons/Button";
import CallButton from "../components/buttons/CallButton";
import EditButton from "../components/buttons/EditButton";
import DeleteButton from "../components/buttons/DeleteButton";
import "./ContactInformation.css";
import { APP } from "../actions/actionTypes";

class ContactInformation extends React.Component {
  onSubmit = values => {
    this.props.saveContact({
      ...values,
      id: this.props.match.params.id,
    });
    this.props.history.push("/contacts");
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          <div className="d-flex justify-content-between">
            <h1 id="h1id" className="margin-auto">
              {this.props.contact.name}
            </h1>
          </div>
          <div className="d-flex justify-content-between information-content">
            <div className="content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.sipaddress")}</h2>
              <div>{this.props.contact.sipaddress !== null ? this.props.contact.sipaddress : "-"}</div>
            </div>
            <div className="content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.email")}</h2>
              <div>{this.props.contact.email !== null ? this.props.contact.email : "-"}</div>
            </div>
            <div className="content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.address")}</h2>
              <div>{this.props.contact.address !== null ? this.props.contact.address : "-"}</div>
            </div>
            <div className="content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.city")}</h2>
              <div>{this.props.contact.city !== null ? this.props.contact.city : "-"}</div>
            </div>
            <div className="content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.postcode")}</h2>
              <div>{this.props.contact.postNr !== null ? this.props.contact.postNr : "-"}</div>
            </div>
          </div>
          <div className="d-flex justify-content-between contact-information-buttons">
            <CallButton sipaddress={this.props.contact.sipaddress} />
            <EditButton to="/contacts/edit/" id={this.props.contact.id} text={this.props.text} />
            <DeleteButton to="/contacts/delete/" id={this.props.contact.id} text={this.props.text} />
            <Button className="contact-information-cancel" to={"/contacts"} text={this.props.text("common.cancel")} />
          </div>

          <div></div>
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
      </div>
    );
  }
}

function getContact(state, props) {
  if (!props.match.params.id) return {};
  var contact = {};
  state.contact.contacts.forEach(item => {
    if (item.id.toString() === props.match.params.id) {
      contact = item;
      contact.name = item.fname + " ";
      if (item.sname) {
        item.name = item.name + item.sname;
      }
    }
  });

  if (contact.name) this.props.setPageTitle([contact.name, state.config.text("contacts.contacts")]);

  return contact;
}

const mapStateToProps = (state, props) => {
  return {
    contacts: state.contacts,
    contact: getContact(state, props),
    loading: state.contact.loading,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
