import React from "react";
import { connect } from "react-redux";
import { Service } from "../components/Service";
import Loading from "../components/Loading";
import { loadServices } from "../actions/serviceActions";
import { call } from "../actions/sipActions";
import { SIP_STATE } from "../constants/sipState";

import "./Services.css";
import { APP } from "../actions/actionTypes";

class Services extends React.Component {
  componentDidMount() {
    this.props.loadServices();
    this.props.setPageTitle(this.props.text("menu.servicesHeadline"));
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    return (
      <div className={"services-container"}>
        <h1 id="h1id">{this.props.text("menu.servicesHeadline")}</h1>
        <ul className="services" aria-label={this.props.text("menu.servicesHeadline")}>
          {this.props.services.map(item => (
            <Service
              text={this.props.text}
              service={item}
              key={item.id}
              disabled={this.props.disabled}
              onClick={() => {
                this.props.call(item.sipaddress);
              }}
            />
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: state.service.services,
    loading: state.service.loading,
    text: state.config.text,
    disabled:
      !state.config.hasPermissions ||
      state.sip.sipState === SIP_STATE.UNREGISTERED ||
      state.sip.sipState === SIP_STATE.REGISTERING,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadServices: () => {
      return dispatch(loadServices());
    },
    call: callTo => {
      return dispatch(call(callTo));
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
