import React from "react";
import { connect } from "react-redux";
import {
  endCall,
  sendMessage as sendTxt,
  muteAudio,
  muteVideo,
  muteRemoteAudio,
  muteRemoteVideo,
  stopRemoteAudio,
  stopVideo,
  stopAudio,
} from "../../../actions/sipActions";
import { unBindLocalStream } from "../../../actions/configActions";
import { CALLS } from "../../../actions/actionTypes";
import DialerView from "../../DialerView";
import RealTimeText from "../../../components/RealTimeText";
import AudioVideo from "../../../components/AudioVideo";
import InCallButtonBar from "../../../components/InCallButtonBar";
import Fullscreen from "react-full-screen";

import "./Default.css";
import { IRootState } from "../../../models/reducerStates";
import { Font, TextColor, WebChatLayout } from "../../../types";
import { BotInfo, BrowserInfo, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from "detect-browser";

type Props = {
  l10n?: Function;
  unBindLocalStream?: Function;
  hangup?: Function;
  muteAudio?: Function;
  muteVideo?: Function;
  muteRemoteAudio?: Function;
  hideVideo?: Function;
  hideDialer?: Function;
  hideSelfview?: Function;
  showMore?: Function;
  handleFullscreenChange?: (enabled: boolean) => any;
  toggleFullscreen?: Function;
  stopVideo?: Function;
  stopAudio?: Function;
  stopRemoteAudio?: Function;
  sendTxt?: Function;
  toggleText?: Function;
  stopKnockKnock?: Function;

  audio?: boolean;
  audioAvailable?: boolean;
  audioVisible?: boolean;
  video?: boolean;
  videoAvailable?: boolean;
  videoVisible?: boolean;
  textAvailable?: boolean;
  textEnabled?: boolean;
  textVisible?: boolean;
  show?: boolean;
  soundOn?: boolean;
  dialerAvailable: boolean;
  dialerVisible?: boolean;
  selfViewVisible?: boolean;
  unreadText?: boolean;
  fullscreenAvailable?: boolean;
  fullscreenEnabled?: boolean;
  browser?: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo;
  textVisibleByDefault?: boolean;
  textLayout?: WebChatLayout;
  knockknock?: boolean;
  text: {
    in: string;
    out: string;
    combined: string;
  };
  colors?: {
    background: TextColor;
    foreground: TextColor;
    in: {
      foreground: TextColor;
      background: TextColor;
    };
    out: {
      foreground: TextColor;
      background: TextColor;
    };
  };
  font?: { size: number; family: Font };
};

class Default extends React.Component<Props> {
  inCallAlready = false;
  knockKnockActive = false;

  beforeUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = "";
  }

  componentDidMount() {
    this.props.toggleText(!this.isScreenSmall());
    window.addEventListener("beforeunload", this.beforeUnload, false);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload, false);
    this.props.unBindLocalStream();
  }

  isScreenSmall() {
    return window.innerWidth < 992;
  }

  stopKnockKnock() {
    setTimeout(() => {
      this.props.stopKnockKnock();
      this.knockKnockActive = false;
    }, 5000);
  }

  render(): JSX.Element {
    if (this.inCallAlready === false) {
      this.inCallAlready = true;
    }

    if (this.knockKnockActive === false) {
      this.stopKnockKnock();
      this.knockKnockActive = true;
    }

    if (this.props.video && !this.props.videoAvailable) {
      this.props.stopVideo();
    }

    if (this.props.audio && !this.props.audioAvailable) {
      this.props.stopAudio();
      this.props.stopRemoteAudio();
    }

    //
    // Height will be 100vh except for iOS devices where the height must be adjusted for the view to
    // look good. Otherwise the view will scroll when the soft keyboard is shown and hide the footer
    // or header buttons.
    //
    return (
      <Fullscreen enabled={this.props.fullscreenEnabled} onChange={this.props.handleFullscreenChange}>
        <div className="callFullscreen">
          <div
            className={
              "box callContainer " +
              (this.props.knockknock ? "flash " : "") +
              (this.props.browser.os.toLowerCase() === "ios" ? "height-IOS" : "height-100-procent") +
              (!this.props.videoVisible && !this.props.textVisible ? "" : "")
            }
          >
            {!this.props.videoVisible && !this.props.textVisible && (
              <p className="margin-auto">{this.props.l10n("call.videoTextDisabled")}</p>
            )}

            <main
              role={"main"}
              className={
                "content callViewContainer" + (this.props.videoVisible || this.props.textVisible ? "" : "display-none")
              }
            >
              <h1 style={{ display: "none" }}>{this.props.l10n("aria.in-call")}</h1>
              <div
                id={"videoContainer"}
                className={"inCallVideoContainer" + (this.props.videoAvailable ? "" : " display-none")}
              >
                <AudioVideo
                  isVisible={this.props.videoVisible}
                  isVideoAvailable={this.props.videoAvailable}
                  direction="remote"
                  kioskmode={false}
                  textIsVisible={this.props.textVisible}
                />
                {this.props.videoAvailable && (
                  <AudioVideo
                    isVisible={this.props.selfViewVisible}
                    isVideoAvailable={this.props.videoAvailable}
                    direction="local"
                    kioskmode={false}
                  />
                )}
              </div>
              {this.props.textEnabled && (
                <div
                  // ref={n => (this.node2 = n)}
                  className={
                    "padding-zero inCallTextContainer" +
                    (!this.props.videoAvailable ? " width-100-procent" : "") +
                    (this.props.textVisible ? "" : " display-none")
                  }
                >
                  <RealTimeText
                    isVisible={this.props.textVisible}
                    isVisibleByDefault={this.props.textVisibleByDefault}
                    layout={this.props.textLayout}
                    text={this.props.text}
                    colors={this.props.colors}
                    font={this.props.font}
                    sendTxt={this.props.sendTxt}
                    kioskmode={false}
                    placeholderCombined={this.props.l10n("call.writeHere")}
                    placeholderOutgoing={this.props.l10n("call.writeHere")}
                    placeholderIncoming={this.props.l10n("call.receiveHere")}
                  />
                </div>
              )}
            </main>
            <DialerView />
            <InCallButtonBar
              l10n={this.props.l10n}
              audio={this.props.audio}
              isAudioAvailable={this.props.audioAvailable}
              video={this.props.video}
              isVideoAvailable={this.props.videoAvailable}
              isVideoVisible={this.props.videoVisible}
              isTextAvailable={this.props.textAvailable}
              isTextEnabled={this.props.textEnabled}
              isTextVisible={this.props.textVisible}
              isSoundOn={this.props.soundOn}
              isDialerVisible={this.props.dialerVisible}
              isDialpadAvailable={this.props.dialerAvailable}
              isSelfViewVisible={this.props.selfViewVisible}
              unreadText={this.props.unreadText}
              hangup={() => this.props.hangup()}
              muteAudio={() => this.props.muteAudio()}
              muteVideo={() => this.props.muteVideo()}
              muteRemoteAudio={() => this.props.muteRemoteAudio()}
              hideVideo={() => this.props.hideVideo()}
              toggleText={() => this.props.toggleText()}
              hideDialer={() => this.props.hideDialer()}
              hideSelfview={() => this.props.hideSelfview()}
              show={this.props.show}
              showMore={() => this.props.showMore()}
              isFullscreenAvailable={this.props.fullscreenAvailable}
              isFullscreenEnabled={this.props.fullscreenEnabled}
              toggleFullscreen={() => this.props.toggleFullscreen()}
            />
          </div>
        </div>
      </Fullscreen>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    text: {
      in: state.call.text_in,
      out: state.call.text_out,
      combined: state.call.text_combined,
    },
    unreadText: state.call.text_unread,
    font: {
      size: state.setting.user.WEB_TEXT_SIZE,
      family: state.setting.user.WEB_FONT,
    },
    colors: {
      background: state.setting.user.WEB_ONEBOX_TEXT_BACKGROUND_COLOR,
      foreground: state.setting.user.WEB_ONEBOX_TEXT_COLOR,
      in: {
        foreground: state.setting.user.WEB_TEXT_IN_COLOR,
        background: state.setting.user.WEB_TEXTAREA_IN_BACKGROUND,
      },
      out: {
        foreground: state.setting.user.WEB_TEXT_OUT_COLOR,
        background: state.setting.user.WEB_TEXTAREA_OUT_BACKGROUND,
      },
    },
    textLayout: state.setting.user.WEB_CHAT_LAYOUT,
    l10n: state.config.text,
    audioAvailable: state.call.audioavailable && state.setting.user.WEB_AUDIO.toLowerCase() === "true",
    videoAvailable: state.call.videoavailable && state.setting.user.WEB_VIDEO.toLowerCase() === "true",
    textAvailable: state.call.textavailable,
    fullscreenAvailable: document.fullscreenEnabled ? state.config.call.fullscreen : false,
    fullscreenEnabled: state.call.fullscreenEnabled,
    audio: state.call.audio,
    video: state.call.video,
    videoVisible: state.call.videoVisible,
    textEnabled: state.config.call.safetext,
    /**
     * // TODO
     * This needs to take into account the screen size.
     * No use in hiding the video and show the text when the call connects on small screens.
     */
    textVisible:
      state.setting.user.WEB_VIDEO.toLowerCase() === "false" || (state.call.textavailable && state.call.textVisible),
    textVisibleByDefault:
      state.config.call.textVisibleByDefault || state.setting.user.WEB_VIDEO.toLowerCase() === "false",
    soundOn: state.call.soundOn,
    dialerAvailable: state.config.call.dialpad,
    dialerVisible: state.call.dialerVisible,
    selfViewVisible: state.call.selfViewVisible,
    show: state.call.showMore,
    browser: state.config.browser,
    contrast: state.accessibility.isHighContrast,
    signal: state.setting.user.WEB_RINGTONE,
    knockknock: state.call.knockknock,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    hangup: () => {
      return dispatch(endCall());
    },
    sendTxt: (str: string) => {
      return dispatch(sendTxt(str));
    },
    muteAudio: () => {
      return dispatch(muteAudio());
    },
    muteVideo: () => {
      return dispatch(muteVideo());
    },
    stopAudio: () => {
      return dispatch(stopAudio());
    },
    stopVideo: () => {
      return dispatch(stopVideo());
    },
    muteRemoteAudio: () => {
      return dispatch(muteRemoteAudio());
    },
    stopRemoteAudio: () => {
      return dispatch(stopRemoteAudio());
    },
    hideVideo: () => {
      return dispatch(muteRemoteVideo());
    },
    toggleText: (value: any) => {
      return dispatch({
        type: CALLS.TOGGLE_TEXT,
        payload: value,
      });
    },
    hideDialer: () => {
      return dispatch({
        type: CALLS.HIDE_DIALER,
      });
    },
    hideSelfview: () => {
      return dispatch({
        type: CALLS.HIDE_SELFVIEW,
      });
    },
    showMore: () => {
      return dispatch({
        type: CALLS.SHOW_MORE,
      });
    },
    toggleFullscreen: () => {
      return dispatch({
        type: CALLS.TOGGLE_FULLSCREEN,
      });
    },
    handleFullscreenChange: (isFullscreen: boolean) => {
      return dispatch({
        type: CALLS.TOGGLE_FULLSCREEN,
        payload: isFullscreen,
      });
    },
    unBindLocalStream: () => {
      return dispatch(unBindLocalStream());
    },
    stopKnockKnock: () => {
      return dispatch({
        type: CALLS.KNOCK_KNOCK,
        payload: false,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Default);
