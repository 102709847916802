import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Standard from "./Standard";
import { SIP_ACTION } from "../../actions/actionTypes";
import RoundRejectButton from "../../components/buttons/RoundRejectButton";
import RoundAnswerButton from "../../components/buttons/RoundAnswerButton";
import { call } from "../../actions/sipActions";
import * as Sentry from "@sentry/react";

export class UnknownError extends React.Component {
  componentDidMount() {
    Sentry.captureMessage("Call ended in Unknown Error", "warning");
  }

  unknown = () => {
    return (
      <div className={"text-align-center margin-auto"}>
        <h1>{this.props.l10n("error.unknown")}</h1>
        <br />
        <div className={"row text-align-center margin-auto"}>
          {this.props.callB && (
            <div className={"col"}>
              <RoundAnswerButton
                text={this.props.l10n("common.callback")}
                onClick={() => this.props.call(this.props.callB)}
              />
            </div>
          )}
          <div className={"col"}>
            <RoundRejectButton
              text={this.props.l10n("common.cancel")}
              onClick={() => this.props.complete()}
              kioskmode={false}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { logo, logoDark, contrast, l10n } = this.props;
    return <Standard logo={logo} logoDark={logoDark} contrast={contrast} l10n={l10n} include={this.unknown()} />;
  }
}

UnknownError.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
  callB: state.call.callB,
  callBName: state.call.callBName,
});

const mapDispatchToProps = dispatch => {
  return {
    call: to => {
      return dispatch(call(to));
    },
    complete: () => {
      return dispatch({
        type: SIP_ACTION.CALL_COMPLETED,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnknownError);
