import { CALLS } from "./actionTypes";
import { Phone } from "../modules/Phone";

export function dtmf(input: string) {
  return (dispatch: Function, getState: Function) => {
    if (!input) return;
    if (!(input.length === 1)) return;

    const phone: Phone = getState().sip.phone;
    const regex = new RegExp("^[01234567890*#]$");

    if (!regex.test(input)) return;

    if (phone) {
      phone.sendDTMF(input);
    }

    dispatch({
      type: CALLS.DTMF,
      payload: input,
    });
  };
}
