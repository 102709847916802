import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import isEmpty from "lodash/isEmpty";

import { save } from "../actions/changeUserActions";

import Button from "../components/buttons/Button";
import InputText from "../components/InputText";
import Loading from "../components/Loading";
import { APP, CHANGE_USER } from "../actions/actionTypes";

const validate = (values, props) => {
  const errors = {};
  var regEx = null;
  if (!isEmpty(values.password1) && props.password.regex && props.password.regexText) {
    regEx = new RegExp(props.password.regex);
    errors.password1 = regEx.test(values.password1) ? undefined : props.password.regexText;
  }

  if (
    !isEmpty(values.password2) &&
    props.password.dontmatch &&
    (isEmpty(values.password1) || values.password1 !== values.password2)
  ) {
    errors.password2 = props.password.dontmatch;
  }
  return errors;
};

class ChangePassword extends React.Component {
  componentDidMount() {
    this.props.clear();
    this.props.setPageTitle(this.props.text("changeuser.titlePassword"));
  }

  onSubmit = values => {
    this.props.save(values);
  };

  password = field => {
    if (!field.visible) {
      return "";
    }

    return (
      <div>
        <InputText name="password1" text={this.props.text("common.password1")} field={field} />
        <InputText name="password2" text={this.props.text("common.password2")} field={field} />
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          <h1 id="h1id">{this.props.text("changeuser.titlePassword")}</h1>

          {this.props.changeUserError && (
            <div className="alert alert-danger col-md-12 col-lg-12" role="alert">
              {this.props.text("changeuser.error") +
                " (" +
                (this.props.changeUserErrorText === ""
                  ? this.props.text("error.noservercontact")
                  : this.props.changeUserErrorText) +
                ")"}
            </div>
          )}

          {this.props.changeUserComplete && (
            <div className="alert alert-success col-md-12 col-lg-12" role="alert">
              {this.props.text("changeuser.complete")}
            </div>
          )}

          <p>{this.props.text("common.required")}</p>

          {!this.props.changeUserInProgress && (
            <div className="margin-top-2em">
              <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {this.password(this.props.password)}

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="float-right">
                    <Button
                      title=" "
                      type="submit"
                      icon="save"
                      text={this.props.text("common.save")}
                      className="mr-3"
                    />
                    <Button title=" " to={"/userinformation"} text={this.props.text("common.cancel")} />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    changeUserInProgress: state.changeUser.changeUserInProgress,
    changeUserComplete: state.changeUser.changeUserComplete,
    changeUserError: state.changeUser.changeUserError,
    changeUserErrorText: state.changeUser.changeUserErrorText,
    text: str => state.config.text(str),
    loading: state.config.features.registration == null ? true : false,
    server: state.config.server.url,
    initialValues: {
      username: state.user.properties.USERNAME,
      firstname: state.user.properties.FORENAME,
      lastname: state.user.properties.SURNAME,
      sipaddress: state.user.properties.SIP_ADDRESS,
      address: state.user.properties.ADDRESS,
      postcode: state.user.properties.POSTCODE,
      city: state.user.properties.CITY,
      email: state.user.properties.EMAIL,
    },
    ...state.changeUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    save: values => {
      dispatch(save(values));
    },
    clear: () => {
      dispatch({ type: CHANGE_USER.CLEAR });
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

ChangePassword = reduxForm({
  form: "changepassword",
  validate,
})(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
