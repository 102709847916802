import React from "react";
import { connect } from "react-redux";
import CallButton from "../../components/buttons/CallButton";
import ProfileImage from "../../components/businessDirectory/ProfileImage";
import { call } from "../../actions/sipActions";

class Item extends React.Component {
  render() {
    return (
      <li key={this.props.item.id} className="d-flex list-group-item">
        <div
          className="d-flex justify-content-between align-items-center flex-fill"
          onClick={() => {
            this.props.onCall(this.props.item);
          }}
          onKeyDown={() => {
            this.props.onCall(this.props.item);
          }}
          role="link"
          tabIndex="0"
        >
          {this.props.item.image && (
            <div className="business-directory-image">
              <ProfileImage
                src={this.props.item.image}
                className="img-fluid img-thumbnail border-0 p-0"
                alt={this.props.item.name}
              />
            </div>
          )}
          <div className="d-flex flex-column flex-fill justify-content-center">
            <div>
              <strong>{this.props.item.name}</strong>
            </div>
          </div>
          <div className="flex-column justify-content-center d-none d-md-flex">
            <CallButton sipaddress={this.props.item.phone} />
          </div>
        </div>
      </li>
    );
  }
}

class List extends React.Component {
  render() {
    const items = this.props.items.map(item => (
      <Item
        key={item.id}
        item={item}
        onCall={item => {
          if (!item.phone || window.innerWidth >= 768) return;
          this.props.call(item.phone);
        }}
      />
    ));
    return (
      <li>
        <h2 className="business-directory-list-letter">{this.props.letter}</h2>
        <ul className="list-group">{items}</ul>
      </li>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    call: callTo => {
      return dispatch(call(callTo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
