import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SIP_ACTION } from "../../actions/actionTypes";
import { triggerNotification } from "../../actions/notificationActions";

export class CanceledRemote extends React.Component {
  componentDidMount() {
    const title = this.props.l10n("common.missedcall") + " " + this.props.from;
    this.props.triggerNotification(title);
    this.props.complete();
  }

  render() {
    return null;
  }
}

CanceledRemote.propTypes = {
  l10n: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  from: state.call.callB === "" ? state.config.text("call.unknownnumber") : state.call.callB,
});

const mapDispatchToProps = dispatch => ({
  triggerNotification: (title, body, action) => dispatch(triggerNotification(title, body, action)),
  complete: () => {
    return dispatch({
      type: SIP_ACTION.CALL_COMPLETED,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CanceledRemote);
