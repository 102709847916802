import { CONNECTION_WEBSOCKET } from "../actions/actionTypes";
import { IConnectionState } from "../models/reducerStates";

const initialState: IConnectionState = {
  websocket: null,
  websocketOnline: false,
};

const connectionReducer = (state: IConnectionState = initialState, action: any) => {
  switch (action.type) {
    case CONNECTION_WEBSOCKET.LOADED_FROM_SESSION:
    case CONNECTION_WEBSOCKET.LOGIN_SUCCESS:
      state = {
        ...state,
        websocket: action.payload.websocket,
      };
      break;
    case CONNECTION_WEBSOCKET.ONLINE:
    case CONNECTION_WEBSOCKET.OFFLINE:
      state = {
        ...state,
        websocketOnline: action.type === CONNECTION_WEBSOCKET.ONLINE,
      };
      break;
    case CONNECTION_WEBSOCKET.MSG:
      if (action.payload.msg.name === "LOGIN_RESPONSE" && action.payload.msg.status === 0) {
        state = {
          ...state,
          websocketOnline: true,
        };
      }
      if (action.payload.msg.name === "LOGIN_RESPONSE" && action.payload.msg.status === 404) {
        state = {
          ...state,
          websocketOnline: false,
        };
      }
      break;
    default:
      break;
  }
  return state;
};

export default connectionReducer;
