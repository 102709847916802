import { APP } from "../actions/actionTypes";
import { IDebugState } from "../models/reducerStates";
import { DebugView } from "../types";

const initialState: IDebugState = {
  log: [],
  state: {},
  view: DebugView.console,
};

const debugReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case APP.LOG:
      state.log.push(action.payload);
      break;
    case APP.DEBUG:
      state.state[action.payload.name] = JSON.stringify(action.payload.value);
      break;
    case APP.DEBUGVIEW:
      state = {
        ...state,
        view: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default debugReducer;
