import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { saveSettings, loadSettings } from "../../actions/settingActions";
import { SETTINGS } from "../../actions/actionTypes";
import Loading from "../../components/Loading";
import Button from "../../components/buttons/Button";
import { WebChatLayout } from "../../types";

class TextFormat extends React.Component {
  componentDidMount() {
    this.props.loadSettings();
  }

  componentWillUnmount() {
    this.props.clearSettingsSaved();
  }

  onSubmit = values => {
    this.props.saveSettings(values);
  };

  preview = (previewtext, textcolor, backgroundcolor) => {
    return (
      <div className={"colorpreview " + backgroundcolor + "-background-color "}>
        <p
          className={
            "margin-zero font-family-" +
            this.props.font +
            " font-size-" +
            this.props.fontsize +
            " " +
            textcolor +
            "-color"
          }
        >
          {previewtext}
        </p>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.config.features.settings.allowChangeText) {
      return <Redirect to={"/settings"} />;
    }

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div>
          <div className="form-group font-weight-bold">
            <label htmlFor="settings-textboxlayout">{this.props.text("settings.textboxlayout")}</label>
            <Field id="settings-textboxlayout" component="select" className="form-control" name="textbox">
              <option value={WebChatLayout.OVER_UNDER} key={WebChatLayout.OVER_UNDER}>
                {this.props.text("settings.overunder")}
              </option>
              <option value={WebChatLayout.SIDE_BY_SIDE} key={WebChatLayout.SIDE_BY_SIDE}>
                {this.props.text("settings.sidebyside")}
              </option>
              <option value={WebChatLayout.ONE_BOX} key={WebChatLayout.ONE_BOX}>
                {this.props.text("settings.one")}
              </option>
            </Field>
          </div>
          <div className="form-group font-weight-bold">
            <label htmlFor="settings-font">{this.props.text("settings.font")}</label>
            <Field id="settings-font" component="select" className="form-control inputtest" name="font">
              {this.props.fonts.map(font => (
                <option value={font.value} key={font.value}>
                  {this.props.text(font.text)}
                </option>
              ))}
            </Field>
          </div>
          <div className="form-group font-weight-bold">
            <label htmlFor="settings-fontsize">{this.props.text("settings.fontsize")}</label>
            <Field id="settings-fontsize" component="select" className="form-control" name="fontsize">
              {this.props.sizes.map(fontsize => (
                <option value={fontsize.value} key={fontsize.value}>
                  {fontsize.text}
                </option>
              ))}
            </Field>
          </div>
          <div className="form-group font-weight-bold">
            <label htmlFor="settings-textformat-preview">{this.props.text("settings.preview")}</label>
            <div id="settings-textformat-preview" className="flex">
              {this.props.textbox === WebChatLayout.OVER_UNDER && (
                <div>
                  {this.preview(
                    this.props.text("settings.previewincomingtext"),
                    this.props.colors.rectextforeground,
                    this.props.colors.rectextbackground
                  )}
                  {this.preview(
                    this.props.text("settings.previewmytext"),
                    this.props.colors.senttextforeground,
                    this.props.colors.senttextbackground
                  )}
                </div>
              )}

              {this.props.textbox === WebChatLayout.SIDE_BY_SIDE && (
                <div className="display-flex align-items-stretch">
                  <div className="side-by-side-box">
                    {this.preview(
                      this.props.text("settings.previewincomingtext"),
                      this.props.colors.rectextforeground,
                      this.props.colors.rectextbackground
                    )}
                  </div>
                  <div className="side-by-side-box">
                    {this.preview(
                      this.props.text("settings.previewmytext"),
                      this.props.colors.senttextforeground,
                      this.props.colors.senttextbackground
                    )}
                  </div>
                </div>
              )}

              {this.props.textbox === WebChatLayout.ONE_BOX && (
                <div>
                  {this.preview(
                    this.props.text("settings.previewbothtext"),
                    this.props.colors.onetextforeground,
                    this.props.colors.onetextbackground
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div></div>
          <div className="float-right">
            <Button title=" " type="submit" icon="save" text={this.props.text("common.save")} />
            {this.props.smallScreenMode && (
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            )}
          </div>
        </div>
        <div></div>
      </form>
    );
  }
}

const selector = formValueSelector("textformat");
const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      fontsize: state.setting.user.WEB_TEXT_SIZE,
      font: state.setting.user.WEB_FONT,
      textbox: state.setting.user.WEB_CHAT_LAYOUT,
    },
    colors: {
      senttextforeground: state.setting.user.WEB_TEXT_OUT_COLOR,
      senttextbackground: state.setting.user.WEB_TEXTAREA_OUT_BACKGROUND,
      rectextforeground: state.setting.user.WEB_TEXT_IN_COLOR,
      rectextbackground: state.setting.user.WEB_TEXTAREA_IN_BACKGROUND,
      onetextforeground: state.setting.user.WEB_ONEBOX_TEXT_COLOR,
      onetextbackground: state.setting.user.WEB_ONEBOX_TEXT_BACKGROUND_COLOR,
    },
    enableReinitialize: true,
    fonts: state.config.fonts.fonts,
    sizes: state.config.fonts.sizes,
    loading: state.setting.loading,
    textbox: selector(state, "textbox"),
    font: selector(state, "font"),
    fontsize: selector(state, "fontsize"),
    text: state.config.text,
    config: state.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: () => {
      return dispatch(loadSettings());
    },
    clearSettingsSaved: () => {
      dispatch({ type: SETTINGS.SAVED_RESET });
    },
  };
};

TextFormat = reduxForm({
  form: "textformat",
})(TextFormat);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextFormat));
