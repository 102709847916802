import React from "react";
import { Route, withRouter } from "react-router-dom";

class DeleteButton extends React.Component {
  btn = props => {
    return (
      <Route
        render={({ history }) => (
          <button
            className="float-left link-button"
            onClick={() => {
              history.push(props.to + props.id);
            }}
          >
            <i aria-hidden="true" className="material-icons icon-link-button">
              delete
            </i>
            <div className="font-size-smaller text-link-button">{props.text("common.delete")}</div>
          </button>
        )}
      />
    );
  };

  render() {
    return this.btn(this.props);
  }
}

export default withRouter(DeleteButton);
