import { ERROR, SIP_ACTION } from "../actions/actionTypes";
import { INetworkState } from "../models/reducerStates";

const initialState: INetworkState = {
  serverError: false,
  freeswitchError: false,
  sipError: false,
  sipErrorMessage: "",
};

export const networkReducer = (state: INetworkState = initialState, action: any) => {
  switch (action.type) {
    case ERROR.NETWORK_ERROR_ALERT:
      if (action.payload.error === "serverError" && state.serverError === false) {
        state = {
          ...state,
          serverError: true,
        };
      } else if (action.payload.error === "freeswitchError" && state.freeswitchError === false) {
        state = {
          ...state,
          freeswitchError: true,
        };
      }
      break;
    case ERROR.NETWORK_CLEAR_ALERT:
      if (action.payload.error === "serverError" && state.serverError === true) {
        state = {
          ...state,
          serverError: false,
        };
      } else if (action.payload.error === "freeswitchError" && state.freeswitchError === true) {
        state = {
          ...state,
          freeswitchError: false,
        };
      }
      break;
    case SIP_ACTION.SIP_ERROR_ALERT:
      state = {
        ...state,
        sipError: true,
        sipErrorMessage: action.payload.message,
      };
      break;
    case SIP_ACTION.SIP_CLEAR_ALERT:
      state = {
        ...state,
        sipError: false,
        sipErrorMessage: "",
      };
      break;
    default:
      break;
  }
  return state;
};
