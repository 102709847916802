import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web-app",
  "common.loading": "Loading",
  "common.call": "Ring",
  "common.answer": "Svar",
  "common.reject": "Advar",
  "common.callback": "Ring tilbage",
  "common.callagain": "Ring igen",
  "common.yes": "Ja",
  "common.no": "Nej",
  "common.on": "ON",
  "common.off": "AF",
  "common.text": "Tekst",
  "common.abort": "Afbryd",
  "common.delete": "Fjerne",
  "common.cancel": "Afbryd",
  "common.missedcall": "Ubesvaret opkald fra",
  "common.save": "Gem",
  "common.hangup": "Læg på",
  "common.add": "Tilføj til",
  "common.firstname": "Fornavn",
  "common.lastname": "Efternavn",
  "common.sipaddress": "SIP-adresse",
  "common.email": "Email",
  "common.address": "Adresse",
  "common.city": "By",
  "common.postcode": "Postnummer",
  "common.calling": "Ringer",
  "common.dialer": "Tonval",
  "common.back": "Tilbage",
  "common.hide": "Skjul",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Lyd",
  "common.reset": "Nulstil",
  "common.here": "her",
  "common.username": "Brugernavn",
  "common.password": "Adgangskode",
  "common.send": "Sende",
  "common.password1": "Adgangskode",
  "common.password2": "Gentag adgangskode",
  "common.phonenumber": "Telefon",
  "common.sip": "SIP-adresse",
  "common.mobilenumber": "En bil",
  "common.isdn": "ISDN nummer",
  "common.3gnumber": "3G nummer",
  "common.fax": "Fax",
  "common.username_helptext": "Format",
  "common.email_helptext": "Ugyldig email",
  "common.password1_helptext": "Mellem 6 og 20 tegn",
  "common.password2_helptext": "Adgangskoderne er ikke de samme",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Feltet indeholder et eller flere ugyldige tegn",
  "common.link": "link",
  "common.Link": "Link",
  "common.anonymous": "Anonym",
  "common.edit": "Redigere",
  "common.contact_information": "Detaljer",
  "common.view_message": "Se meddelelsen",
  "common.close_message": "Luk meddelelsen",
  "common.required": "* Obligatorisk felt",
  "common.to_startpage": "Til startside",
  "common.organisation": "Organisation",
  "common.permissions.heading": "The service needs permission to use your camera and/or microphone.",
  "common.permissions.subheading":
    'Du kan senere inden hvert opkald beslutte, om du vil bruge video / lyd i opkaldet eller ej.<br>Firefox-brugere rådes til at "huske denne beslutning" for at undgå lignende spørgsmål senere.<br>Når du accepterer tilladelserne, aktiveres kameraet / mikrofonen i et par sekunder og slukkes derefter.',
  "common.app_for_os": "%APPNAME% til %OS%",

  /** Toolbar */
  "toolbar.audiooff": "Lyden er AF",
  "toolbar.audioon": "Lyden er TIL",
  "toolbar.videooff": "Videoen er AF",
  "toolbar.videoon": "Videoen er TIL",
  "toolbar.cameraoff": "Kameraet er AF",
  "toolbar.cameraon": "Kameraet er TIL",
  "toolbar.fullscreenoff": "Fuldskærm er AF",
  "toolbar.fullscreenon": "Fuldskærm er TIL",
  "toolbar.micoff": "Mikrofonen er AF",
  "toolbar.micon": "Mikrofonen er TIL",
  "toolbar.selfviewoff": "Selvbillede er AF",
  "toolbar.selfviewon": "Selvbillede er TIL",
  "toolbar.hidetext": "Teksten er AF",
  "toolbar.showtext": "Tekst er TIL",
  "toolbar.hidedialpad": "Tastaturet er slået AF",
  "toolbar.showdialpad": "Tastaturet er slået TIL",
  "toolbar.useaudioon": "Ring med lyd er ON",
  "toolbar.useaudiooff": "Ring med lyd er AF",
  "toolbar.usevideoon": "Ring med video er ON",
  "toolbar.usevideooff": "Ring med video er AF",
  "toolbar.usetexton": "Ring med tekst er ON",
  "toolbar.usetextoff": "Ring med tekst er AF",
  "toolbar.more": "Mere",
  "toolbar.hide": "Skjul",
  "toolbar.togglevideo": "Se video",
  "toolbar.toggletext": "Se tekst",
  "toolbar.toSettings": "Gå til siden %indstillinger",

  /** Titles */
  "titles.login": "Log ind",
  "titles.services": "Tjenester",
  "titles.contacts": "Mine kontakter",
  "titles.call": "Ring",
  "titles.directory": "Business Directory",
  "titles.history": "Historie",
  "titles.messages": "Beskeder",
  "titles.settings": "Indstillinger",
  "titles.userinformation": "Min profil",
  "titles.log": "Fejlfinde",
  "titles.about": "Oplysninger om systemer",
  "titles.registration": "Registrering",
  "titles.newpassword": "Få en ny adgangskode",
  "titles.resetpassword": "Nulstil adgangskode",
  "titles.verifyaccount": "Aktivér konto",

  /** Menu */
  "menu.logo": "Til startside",
  "menu.services": "Tjenester",
  "menu.servicesHeadline": "Tjenester",
  "menu.contacts": "Kontakter",
  "menu.mycontacts": "Mine kontakter",
  "menu.call": "Ring",
  "menu.directory": "Business Directory",
  "menu.history": "Historisk",
  "menu.messages": "Beskeder",
  "menu.settings": "Indstillinger",
  "menu.logout": "Log ud",
  "menu.userinformation": "Konto",
  "menu.userprofile": "Min profil",
  "menu.log": "Fejlfinde",
  "menu.about": "Oplysninger om systemer",

  /** Login screen */
  "login.loginanonymous.headline": "Ring uden login",
  "login.loginanonymous": "Log ikke ind",
  "login.headline": "Ring med login",
  "login.login": "Log ind",
  "login.loginfailed": "Login mislykkedes",
  "login.resetpassword": "Glemt din adgangskode?",
  "login.registration": "Vil du tilmelde dig?",
  "login.nowebrtc": "Din browser understøtter ikke kommunikation i realtid med lyd og video (WebRTC).",
  "login.debug": "Debugging-tilstand",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Forkert brugernavn eller adgangskode",
  "error.usernameNotExists": "Brugernavnet eksisterer ikke",
  "error.noservercontact": "Ingen kontakt til serveren",
  "error.accountnotactive": "Kontoen er ikke aktiveret",
  "error.accountexpired": "Konto gyldighed er bestået",
  "error.tokenexpired": "Godkendelsessessionen er udløbet, skal du logge ind igen",
  "error.tokeninvalid": "Autentificeringstokenet er ugyldigt",
  "error.unknown": "Ukendt fejl",
  "error.unknownsender": "Ukendt afsender",
  "error.unknownsip": "Ukendt SIP",
  "error.notextsent": "Ingen tekst blev sendt",
  "error.acquiremedia":
    "Du kan ikke foretage opkald med lyd / video, medmindre du har valgt dette på installationssiden.",
  "error.mediaNotReadable":
    "Der er ikke adgang til lyd- eller videoenheden. Sørg for, at de ikke bruges af andre applikationer, og opdater siden.",
  "error.requestedDeviceNotFound": "Den anmodede medieenhed blev ikke fundet. Sørg for, at den er tilsluttet korrekt.",
  "error.cantcall": "Du kan ikke foretage opkald, når du ikke er i kontakt med systemet",
  "error.cantcall_without_media": "Du kan ikke foretage opkald uden lyd, video eller tekst",
  "error.404": "404 - Den side, du forsøger at se, findes ikke",
  "error.unsupportedbrowser": "Din webbrowser understøttes i øjeblikket ikke. Brug en anden webbrowser.",
  "error.browseroutofdate":
    "Din webbrowser er forældet. Opdater den til den nyeste version for at bruge applikationen.",
  "error.browseroutofdate.ios.15.1": "Denne iOS-version understøttes ikke. Opdater venligst til mindst iOS 15.2.",
  "error.browserwarning.closeAndDontShowAgain": "Luk og vis ikke igen",
  "error.browserwarning.macos":
    "Hvis du oplever problemer med at foretage opkald fra din iPad og i øjeblikket bruger iOS version 15.1, skal du opgradere til iOS 15.2 eller nyere.",

  /** User settings */
  "settings.white": "Hvid",
  "settings.lightgrey": "Lysegrå",
  "settings.darkgrey": "Mørk grå",
  "settings.black": "Sort",
  "settings.yellow": "Gul",
  "settings.blue": "Blå",
  "settings.red": "Rød",
  "settings.green": "Grøn",
  "settings.purple": "Lilla",
  "settings.orange": "Orange",
  "settings.pink": "Rosa",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Side ved side",
  "settings.overunder": "Over og under",
  "settings.one": "Fælles tekstfelt",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Klokke",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Klassisk",
  "settings.settings": "Indstillinger",
  "settings.text": "Tekst",
  "settings.font": "Skrifttyper",
  "settings.fontsize": "Størrelse",
  "settings.textboxlayout": "Layout for tekstfelter",
  "settings.mycolors": "Mit tekstfelt",
  "settings.incomingcolors": "Indgående tekstfelt",
  "settings.sharedcolors": "Fælles tekstfelt",
  "settings.textcolors": "Tekstfarve",
  "settings.backgroundcolors": "Baggrundfarve",
  "settings.preview": "Eksempel",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing Elit, sed Tempor og vitalitet, så arbejdskraft og sorg, nogle vigtige ting at gøre eiusmod.",
  "settings.previewmytext": "Min tekst",
  "settings.previewincomingtext": "Indgående tekst",
  "settings.previewbothtext": "Min tekst, Indgående tekst",
  "settings.incomingcalls": "Indgående opkald",
  "settings.messagebox": "Besked",
  "settings.autoanswer": "Brug tekstbesked, når du ikke reagerer",
  "settings.autoanswermessage": "Budskabet",
  "settings.duringcalls": "I samtale",
  "settings.colors": "Farver",
  "settings.textformat": "Tekst",
  "settings.media": "Media",
  "settings.audio": "Lyd",
  "settings.video": "Video",
  "settings.ringtone": "Ringetone",
  "settings.mediamail": "Beskeder",
  "settings.about": "Systemoplysninger",
  "settings.logout": "Log ud",
  "settings.logout.anonymous": "Log ud",
  "settings.saved": "Indstillingerne er blevet gemt",
  "settings.media_explanation":
    "Bestemm hvilken måde du skal ringe til. Hvis en indstilling er tændt, kan du altid slukke for et opkald, men hvis det er slukket, kan du ikke tænde det i et opkald.",
  "settings.useaudio": "Ring med lyd",
  "settings.usevideo": "Ring med video",
  "settings.bandwidth.label": "Båndbredde",
  "settings.bandwidth.high": "Høj",
  "settings.bandwidth.medium": "Medium",
  "settings.bandwidth.low": "Lav",

  /** Messages */
  "messages.messages": "Beskeder",
  "messages.unreadmessages": "Ulæst",
  "messages.readmessages": "Læse",
  "messages.nomessages": "Du har ingen beskeder",
  "messages.deleteallread": "Slet alle læste meddelelser",
  "messages.deleteallreadconfirm": "Slet alle læste meddelelser?",
  "messages.messageAlert": "Beskeden er blevet slettet",

  /** Contacts */
  "contacts.contacts": "Mine kontakter",
  "contacts.addcontact": "Tilføj kontakt",
  "contacts.deletecontact": "Fjern stikket",
  "contacts.nosipgiven": "Udfyld venligst en SIP-adresse til kontakten",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Indtast nummer / navn her",
  "call.incomingtext": "Indgående tekst",
  "call.outgoingtext": "Udgående tekst",
  "call.incoming": "Indgående opkald fra",
  "call.unknownnumber": "Ukendt nummer",
  "call.unknownnumber_uppercase": "Ukendt nummer",
  "call.endedcall": "Afsluttede samtalen",
  "call.yourejected": "Du afviste opkaldet",
  "call.noanswer": "Intet svar",
  "call.rejected": "Kunne ikke svare i øjeblikket",
  "call.notfound": "Kan ikke nås",
  "call.toolongtext": "\n<Teksten er for lang. Maks. 1000 tegn>\n",
  "call.notificationbody": "Klik her for at svare",
  "call.videoTextDisabled": "Tekst og indgående video er ",
  "call.writeHere": "Skriv her...",
  "call.receiveHere": "Her er den indgående tekst",
  "call.establishing": "Etablering af opkald...",

  /** History screen */
  "history.history": "Historisk",

  /** Reset password */
  "resetpassword.processing": "Behandling ...",
  "resetpassword.completed": "Password reset er afsluttet",
  "resetpassword.email_or_username": "Email eller brugernavn",
  "resetpassword.title": "Nulstil adgangskode",
  "resetpassword.explanation":
    "Indtast din e-mail eller dit brugernavn og send et link til den givne e-mail eller den e-mail, der er knyttet til brugernavnet. Via linket kan du nulstille dit kodeord.",
  "resetpassword.explanation_complete": "Du vil snart modtage en email med yderligere instruktioner.",
  "resetpassword.invalid_link": "Ugyldig nulstillingslink",
  "resetpassword.password_changed": "Dit kodeord er ændret",
  "resetpassword.enter_new_password": "Indtast ny adgangskode",
  "resetpassword.button_reset": "Gem",
  "resetpassword.password": "Adgangskode",
  "resetpassword.password_repeat": "Gentag adgangskode",
  "resetpassword.password_requirement": "Adgangskoden skal være mellem 6 og 20 tegn",
  "resetpassword.error": "Det var ikke muligt at nulstille adgangskoden. Prøv igen eller kontakt din administrator",
  "resetpassword.link_too_old": "linket er for gammel",

  /** Registration */
  "registration.error": "Det var ikke muligt at afslutte registreringen. Prøv igen eller kontakt din administrator.",
  "registration.title": "Registrering",
  "registration.processing": "Behandling ...",
  "registration.not_enabled": "Registrering er ikke aktiveret.",
  "registration.completed_headline": "Du er blevet registreret",
  "registration.completed": "Din registrering er afsluttet.",
  "registration.completed_admin_approval": "Din konto skal godkendes af en administrator, før du kan logge ind.",
  "registration.completed_verification":
    "Din konto skal bekræftes, før du kan logge ind. Du modtager snart en e-mail med instruktioner.",
  "registration.completed_link": "/",
  "registration.username_exists": "Brugernavnet er allerede registreret. Indtast venligst et andet brugernavn.",
  "registration.email_exists": "E-mailen er allerede registreret. Indtast venligst en anden email.",
  "registration.sip_exists": "SIP-adressen er allerede registreret. Indtast venligst en anden SIP-adresse.",
  "registration.license1": "Ja, jeg har læst",
  "registration.license_link_text": "Oplysninger om behandling af personoplysninger",
  "registration.license2": "og acceptere betingelserne herfor",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Accept vilkårene, hvis du ønsker at tilmelde dig",
  "registration.register": "Tilmeld",

  /** Verify Account */
  "verifyaccount.completed.title": "Din konto er aktiveret!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Noget gik galt! Prøv at registrere kontoen igen.",
  "verifyaccount.error.expired": "Bekræftelseslinket er udløbet. Prøv at registrere kontoen igen.",
  "verifyaccount.registration": "Vil du tilmelde dig?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Rediger konto",
  "changeuser.titlePassword": "Skift adgangskode",
  "changeuser.newPassword": "Ny adgangskode",
  "changeuser.error": "Det var ikke muligt at opdatere kontoen. Prøv igen, eller kontakt din administrator.",
  "changeuser.complete": "Konto opdateret",
  "changeuser.processing": "Behandling ...",
  "changeuser.username_exists": "Brugernavnet er allerede registreret. Indtast venligst et andet brugernavn.",
  "changeuser.email_exists": "E-mailen er allerede registreret. Indtast venligst en anden email.",
  "changeuser.email_empty": "E-mail er påkrævet. Skriv en e-mail.",
  "changeuser.sip_exists": "SIP-adressen er allerede registreret. Indtast venligst en anden SIP-adresse.",

  /** Network */
  "network.networkError": "Der opstod et problem under forbindelse til serveren",
  "network.establishing": "Forsøger at oprette forbindelse til serveren...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Mørkt tema",
  "accessibility.originaltheme": "Oprindelige tema",

  /** About */
  "about.user": "Brugernavn",
  "about.sip": "SIP-adresse",
  "about.server": "Server IP",
  "about.version": "Version",
  "about.browser": "Browser",
  "about.browserversion": "Browser version",
  "about.os": "Operativsystem",

  /** Aria */
  "aria.mainmenu": "Hovedmenuen",
  "aria.submenu_contacts": "Undermenuen i Kontakter",
  "aria.submenu_account": "Konto undermenu",
  "aria.selected_page": "Dit side",
  "aria.back_button": "Slet det sidste tegn",
  "aria.accept_terms": "Accepter vilkår",
  "aria.link_sound_video": "Gå til lyd- og videoindstillingerne",
  "aria.selfview": "Video on dig selv",
  "aria.in-call": "I opkald",

  /** Kiosk */
  "kiosk.call_ended": "Opkaldet er afsluttet, du kan nu lukke denne side.",

  /** Modal */
  "modal.close": "Luk",
  "modal.cancel": "Afbryd",
  "modal.confirm": "Bekræfte",
  "modal.logout_confirmation.title": "Er du sikker på, at du vil logge af?",
  "modal.logout_confirmation.confirm": "Ja",
  "modal.logout_confirmation.cancel": "Nej, afbryd",
};

export default translations;
