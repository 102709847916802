import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web-app",
  "common.loading": "Laster inn",
  "common.call": "Anrop",
  "common.answer": "Svar",
  "common.reject": "Avvis",
  "common.callback": "Ring tilbake",
  "common.callagain": "Ring igjen",
  "common.yes": "Ja",
  "common.no": "Nei",
  "common.on": "PÅ",
  "common.off": "AV",
  "common.text": "Tekst",
  "common.abort": "Avbryt",
  "common.delete": "Slett",
  "common.cancel": "Avbryt",
  "common.missedcall": "Mistet samtale fra",
  "common.save": "Lagre",
  "common.hangup": "Legg på",
  "common.add": "Legge til",
  "common.firstname": "Fornavn",
  "common.lastname": "Etternavn",
  "common.sipaddress": "SIP-adresse",
  "common.email": "E-post",
  "common.address": "Adresse",
  "common.city": "By",
  "common.postcode": "Postnummer",
  "common.calling": "Ringer",
  "common.dialer": "Dialer",
  "common.back": "Tilbake",
  "common.hide": "Gjemme seg",
  "common.online": "På nett",
  "common.video": "Video",
  "common.audio": "Lyd",
  "common.reset": "Nullstille",
  "common.here": "her",
  "common.username": "Brukernavn",
  "common.password": "Passord",
  "common.send": "Sende",
  "common.password1": "Passord",
  "common.password2": "Gjenta passord",
  "common.phonenumber": "Telefon",
  "common.sip": "SIP-adresse",
  "common.mobilenumber": "Mobil",
  "common.isdn": "ISDN-nummer",
  "common.3gnumber": "3G-nummer",
  "common.fax": "Faks",
  "common.username_helptext":
    "Mellom 5 og 100 tegn. Bare 0-9 ,. (Periode), - (bindestreker) og a-z (små bokstaver) er tillatt.",
  "common.email_helptext": "Ugyldig epost",
  "common.password1_helptext": "Mellom 6 og 20 tegn",
  "common.password2_helptext": "Passord er ikke det samme",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Feltet inneholder ett eller flere ugyldige tegn",
  "common.link": "lenke",
  "common.Link": "Link",
  "common.anonymous": "Anonym",
  "common.edit": "Redigere",
  "common.contact_information": "Kontaktinformasjon",
  "common.view_message": "Vis melding",
  "common.close_message": "Lukk meldingen",
  "common.required": "* Obligatorisk felt",
  "common.to_startpage": "Til hjemmesiden",
  "common.organisation": "Organisasjon",
  "common.permissions.heading": "Tjenesten trenger rett til å bruke kameraet og / eller mikrofonen.",
  "common.permissions.subheading":
    'Du kan senere før hver samtale bestemme om du vil ha video / lyd i samtalen eller ikke. <br> Firefox-brukere anbefales å "huske denne beslutningen" for å unngå lignende problemer senere. <br> Når du aksepterer rettighetene, vil kameraet / mikrofonen aktiveres i noen sekunder og deretter slås av.',
  "common.app_for_os": "%APPNAME% for %OS%",

  /** Toolbar */
  "toolbar.hidetext": "Teksten er AV",
  "toolbar.showtext": "Teksten er PÅ",
  "toolbar.micoff": "Mikrofonen er AV",
  "toolbar.micon": "Mikrofonen er PÅ",
  "toolbar.audiooff": "Lyden er AV",
  "toolbar.audioon": "Lyd er PÅ",
  "toolbar.videooff": "Innkommende video er AV",
  "toolbar.videoon": "Innkommende video er PÅ",
  "toolbar.cameraoff": "Videoen min er AV",
  "toolbar.cameraon": "Videoen min er PÅ",
  "toolbar.fullscreenoff": "Fullskjerm er AV",
  "toolbar.fullscreenon": "Fullskjerm er PÅ",
  "toolbar.selfviewoff": "Selvvisning er AV",
  "toolbar.selfviewon": "Selfview er PÅ",
  "toolbar.hidedialpad": "Tastaturet er PÅ",
  "toolbar.showdialpad": "Tastaturet er AV",
  "toolbar.useaudioon": "Samtale med lyd er PÅ",
  "toolbar.useaudiooff": "Samtale med lyd er AV",
  "toolbar.usevideoon": "Samtale med video er PÅ",
  "toolbar.usevideooff": "Samtale med video er AV",
  "toolbar.usetexton": "Samtale med tekst er PÅ",
  "toolbar.usetextoff": "Samtale med tekst er AV",
  "toolbar.more": "Mer",
  "toolbar.hide": "Gjemme seg",
  "toolbar.togglevideo": "Vis video",
  "toolbar.toggletext": "Vis tekst",
  "toolbar.toSettings": "Gå til innstillinger for%",

  /** Titles */
  "titles.login": "Logg inn",
  "titles.services": "Tjenester",
  "titles.contacts": "Kontaktene mine",
  "titles.call": "Ringe",
  "titles.directory": "Business Directory",
  "titles.history": "Historisk",
  "titles.messages": "Meldinger",
  "titles.settings": "Innstillinger",
  "titles.userinformation": "Min profil",
  "titles.log": "Feilsøk",
  "titles.about": "Systeminformasjon",
  "titles.registration": "Registrering",
  "titles.newpassword": "Få et nytt passord",
  "titles.resetpassword": "Tilbakestille passord",
  "titles.verifyaccount": "Aktiver bruker",

  /** Menu */
  "menu.logo": "Til hjemmesiden",
  "menu.services": "Tjenester",
  "menu.servicesHeadline": "Tjenester",
  "menu.contacts": "Kontakter",
  "menu.mycontacts": "Kontaktene mine",
  "menu.call": "Anrop",
  "menu.directory": "Business Directory",
  "menu.history": "Historie",
  "menu.messages": "Meldinger",
  "menu.settings": "Innstillinger",
  "menu.logout": "Logg ut",
  "menu.userinformation": "Regnskap",
  "menu.userprofile": "Min profil",
  "menu.log": "Feilsøk",
  "menu.about": "Systeminformasjon",

  /** Login screen */
  "login.loginanonymous.headline": "Klikk her for anonymt anrop",
  "login.loginanonymous": "Anonym pålogging",
  "login.headline": "Logg Inn",
  "login.login": "Logg Inn",
  "login.loginfailed": "Innlogging feilet",
  "login.resetpassword": "Har du glemt passordet ditt?",
  "login.registration": "Vil du registrere deg?",
  "login.nowebrtc": "Nettleseren din støtter ikke sanntids lyd- og videokommunikasjon (WebRTC).",
  "login.debug": "Feilsøkingsmodus",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Ugyldig brukernavn eller passord",
  "error.usernameNotExists": "Brukernavnet eksisterer ikke",
  "error.noservercontact": "Ingen kontakt med serveren",
  "error.accountnotactive": "Kontoen er ikke aktiv",
  "error.accountexpired": "Kontoen er utløpt",
  "error.tokenexpired": "Økten er utløpt. Vennligst logg på igjen",
  "error.tokeninvalid": "Sikkerhetsnøkkelen er ugyldig",
  "error.unknown": "Ukjent feil",
  "error.unknownsender": "Ukjent avsender",
  "error.unknownsip": "Ukjent SIP",
  "error.notextsent": "Ingen tekst sendt",
  "error.acquiremedia":
    "Tjenesten trenger rett til å bruke kameraet og / eller mikrofonen. Før hver samtale kan du bestemme om du vil ha video / lyd i samtalen.",
  "error.mediaNotReadable":
    "Kamera eller mikrofon er opptatt. Forsikre deg om at de ikke brukes av andre applikasjoner, og last siden på nytt.",
  "error.requestedDeviceNotFound":
    "Den forespurte medieenheten ble ikke funnet. Forsikre deg om at den er riktig tilkoblet.",
  "error.cantcall": "Du kan ikke ringe når du ikke er i kontakt med systemet",
  "error.cantcall_without_media": "Du kan ikke ringe uten lyd, video eller tekst",
  "error.404": "404 - Siden du prøver å se eksisterer ikke",
  "error.unsupportedbrowser": "Nettleseren din støttes for øyeblikket ikke. Bruk en annen nettleser.",
  "error.browseroutofdate":
    "Nettleseren din er utdatert. Oppdater den til den nyeste versjonen for å bruke applikasjonen.",
  "error.browseroutofdate.ios.15.1": "Denne iOS-versjonen støttes ikke, oppdater til minst iOS 15.2.",
  "error.browserwarning.closeAndDontShowAgain": "Lukk og ikke vis igjen",
  "error.browserwarning.macos":
    "Hvis du opplever problemer med å ringe fra iPad og bruker iOS versjon 15.1, vennligst oppgrader til iOS 15.2 eller nyere.",

  /** User settings */
  "settings.white": "Hvit",
  "settings.lightgrey": "Lysegrå",
  "settings.darkgrey": "Mørk grå",
  "settings.black": "Svart",
  "settings.yellow": "Gul",
  "settings.blue": "Blå",
  "settings.red": "Rød",
  "settings.green": "Grønn",
  "settings.purple": "Lilla",
  "settings.orange": "Oransje",
  "settings.pink": "Rosa",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida uten Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Tegneserie Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Side ved side",
  "settings.overunder": "Over og under",
  "settings.one": "Delt tekstvindu",
  "settings.smartphone": "Smarttelefon",
  "settings.bell": "Klokke",
  "settings.marimba": "Marimba",
  "settings.glas": "Glass",
  "settings.classic": "Klassisk",
  "settings.settings": "Innstillinger",
  "settings.text": "Tekst",
  "settings.font": "Skrift",
  "settings.fontsize": "Skriftstørrelse",
  "settings.textboxlayout": "Tekstvinduoppsett",
  "settings.mycolors": "Tekstvinduet mitt",
  "settings.incomingcolors": "Innkommende tekstvindu",
  "settings.sharedcolors": "Delt tekstvindu",
  "settings.textcolors": "Tekstfarge",
  "settings.backgroundcolors": "Bakgrunnsfarge",
  "settings.preview": "Forhåndsvisning",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "settings.previewmytext": "Teksten min",
  "settings.previewincomingtext": "Innkommende tekst",
  "settings.previewbothtext": "Min tekst, Innkommende tekst",
  "settings.incomingcalls": "Innkommende anrop",
  "settings.messagebox": "Meldingsboks",
  "settings.autoanswer": "Bruk en tekstmelding når du ikke svarer",
  "settings.autoanswermessage": "Meldingen",
  "settings.duringcalls": "Under samtaler",
  "settings.colors": "Farger",
  "settings.textformat": "Tekst",
  "settings.media": "Media",
  "settings.audio": "Lyd",
  "settings.video": "Video",
  "settings.ringtone": "Ringetone",
  "settings.mediamail": "Meldinger",
  "settings.about": "Systeminformasjon",
  "settings.logout": "Logg ut",
  "settings.logout.anonymous": "Exit",
  "settings.saved": "Innstillingene er lagret",
  "settings.media_explanation":
    "Bestem hvilken vei du vil ringe. Hvis en innstilling er PÅ, kan du alltid slå den av midlertidig under en samtale, men hvis den er AV, kan du ikke slå den på under en samtale.",
  "settings.useaudio": "Ring med lyd",
  "settings.usevideo": "Ring med video",
  "settings.bandwidth.label": "Båndbredde",
  "settings.bandwidth.high": "Høy",
  "settings.bandwidth.medium": "Medium",
  "settings.bandwidth.low": "Lav",

  /** Messages */
  "messages.messages": "Meldinger",
  "messages.unreadmessages": "Ulest",
  "messages.readmessages": "Lese",
  "messages.nomessages": "Du har ingen meldinger",
  "messages.deleteallread": "Slett alle de leste meldingene",
  "messages.deleteallreadconfirm": "Slette alle de leste meldingene?",
  "messages.messageAlert": "Melding slettet",

  /** Contacts */
  "contacts.contacts": "Kontaktene mine",
  "contacts.addcontact": "Legg til kontakt",
  "contacts.deletecontact": "Slett kontakt",
  "contacts.nosipgiven": "Vennligst skriv inn en SIP-adresse for kontaktene",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Skriv nummer / navn her",
  "call.incomingtext": "Innkommende tekst",
  "call.outgoingtext": "Utgående tekst",
  "call.incoming": "Innkommende samtale fra",
  "call.unknownnumber": "ukjent nummer",
  "call.unknownnumber_uppercase": "Ukjent nummer",
  "call.endedcall": "avsluttet samtalen",
  "call.yourejected": "Du avviste samtalen",
  "call.noanswer": "Ingen svar",
  "call.rejected": "kunne ikke svare for øyeblikket",
  "call.notfound": "er ikke tilgjengelig",
  "call.toolongtext": " n <For mange tegn oppgitt. Maks 1000.>  N",
  "call.notificationbody": "Klikk her for å svare",
  "call.videoTextDisabled": "Tekst og innkommende video er AV",
  "call.writeHere": "Skriv her...",
  "call.receiveHere": "Det er her den innkommende teksten vises",
  "call.establishing": "Oppretter samtale ...",

  /** History screen */
  "history.history": "Historie",

  /** Reset password */
  "resetpassword.processing": "Behandler ...",
  "resetpassword.completed": "Tilbakestilling av passord er fullført",
  "resetpassword.email_or_username": "E-post eller brukernavn",
  "resetpassword.title": "Nytt passord",
  "resetpassword.explanation":
    "Skriv inn din e-post eller brukernavnet ditt, og en lenke vil bli sendt til den gitte e-posten eller e-postadressen som er knyttet til brukernavnet. Gjennom lenken kan du tilbakestille passordet ditt.",
  "resetpassword.explanation_complete": "Du vil snart motta en e-post med ytterligere instruksjoner.",
  "resetpassword.invalid_link": "Ugyldig gjenopprettingskobling",
  "resetpassword.password_changed": "Ditt passord har blitt endret",
  "resetpassword.enter_new_password": "Skriv inn nytt passord",
  "resetpassword.button_reset": "Nullstille",
  "resetpassword.password": "Passord",
  "resetpassword.password_repeat": "Gjenta passord",
  "resetpassword.password_requirement": "Passordet må være mellom 6 og 20 tegn",
  "resetpassword.error": "Det var ikke mulig å tilbakestille passordet. Prøv igjen eller kontakt administratoren din",
  "resetpassword.link_too_old": "lenken er for gammel",

  /** Registration */
  "registration.error": "Det var ikke mulig å fullføre registreringen. Prøv igjen eller kontakt administratoren din.",
  "registration.title": "Registrering",
  "registration.processing": "Behandler ...",
  "registration.not_enabled": "Registrering er ikke aktivert.",
  "registration.completed_headline": "Du har blitt registrert",
  "registration.completed": "Registreringen din er fullført.",
  "registration.completed_admin_approval": "Brukeren din må godkjennes av en administrator før du kan logge på.",
  "registration.completed_verification":
    "Brukeren din må bekreftes før du kan logge inn. Du vil snart motta en e-post med instruksjoner.",
  "registration.completed_link": "/",
  "registration.username_exists": "Brukernavnet er allerede registrert. Vennligst skriv inn et annet brukernavn.",
  "registration.email_exists": "E-postadressen er allerede registrert. Vennligst skriv inn en annen e-post.",
  "registration.sip_exists": "SIP-adressen er allerede registrert. Vennligst skriv inn en annen SIP-adresse.",
  "registration.license1": "Ja, jeg har lest",
  "registration.license_link_text": "informasjonen om behandling av personopplysninger",
  "registration.license2": "og godtar vilkårene i dette",
  "registration.licenselink": "",
  "registration.info": "",
  "registration.check_checkbox": "Godta vilkårene hvis du vil registrere deg",
  "registration.register": "Registrer",

  /** Verify Account */
  "verifyaccount.completed.title": "Din bruker er nå aktivert!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Noe gikk galt! Vennligst forsøk å registrere din bruker på nytt.",
  "verifyaccount.error.expired":
    "Tidsfristen for å aktivere brukeren er overskredet. Vennligst forsøk å registrere din bruker på nytt.",
  "verifyaccount.registration": "Vil du registrere deg?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Rediger bruker",
  "changeuser.titlePassword": "Bytt passord",
  "changeuser.newPassword": "Nytt passord",
  "changeuser.error": "Det var ikke mulig å oppdatere kontoen. Prøv igjen eller kontakt administratoren din.",
  "changeuser.complete": "Konto oppdatert",
  "changeuser.processing": "Behandler ...",
  "changeuser.username_exists": "Brukernavnet er allerede registrert. Vennligst skriv inn et annet brukernavn.",
  "changeuser.email_exists": "E-postadressen er allerede registrert. Vennligst skriv inn en annen e-post.",
  "changeuser.email_empty": "E-post er obligatorisk. Vennligst skriv inn en e-post.",
  "changeuser.sip_exists": "SIP-adressen er allerede registrert. Vennligst skriv inn en annen SIP-adresse.",

  /** Network */
  "network.networkError": "Det oppstod et problem under tilkobling til serveren",
  "network.establishing": "Prøver å opprette forbindelse til serveren...",
  "network.offline": "Frakoblet",

  /** Accessibility */
  "accessibility.darktheme": "Mørkt tema",
  "accessibility.originaltheme": "Opprinnelig tema",

  /** About */
  "about.user": "Brukernavn",
  "about.sip": "SIP-adresse",
  "about.server": "Server IP",
  "about.version": "Versjon",
  "about.browser": "Nettleser",
  "about.browserversion": "Nettleserversjon",
  "about.os": "Operativsystem",

  /** Aria */
  "aria.mainmenu": "Hovedmeny",
  "aria.submenu_contacts": "Undermeny med kontakter",
  "aria.submenu_account": "Undermeny for konto",
  "aria.selected_page": "Valgt side",
  "aria.back_button": "Fjern siste tegn",
  "aria.accept_terms": "Godta vilkår",
  "aria.link_sound_video": "Gå til innstillinger for lyd og video",
  "aria.selfview": "Selvbilde-video",
  "aria.in-call": "I samtale",

  /** Kiosk */
  "kiosk.call_ended": "Samtalen er avsluttet. Du kan nå lukke denne siden.",

  /** Modal */
  "modal.close": "Lukk",
  "modal.cancel": "Avbryt",
  "modal.confirm": "OK",
  "modal.logout_confirmation.title": "Er du sikker på at du vil logge av?",
  "modal.logout_confirmation.confirm": "Ja",
  "modal.logout_confirmation.cancel": "Nei, avbryt",
};

export default translations;
