import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { acceptCall, rejectCall } from "../../actions/sipActions";
import { triggerNotification, removeNotification } from "../../actions/notificationActions";
import Standard from "./Standard";
import RoundAnswerButton from "../../components/buttons/RoundAnswerButton";
import RoundRejectButton from "../../components/buttons/RoundRejectButton";
import Sound from "react-sound";

export class IncomingCall extends React.Component {
  keyPress = e => {
    if (e.keyCode === 13 || e.keyCode === 0 || e.keyCode === 32) {
      this.props.acceptCall();
      e.preventDefault();
    }
  };

  componentDidMount() {
    window.addEventListener("keypress", this.keyPress);
    this.createNotificationCalling();
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPress);
    this.props.removeNotification();
  }

  createNotificationCalling = () => {
    this.lastNotificationMissedCall = false;
    var title = this.props.l10n("call.incoming") + this.props.from;
    var body = this.props.l10n("call.notificationbody");
    var acceptCall = this.props.acceptCall;
    this.props.triggerNotification(title, body, acceptCall);
  };

  incoming = () => {
    const ringtone =
      this.props.ringtone in this.props.ringtones
        ? this.props.ringtones[this.props.ringtone].audio
        : this.props.ringtones[this.props.default_ringtone].audio;

    return (
      <div className={"text-align-center margin-auto"}>
        <h1>{this.props.l10n("call.incoming") + " " + this.props.from}</h1>
        <div className={"row text-align-center margin-auto"}>
          <div className={"col"}>
            <RoundAnswerButton text={this.props.l10n("common.answer")} onClick={() => this.props.acceptCall()} />
          </div>
          <div className={"col"}>
            <RoundRejectButton
              text={this.props.l10n("common.reject")}
              onClick={() => this.props.rejectCall()}
              kioskmode={false}
            />
          </div>
        </div>
        <Sound url={ringtone} playStatus={Sound.status.PLAYING} loop={true} />
      </div>
    );
  };

  render() {
    const { logo, logoDark, contrast, l10n } = this.props;
    return (
      <Standard
        logo={logo}
        logoDark={logoDark}
        contrast={contrast}
        l10n={l10n}
        flash={true}
        include={this.incoming()}
      />
    );
  }
}

IncomingCall.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
  logo: state.config.branding.logoUrl,
  logoDark: state.config.branding.logoDarkUrl,
  ringtones: state.config.ringtones,
  default_ringtone: state.config.ringtonesDefault,
  ringtone: state.setting.user.WEB_RINGTONE,
  from: state.call.callB === "" ? state.config.text("call.unknownnumber") : state.call.callB,
});

const mapDispatchToProps = dispatch => ({
  triggerNotification: (title, body, action) => dispatch(triggerNotification(title, body, action)),
  removeNotification: () => dispatch(removeNotification()),
  acceptCall: () => dispatch(acceptCall()),
  rejectCall: () => dispatch(rejectCall()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCall);
