import React from "react";
import RoundButton from "./RoundButton";

const RoundRejectButton = ({ text, onClick, kioskmode }) => (
  <RoundButton
    text={text}
    icon="call_end"
    onClick={onClick}
    color={"red"}
    className={kioskmode === true ? "hangup-button-kiosk" : ""}
  />
);

export default RoundRejectButton;
