import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Navbar from "../components/nav/Navbar";
import Alerts from "../components/Alerts";
import UnsupportedBrowser from "../containers/UnsupportedBrowser";
import Services from "../containers/Services";
import Messages from "../containers/Messages";
import MessageView from "../containers/MessageView";
import MessagesDelete from "../containers/MessagesDelete";
import History from "../containers/History";
import Settings from "../containers/Settings";
import About from "../containers/settings/About";
import ContactAddEdit from "../containers/ContactAddEdit";
import ContactInformation from "../containers/ContactInformation";
import ContactDelete from "../containers/ContactDelete";
import Contacts from "../containers/Contacts";
import Call from "../containers/Call";
import FourOFour from "../containers/FourOFour";
import ChangeUser from "../containers/ChangeUser";
import ChangePassword from "../containers/ChangePassword";
import Log from "../containers/Log";
import Footer from "../containers/Footer";
import "./DefaultLayout.css";
import UserInformation from "../containers/UserInformation";
import { loadMessages } from "../actions/messageActions";
import { inIframe } from "../misc/inIframe";
import IConfig from "../models/IConfig";
import { IRootState } from "../models/reducerStates";
import BusinessDirectory from "../containers/BusinessDirectory";
import Modal from "../components/Modal";
import { toggleNavbar } from "../actions/accessibilityActions";
import DownloadApp from "../components/DownloadApp";

function Username(props: any) {
  if (props.enabled) {
    return (
      <div className="username">
        <small>{props.username}</small> &nbsp;
      </div>
    );
  }
  return null;
}

const NoMatch = () => <Redirect to={{ pathname: "/" }} />;

class DefaultLayout extends React.Component<any> {
  componentDidMount() {
    this.props.loadMessages();
  }

  getDefaultComponent() {
    switch (this.props.home) {
      case "directory":
        return BusinessDirectory;
      case "services":
        return Services;
      case "call":
        return Call;
      case "contacts":
        return Contacts;
      case "history":
        return History;
      case "messages":
        return Messages;
      default:
        return Services;
    }
  }

  render() {
    if (this.props.kiosk || (!this.props.allowIframe && inIframe()) || (this.props.forceIframe && !inIframe())) {
      return <FourOFour />;
    }

    var pagename;
    if (this.props.location.pathname !== undefined) {
      if (this.props.location.pathname === "/") {
        pagename = this.props.home;
      } else {
        pagename = this.props.location.pathname.split("/")[1];
      }
    }

    // var offlineText =
    //   this.props.serverError || this.props.freeswitchError
    //     ? this.props.text("network.networkError")
    //     : !this.props.isSipOnline && this.props.isOnlineEnabled
    //     ? this.props.text("network.offline")
    //     : this.props.sipError
    //     ? this.props.sipErrorMessage
    //     : "No error";

    var offlineText = "No error";
    if (this.props.serverError || this.props.freeswitchError) {
      offlineText = this.props.text("network.networkError");
    } else if (!this.props.isSipOnline && this.props.isOnlineEnabled) {
      offlineText = this.props.text("network.offline");
    } else if (this.props.sipError) {
      offlineText = this.props.sipErrorMessage;
    }

    var nocontainer = pagename === "settings" ? true : false;

    var activeContacts = pagename === "services" || pagename === "contacts";
    var activeAccount = pagename === "userinformation" || pagename === "settings";
    var footerVisible = ["services", "call"].indexOf(pagename) > -1;
    var contactsSubMenuOpen = activeContacts && this.props.links.services.enabled && this.props.links.contacts.enabled;
    var accountSubMenuOpen =
      activeAccount && this.props.links.userinformation.enabled && this.props.links.settings.enabled;
    var navSubMenuOpen = contactsSubMenuOpen || accountSubMenuOpen;
    var showErrorAlert =
      this.props.serverError ||
      this.props.freeswitchError ||
      this.props.sipError ||
      (!this.props.isSipOnline && this.props.isOnlineEnabled);

    nocontainer = !this.props.browserIsSupported ? false : nocontainer;

    const defaultComponent = this.getDefaultComponent();
    return (
      <React.Fragment>
        <DownloadApp />
        <Modal />
        <div className="branding-header">
          <Username enabled={this.props.isNameEnabled} username={this.props.username} />
          <Navbar
            links={this.props.links}
            text={this.props.text}
            activeTabName={pagename}
            contrast={this.props.contrast}
            logo={this.props.logo}
            settingsInMenu={this.props.settingsInMenu}
            toggleNavbar={this.props.toggleNavbar}
            navbarIsExpanded={this.props.navbarIsExpanded}
            logout={this.props.isLogoutLinkEnabled}
          />
        </div>

        <main
          className={(nocontainer ? "" : "main-container margin-sub") + (footerVisible ? " footer-visible" : "")}
          role="main"
        >
          <Alerts />

          {!this.props.browserIsSupported ? (
            <Switch>
              <Route component={UnsupportedBrowser} />
            </Switch>
          ) : (
            <React.Fragment>
              {this.props.browserErrorMessage && <UnsupportedBrowser />}
              <Switch>
                <Route path="/services" component={Services} />
                {this.props.links.contacts.enabled && (
                  <Route path="/contacts">
                    <Switch>
                      <Route path="/contacts/edit/:id" render={props => <ContactAddEdit {...props} mode="edit" />} />
                      <Route
                        path="/contacts/information/:id"
                        render={props => <ContactInformation {...props} mode="information" />}
                      />
                      <Route path="/contacts/delete/:id" render={props => <ContactDelete {...props} />} />
                      <Route path="/contacts/add" render={props => <ContactAddEdit {...props} mode="add" />} />
                      <Route path="/" component={Contacts} />
                    </Switch>
                  </Route>
                )}
                {this.props.links.directory.enabled && <Route path="/directory" component={BusinessDirectory} />}
                <Route path="/history" component={History} />
                <Route
                  path="/settings/:settingFor/:settingForFor"
                  render={() => (
                    <Settings
                      navSubMenuOpen={navSubMenuOpen}
                      showErrorAlert={showErrorAlert}
                      offlineText={offlineText}
                    />
                  )}
                />
                <Route
                  path="/settings/:settingFor"
                  render={() => (
                    <Settings
                      navSubMenuOpen={navSubMenuOpen}
                      showErrorAlert={showErrorAlert}
                      offlineText={offlineText}
                    />
                  )}
                />
                <Route
                  path="/settings"
                  render={() => (
                    <Settings
                      navSubMenuOpen={navSubMenuOpen}
                      showErrorAlert={showErrorAlert}
                      offlineText={offlineText}
                    />
                  )}
                />
                <Route path="/call" component={Call} />
                <Route path="/messages/view/:messageNumber" component={MessageView} />
                <Route path="/messages/delete" component={MessagesDelete} />
                <Route path="/messages" component={Messages} />
                <Route path="/userinformation/edit" component={ChangeUser} />
                <Route path="/userinformation/changePassword" component={ChangePassword} />
                <Route path="/userinformation" component={UserInformation} />
                <Route path="/log" component={Log} />
                <Route path="/about" component={About} />
                <Route exact path="/" component={defaultComponent} />
                <Route component={NoMatch} />
              </Switch>
            </React.Fragment>
          )}
        </main>
        <Switch>
          <Route path="/services" component={Footer} />
          <Route exact path="/" component={Footer} />
          <Route path="/call" component={Footer} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  const config: IConfig = state.config;
  const text = state.config.text;

  const getEnabledValueAsBoolean = (value: any) => {
    if (typeof value === "boolean") return value;
    if (typeof value !== "object") return false;
    try {
      if (value.length === 0) return false;
      return value.indexOf(state.user.properties.ORG_ID) !== -1;
    } catch (e) {
      return false;
    }
  };

  return {
    links: {
      services: {
        enabled: getEnabledValueAsBoolean(config.features.services.enabled),
        text: text("menu.services"),
      },
      contacts: {
        enabled: getEnabledValueAsBoolean(config.features.contacts.enabled),
        text: text("menu.contacts"),
      },
      directory: {
        enabled: getEnabledValueAsBoolean(config.features.directory.enabled),
        text: state.config.text("menu.directory"),
      },
      mycontacts: {
        text: text("menu.mycontacts"),
      },
      call: {
        enabled: getEnabledValueAsBoolean(config.features.call.enabled),
        text: text("menu.call"),
      },
      history: {
        enabled: getEnabledValueAsBoolean(config.features.history.enabled),
        text: text("menu.history"),
      },
      messages: {
        enabled: getEnabledValueAsBoolean(config.features.messages.enabled),
        text: text("menu.messages"),
        messages: state.message.messages,
      },
      settings: {
        enabled: getEnabledValueAsBoolean(config.features.settings.enabled),
        text: text("menu.settings"),
      },
      online: {
        enabled: getEnabledValueAsBoolean(config.layout.showOnlineStatus),
        text: text("common.online"),
      },
      debug: {
        enabled: getEnabledValueAsBoolean(config.debug.enabled && config.debug.showInMenu),
        text: text("menu.log"),
      },
      userinformation: {
        enabled:
          getEnabledValueAsBoolean(config.features.changeUser.enabled) && !config.features.anonymous.enabled
            ? true
            : false,
        text: text("menu.userinformation"),
      },
      userprofile: {
        text: text("menu.userprofile"),
      },
      contrasttoggle: {
        enabled: state.config.layout.showThemeToggleInMenu,
      },
    },
    contrast: state.accessibility.isHighContrast,
    authenticated: state.user.authenticated,
    username: state.user.username,
    text: text,
    isSipOnline: state.sip.online,
    kiosk: config.features.kiosk.enabled,
    allowIframe: config.iframe.allow,
    forceIframe: config.iframe.force,
    isOnlineEnabled: config.layout.showOnlineStatus,
    isLogoutLinkEnabled: config.layout.showLogoutInMenu,
    settingsInMenu: config.layout.showSettingsInMenu,
    isNameEnabled: false,
    home: config.layout.home,
    sipState: state.sip.sipState,
    serverError: state.network.serverError,
    freeswitchError: state.network.freeswitchError,
    sipError: state.network.sipError,
    sipErrorMessage: state.network.sipErrorMessage,
    browserIsSupported: state.config.browser.isSupported,
    browserErrorMessage: state.config.browser.errorMessage,
    logo: state.accessibility.isHighContrast ? config.branding.logoDarkUrl : config.branding.logoUrl,
    navbarIsExpanded: state.accessibility.navbarIsExpanded,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadMessages: () => {
      return dispatch(loadMessages());
    },
    toggleNavbar: () => {
      return dispatch(toggleNavbar());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout));
