import React from "react";
import { connect } from "react-redux";
import "./Loading.css";

class Loading extends React.Component {
  visible = false;

  componentDidMount() {
    this._ismounted = true;

    setTimeout(
      function() {
        this.visible = true;
        if (this._ismounted) {
          this.forceUpdate();
        }
      }.bind(this),
      2000
    );
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    if (this.visible) {
      return (
        <div>
          <div className="text-align-center margin-top-4em font-size-large">{this.props.text("common.loading")}</div>
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
            <div className="rect6"></div>
            <div className="rect7"></div>
          </div>
        </div>
      );
    }

    return <div></div>;
  }
}

const mapStateToProps = state => {
  return {
    text: state.config.text,
  };
};

export default connect(mapStateToProps)(Loading);
