import React from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import EditButton from "../components/buttons/EditButton";
import "./UserInformation.css";
import { APP } from "../actions/actionTypes";

class UserInformation extends React.Component {
  componentDidMount() {
    this.props.setPageTitle(this.props.text("menu.userprofile"));
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    var username = this.props.userinformation.username;
    var firstname = this.props.userinformation.firstname;
    var lastname = this.props.userinformation.lastname;
    var sip = this.props.userinformation.sipaddress.split("|").join("\n");
    var email = this.props.userinformation.email;
    var address = this.props.userinformation.address;
    var city = this.props.userinformation.city;
    var postNr = this.props.userinformation.postcode;

    return (
      <div className="row">
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
        <div className="col-md-10 col-lg-6 col-xl-6">
          <h1 id="h1id" className="">
            {this.props.text("menu.userprofile")}
          </h1>
          <div className="user-information-content">
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.username")}</h2>
              <div>{username !== null && username !== undefined && username !== "" ? username : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.sipaddress")}</h2>
              <div>{sip !== null && sip !== undefined && sip !== "" ? sip : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.firstname")}</h2>
              <div>{firstname !== null && firstname !== undefined && firstname !== "" ? firstname : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.lastname")}</h2>
              <div>{lastname !== null && lastname !== undefined && lastname !== "" ? lastname : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.email")}</h2>
              <div>{email !== null && email !== undefined && email !== "" ? email : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.address")}</h2>
              <div>{address !== null && address !== undefined && address !== "" ? address : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.postcode")}</h2>
              <div>{postNr !== null && postNr !== undefined && postNr !== "" ? postNr : "-"}</div>
            </div>
            <div className="user-content-item">
              <h2 className="h6 font-weight-bold my-0">{this.props.text("common.city")}</h2>
              <div>{city !== null && city !== undefined && city !== "" ? city : "-"}</div>
            </div>
          </div>
          <div className="d-flex justify-content-between contact-information-buttons">
            <EditButton to="/userinformation/edit/" id={""} text={this.props.text} margin_auto="true" />
          </div>
          <div></div>
        </div>
        <div className="col-md-1 col-lg-3 col-xl-3"></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.contact.loading,
    text: state.config.text,
    userinformation: {
      username: state.user.properties.USERNAME,
      firstname: state.user.properties.FORENAME,
      lastname: state.user.properties.SURNAME,
      sipaddress: state.user.properties.SIP_ADDRESS,
      address: state.user.properties.ADDRESS,
      postcode: state.user.properties.POSTCODE,
      city: state.user.properties.CITY,
      email: state.user.properties.EMAIL,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);
