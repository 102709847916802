import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";

import { setNewPassword } from "../actions/resetPasswordActions";

import ErrorAlert from "../components/ErrorAlert";

const queryString = require("querystringify");

const validate = (values, props) => {
  const errors = {};
  var regEx = null;

  if (!isEmpty(values.password1) && props.regex) {
    regEx = new RegExp(props.regex);
    errors.password1 = regEx.test(values.password1) ? undefined : props.text("common.password1_helptext");
  }

  if (!isEmpty(values.password2) && (isEmpty(values.password1) || values.password1 !== values.password2)) {
    errors.password2 = props.text("common.password2_helptext");
  }
  return errors;
};

class NewPassword extends Component {
  componentDidMount() {
    this.props.reset();
  }

  onSubmit = values => {
    this.props.setNewPassword(this.props.urluid, this.props.urlkey, values.password1);
  };

  renderPasswordField = ({ input, value, meta: { error } }) => {
    var className = "form-control";
    if (error) {
      className = "form-control is-invalid";
    } else {
      if (!isEmpty(input.value)) {
        className = "form-control is-valid";
      }
    }

    return (
      <div>
        <input {...input} type="password" className={className} required={true} />
        {true && error && (
          <span className="color-red">
            <b>{error}</b>
          </span>
        )}
      </div>
    );
  };

  password = () => {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="password1" className="font-weight-bold">
            {this.props.text("resetpassword.password")}
          </label>
          <Field
            id="password1"
            component={this.renderPasswordField}
            type="password"
            className="form-control"
            name="password1"
          ></Field>
        </div>
        <div className="form-group">
          <label htmlFor="password2" className="font-weight-bold">
            {this.props.text("resetpassword.password_repeat")}
          </label>
          <Field
            id="password2"
            component={this.renderPasswordField}
            type="password"
            className="form-control"
            name="password2"
          ></Field>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return <main></main>;
    }

    if (!this.props.enabled) {
      return <main>Reset password not enabled</main>;
    }

    if (!this.props.urlkey || !this.props.urluid) {
      return (
        <main>
          <br />
          <div className="alert alert-danger col-md-12 col-lg-12" role="alert">
            <span aria-hidden="true"></span>
            {this.props.text("resetpassword.invalid_link")}
          </div>
        </main>
      );
    }

    if (this.props.newPasswordInProgress) {
      return (
        <main>
          <h1 id="h1id">{this.props.text("resetpassword.processing")}</h1>
          <br />
          <br />
          <div>
            <div className="loader" />
          </div>
        </main>
      );
    }

    if (this.props.newPasswordComplete) {
      return (
        <main>
          <h1 id="h1id">{this.props.text("resetpassword.password_changed")}</h1>
          {this.props.location.search.indexOf("hideLogin=true") === -1 && (
            <Link className="underlined" to={"/"}>
              {this.props.text("common.to_startpage")}
            </Link>
          )}
        </main>
      );
    }

    var errorText = "";
    if (this.props.serverError === true) {
      errorText = this.props.text("network.networkError");
    } else {
      var error =
        this.props.newPasswordErrorText === "Not Found"
          ? this.props.text("resetpassword.link_too_old")
          : this.props.newPasswordErrorText;
      errorText = this.props.text("resetpassword.error");
      if (error.length > 0) {
        errorText += " (" + error + ")";
      }
    }

    return (
      <main>
        <h1 id="h1id">{this.props.text("resetpassword.enter_new_password")}</h1>

        {this.props.newPasswordError && <ErrorAlert text={errorText} />}

        <p>{this.props.text("resetpassword.password_requirement") + "."}</p>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {this.password()}
          <button type="submit" className="btn button">
            {this.props.text("resetpassword.button_reset")}
          </button>
        </form>
      </main>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.config.text == null ? true : false,
    newPasswordInProgress: state.resetPassword.newPasswordInProgress,
    newPasswordComplete: state.resetPassword.newPasswordComplete,
    newPasswordError: state.resetPassword.newPasswordError,
    newPasswordErrorText: state.resetPassword.newPasswordErrorText,
    serverError: state.network.serverError,
    urlkey: queryString.parse(props.location.search).key,
    urluid: queryString.parse(props.location.search).uid,
    text: str => state.config.text(str),
    config: {
      registration: state.config.features.registration,
      resetpassword: state.config.resetPassword,
    },
    enabled: state.config.resetPassword.enabled,
    regex: state.config.password.regex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewPassword: (uid, key, password) => {
      dispatch(setNewPassword(uid, key, password));
    },
    reset: () => {
      dispatch({ type: "resetpassword.RESET" });
    },
  };
};

NewPassword = reduxForm({
  form: "newpassword",
  validate,
})(NewPassword);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPassword));
