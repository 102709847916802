import React from "react";
import { Link } from "react-router-dom";
import { SettingsSubNavItemMaterial } from "./SettingsSubNavItemMaterial";
import "./SettingsNav.css";

export const SettingsNavItemMaterial = props => {
  var route = props.route === undefined ? "" : props.route;
  var chosenRoute = props.chosenRoute === undefined ? "#" : props.chosenRoute;
  var isChosen = route === chosenRoute && !route.indexOf("duringcalls") >= 0 ? true : false;
  var activeSubMenu = route.indexOf("duringcalls") >= 0 && chosenRoute.indexOf("duringcalls") >= 0;

  return (
    <li
      className={
        "settings-menu-item" +
        (isChosen ? " activeSettingsTab" : "") +
        (!props.haveSubmeny || !activeSubMenu ? " settings-menu-item-focus" : " border-none")
      }
      aria-current={route === chosenRoute ? "page" : "false"}
    >
      <Link className="settings-menu-link" to={"/settings/" + route} onClick={props.onClick}>
        <div className={"nav-item-focus-border"}>
          <i aria-hidden="true" className="material-icons md-48 settings-menu-icon">
            {props.icon}
          </i>
          <div className="settings-menu-text">{props.text}</div>
          {props.haveSubmeny === true && (
            <i aria-hidden="true" className="material-icons md-36 settings-menu-icon-expand">
              expand_more
            </i>
          )}
        </div>
      </Link>

      {activeSubMenu && (
        <ul className="settings-submenu">
          {props.textEnabled && (
            <SettingsSubNavItemMaterial
              text={props.alltext("settings.colors")}
              route="/settings/duringcalls/colors"
              chosen={"duringcalls/colors" === chosenRoute}
              last={false}
            />
          )}
          {props.textEnabled && (
            <SettingsSubNavItemMaterial
              text={props.alltext("settings.textformat")}
              route="/settings/duringcalls/textformat"
              chosen={"duringcalls/textformat" === chosenRoute}
              last={props.mediaConfigured ? false : true}
            />
          )}
          {props.mediaConfigured && (
            <SettingsSubNavItemMaterial
              text={props.mediaText}
              route="/settings/duringcalls/media"
              chosen={"duringcalls/media" === chosenRoute}
              last={true}
            />
          )}
        </ul>
      )}
    </li>
  );
};
