import { MESSAGES } from "../actions/actionTypes";
import { IMessageState } from "../models/reducerStates";

const initialState: IMessageState = {
  loading: true,
  messages: [],
  messageAlert: false,
};

export const messageReducer = (state: IMessageState = initialState, action: any) => {
  switch (action.type) {
    case MESSAGES.LOADED:
      state = {
        ...state,
        messages: action.payload.messages,
        loading: false,
      };
      break;
    case MESSAGES.LOADING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MESSAGES.DELETED_ALERT:
      state.messageAlert = true;
      break;
    case MESSAGES.CLEAR_ALERT:
      state.messageAlert = false;
      break;
    default:
      break;
  }
  return state;
};
