import React from "react";
import Registration from "../containers/Registration";
import NoNavLayout from "../layouts/NoNavLayout";

const RegistrationPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <Registration />
    </NoNavLayout>
  );
};

export default RegistrationPage;
