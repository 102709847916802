import $ from "jquery";
import { APP } from "./actionTypes";

const stylesheetHref = "highContrastTheme.css";
const stylesheetId = "high-contrast-theme";

export function updateScreenSize() {
  return dispatch => {
    const isSmallScreen = window.innerWidth < 992 ? true : false;
    dispatch({ type: APP.SCREEN_IS_SMALL, payload: isSmallScreen });
  };
}

export function updateAppIsInBackground(isInBackground = false) {
  return dispatch => {
    dispatch({ type: APP.IS_IN_BACKGROUND, payload: isInBackground });
  };
}

export function toggleContrast() {
  return (dispatch, getstate) => {
    if (getstate().accessibility.isHighContrast) {
      window.localStorage.setItem("darkTheme", false);
      $(`#${stylesheetId}`).remove();
    } else {
      window.localStorage.setItem("darkTheme", true);
      $("head").append(`<link href='${stylesheetHref}' id='${stylesheetId}' rel='stylesheet' />`);
    }
    dispatch({ type: APP.TOGGLE_CONTRAST });
  };
}

export function toggleNavbar() {
  return dispatch => {
    dispatch({ type: APP.TOGGLE_NAVBAR });
  };
}
