import React from "react";
import DefaultImage from "../assets/images/contact.png";

export const Service = props => {
  return (
    <li className="service text-align-center">
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="btn background-color-inherit mousefocus-border"
      >
        <div className="service-center">
          <div className="text-align-center service-image">
            <img
              src={props.service.imageUrl !== "" ? props.service.imageUrl : DefaultImage}
              width="120"
              height="120"
              alt={props.text("common.call") + " " + props.service.name}
            />
          </div>
          <div className="text-align-center service-text">
            <strong aria-hidden="true">{props.service.name}</strong>
          </div>
        </div>
      </button>
    </li>
  );
};
