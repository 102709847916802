import ContactService from "../services/contactsService";
import { CONTACTS } from "./actionTypes";

const contactsLoading = () => ({
  type: CONTACTS.LOADING,
  payload: {},
});

const contactsLoaded = contacts => ({
  type: CONTACTS.LOADED,
  payload: {
    contacts: contacts,
  },
});

export function loadContacts() {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    dispatch(contactsLoading());

    ContactService.loadContacts(getstate().config.server.url, securityToken, dispatch)
      .then(contacts => {
        dispatch(contactsLoaded(contacts));
      })
      .catch(function(error) {});
  };
}

export function deleteContact(id) {
  return (dispatch, getstate) => {
    var securityToken = getstate().user.securityToken;

    dispatch(contactsLoading());

    ContactService.delete(id, getstate().config.server.url, securityToken, dispatch)
      .then(() => {
        dispatch(deleteContactLocally(id));
      })
      .catch(function(error) {
        dispatch(serverError());
      });
  };
}

export function saveContact(values) {
  return (dispatch, getstate) => {
    var data = { ...values };
    delete data["name"];

    var securityToken = getstate().user.securityToken;

    dispatch(contactsLoading());

    if (values.id) {
      ContactService.update(data, getstate().config.server.url, securityToken, dispatch)
        .then(() => {
          dispatch(updateContactLocally(values));
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          dispatch(serverError());
        });
    } else {
      ContactService.add(data, getstate().config.server.url, securityToken, dispatch)
        .then(() => {
          dispatch(loadContacts());
        })
        .catch(function(error) {
          dispatch(serverError());
        });
    }
  };
}

export const updateContactLocally = data => ({
  type: CONTACTS.UPDATE_LOCALLY,
  payload: {
    data: data,
  },
});

export const deleteContactLocally = id => ({
  type: CONTACTS.DELETE_LOCALLY,
  payload: {
    id: id,
  },
});

export const serverError = () => ({
  type: CONTACTS.REMOVE_LOADING,
});

export const noSipGiven = () => ({
  type: CONTACTS.NO_SIP_GIVEN,
});

export const clearContactAlert = () => ({
  type: CONTACTS.CLEAR_ALERT,
});
