import React from "react";
import "./Dialer.css";

const DialButton = props => {
  return (
    <button
      type="button"
      onClick={() => props.onClick(props.number)}
      style={{ fontSize: props.fontSize }}
      className="btn button btn-sm dialer-button-btn dialer-button"
    >
      <span className="dialer-button-number">{props.number}</span>
    </button>
  );
};

class Dialer extends React.Component {
  render() {
    return (
      <div className={"dialer" + (this.props.inCall ? " dialer-incall" : "")}>
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="1" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="2" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="3" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="4" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="5" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="6" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="7" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="8" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="9" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="*" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="0" />
        <DialButton fontSize={this.props.fontSize} onClick={this.props.onClick} number="#" />
      </div>
    );
  }
}

export default Dialer;
