import MmxApi from "../api/mmxApi";

const contactsService = {
  //Add a contact in the server
  getPersistantContacts: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.contacts.getPersistantContacts();
  },

  // Load Business Directory from server
  getBusinessDirectory: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);

    return new Promise((resolve, reject) => {
      Promise.all([
        api.rue.getBusinessDirectoryTypes(),
        api.rue.getBusinessDirectoryCategories(),
        api.rue.getBusinessDirectory(),
      ])
        .then(results => {
          let types = results[0];
          let categories = results[1];
          let directory = results[2];

          for (let i = 0; i < directory.length; i++) {
            const typeId = directory[i].type.id;
            const typeIndex = types.findIndex(type => type.id === typeId);
            if (typeIndex !== -1) {
              types[typeIndex].count = types[typeIndex].count ? ++types[typeIndex].count : 1;
            }

            const categoryId = directory[i].category.id;
            const categoryIndex = categories.findIndex(category => category.id === categoryId);
            if (categoryIndex !== -1) {
              categories[categoryIndex].count = categories[categoryIndex].count ? ++categories[categoryIndex].count : 1;
            }
          }

          types = types.filter(type => type.count);
          categories = categories.filter(category => category.count);

          resolve({
            types: types,
            categories: categories,
            directory: directory,
          });
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  //Load contacts from server
  loadContacts: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.contacts
        .search("%25")
        .done(data => {
          let contacts = data.contactList.map((item, index) => {
            return { ...item };
          });
          resolve(contacts);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },

  //Delete a contact in the server
  delete: (id, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.contacts.delete(id);
  },

  //Update the information of a contact in the server
  update: (data, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.contacts.update(data);
  },

  //Add a contact in the server
  add: (data, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.contacts.add(data);
  },
};

export default contactsService;
