import React from "react";
import { connect } from "react-redux";
import { ActiveService } from "../components/buttons/ActiveService";
import { saveSettings, loadSettings } from "../actions/settingActions";
import { Link } from "react-router-dom";
import { year } from "../misc/helpers";

import "./Footer.css";

class Footer extends React.Component {
  render() {
    const route = "/settings/duringcalls/media";

    const toSettings = this.props.toSettings.replace("%", this.props.mediaText.toLowerCase());

    return (
      <footer className="footer-div">
        <div className="footer-text">{this.props.release.version}</div>
        {this.props.isLoggedIn && this.props.showButtons ? (
          <ul className={"available-services"}>
            {this.props.audioAvailable && this.props.buttonsInFooter && (
              <ActiveService
                setting={"audio"}
                active={this.props.audioActive}
                disabled={!this.props.hasPermissions}
                icon={this.props.audioActive === "true" ? "mic" : "mic_off"}
                icon2={this.props.audioActive === "true" ? "volume_up" : "volume_off"}
                doubleIcons={this.props.doubleIcons}
                title={this.props.audioActive === "true" ? this.props.micon : this.props.micoff}
                text={
                  this.props.textOnButtons
                    ? this.props.audioActive === "true"
                      ? this.props.micon
                      : this.props.micoff
                    : ""
                }
                onClick={this.props.saveSettings}
              />
            )}
            {this.props.videoAvailable && this.props.buttonsInFooter && (
              <ActiveService
                setting={"video"}
                active={this.props.videoActive}
                disabled={!this.props.hasPermissions}
                icon={this.props.videoActive === "true" ? "videocam" : "videocam_off"}
                title={this.props.videoActive === "true" ? this.props.cameraon : this.props.cameraoff}
                text={
                  this.props.textOnButtons
                    ? this.props.videoActive === "true"
                      ? this.props.cameraon
                      : this.props.cameraoff
                    : ""
                }
                onClick={this.props.saveSettings}
              />
            )}
            {this.props.mediaSettings && this.props.linkInFooter && (
              <li className={"service-icons"} aria-label={toSettings}>
                <Link
                  className={"btn available-services-button"}
                  to={route}
                  title={toSettings}
                  aria-label={this.props.ariaLabel}
                >
                  <i
                    aria-hidden="true"
                    className={
                      "material-icons service-icon vertical-align-middle" +
                      (this.props.textOnButtons ? " margin-right-0_2em" : "")
                    }
                  >
                    {"settings"}
                  </i>
                  {this.props.textOnButtons && <span className="button-text">{toSettings}</span>}
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <ul className={"spacer"}></ul>
        )}
        <div className="footer-text">{this.props.text}</div>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.user.authenticated,
    text: state.config.text("common.copyright").replace("%YEAR%", year),
    audioAvailable: state.config.call.audio,
    showButtons: state.config.call.audio || state.config.call.video,
    audioActive: state.setting.user.WEB_AUDIO,
    videoAvailable: state.config.call.video,
    videoActive: state.setting.user.WEB_VIDEO,
    textAvailable: state.config.call.safetext,
    textOnButtons: state.config.layout.textInFooter,
    mediaSettings: state.config.features.settings.allowChangeMedia,
    linkInFooter: state.config.layout.linkToSettingsInFooter,
    buttonsInFooter: state.config.layout.mediaButtonsInFooter,
    doubleIcons: state.config.layout.useDoubleAudioIconsInFooter,
    ariaLabel: state.config.text("aria.link_sound_video"),
    micon: state.config.text("toolbar.useaudioon"),
    micoff: state.config.text("toolbar.useaudiooff"),
    cameraon: state.config.text("toolbar.usevideoon"),
    cameraoff: state.config.text("toolbar.usevideooff"),
    texton: state.config.text("toolbar.usetexton"),
    textoff: state.config.text("toolbar.usetextoff"),
    toSettings: state.config.text("toolbar.toSettings"),
    audioText: state.config.text("settings.audio"),
    videoText: state.config.text("settings.video"),
    hasPermissions: state.config.hasPermissions,
    release: state.config.release,
    mediaText:
      state.config.call.audio && state.config.call.video
        ? state.config.text("settings.audio") + "/" + state.config.text("settings.video")
        : state.config.call.video
        ? state.config.text("settings.video")
        : state.config.text("settings.audio"),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: () => {
      return dispatch(loadSettings());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
