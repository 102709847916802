import React from "react";

export const ActiveService = props => {
  return (
    <li className={"service-icons"} aria-label={props.title}>
      <button
        className="btn"
        title={props.title}
        disabled={props.disabled}
        onClick={function() {
          if (props.onClick === undefined || props.onClick === null) {
            return;
          }
          var active = props.active === "true" ? "false" : "true";
          var values = props.setting === "audio" ? { audio: active } : { video: active };
          props.onClick(values);
        }}
      >
        {props.doubleIcons && (
          <span>
            <i aria-hidden="true" className={"material-icons service-icon vertical-align-middle"}>
              {props.icon}
            </i>
            <i
              aria-hidden="true"
              className={
                "material-icons service-icon vertical-align-middle" + (props.text ? " margin-right-0_2em" : "")
              }
            >
              {props.icon2}
            </i>
          </span>
        )}
        {!props.doubleIcons && (
          <i
            aria-hidden="true"
            className={"material-icons service-icon vertical-align-middle" + (props.text ? " margin-right-0_2em" : "")}
          >
            {props.icon}
          </i>
        )}
        {props.text && <span className="button-text">{props.text}</span>}
      </button>
    </li>
  );
};
