import appstoreAr from "../assets/images/appstores/appstore-ar.svg";
import appstoreDe from "../assets/images/appstores/appstore-de.svg";
import appstoreDa from "../assets/images/appstores/appstore-da.svg";
import appstoreEn from "../assets/images/appstores/appstore-en.svg";
import appstoreFi from "../assets/images/appstores/appstore-fi.svg";
import appstoreFr from "../assets/images/appstores/appstore-fr.svg";
import appstoreNl from "../assets/images/appstores/appstore-nl.svg";
import appstoreNo from "../assets/images/appstores/appstore-no.svg";
import appstoreSv from "../assets/images/appstores/appstore-sv.svg";
import appstoreTh from "../assets/images/appstores/appstore-th.svg";

import googleplayAr from "../assets/images/appstores/googleplay-ar.svg";
import googleplayDe from "../assets/images/appstores/googleplay-de.svg";
import googleplayDa from "../assets/images/appstores/googleplay-da.svg";
import googleplayEn from "../assets/images/appstores/googleplay-en.svg";
import googleplayFi from "../assets/images/appstores/googleplay-fi.svg";
import googleplayFr from "../assets/images/appstores/googleplay-fr.svg";
import googleplayNl from "../assets/images/appstores/googleplay-nl.svg";
import googleplayNo from "../assets/images/appstores/googleplay-no.svg";
import googleplaySv from "../assets/images/appstores/googleplay-sv.svg";
import googleplayTh from "../assets/images/appstores/googleplay-th.svg";

const badges: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  ar: {
    iOS: appstoreAr,
    Android: googleplayAr,
  },
  de: {
    iOS: appstoreDe,
    Android: googleplayDe,
  },
  da: {
    iOS: appstoreDa,
    Android: googleplayDa,
  },
  en: {
    iOS: appstoreEn,
    Android: googleplayEn,
  },
  fi: {
    iOS: appstoreFi,
    Android: googleplayFi,
  },
  fr: {
    iOS: appstoreFr,
    Android: googleplayFr,
  },
  nl: {
    iOS: appstoreNl,
    Android: googleplayNl,
  },
  no: {
    iOS: appstoreNo,
    Android: googleplayNo,
  },
  sv: {
    iOS: appstoreSv,
    Android: googleplaySv,
  },
  th: {
    iOS: appstoreTh,
    Android: googleplayTh,
  },
};

export default badges;
