import MmxApi from "../api/mmxApi";

const userService = {
  systemSettings: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return new Promise((resolve, reject) => {
      api.user
        .systemSettings()
        .done(data => {
          var systemConfig = {};
          data.settings.forEach(function(element) {
            systemConfig[element.key] = element.value;
          });
          resolve(systemConfig);
        })
        .fail((xhr, ajaxOptions, thrownError) => {
          reject({ xhr, ajaxOptions, thrownError });
        });
    });
  },

  validateByKey: (key, value, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.validateByKey(key, encodeURIComponent(value));
  },

  getUserProperties: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.user.getUserProperties();
  },

  setUserProperty: (key, value, server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.user.setUserProperty(key, value);
  },

  login: (username, password, token, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    const params = {
      username,
      password,
      token,
      force: true,
      willUseSipRegistration: true,
    };

    if (password) {
      delete params.token;
    } else {
      delete params.username;
      delete params.password;
    }

    return new Promise((resolve, reject) => {
      api.user
        .login(params)
        .then(data => {
          var systemConfig = {};
          var properties = {};
          var localIpAddress = data.remoteAddress;
          data.properties.propertySet.forEach(function(element) {
            properties[element.key] = element.value;
          }, this);
          data.capabilities.forEach(function(element) {
            systemConfig[element.key] = element.value;
          }, this);

          var user = {
            properties: { ...properties },
            authenticated: true,
            username,
            password,
            token,
            securityToken: data.securityToken,
            uri: data.uri,
          };
          resolve({ user, systemConfig, localIpAddress });
        })
        .catch(xhr => {
          var errorCode = -2;

          try {
            var response = JSON.parse(xhr.responseText);
            if (typeof response.errorCode !== "undefined") {
              errorCode = response.errorCode;
            }
          } catch (e) {
            if (e.responseText) {
              errorCode = -1;
            }
          }
          console.error(`Error when login against ${server}/userApi/user/api/user/login`, xhr);
          reject({ errorCode, xhr });
        });
    });
  },

  logout: (server, securityToken, dispatch) => {
    const api = new MmxApi(server, securityToken, dispatch);
    return api.user.logout();
  },

  // TODO: use this
  requestResetPassword: (lang, organisationId, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.requestResetPassword({
      lang: encodeURIComponent(lang),
      orgid: encodeURIComponent(organisationId),
    });
  },

  // TODO: use this
  setNewPassword: (key, password, uid, server, dispatch) => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.setNewPassword({
      key: encodeURIComponent(key),
      password: encodeURIComponent(password),
      uid: encodeURIComponent(uid),
    });
  },
};

export default userService;
