import isEmpty from "lodash/isEmpty";
import RegistrationService from "../services/registrationService";
import UserService from "../services/userService";
import { REGISTRATION } from "./actionTypes";

const generateUsernameFromName = (fname, sname) => {
  let username = fname.replace(/\s+/, "") + "." + sname.replace(/\s+/, "");
  while (username.length < 5) {
    username += "0";
  }
  return username
    .replace(/[åä]/g, "a")
    .replace(/[ÅÄ]/g, "A")
    .replace(/ö/g, "o")
    .replace(/Ö/g, "O")
    .toLowerCase();
};

function findFreeUsername(getstate, dispatch, username, counter) {
  return new Promise((resolve, reject) => {
    function findUN(getstate, dispatch, username, counter) {
      UserService.validateByKey("username", username + counter, getstate().config.server.url, dispatch)
        /* username is unique */
        .then(() => {
          resolve(username + counter);
        })
        /* username is not unique */
        .catch(() => {
          if (counter === "") {
            counter = 1;
          } else {
            counter = counter + 1;
          }
          findUN(getstate, dispatch, username, counter);
        });
    }
    findUN(getstate, dispatch, username, counter);
  });
}

export function registration(values) {
  return (dispatch, getstate) => {
    var payload = {};

    dispatch({ type: REGISTRATION.REGISTRATION_INIT });

    if (!isEmpty(values.username)) payload["username"] = values.username;
    if (!isEmpty(values.email)) payload["email1"] = values.email;
    if (!isEmpty(values.password1)) payload["password"] = values.password1;
    if (!isEmpty(values.firstname)) payload["fname"] = values.firstname;
    if (!isEmpty(values.lastname)) payload["sname"] = values.lastname;
    if (!isEmpty(values.address)) payload["address"] = values.address;
    if (!isEmpty(values.postcode)) payload["postcode"] = values.postcode;
    if (!isEmpty(values.city)) payload["city"] = values.city;
    if (!isEmpty(values.sipaddress)) payload["sipaddress"] = values.sipaddress;
    if (!isEmpty(values.phonenumber)) payload["phonenumber"] = values.phonenumber;
    if (!isEmpty(values.mobilenumber)) payload["mobilephone"] = values.mobilenumber;
    if (!isEmpty(values.textphonenumber)) payload["textphonenumber"] = values.textphonenumber;
    if (!isEmpty(values.isdnnumber)) payload["isdnnumber"] = values.isdnnumber;
    if (!isEmpty(values.gggnumber)) payload["mobile3Gnumber"] = values.gggnumber;
    if (!isEmpty(values.faxnumber)) payload["faxnumber"] = values.faxnumber;
    if (!isEmpty(values.orgID)) {
      payload["orgID"] = values.orgID;
    } else {
      payload["orgID"] = getstate().config.server.organisationId;
    }

    if (
      isEmpty(values.lastname) &&
      isEmpty(values.firstname) &&
      isEmpty(values.sipaddress) &&
      !isEmpty(values.username)
    ) {
      payload["sipaddress"] = values.username;
    }

    if (!payload["username"]) {
      if (!isEmpty(values.firstname) && !isEmpty(values.lastname)) {
        findFreeUsername(getstate, dispatch, generateUsernameFromName(values.firstname, values.lastname), "").then(
          username => {
            payload["username"] = username;
            dispatch(sendRegistration(payload));
          }
        );
      } else if (!isEmpty(values.email)) {
        findFreeUsername(getstate, dispatch, values.email.split("@")[0], "").then(username => {
          payload["username"] = username;
          dispatch(sendRegistration(payload));
        });
      } else if (!isEmpty(values.sipaddress)) {
        findFreeUsername(getstate, dispatch, values.sipaddress.split("@")[0], "").then(username => {
          payload["username"] = username;
          dispatch(sendRegistration(payload));
        });
      }
    } else {
      dispatch(sendRegistration(payload));
    }
  };
}

function sendRegistration(payload) {
  return (dispatch, getstate) => {
    var agp =
      getstate().config.features.registration.autogeneratePassword || payload.password === undefined ? "true" : "false";
    var sendRegistrationMail = getstate().config.features.registration.sendRegistrationMail ? "true" : "false";
    var lang = encodeURIComponent(navigator.language);
    let params = {
      generatePassword: agp,
      sendRegistrationMail: sendRegistrationMail,
      lang: lang,
    };

    RegistrationService.createUser(JSON.stringify(payload), params, getstate().config.server.url, dispatch)
      .done(() => {
        dispatch({ type: REGISTRATION.REGISTRATION_SUCCESS });
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        dispatch({
          type: REGISTRATION.REGISTRATION_ERROR,
          payload: thrownError,
        });
      });
  };
}

export function init() {
  return (dispatch, getstate) => {
    let params = { id: getstate().config.server.organisationId };

    RegistrationService.getOrgSettings(params, getstate().config.server.url, dispatch).done(data => {
      var properties = {};
      data.settings.map(function(element) {
        properties[element.key] = element.value;
        return true;
      });

      dispatch({
        type: REGISTRATION.CONFIG_INIT_REGISTRATION,
        payload: {
          ...properties,
          config: getstate().config,
        },
      });
    });
  };
}
