import React from "react";
import { withRouter } from "react-router-dom";
import "./RoundButton.css";

class RoundButton extends React.Component {
  btn = (props, click) => {
    var typeNew = props.type ? props.type : "button";
    var size = props.size ? props.size : "42";
    var btnSize = props.btnSize ? props.btnSize : "";
    var classes = props.className !== undefined ? props.className : "";
    classes = classes + (props.color === "red" ? " round-red-button" : "");

    var title;
    switch (props.title) {
      case " ":
        title = "";
        break;
      case "":
        title = props.title;
        break;
      default:
        title = props.text;
    }

    if (props.inverted && props.inverted === true) {
      return (
        <div className={"InCallButtonContainer"}>
          <button
            title={title}
            className={"btn roundButton roundButton-inverted " + btnSize + " " + classes}
            type={typeNew}
            onClick={click}
          >
            {props.icon && !props.hide && (
              <i aria-hidden="true" className={"material-icons roundButton-icon md-" + size}>
                {props.icon}
              </i>
            )}
          </button>
          <br />
          <label className={"roundButton-label"} htmlFor={title}>
            {title}
          </label>
        </div>
      );
    } else {
      return (
        <div className={"InCallButtonContainer"}>
          <button title={title} className={"link-button"} type={typeNew} onClick={click}>
            {props.icon && !props.hide && (
              <div className={"round-button round-button-large " + classes}>
                <i aria-hidden="true" className={"material-icons roundButton-icon md-" + size}>
                  {props.icon}
                </i>
              </div>
            )}
            {!props.hide && <div className={"text-link-button text-link-button-large"}>{title}</div>}
          </button>
        </div>
      );
    }
  };

  render() {
    if (this.props.to) {
      return this.btn(this.props, () => {
        this.props.history.push(this.props.to);
      });
    }

    if (this.props.onClick) {
      return this.btn(this.props, () => {
        this.props.onClick();
      });
    }

    return this.btn(this.props);
  }
}

export default withRouter(RoundButton);
