import React from "react";
import $ from "jquery";
import { WebChatLayout } from "../types";

import "./RealTimeText.css";

export default class RealTimeText extends React.Component {
  constructor(props) {
    super(props);
    this.textOutRef = null;
    this.textInRef = null;
    this.textCombinedRef = null;
  }

  init() {
    window.addEventListener(
      "keydown",
      function(e) {
        if ([37, 38, 39, 40].indexOf(e.keyCode) > -1 && $(e.target)[0] === $("textarea")[0]) {
          e.preventDefault();
        }
      },
      false
    );

    $("#txt").click(function() {
      $("#txt").putCursorAtEnd();
    });
  }

  onInput = () => {
    let textOld = this.textOutRef ? this.props.text.out : this.props.text.combined;
    let textNew = this.textOutRef ? this.textOutRef.value : this.textCombinedRef.value;
    if (!textOld) textOld = "";
    if (!textNew) textNew = "";

    const lengthDiff = textNew.length - textOld.length;

    // No change
    if (lengthDiff === 0) {
      return;
    }

    if (lengthDiff < 0) {
      if (textNew.substring(0, textNew.length) !== textOld.substring(0, textNew.length)) {
        // Cut some text in the middle
        return;
      }
    }

    if (lengthDiff < -50) {
      // Disable to delete all chars
      return;
    }

    let msg = "";

    // Deleted chars
    if (lengthDiff < 0) {
      const backspace = String.fromCharCode(8);
      for (let i = 0; i < Math.abs(lengthDiff); i++) {
        msg = msg + backspace;
      }
    }

    // Added chars
    if (lengthDiff > 0) {
      msg = msg + textNew.substring(textOld.length, textNew.length);
    }

    this.props.sendTxt(msg);
  };

  componentDidUpdate() {
    if (this.textOutRef) this.textOutRef.scrollTop = this.textOutRef.scrollHeight;
    if (this.textInRef) this.textInRef.scrollTop = this.textInRef.scrollHeight;
    if (this.textCombinedRef) this.textCombinedRef.scrollTop = this.textCombinedRef.scrollHeight;
    this.init();
  }

  render() {
    const { isVisible, layout, text, colors, font, kioskmode } = this.props;

    const visibleClass = isVisible ? "" : "display-none";
    const over_under_text_class = kioskmode === true ? "over-under-text-kiosk" : "over-under-text";

    const twoPanes = (className1, className2) => {
      const classesIn =
        "form-control inCall-text-in-out " +
        colors.in.foreground +
        "-color " +
        colors.in.background +
        "-background-color" +
        " font-size-" +
        font.size +
        " font-family-" +
        font.family;

      const classesOut =
        "form-control inCall-text-in-out " +
        colors.out.foreground +
        "-color " +
        colors.out.background +
        "-background-color" +
        " font-size-" +
        font.size +
        " font-family-" +
        font.family;

      return (
        <div className={className1}>
          <div className={className2 + " " + className2 + "-1"} ref={o => (this.text_in_container = o)}>
            <textarea
              placeholder={this.props.placeholderIncoming}
              aria-label={this.props.placeholderIncoming}
              className={classesIn}
              spellCheck={false}
              value={text.in}
              readOnly
              ref={obj => {
                this.textInRef = obj;
              }}
            ></textarea>
          </div>
          <div className={className2 + " " + className2 + "-2"} ref={o => (this.text_out_container = o)}>
            <textarea
              id={"txt"}
              placeholder={this.props.placeholderOutgoing}
              aria-label={this.props.placeholderOutgoing}
              className={classesOut}
              spellCheck={false}
              onChange={this.onInput}
              value={text.out}
              ref={obj => {
                this.textOutRef = obj;
              }}
            ></textarea>
          </div>
        </div>
      );
    };

    const onePane = (className1, className2) => {
      const classes =
        "form-control min-width-height-100-procent resize-none " +
        colors.foreground +
        "-color " +
        colors.background +
        "-background-color" +
        " font-size-" +
        font.size +
        " font-family-" +
        font.family;
      return (
        <div className={className1}>
          <div className={className2} ref={o => (this.text_combined_container = o)}>
            <textarea
              id={"txt"}
              placeholder={this.props.placeholderCombined}
              aria-label={this.props.placeholderCombined}
              className={classes}
              spellCheck={false}
              onInput={this.onInput}
              value={text.combined}
              ref={obj => {
                this.textCombinedRef = obj;
              }}
            ></textarea>
          </div>
        </div>
      );
    };

    let className1 = "";
    let className2 = "";

    switch (layout) {
      case WebChatLayout.OVER_UNDER:
        className1 = `min-width-height-100-procent ${visibleClass} ${over_under_text_class}`;
        className2 = "inCall-over-under-text";
        return twoPanes(className1, className2);

      case WebChatLayout.SIDE_BY_SIDE:
        className1 = `min-width-height-100-procent display-flex ${visibleClass} side-by-side-text`;
        className2 = "inCall-side-by-side-text";
        return twoPanes(className1, className2);

      case WebChatLayout.ONE_BOX:
      default:
        className1 = `min-width-height-100-procent display-flex ${visibleClass} one-box-text`;
        className2 = "min-width-height-100-procent";
        return onePane(className1, className2);
    }
  }
}
