import React from "react";
import RoundButton from "../components/buttons/RoundButton";
import RoundRejectButton from "../components/buttons/RoundRejectButton";
import "./InCallButtonBar.css";

const InCallButtonBar = ({
  l10n,
  isAudioAvailable,
  isVideoAvailable,
  isTextAvailable,
  isDialpadAvailable,
  isVideoVisible,
  isTextEnabled,
  isSoundOn,
  isTextVisible,
  isDialerVisible,
  isSelfViewVisible,
  unreadText,
  audio,
  video,
  hangup,
  muteAudio,
  muteVideo,
  muteRemoteAudio,
  hideVideo,
  toggleText,
  hideDialer,
  hideSelfview,
  show,
  showMore,
  isFullscreenAvailable,
  isFullscreenEnabled,
  toggleFullscreen,
}) => (
  <div>
    <nav className={"d-lg-none InCallButtonBar" + (show ? " InCallButtonBar-expanded" : "")}>
      <div className={"row InCallButtonBarFirstRow"}>
        <div className={"col padding-zero"}>
          <RoundRejectButton onClick={hangup} text={l10n("common.hangup")} />
        </div>
        {isVideoAvailable && isTextAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              className={!isTextVisible && unreadText ? "flashing" : ""}
              icon={isTextVisible ? "videocam" : "text_fields"}
              onClick={toggleText}
              text={isTextVisible ? l10n("toolbar.togglevideo") : l10n("toolbar.toggletext")}
            />
          </div>
        )}
        <div className={"col padding-zero"}>
          <RoundButton
            icon={show ? "close" : "expand_more"}
            onClick={showMore}
            text={show ? l10n("toolbar.hide") : l10n("toolbar.more")}
          />
        </div>
      </div>
      <div className={"row InCallButtonBarSecondRow"}>
        {isAudioAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={audio ? "mic" : "mic_off"}
              onClick={muteAudio}
              text={audio ? l10n("toolbar.micon") : l10n("toolbar.micoff")}
            />
          </div>
        )}
        {isVideoAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={video ? "videocam" : "videocam_off"}
              onClick={muteVideo}
              text={video ? l10n("toolbar.cameraon") : l10n("toolbar.cameraoff")}
            />
          </div>
        )}
        {isAudioAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={isSoundOn ? "volume_up" : "volume_off"}
              onClick={muteRemoteAudio}
              text={isSoundOn ? l10n("toolbar.audioon") : l10n("toolbar.audiooff")}
            />
          </div>
        )}
        {isVideoAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={isSelfViewVisible ? "portrait" : "crop_free"}
              onClick={hideSelfview}
              text={isSelfViewVisible ? l10n("toolbar.selfviewon") : l10n("toolbar.selfviewoff")}
            />
          </div>
        )}
        <div className={"col padding-zero"}>
          <RoundButton
            icon={"dialpad"}
            onClick={hideDialer}
            text={isDialerVisible ? l10n("toolbar.hidedialpad") : l10n("toolbar.showdialpad")}
          />
        </div>
      </div>
    </nav>
    <nav className={"d-none d-lg-block InCallButtonBar"}>
      <div className={"row InnerInCallButtonBar margin-auto"}>
        <div className={"col padding-zero"}>
          <RoundRejectButton onClick={hangup} text={l10n("common.hangup")} />
        </div>
        {isAudioAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={audio ? "mic" : "mic_off"}
              onClick={muteAudio}
              text={audio ? l10n("toolbar.micon") : l10n("toolbar.micoff")}
            />
          </div>
        )}
        {isAudioAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={isSoundOn ? "volume_up" : "volume_off"}
              onClick={muteRemoteAudio}
              text={isSoundOn ? l10n("toolbar.audioon") : l10n("toolbar.audiooff")}
            />
          </div>
        )}
        {isVideoAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={video ? "videocam" : "videocam_off"}
              onClick={muteVideo}
              text={video ? l10n("toolbar.cameraon") : l10n("toolbar.cameraoff")}
            />
          </div>
        )}
        {isFullscreenAvailable && (
          <div className={"col padding-zero"}>
            <RoundButton
              icon={isFullscreenEnabled ? "fullscreen_exit" : "fullscreen"}
              onClick={toggleFullscreen}
              text={isFullscreenEnabled ? l10n("toolbar.fullscreenon") : l10n("toolbar.fullscreenoff")}
            />
          </div>
        )}
        <div className={"col padding-zero"}>
          <ul className={"more-menu"}>
            {isTextAvailable && isVideoAvailable && isTextEnabled && (
              <li className={"expand-button" + (show ? "" : " expand-button-hidden")}>
                <RoundButton
                  hide={!show}
                  className={!isTextVisible && unreadText ? "flashing" : ""}
                  icon={isTextVisible ? "text_fields" : "format_clear"}
                  onClick={toggleText}
                  text={isTextVisible ? l10n("toolbar.showtext") : l10n("toolbar.hidetext")}
                />
              </li>
            )}
            {isVideoAvailable && isTextAvailable && (
              <li className={"expand-button" + (show ? "" : " expand-button-hidden")}>
                <RoundButton
                  hide={!show}
                  icon={isVideoVisible ? "ondemand_video" : "tv_off"}
                  onClick={hideVideo}
                  text={isVideoVisible ? l10n("toolbar.videoon") : l10n("toolbar.videooff")}
                />
              </li>
            )}
            {isVideoAvailable && (
              <li className={"expand-button" + (show ? "" : " expand-button-hidden")}>
                <RoundButton
                  hide={!show}
                  icon={isSelfViewVisible ? "portrait" : "crop_free"}
                  onClick={hideSelfview}
                  text={isSelfViewVisible ? l10n("toolbar.selfviewon") : l10n("toolbar.selfviewoff")}
                />
              </li>
            )}
            {isDialpadAvailable && (
              <li className={"expand-button expand-button-first" + (show ? "" : " expand-button-hidden")}>
                <RoundButton
                  hide={!show}
                  icon={"dialpad"}
                  onClick={hideDialer}
                  text={isDialerVisible ? l10n("toolbar.hidedialpad") : l10n("toolbar.showdialpad")}
                />
              </li>
            )}
            {(isTextAvailable || isVideoAvailable || isDialpadAvailable) && (
              <li className={"padding-zero"}>
                <RoundButton
                  icon={show ? "close" : "more_horiz"}
                  onClick={showMore}
                  className={!isTextVisible && unreadText ? "flashing" : ""}
                  text={show ? l10n("toolbar.hide") : l10n("toolbar.more")}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  </div>
);

export default InCallButtonBar;
