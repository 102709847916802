import React from "react";
import ResetPassword from "../containers/ResetPassword";
import NoNavLayout from "../layouts/NoNavLayout";

const ResetPasswordPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <ResetPassword />
    </NoNavLayout>
  );
};

export default ResetPasswordPage;
