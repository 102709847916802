import $ from "jquery";
import { localLogout } from "../actions/userActions";
import { addAlert, removeAlert, Types as alertTypes } from "../actions/alertActions";
import config from "../config/config";

const TIMEOUT = 10000;
const USE_CACHE = false;

type RequestParams = {
  securityToken?: string;
  [key: string]: string | number;
};

type RequestPayload = {
  [key: string]: string | number;
};

/**
 * The class containing and handling all api calls
 */
class MmxApi {
  server;
  securityToken;
  dispatch;
  serverError;

  /**
   * Creates an instance of the class.
   *
   * @param {String}  server          The api for the server. This will be added before all api calls.
   * @param {String}  securityToken   The security token for the session.
   * @param {Function}  dispatch      Used to send actions to redux.
   */
  constructor(server: string, securityToken: string, dispatch: Function) {
    this.server = server;
    this.securityToken = securityToken;
    this.dispatch = dispatch;
    this.serverError = false;
  }

  /* These are what is used outside of the class */
  contacts = {
    getPersistantContacts: (): JQuery.jqXHR => {
      return this.get("contacts/getPersistantContacts");
    },
    search: (searchString: string): JQuery.jqXHR => {
      return this.get("contacts/search", { searchString });
    },
    delete: (id: number): JQuery.jqXHR => {
      return this.get("contacts/delete", { phonebookId: id }, false);
    },
    update: (payload: RequestPayload): JQuery.jqXHR => {
      return this.post("contacts/update", payload);
    },
    add: (payload: RequestPayload): JQuery.jqXHR => {
      return this.post("contacts/add", payload);
    },
  };
  messages = {
    messages: (): JQuery.jqXHR => {
      return this.get("messages");
    },
    markAsRead: (messageNumber: number): JQuery.jqXHR => {
      return this.put(`messages/${messageNumber}/markAsRead`, true);
    },
    delete: (messageNumber: number): JQuery.jqXHR => {
      return this.delete(`messages/${messageNumber}`);
    },
    deleteReadMessages: (): JQuery.jqXHR => {
      return this.post("messages/deleteReadMessages");
    },
  };
  user = {
    systemSettings: (): JQuery.jqXHR => {
      return this.get("user/getSystemSettingsForUser");
    },
    validateByKey: (key: string, value: string): JQuery.jqXHR => {
      // TODO: change api to GET on server side
      return this.post("signUp/validateByKey", {}, { key: key, value: value });
    },
    getUserProperties: (): JQuery.jqXHR => {
      return this.get("user/getProperties");
    },
    setUserProperty: (key: string, value: string): JQuery.jqXHR => {
      // TODO: change api to POST on server side
      return this.get("user/setProperty", { key: key, value: value }, false);
    },
    login: (payload: RequestPayload): JQuery.jqXHR => {
      return this.post("user/login", payload, {}, true);
    },
    logout: (): JQuery.jqXHR => {
      // TODO: change api to POST on server side
      return this.get("user/logout", {}, false);
    },
    createUser: (payload: RequestPayload, params: RequestParams): JQuery.jqXHR => {
      return this.post("signUp/create", payload, params, true);
    },
    verifyUserAccount: (userId: number, verificationKey: string): JQuery.jqXHR => {
      return this.post(`signUp/verify/${userId}/${verificationKey}`, {}, {}, true);
    },
    getOrgSettings: (params: RequestParams): JQuery.jqXHR => {
      return this.get("user/getOrgSettings", params);
    },
    requestResetPassword: (params: RequestParams): JQuery.jqXHR => {
      return this.get("user/requestResetPassword", params, false);
    },
    setNewPassword: (params: RequestParams): JQuery.jqXHR => {
      // TODO: change api to POST on server side
      return this.get("user/setNewPassword", params, false);
    },
  };
  settings = {
    webSettings: (): JQuery.jqXHR => {
      return this.get("user/webSettings");
    },
    updateSetting: (key: string, payload: RequestPayload): JQuery.jqXHR => {
      return this.post("user/webSettings/" + key, payload);
    },
  };
  rue = {
    getBusinessDirectory: (): JQuery.jqXHR => {
      return this.get(`business_directory`);
    },
    getBusinessDirectoryTypes: (): JQuery.jqXHR => {
      return this.get(`business_directory/types`);
    },
    getBusinessDirectoryCategories: (): JQuery.jqXHR => {
      return this.get(`business_directory/categories`);
    },
  };

  /* Help functions to abstract some parameters to the function sendJson. */
  get(apiAction: string, params: RequestParams = {}, expectedResponse: boolean = true): JQuery.jqXHR {
    return this.sendJson(apiAction, "GET", params, {}, expectedResponse);
  }
  post(
    apiAction: string,
    payload: RequestPayload = {},
    params: RequestParams = {},
    expectedResponse: boolean = false
  ): JQuery.jqXHR {
    return this.sendJson(apiAction, "POST", params, payload, expectedResponse);
  }
  put(apiAction: string, expectedResponse: boolean = false): JQuery.jqXHR {
    return this.sendJson(apiAction, "PUT", {}, {}, expectedResponse);
  }
  delete(apiAction: string): JQuery.jqXHR {
    return this.sendJson(apiAction, "DELETE", {}, {}, false);
  }

  /**
   * Does an api call to the server for the given api action and http method.
   *
   * @param {String} apiAction            The action the user wants from the server, for example:
   *                                      "user/login", "contacts/add".
   * @param {String}  httpMethod          The http method to be used, for example: 'PUT', 'DELETE'.
   * @param {Object}  params              An object with parameters to be added to the url.
   * @param {Object}  payload             An object with the payload to be sent to server.
   * @param {Boolean} expectedResponse    Is a response expected from the server? If not then the data type of
   * @param {String}  dataType            Expected data type for the reponse.
   *
   * @return {String}                     The response from the server. If no contact with the server was made then
   *                                      the function "handleAjaxError" will be run abrupting this one.
   */
  sendJson(
    apiAction: string,
    httpMethod: string,
    params: RequestParams = {},
    payload: RequestPayload = {},
    expectedResponse: boolean = true,
    dataType: string = "html"
  ): JQuery.jqXHR {
    if (apiAction === null || httpMethod === null) {
      return null;
    }

    let jsonObject: JQueryAjaxSettings = {
      method: httpMethod,
      url: this.addParams(this.url(apiAction), params),
      timeout: TIMEOUT,
      cache: USE_CACHE,
      dataType: "json",
    };

    if (httpMethod.toUpperCase() === "POST") {
      jsonObject.crossDomain = true;
      jsonObject.contentType = "application/json; charset=utf-8";
      // TODO: Convert all files that use mmxApi to TypeScript and remove this type check
      if (typeof payload === "object") {
        jsonObject.data = JSON.stringify(payload);
      } else {
        jsonObject.data = payload;
      }
    }

    if (expectedResponse === false) {
      jsonObject = {
        ...jsonObject,
        dataType,
      };
    }

    var response = $.ajax(jsonObject);
    response
      .done(() => {
        this.dispatch(removeAlert("networkError"));
      })
      .fail((xhr, ajaxOptions, thrownError) => {
        console.error("Error when sending action request to server: ", apiAction);

        if (xhr.status === 401 || xhr.status === 403) {
          this.dispatch(localLogout());
        } else if (xhr.status === 0) {
          this.dispatch(
            addAlert({
              message: config.text("network.networkError"),
              type: alertTypes.ERROR,
              id: "networkError",
            })
          );
        }
      });

    return response;
  }

  /**
   * Creates the url without parameters. The purpose of this function is to abstract the api destination
   * so it will only be written in one place.
   *
   * @param {String}  method      The part of the url that changes depending on the method/action the user
   *                              wants from the server, for example: "user/login", "contacts/add".
   * @return {String}             The concated string of the url and parameters.
   */
  url(method: string): string {
    return `${this.server}/userApi/user/api/${method}`;
  }

  /**
   * This function adds the params to the url with a separation of a ? between.
   *
   * @param {String}  url         An url.
   * @param {Object}  params      An object with keys/fields containing the parameters to be added.
   * @return {String}             The concated string of the url and parameters.
   */
  addParams(url: string, params: RequestParams): string {
    var newUrl = "";
    if (this.securityToken !== null && this.securityToken !== undefined) {
      params.securityToken = this.securityToken;
    }
    if (params != null && Object.keys(params).length > 0) {
      const paramStr = Object.keys(params)
        .map(key => {
          return key + "=" + params[key];
        })
        .join("&");
      newUrl = `${url}?${paramStr}`;
    } else {
      newUrl = url;
    }
    return newUrl;
  }
}

export default MmxApi;
