import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web app",
  "common.loading": "Chargement en cours",
  "common.call": "Appeler",
  "common.answer": "Décrocher",
  "common.reject": "Refuser",
  "common.callback": "Rappeler",
  "common.callagain": "Recomposer",
  "common.yes": "Oui",
  "common.no": "Non",
  "common.on": "Activé",
  "common.off": "Désactivé",
  "common.text": "Texte",
  "common.abort": "Quitter",
  "common.delete": "Supprimer",
  "common.cancel": "Annuler",
  "common.missedcall": "Appel en absence de",
  "common.save": "Sauvegarder",
  "common.hangup": "Raccrocher",
  "common.add": "Ajouter",
  "common.firstname": "Prénom",
  "common.lastname": "Nom",
  "common.sipaddress": "Adresse SIP",
  "common.email": "Email",
  "common.address": "Adresse",
  "common.city": "Ville",
  "common.postcode": "Code postal",
  "common.calling": "Appel en cours",
  "common.dialer": "Composeur",
  "common.back": "Retourner",
  "common.hide": "Cacher",
  "common.online": "Online",
  "common.video": "Vidéo",
  "common.audio": "Audio",
  "common.reset": "Réinitialiser",
  "common.here": "ici",
  "common.username": "Nom d'utilisateur",
  "common.password": "Mot de passe",
  "common.send": "Envoyer",
  "common.password1": "Mot de passe",
  "common.password2": "Répéter le mot de passe",
  "common.phonenumber": "Téléphone",
  "common.sip": "Adresse SIP",
  "common.mobilenumber": "Portable",
  "common.isdn": "Numéro ISDN",
  "common.3gnumber": "Numéro 3G",
  "common.fax": "Fax",
  "common.username_helptext":
    "Entre 5 et 100 caractères. Seulement 0-9, .(point), -(tiret) et a-z(lettres minuscules) sont permis.",
  "common.email_helptext": "Email non valable",
  "common.password1_helptext": "Entre 6 et 20 caractères",
  "common.password2_helptext": "Mots de passe pas identiques",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Le champ contient un ou plusieurs caractères invalides",
  "common.link": "link",
  "common.Link": "Link",
  "common.anonymous": "Anonyme",
  "common.edit": "Editer",
  "common.contact_information": "Information de contact",
  "common.view_message": "Regarder le message",
  "common.close_message": "Fermer le message",
  "common.required": "* Champ obligatoire",
  "common.to_startpage": "Aller à l'accueil",
  "common.organisation": "Organisation",
  "common.permissions.heading": "Le service a besoin d'une autorisation pour utiliser votre caméra et / ou microphone.",
  "common.permissions.subheading":
    "Vous pouvez plus tard, avant chaque appel, décider si vous souhaitez utiliser la vidéo / l'audio dans l'appel ou non.<br>Il est conseillé aux utilisateurs de Firefox de \"Se souvenir de cette décision\" pour éviter des questions similaires ultérieurement.<br>Lorsque vous acceptez les autorisations, la caméra / le microphone sera activé pendant quelques secondes, puis désactivé.",
  "common.app_for_os": "%APPNAME% pour %OS%",

  /** Titles */
  "titles.login": "Login",
  "titles.services": "Services",
  "titles.contacts": "Mes contacts",
  "titles.call": "Appel",
  "titles.directory": "Business Directory",
  "titles.history": "Historique",
  "titles.messages": "Messages",
  "titles.settings": "Paramètres",
  "titles.userinformation": "Mon profil",
  "titles.log": "Debug",
  "titles.about": "Information à propos du système",
  "titles.registration": "Enregistrement",
  "titles.newpassword": "Nouveau mot de passe",
  "titles.resetpassword": "Réinitialisation du mot de passe",
  "titles.verifyaccount": "Activer le compte",

  /** Toolbar */
  "toolbar.hidetext": "Texte est désactivé",
  "toolbar.showtext": "Texte est activé",
  "toolbar.micoff": "Microphone est désactivé",
  "toolbar.micon": "Microphone est activé",
  "toolbar.audiooff": "Audio est désactivé",
  "toolbar.audioon": "Audio est activé",
  "toolbar.videooff": "Vidéo entrante est désactivée",
  "toolbar.videoon": "Vidéo entrante est activée",
  "toolbar.cameraoff": "Ma vidéo est désactivée",
  "toolbar.cameraon": "Ma vidéo est activée",
  "toolbar.fullscreenoff": "Fullscreen est désactivé",
  "toolbar.fullscreenon": "Fullscreen est activé",
  "toolbar.selfviewoff": "Selfview est désactivé",
  "toolbar.selfviewon": "Selfview est activé",
  "toolbar.hidedialpad": "Clavier numérique est activé",
  "toolbar.showdialpad": "Clavier numérique est desactivé",
  "toolbar.useaudioon": "Appel avec son est activé",
  "toolbar.useaudiooff": "Appel avec son est désactivé",
  "toolbar.usevideoon": "Appel avec vidéo est activé",
  "toolbar.usevideooff": "Appel avec vidéo est désactivé",
  "toolbar.usetexton": "Appel avec texte est activé",
  "toolbar.usetextoff": "Appel avec texte est désactivé",
  "toolbar.more": "Plus",
  "toolbar.hide": "Moins",
  "toolbar.togglevideo": "Montrer vidéo",
  "toolbar.toggletext": "Montrer texte",
  "toolbar.toSettings": "Aller à paramètre %",

  /** Menu */
  "menu.logo": "Aller à l'accueil",
  "menu.services": "Services",
  "menu.servicesHeadline": "Services",
  "menu.contacts": "Contacts",
  "menu.mycontacts": "Mes contacts",
  "menu.call": "Appel",
  "menu.directory": "Business Directory",
  "menu.history": "Historique",
  "menu.messages": "Messages",
  "menu.settings": "Paramètres",
  "menu.logout": "Logout",
  "menu.userinformation": "Compte",
  "menu.userprofile": "Mon profil",
  "menu.log": "Debug",
  "menu.about": "Information ä propos du système",

  /** Login screen */
  "login.loginanonymous.headline": "Cliquer ici pour faire un appel anonyme",
  "login.loginanonymous": "Login anonyme",
  "login.headline": "Login",
  "login.login": "Login",
  "login.loginfailed": "Login a échoué",
  "login.resetpassword": "Avez-vous oublié votre mot de passe?",
  "login.registration": "Voulez-vous vous enregistrer?",
  "login.nowebrtc": "Votre browser ne supporte pas de communication vidéo et audio en temps réel (WebRTC).",
  "login.debug": "Mode Debug",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Nom d'utilisateur ou mot de passe non valide",
  "error.usernameNotExists": "Le nom d'utilisateur n'existe pas",
  "error.noservercontact": "Pas de connexion avec le serveur",
  "error.accountnotactive": "Le compte n'est pas actif",
  "error.accountexpired": "Le compte a expiré",
  "error.tokenexpired": "La session d'authentification a expiré. Veuillez vous reconnecter",
  "error.tokeninvalid": "Le token d'identification n'est pas valide",
  "error.unknown": "Erreur inconnue",
  "error.unknownsender": "Emetteur inconnu",
  "error.unknownsip": "SIP inconnu",
  "error.notextsent": "Aucun texte envoyé",
  "error.acquiremedia":
    "Vous ne pouvez pas effectuer d'appel audio/vidéo si vous n'autorisez pas l'accès au média. Désactivez ces paramètres si vous désirez faire un appel sans audio/vidéo.",
  "error.mediaNotReadable":
    "Le périphérique audio ou vidéo n'est pas accessible. Veuillez vous assurer qu'ils ne sont pas utilisés par une autre application et actualiser la page.",
  "error.requestedDeviceNotFound":
    "Le périphérique multimédia demandé est introuvable. Veuillez vous assurer qu'il est correctement connecté.",
  "error.cantcall": "Vous ne pouvez pas faire d'appel si vous n'êtes pas connectés au système",
  "error.cantcall_without_media": "Vous ne pouvez pas faire d'appel sans son, vidéo ou texte",
  "error.404": "404 - La page que vous cherchez n'existe pas",
  "error.unsupportedbrowser": "Votre browser n'est pas supporté, veuillez utiliser un autre browser.",
  "error.browseroutofdate":
    "Votre browser n'est plus actuel. Veuillez l'actualiser à la version la plus récente afin de pouvoir utiliser l'application.",
  "error.browseroutofdate.ios.15.1":
    "Cette version iOS n'est pas prise en charge, veuillez mettre à jour au moins vers iOS 15.2.",
  "error.browserwarning.closeAndDontShowAgain": "Fermer et ne plus afficher",
  "error.browserwarning.macos":
    "Si vous rencontrez des problèmes pour passer des appels depuis votre iPad et que vous utilisez actuellement iOS version 15.1, veuillez effectuer une mise à niveau vers iOS 15.2 ou une version ultérieure.",

  /** User settings */
  "settings.white": "Blanc",
  "settings.lightgrey": "Gris clair",
  "settings.darkgrey": "Gris foncé",
  "settings.black": "Noir",
  "settings.yellow": "Jaune",
  "settings.blue": "Bleu",
  "settings.red": "Rouge",
  "settings.green": "Vert",
  "settings.purple": "Violet",
  "settings.orange": "Orange",
  "settings.pink": "Pink",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Côte à côte",
  "settings.overunder": "Au-dessus et au-dessous",
  "settings.one": "Fenêtre partagée",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Cloche",
  "settings.marimba": "Marimba",
  "settings.glas": "Verre",
  "settings.classic": "Classique",
  "settings.settings": "Paramètres",
  "settings.text": "Texte",
  "settings.font": "Polices de caractère",
  "settings.fontsize": "Taille du texte",
  "settings.textboxlayout": "Layout de la fenêtre du texte",
  "settings.mycolors": "Ma fenêtre de texte",
  "settings.incomingcolors": "Fenêtre de texte entrant",
  "settings.sharedcolors": "Fenêtre partagée",
  "settings.textcolors": "Couleur du texte",
  "settings.backgroundcolors": "Couleur de fond",
  "settings.preview": "Aperçu",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "settings.previewmytext": "Mon texte",
  "settings.previewincomingtext": "Texte entrant",
  "settings.previewbothtext": "Mon texte, texte entrant",
  "settings.incomingcalls": "Appels entrants",
  "settings.messagebox": "Message box",
  "settings.autoanswer": "Utilisez un message texte si vous ne répondez pas",
  "settings.autoanswermessage": "Le message",
  "settings.duringcalls": "Appels",
  "settings.colors": "Couleurs",
  "settings.textformat": "Texte",
  "settings.media": "Média",
  "settings.audio": "Audio",
  "settings.video": "Vidéo",
  "settings.ringtone": "Sonnerie",
  "settings.mediamail": "Messages",
  "settings.about": "Information de système",
  "settings.logout": "Log out",
  "settings.logout.anonymous": "Sortir",
  "settings.saved": "Les paramètres ont été enregistrés",
  "settings.media_explanation":
    "Choisissez la manière dont vous voulez faire vos appels. Si un paramètre est activé, vous pouvez à tout moment le désactiver durant un appel. Par contre, s'il est désactivé, vous ne pourrez pas l'activer durant l'appel.",
  "settings.useaudio": "Appel en utilisant l'audio",
  "settings.usevideo": "Appel en utilisant la vidéo",
  "settings.bandwidth.label": "Bande passante",
  "settings.bandwidth.high": "Élevée",
  "settings.bandwidth.medium": "Moyen",
  "settings.bandwidth.low": "Faible",

  /** Messages */
  "messages.messages": "Messages",
  "messages.unreadmessages": "Non lu",
  "messages.readmessages": "Lu",
  "messages.nomessages": "Vous n'avez pas des messages",
  "messages.deleteallread": "Effacer tous les messages lus",
  "messages.deleteallreadconfirm": "Effacer tous les messages lus?",
  "messages.messageAlert": "Message effacé",

  /** Contacts */
  "contacts.contacts": "Mes contacts",
  "contacts.addcontact": "Ajouter un contact",
  "contacts.deletecontact": "Effacer un contact",
  "contacts.nosipgiven": "Veuillez associez au contact une adresse SIP",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Entrez le numéro/nom ici",
  "call.incomingtext": "Texte entrant",
  "call.outgoingtext": "Texte sortant",
  "call.incoming": "Appel entrant de ",
  "call.unknownnumber": "numéro inconnu",
  "call.unknownnumber_uppercase": "Numéro inconnu",
  "call.endedcall": "a terminé l'appel",
  "call.yourejected": "Vous avez rejeté l'appel",
  "call.noanswer": "Pas de réponse",
  "call.rejected": "n'a pas pu répondre pour le moment",
  "call.notfound": "n'est pas joignable",
  "call.toolongtext": "\n<Trop de caractères. Max 1000.>\n",
  "call.notificationbody": "Cliquez ici pour répondre",
  "call.videoTextDisabled": "Texte et vidéo entrants sont désactivés",
  "call.writeHere": "Ecrivez ici...",
  "call.receiveHere": "C'est l'endroit où apparaîtra le texte entrant",
  "call.establishing": "Etablissement de l'appel...",

  /** History screen */
  "history.history": "Historique",

  /** Reset password */
  "resetpassword.processing": "En traitement ...",
  "resetpassword.completed": "La réinitialisation du mot de passe est terminée",
  "resetpassword.email_or_username": "Email ou nom d'utilisateur",
  "resetpassword.title": "Nouveau mot de passe",
  "resetpassword.explanation":
    "Entrez votre Email ou nom d'utilisateur. Un lien sera envoyé à l'adresse Email ou l'adresse associée au nom d'utilisateur. Suivez le lien pour réinitialiser votre mot de passe.",
  "resetpassword.explanation_complete": "Vous allez recevoir un Email sous peu avec de nouvelles instructions.",
  "resetpassword.invalid_link": "Lien de réinitialisation du mot de passe non valide",
  "resetpassword.password_changed": "Votre mot de passe a été modifié",
  "resetpassword.enter_new_password": "Entrez le nouveau mot de passe",
  "resetpassword.button_reset": "Réinitialiser",
  "resetpassword.password": "Mot de passe",
  "resetpassword.password_repeat": "Répéter le mot de passe",
  "resetpassword.password_requirement": "Le mot de passe doit contenir entre 6 et 20 caractères",
  "resetpassword.error":
    "Il n'a pas été possible de réinitialiser votre mot de passe. Veuillez réessayer ou contactez votre administrateur",
  "resetpassword.link_too_old": "le lien est trop ancien",

  /** Registration */
  "registration.error":
    "Il n' pas été possible de terminer l'enregistrement. Veuillez réessayer ou contactez votre administrateur. ",
  "registration.title": "Enregistrement",
  "registration.processing": "En traitement ...",
  "registration.not_enabled": "L'enregistrement n'est pas activé.",
  "registration.completed_headline": "Vous avez été enregistré",
  "registration.completed": "Votre enregistrement est terminé.",
  "registration.completed_admin_approval":
    "Votre compte doit être approuvé par un administrateur avant de pouvoir vous connecter.",
  "registration.completed_verification":
    "Votre compte doit être confirmé avant de pouvoir vous connecter. Vous recevrez un e-mail avec des instructions sous peu.",
  "registration.completed_link": "/",
  "registration.username_exists": "Ce nom d'utilisateur existe déjà. Veuillez choisir un autre nom d'utilisateur.",
  "registration.email_exists": "L'adresse Email existe déjà. Veuillez entrez une autre adresse.",
  "registration.sip_exists": "L'adresse SIP existe déjà. Veuilelz entrez une autre adresse sip.",
  "registration.license1": "Oui, j'ai lu ",
  "registration.license_link_text": " l'information à propos de la façon dont nous traitons les données personnelles",
  "registration.license2": " et accepte les conditions",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Veuillez accepter les conditions si vous voulez vous enregister",
  "registration.register": "Enregistrer",

  /** Verify Account */
  "verifyaccount.completed.title": "Votre compte est activé!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Quelque chose s'est mal passé ! Veuillez réessayer d'enregistrer le compte.",
  "verifyaccount.error.expired": "Le lien de vérification a expiré. Veuillez réessayer d'enregistrer le compte.",
  "verifyaccount.registration": "Voulez-vous vous enregistrer?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Modifier le compte",
  "changeuser.titlePassword": "Modifier le mot de passe",
  "changeuser.newPassword": "Nouveau mot de passe",
  "changeuser.error":
    "Il n'a pas été possible de faire la mise à jour. Veuillez réessayer ou contactez votre administrateur. ",
  "changeuser.complete": "Compte actualisé",
  "changeuser.processing": "Traitement en cours ...",
  "changeuser.username_exists": "Le nom d'utilisateur est déjà utilisé. Veuillez choisir un autre nom d'utilisateur.",
  "changeuser.email_exists": "L'Email est déjà utilisé. Veuillez choisir une autre adresse Email.",
  "changeuser.email_empty": "L'Email est requis. Veuillez entrer une adresse Email.",
  "changeuser.sip_exists": "L'adresse SIP est déjà utilisée. Veuillez choisir une autre adresse sip",

  /** Network */
  "network.networkError": "Un problème s'est produit lors de la connexion au serveur",
  "network.establishing": "Tentative d’établissement de la connexion au serveur...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Thème sombre",
  "accessibility.originaltheme": "Thème lumineux",

  /** About */
  "about.user": "Nom d'utilisateur",
  "about.sip": "Adresse SIP",
  "about.server": "Serveur IP",
  "about.version": "Version",
  "about.browser": "Browser",
  "about.browserversion": "Version du browser",
  "about.os": "Système d'exploitation",

  /** Aria */
  "aria.mainmenu": "Menu principal",
  "aria.submenu_contacts": "Sous-menu de contacts",
  "aria.submenu_account": "Sous-menu de compte",
  "aria.selected_page": "Page selectionnée",
  "aria.back_button": "Effacer le dernier caractère",
  "aria.accept_terms": "Accepter les conditions du contrat",
  "aria.link_sound_video": "Pour audio et vidéo, aller aux paramètres",
  "aria.selfview": "Selfview vidéo",
  "aria.in-call": "Appel en cours",

  /** Kiosk */
  "kiosk.call_ended": "L'appel est terminé, vous pouvez maintenant fermer cette page.",

  /** Modal */
  "modal.close": "Fermer",
  "modal.cancel": "Annuler",
  "modal.confirm": "D'accord",
  "modal.logout_confirmation.title": "Êtes-vous sûr de vouloir vous déconnecter?",
  "modal.logout_confirmation.confirm": "Oui",
  "modal.logout_confirmation.cancel": "Non, annuler",
};

export default translations;
