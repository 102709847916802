import MmxApi from "../api/mmxApi";

const registrationService = {
  createUser: (payload: any, params: any, server: string, dispatch: Function): JQuery.jqXHR => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.createUser(payload, params);
  },

  verifyUserAccount: (params: any, server: string, dispatch: Function): JQuery.jqXHR => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.verifyUserAccount(params.userId, params.verificationKey);
  },

  getOrgSettings: (params: any, server: string, dispatch: Function): JQuery.jqXHR => {
    const api = new MmxApi(server, null, dispatch);
    return api.user.getOrgSettings(params);
  },
};

export default registrationService;
