import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class Loading extends React.Component {
  render() {
    return (
      <div className="callFullscreen">
        <div className="text-align-center margin-auto">
          <div className="row justify-content-center">
            <div className="col-10 col-md-8" role="alert">
              <h1 className="mb-3">{this.props.l10n("common.permissions.heading")}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.l10n("common.permissions.subheading"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  l10n: PropTypes.func.isRequired,
  contrast: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  l10n: state.config.text,
  contrast: state.accessibility.isHighContrast,
});

export default connect(mapStateToProps)(Loading);
