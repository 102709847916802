import React from "react";
import PropTypes from "prop-types";

const SectionHeader = props => (
  <div className="d-flex justify-content-between">
    <div>
      <h1 id="h1id">{props.text}</h1>
    </div>
    <div className="float-right">{props.children}</div>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.object,
};

export default SectionHeader;
