import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import ILanguage from "../models/Language/ILanguage";
import { text } from "../language";
import { IRootState, IUserState } from "../models/reducerStates";

import RegistrationService from "../services/registrationService";
import { Location } from "history";

type Props = {
  location: Location;
  server: string;
  registration_enabled: boolean;
  text: typeof text;
  dispatch: Function;
  match: any;
};

type State = {
  isLoaded: boolean;
  error: string;
  user: IUserState;
};

class VerifyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      user: null,
    };
  }

  componentDidMount() {
    RegistrationService.verifyUserAccount(
      {
        userId: this.props.match.params.uid,
        verificationKey: this.props.match.params.key,
      },
      this.props.server,
      this.props.dispatch
    )
      .done((data: any) => {
        if (typeof data.user === "undefined") {
          this.setState({
            isLoaded: true,
            error: this.props.text("verifyaccount.error.expired"),
          });
        }

        this.setState({
          isLoaded: true,
          user: data.user,
        });
      })
      .fail((xhr: JQueryXHR) => {
        let error = this.props.text("verifyaccount.error.generic");

        if (xhr.responseJSON.code === 1001 || xhr.responseJSON.code === 1004) {
          error = this.props.text("verifyaccount.error.expired");
        }

        this.setState({
          isLoaded: true,
          error: error,
        });
      });
  }

  render() {
    return this.state.isLoaded ? (
      <main aria-labelledby="h1id">
        {this.state.error ? (
          <React.Fragment>
            <ErrorAlert text={this.state.error} />
            <div className="mt-4">
              {this.props.registration_enabled && (
                <div className="mt-1">
                  <strong>
                    <Link className="underlined" to={"/registration"}>
                      {this.props.text("verifyaccount.registration")}
                    </Link>
                  </strong>
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1 id="h1id">{this.props.text("verifyaccount.completed.title")}</h1>
            {this.props.text("verifyaccount.completed.body") !== "?verifyaccount.completed.body" && (
              <p>
                {this.props
                  .text("verifyaccount.completed.body")
                  .replace("%SIP_ADDRESS%", this.state.user.properties.SIP_ADDRESS)}
              </p>
            )}
            <div className="mt-4">
              {this.props.text("verifyaccount.optionalLink") !== "?verifyaccount.optionalLink" && (
                <div className="mt-1">
                  <strong>
                    <a className="underlined" href={this.props.text("verifyaccount.optionalLink")}>
                      {this.props.text("verifyaccount.optionalLinkInfo")}
                    </a>
                  </strong>
                </div>
              )}
              {this.props.location.search.indexOf("hideLogin=true") === -1 && (
                <div className="mt-1">
                  <strong>
                    <Link className="underlined" to={"/"}>
                      {this.props.text("common.to_startpage")}
                    </Link>
                  </strong>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </main>
    ) : null;
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    server: state.config.server.url,
    registration_enabled: state.config.features.registration.enabled,
    text: (str: keyof ILanguage) => state.config.text(str),
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatch: () => dispatch,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyAccount));
