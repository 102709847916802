import React from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import { loadMessages, deleteMessage } from "../actions/messageActions";
import { withRouter } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/buttons/Button";
import CallButton from "../components/buttons/CallButton";
import "./MessageView.css";
import { APP } from "../actions/actionTypes";

class MessagesView extends React.Component {
  componentDidMount() {
    if (this.props.messages.length === 0) {
      this.props.loadMessages();
    }
    this.props.setPageTitle(this.props.text("menu.userprofile"));
  }

  findContactnameFromSip = sip => {
    for (var i = 0; i < this.props.contacts.length; i++) {
      if (this.props.contacts[i].sipaddress === sip) {
        if (this.props.contacts[i].fname && this.props.contacts[i].sname) {
          return this.props.contacts[i].fname + " " + this.props.contacts[i].sname;
        } else if (this.props.contacts[i].fname) {
          return this.props.contacts[i].fname;
        } else if (this.props.contacts[i].sname) {
          return this.props.contacts[i].sname;
        }
      }
    }
    return sip;
  };

  deleteMessage = item => {
    this.props.deleteMessage(item.messageNumber);
    this.props.history.push("/messages");
  };

  render() {
    if (this.props.isLoadingMessages) {
      return <Loading />;
    }

    var theMessage;
    for (var i = 0; i < this.props.messages.length; i++) {
      if (this.props.messages[i].messageNumber === this.props.messageNumber) {
        theMessage = this.props.messages[i];
        break;
      }
    }

    var contactName = this.findContactnameFromSip(theMessage.fromSipaddress);

    return (
      <div>
        <div className="margin-bottom-10vh">
          <SectionHeader text={contactName} />
          <p>{theMessage.date}</p>
          <p className="margin-top-1em">{theMessage.text}</p>
        </div>
        <div className="message-toolbar">
          <CallButton sipaddress={theMessage.fromDisplayName} />
          <button
            className="link-button no-link-button"
            text={this.props.text("common.delete")}
            onClick={() => this.deleteMessage(theMessage)}
          >
            <i aria-hidden="true" className="material-icons icon-link-button">
              delete
            </i>
            <div className="font-size-smaller text-link-button">{this.props.text("common.delete")}</div>
          </button>
          <Button className="contact-information-cancel" to={"/messages"} text={this.props.text("common.cancel")} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    messages: state.message.messages,
    isLoadingMessages: state.message.loading,
    messageNumber:
      props.match && props.match.params && props.match.params.messageNumber
        ? parseInt(props.match.params.messageNumber, 10)
        : 0,
    contacts: state.contact.contacts,
    text: state.config.text,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadMessages: () => {
      return dispatch(loadMessages());
    },
    deleteMessage: messageNumber => {
      return dispatch(deleteMessage(messageNumber));
    },
    setPageTitle: title => {
      dispatch({
        type: APP.UPDATE_PAGE_TITLE,
        payload: {
          title,
        },
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesView));
