export enum CallStatus {
  INCALL = "INCALL",
  OUTGOING = "OUTGOING",
  HANGUPA = "HANGUPA",
  REJECTEDB = "REJECTEDB",
}

export enum WebChatLayout {
  OVER_UNDER = "OVER_UNDER",
  SIDE_BY_SIDE = "SIDE_BY_SIDE",
  ONE_BOX = "ONE_BOX",
}

export enum DebugView {
  debug = 1,
  log = 2,
  console = 3,
}

export type Ringtone = "SMARTPHONE" | "BELL" | "MARIMBA" | "CLASSIC" | "GLAS";

export type TextColor =
  | "white"
  | "lightgrey"
  | "darkgrey"
  | "black"
  | "blue"
  | "green"
  | "yellow"
  | "orange"
  | "red"
  | "pink"
  | "purple";

export type Font = "times" | "arial" | "trebuchet" | "lucida" | "verdana" | "comicsans" | "couriernew";

export const BandwidthRestrictions = {
  high: {
    audio: 0,
    video: 0,
  },
  medium: {
    audio: 256,
    video: 768,
  },
  low: {
    audio: 64,
    video: 448,
  },
};
