import React from "react";
import NewPassword from "../containers/NewPassword";
import NoNavLayout from "../layouts/NoNavLayout";

const NewPasswordPage = props => {
  props.updatePageTitle(props.title);
  return (
    <NoNavLayout>
      <NewPassword />
    </NoNavLayout>
  );
};

export default NewPasswordPage;
