import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import RealTimeText from "../../../components/RealTimeText";
import HangupButton from "../../../components/buttons/HangupButton";
import { sendMessage as sendTxt, endCall } from "../../../actions/sipActions";
import { unBindLocalStream } from "../../../actions/configActions";
import { WebChatLayout } from "../../../types";
import AudioVideo from "../../../components/AudioVideo";
import { CALLS } from "../../../actions/actionTypes";
import RoundButton from "../../../components/buttons/RoundButton";
import Fullscreen from "react-full-screen";

import "./Kiosk.css";
import DialerView from "../../DialerView";

class Kiosk extends React.Component {
  inCallAlready = false;

  beforeUnload(e) {
    e.preventDefault();
    e.returnValue = "";
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeUnload, false);
    window.addEventListener("resize", this.adjustVideoSize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload, false);
    window.removeEventListener("resize", this.adjustVideoSize.bind(this));
    this.props.unBindLocalStream();
  }

  adjustVideoSize() {
    if (this.props.videoavailable) {
      if (this.props.videoVisible) {
        setTimeout(() => {
          $("#videoContainer").width($("#remote-video").width() + "px");
          $("#remote-video-container").width($("#remote-video").width() + "px");
          if (this.props && this.props.browser.name === "ie") {
            $(".inCallTextContainer").width($(window).width() - $("#videoContainer").width() + "px");
          }
        }, 50);
      } else {
        $("#videoContainer").width("0px");
        $("#remote-video-container").width("0px");
        if (this.props && this.props.browser.name === "ie") {
          $(".inCallTextContainer").width($(window).width() + "px");
        }
      }
    }
  }

  render() {
    if (this.inCallAlready === false) {
      this.inCallAlready = true;
    }

    const { layout, text, colors, font, sendTxt, isVideoAvailable, hangup, l10n } = this.props;
    return (
      <Fullscreen enabled={this.props.fullscreenEnabled} onChange={this.props.handleFullscreenChange}>
        <div id="kiosk-container">
          <RealTimeText
            isVisible={true}
            layout={layout}
            text={text}
            colors={colors}
            font={font}
            sendTxt={sendTxt}
            kioskmode={true}
          />
          <AudioVideo isVisible={true} isVideoAvailable={isVideoAvailable} direction="remote" kioskmode={true} />
          <AudioVideo isVisible={true} isVideoAvailable={isVideoAvailable} direction="local" kioskmode={true} />
          <HangupButton text={l10n("common.hangup")} onClick={hangup} kioskmode={true} />
          <div className="kiosk-button-bar">
            {this.props.isFullscreenAvailable && (
              <RoundButton
                icon={this.props.fullscreenEnabled ? "fullscreen_exit" : "fullscreen"}
                onClick={this.props.toggleFullscreen}
                text={this.props.fullscreenEnabled ? l10n("toolbar.fullscreenon") : l10n("toolbar.fullscreenoff")}
              />
            )}
            {this.props.dialpadavailable && (
              <RoundButton
                icon={"dialpad"}
                onClick={this.props.hideDialer}
                text={this.props.dialerVisible ? l10n("toolbar.hidedialpad") : l10n("toolbar.showdialpad")}
              />
            )}
          </div>
          <DialerView />
        </div>
      </Fullscreen>
    );
  }
}

const mapStateToProps = state => {
  return {
    font: {
      size: state.setting.user.WEB_TEXT_SIZE,
      family: state.setting.user.WEB_FONT,
    },
    colors: {
      background: state.setting.user.WEB_ONEBOX_TEXT_BACKGROUND_COLOR,
      foreground: state.setting.user.WEB_ONEBOX_TEXT_COLOR,
      in: {
        foreground: state.setting.user.WEB_TEXT_IN_COLOR,
        background: state.setting.user.WEB_TEXTAREA_IN_BACKGROUND,
      },
      out: {
        foreground: state.setting.user.WEB_TEXT_OUT_COLOR,
        background: state.setting.user.WEB_TEXTAREA_OUT_BACKGROUND,
      },
    },
    text: {
      in: state.call.text_in,
      out: state.call.text_out,
      combined: state.call.text_combined,
    },
    layout: WebChatLayout.OVER_UNDER,
    isVideoAvailable: state.call.videoavailable,
    l10n: state.config.text,
    dialerVisible: state.call.dialerVisible,
    dialpadavailable: state.config.call.dialpad,
    isFullscreenAvailable: document.fullscreenEnabled ? state.config.call.fullscreen : false,
    fullscreenEnabled: state.call.fullscreenEnabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendTxt: str => {
      return dispatch(sendTxt(str));
    },
    hangup: () => {
      return dispatch(endCall());
    },
    unBindLocalStream: () => {
      return dispatch(unBindLocalStream());
    },
    hideDialer: () => {
      return dispatch({
        type: CALLS.HIDE_DIALER,
      });
    },
    toggleFullscreen: () => {
      return dispatch({
        type: CALLS.TOGGLE_FULLSCREEN,
      });
    },
    handleFullscreenChange: isFullscreen => {
      return dispatch({
        type: CALLS.TOGGLE_FULLSCREEN,
        payload: isFullscreen,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kiosk);
