import React from "react";

const Standard = ({ contrast, logoDark, logo, l10n, flash = false, include }) => {
  return (
    <div className="callFullscreen">
      <header className="inCallHeader branding-header">
        <img className="logo" src={contrast ? logoDark : logo} alt={l10n("menu.logo")} />
      </header>
      <div className="box callContainer">
        <main className={"row content margin-0_1em call-center" + (flash ? " flash" : "")}>{include}</main>
      </div>
    </div>
  );
};

export default Standard;
