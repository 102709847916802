import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { saveSettings, loadSettings } from "../../actions/settingActions";
import { SETTINGS } from "../../actions/actionTypes";
import Loading from "../../components/Loading";
import Button from "../../components/buttons/Button";
import "../Settings.css";
import { BandwidthRestrictions } from "../../types";

class Media extends React.Component {
  componentDidMount() {
    //this.props.loadSettings(); /* Server does not save these settings yet */
  }

  componentWillUnmount() {
    this.props.clearSettingsSaved();
  }

  onSubmit = values => {
    this.props.saveSettings(values);
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.media_enabled || this.props.configuredMedia === 0) {
      return <Redirect to={"/settings"} />;
    }

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <p>{this.props.text("settings.media_explanation")}</p>
        <div>
          {this.props.audioAvailable && (
            <div className="form-group font-weight-bold">
              <label htmlFor="settings-audio">{this.props.text("settings.useaudio")}</label>
              <Field id="settings-audio" component="select" className="form-control inputtest" name="audio">
                <option value={true} key={true}>
                  {this.props.text("common.on")}
                </option>
                <option value={false} key={false}>
                  {this.props.text("common.off")}
                </option>
              </Field>
            </div>
          )}
          {this.props.videoAvailable && (
            <div className="form-group font-weight-bold">
              <label htmlFor="settings-video">{this.props.text("settings.usevideo")}</label>
              <Field id="settings-video" component="select" className="form-control" name="video">
                <option value={true} key={true}>
                  {this.props.text("common.on")}
                </option>
                <option value={false} key={false}>
                  {this.props.text("common.off")}
                </option>
              </Field>
            </div>
          )}
        </div>

        <div className="form-group font-weight-bold">
          <label htmlFor="settings-audio">{this.props.text("settings.bandwidth.label")}</label>
          <Field id="settings-bandwidth" component="select" className="form-control inputtest" name="bandwidth">
            {Object.keys(BandwidthRestrictions).map(key => (
              <option value={key} key={key}>
                {this.props.text(`settings.bandwidth.${key}`)}
              </option>
            ))}
          </Field>
        </div>

        <div className="d-flex justify-content-between">
          <div />
          <div className="float-right">
            <Button title=" " type="submit" icon="save" text={this.props.text("common.save")} />
            {this.props.smallScreenMode && (
              <Button title=" " to={"/settings"} text={this.props.text("common.cancel")} />
            )}
          </div>
        </div>
        <div />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      audio: state.setting.user.WEB_AUDIO,
      video: state.setting.user.WEB_VIDEO,
      bandwidth: state.setting.user.WEB_BANDWIDTH,
    },
    audioAvailable: state.config.call.audio,
    videoAvailable: state.config.call.video,
    enableReinitialize: true,
    loading: state.setting.loading,
    text: state.config.text,
    media_enabled: state.config.features.settings.allowChangeMedia,
    configuredMedia: state.config.call.audio * 1 + state.config.call.video * 2,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSettings: values => {
      return dispatch(saveSettings(values));
    },
    loadSettings: () => {
      return dispatch(loadSettings());
    },
    clearSettingsSaved: () => {
      dispatch({ type: SETTINGS.SAVED_RESET });
    },
  };
};

Media = reduxForm({
  form: "media",
})(Media);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
