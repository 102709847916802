import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web app",
  "common.loading": "กำลังโหลด",
  "common.call": "โทร",
  "common.answer": "รับสาย",
  "common.reject": "ปฎิเสธ",
  "common.callback": "โทรกลับ",
  "common.callagain": "โทรอีกครั้ง",
  "common.yes": "ใช่",
  "common.no": "ไม่",
  "common.on": "เปิด",
  "common.off": "ปิด",
  "common.text": "ข้อความ",
  "common.abort": "ยกเลิก",
  "common.delete": "ลบ",
  "common.cancel": "ยกเลิก",
  "common.missedcall": "สายไม่ได้รับจาก",
  "common.save": "บันทึก",
  "common.hangup": "วางสาย",
  "common.add": "เพิ่ม",
  "common.firstname": "ชื่อ",
  "common.lastname": "นามสกุล",
  "common.sipaddress": "ที่อยู่ SIP",
  "common.email": "อีเมล์",
  "common.address": "ที่อยู่",
  "common.city": "เมือง",
  "common.postcode": "รหัสไปรษณีย์",
  "common.calling": "กำลังโทร",
  "common.dialer": "ปุ่มกด",
  "common.back": "กลับ",
  "common.hide": "ซ่อน",
  "common.online": "อยู่ในระบบ",
  "common.video": "วีดีโอ",
  "common.audio": "เสียง",
  "common.reset": "ตั้งใหม่",
  "common.here": "ที่นี่",
  "common.username": "ชื่อผู้ใช้",
  "common.password": "รหัสผ่าน",
  "common.send": "ส่ง",
  "common.password1": "รหัสผ่าน ",
  "common.password2": "ยืนยันรหัสผ่าน",
  "common.phonenumber": "โทรศัพท์",
  "common.sip": "ที่อยู่ SIP",
  "common.mobilenumber": "เบอร์มือถือ",
  "common.isdn": "หมายเลข ISDN",
  "common.3gnumber": "เบอร์โทร 3G",
  "common.fax": "แฟกซ์",
  "common.username_helptext": "ระหว่าง 5 ถึง 100 ตัวอักษร. เฉพาะ 0-9, .(จุด), -(ขีด) และ a-z(ตัวเล็ก) เท่านั้น.",
  "common.email_helptext": "",
  "common.password1_helptext": "ระหว่าง 6 ถึง 20 ตัวอักษร",
  "common.password2_helptext": "รหัสผ่านไม่ตรงกัน",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "ฟิลด์นี้มีอักขระที่ไม่ถูกต้องอย่างน้อยหนึ่งตัว",
  "common.link": "ลิงค์",
  "common.Link": "ลิงค์",
  "common.anonymous": "ไม่ระบุชื่อ",
  "common.edit": "แก้ไข",
  "common.contact_information": "ข้อมูลผู้ติดต่อ",
  "common.view_message": "ดูข้อความ",
  "common.close_message": "ปิดข้อความ",
  "common.required": "* ช่องที่ต้องกรอก",
  "common.to_startpage": "เพื่อเริ่มหน้า",
  "common.organisation": "องค์กร",
  "common.permissions.heading": "บริการต้องได้รับอนุญาตในการใช้กล้องและ / หรือไมโครโฟนของคุณ",
  "common.permissions.subheading":
    'คุณสามารถตัดสินใจได้ในภายหลังก่อนการโทรแต่ละครั้งว่าคุณต้องการใช้วิดีโอ / เสียงในการโทรหรือไม่<br>ผู้ใช้ Firefox ควร "จดจำการตัดสินใจนี้" เพื่อหลีกเลี่ยงคำถามที่คล้ายกันในภายหลัง<br>เมื่อคุณยอมรับสิทธิ์กล้อง / ไมโครโฟนจะเปิดใช้งานสองสามวินาทีจากนั้นจะปิด',
  "common.app_for_os": "%APPNAME% for %OS%",

  /** Titles */
  "titles.login": "เข้าสู่ระบบ",
  "titles.services": "บริการ",
  "titles.contacts": "ผู้ติดต่อของฉัน",
  "titles.call": "โทร",
  "titles.directory": "Business Directory",
  "titles.history": "ประวัติ",
  "titles.messages": "ข้อความ",
  "titles.settings": "การตั้งค่า",
  "titles.userinformation": "ข้อมูลส่วนตัว",
  "titles.log": "การแก้ไข",
  "titles.about": "ข้อมูลเกี่ยวกับระบบ",
  "titles.registration": "ลงทะเบียน",
  "titles.newpassword": "ขอรหัสผ่านใหม่",
  "titles.resetpassword": "ตั้งรหัสผ่านใหม่",
  "titles.verifyaccount": "เปิดใช้งานบัญชี",

  /** Toolbar */
  "toolbar.hidetext": "ปิดข้อความ",
  "toolbar.showtext": "เปิดข้อความ",
  "toolbar.micoff": "ปิดไมค์",
  "toolbar.micon": "เปิดไมค์",
  "toolbar.audiooff": "ปิดเสียง",
  "toolbar.audioon": "เปิดเสียง",
  "toolbar.videooff": "ปิดวิดีโอสายเรียกเข้า",
  "toolbar.videoon": "เปิดวิดีโอสายเรียกเข้า",
  "toolbar.cameraoff": "ปิดกล้องวิดีโอ",
  "toolbar.cameraon": "เปิดกล้องวิดีโอ",
  "toolbar.fullscreenoff": "ปิดเต็มหน้าจอ",
  "toolbar.fullscreenon": "เปิดเต็มหน้าจอ",
  "toolbar.selfviewoff": "ปิดวิดีโอแสดงตัวเอง",
  "toolbar.selfviewon": "เปิดวิดีโอแสดงตัวเอง",
  "toolbar.hidedialpad": "ปิดปุ่มกดหมายเลข",
  "toolbar.showdialpad": "เปิดปุ่มกดหมายเลข",
  "toolbar.useaudioon": "เปิดการโทรแบบเสียง",
  "toolbar.useaudiooff": "ปิดการโทรแบบเสียง",
  "toolbar.usevideoon": "เปิดการโทรแบบวิดีโอ",
  "toolbar.usevideooff": "ปิดการโทรแบบวิดีโอ",
  "toolbar.usetexton": "เปิดการโทรแบบข้อความ",
  "toolbar.usetextoff": "ปิดการโทรแบบข้อความ",
  "toolbar.more": "เพิ่มเติม",
  "toolbar.hide": "ซ่อน",
  "toolbar.togglevideo": "สลับวิดีโอ",
  "toolbar.toggletext": "สลับข้อความ",
  "toolbar.toSettings": "ไปที่การตั้งค่า%",

  /** Menu */
  "menu.logo": "หน้าหลัก",
  "menu.services": "บริการ",
  "menu.servicesHeadline": "บริการ",
  "menu.contacts": "ผู้ติดต่อ",
  "menu.mycontacts": "ผู้ติดต่อของฉัน",
  "menu.call": "โทร",
  "menu.directory": "Business Directory",
  "menu.history": "ประวัติ",
  "menu.messages": "กล่องข้อความ",
  "menu.settings": "ตั้งค่า",
  "menu.logout": "ออกจากระบบ",
  "menu.userinformation": "บัญชี",
  "menu.userprofile": "ข้อมูลส่วนตัว",
  "menu.log": "ข้อมูลการใช้งาน",
  "menu.about": "ข้อมูลเกี่ยวกับระบบ",

  /** Login screen */
  "login.loginanonymous.headline": "คลิกที่นี่เพื่อโทรออกแบบไม่ระบุตัวตน",
  "login.loginanonymous": "ล็อกอินแบบไม่ระบุตัวตน",
  "login.headline": "ล็อกอิน",
  "login.login": "ล็อกอิน",
  "login.loginfailed": "ล็อกอินไม่สำเร็จ",
  "login.resetpassword": "คุณลืมรหัสผ่านหรือไม่?",
  "login.registration": "ต้องการสมัครสมาชิกหรือไม่?",
  "login.nowebrtc": "เบราว์เซอร์ไม่รองรับการสนทนาเสียงและวิดีโอแบบ Real-time (WebRTC).",
  "login.debug": "โหลดแก้ไขข้อบกพร่อง",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
  "error.usernameNotExists": "ชื่อผู้ใช้มีในระบบแล้ว",
  "error.noservercontact": "ไม่สามารถติดต่อเซิร์ฟเวอร์ได้",
  "error.accountnotactive": "บัญชีไม่ได้ใช้งาน",
  "error.accountexpired": "บัญชีหมดอายุ",
  "error.tokenexpired": "เซสชันการตรวจสอบสิทธิ์หมดอายุโปรดลงชื่อเข้าใช้อีกครั้ง",
  "error.tokeninvalid": "โทเค็นการรับรองความถูกต้องไม่ถูกต้อง",
  "error.unknown": "ความผิดพลาดที่ไม่รู้จัก",
  "error.unknownsender": "ไม่ทราบผู้ส่ง",
  "error.unknownsip": "ไม่ทราบ SIP",
  "error.notextsent": "ไม่มีข้อความที่ส่ง",
  "error.acquiremedia":
    "คุณไม่สามารถโทรออกแบบ เสียง/วิดีโอ ถ้าคุณไม่อนุญาตให้ใช้งานอุปกรณ์ไมค์และกล้อง. ถ้าคุณต้องการโทรออกแบบไม่ใช้ เสียง/วิดีโอ กรุณาเอาเครื่องหมายถูกออกที่หน้าตั้งค่า.",
  "error.mediaNotReadable":
    "ไม่สามารถเข้าถึงอุปกรณ์เสียงหรือวิดีโอได้ โปรดตรวจสอบว่าไม่ได้ใช้งานโดยแอปพลิเคชันอื่นใดและรีเฟรชหน้านี้",
  "error.requestedDeviceNotFound": "ไม่พบอุปกรณ์สื่อที่ร้องขอ โปรดตรวจสอบให้แน่ใจว่าได้เชื่อมต่ออย่างถูกต้อง",
  "error.cantcall": "คุณไม่สามารถโทรออกเมื่อคุณไม่ได้ติดต่อกับระบบ",
  "error.cantcall_without_media": "คุณไม่สามารถโทรออกโดยไม่มีเสียงวิดีโอหรือข้อความ",
  "error.404": "404 - หน้าที่คุณกำลังค้นหาไม่มีอยู่",
  "error.unsupportedbrowser": "เว็บเบราว์เซอร์ของคุณไม่รองรับโปรดใช้เว็บเบราว์เซอร์อื่น",
  "error.browseroutofdate": "เว็บเบราว์เซอร์ของคุณล้าสมัย โปรดอัปเดตเป็นเวอร์ชันล่าสุดเพื่อใช้แอปพลิเคชัน",
  "error.browseroutofdate.ios.15.1": "ไม่รองรับ iOS เวอร์ชันนี้ โปรดอัปเดตเป็น iOS 15.2 เป็นอย่างน้อย",
  "error.browserwarning.closeAndDontShowAgain": "ปิดแล้วไม่ต้องแสดงอีก",
  "error.browserwarning.macos":
    "หากคุณประสบปัญหาในการโทรออกจาก iPad ของคุณและกำลังใช้ iOS เวอร์ชัน 15.1 โปรดอัปเกรดเป็น iOS 15.2 หรือใหม่กว่า",

  /** User settings */
  "settings.white": "ขาว",
  "settings.lightgrey": "เทาอ่อน",
  "settings.darkgrey": "เทาเข้ม",
  "settings.black": "ดำ",
  "settings.yellow": "เหลียง",
  "settings.blue": "น้ำเงิน",
  "settings.red": "แดง",
  "settings.green": "เขียว",
  "settings.purple": "ม่วง",
  "settings.orange": "ส้ม",
  "settings.pink": "ชมพู",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida Sans Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic Sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "แยกข้อความซ้าย-ขวา",
  "settings.overunder": "แยกข้อความบน-ล่าง",
  "settings.one": "รวมข้อความในหน้าต่างเดียวกัน",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Bell",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Classic",
  "settings.settings": "การตั้งค่า",
  "settings.text": "ข้อความ",
  "settings.font": "ตัวอักษร",
  "settings.fontsize": "ขนาดตัวอักษร",
  "settings.textboxlayout": "ขนาดข้อความ",
  "settings.mycolors": "หน้าต่างข้อความของฉัน",
  "settings.incomingcolors": "หน้าต่างข้อความเข้า",
  "settings.sharedcolors": "หน้าต่างข้อความใช้ร่วมกัน",
  "settings.textcolors": "สีข้อความ",
  "settings.backgroundcolors": "สีพื้นหลัง",
  "settings.preview": "แบบตัวอย่าง",
  "settings.loremipsum": "ข้อความสำหรับทดสอบ (ศูนย์บริการถ่ายทอดการสื่อสารแห่งประเทศไทย TTRS)",
  "settings.previewmytext": "ข้อความของฉัน",
  "settings.previewincomingtext": "ข้อความเข้า",
  "settings.previewbothtext": "ข้อความของฉัน, ข้อความเข้า",
  "settings.incomingcalls": "สายเรียกเข้า",
  "settings.messagebox": "กล่องข้อความ",
  "settings.autoanswer": "ใช้ข้อความตัวอักษร เมื่อท่านไม่ตอบ",
  "settings.autoanswermessage": "ข้อความ",
  "settings.duringcalls": "ระหว่างโทร",
  "settings.colors": "สี",
  "settings.textformat": "ตัวอักษร",
  "settings.media": "ภาพ",
  "settings.audio": "เสียง",
  "settings.video": "วีดีโอ",
  "settings.ringtone": "เสียงเรียกเข้า",
  "settings.mediamail": "ข้อความ",
  "settings.about": "ระบบข้อมูล",
  "settings.logout": "ออกจากระบบ",
  "settings.logout.anonymous": "ออกจากระบบ",
  "settings.saved": "บันทึกการตั้งค่า",
  "settings.media_explanation":
    "การตั้งค่าเหล่านี้ช่วยให้คุณสามารถเลือกวิธีการโทรของคุณ หากหนึ่งในการตั้งค่าเหล่านี้เปิดอยู่คุณสามารถปิดการใช้งานสายชั่วคราวได้ อย่างไรก็ตามหากปิดอยู่จะไม่สามารถเปิดใช้งานการโทรได้",
  "settings.useaudio": "ติดต่อโดยเสียง",
  "settings.usevideo": "ติดต่อโดยวิดีโอ",
  "settings.bandwidth.label": "แบนด์วิดธ์",
  "settings.bandwidth.high": "สูง",
  "settings.bandwidth.medium": "ปานกลาง",
  "settings.bandwidth.low": "ต่ำ",

  /** Messages */
  "messages.messages": "ข้อความ",
  "messages.unreadmessages": "ไม่สามารถอ่าน",
  "messages.readmessages": "อ่าน",
  "messages.nomessages": "คุณไม่มีข้อความ",
  "messages.deleteallread": "ลบข้อความอ่านแล้วทั้งหมด",
  "messages.deleteallreadconfirm": "ต้องการลบข้อความอ่านแล้วทั้งหมด?",
  "messages.messageAlert": "ลบข้อความแล้ว",

  /** Contacts */
  "contacts.contacts": "ผู้ติดต่อของฉัน",
  "contacts.addcontact": "เพิ่มผู้ติดต่อ",
  "contacts.deletecontact": "ลบผู้ติดต่อ",
  "contacts.nosipgiven": "โปรดระบุหมายเลข SIP ของผู้ติดต่อ",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "เขียน หมายเลข/ชื่อ ที่นี่",
  "call.incomingtext": "ข้อความตัวอักษรเข้า",
  "call.outgoingtext": "ข้อความตัวอักษรออก",
  "call.incoming": "ติดต่อเข้ามาจาก",
  "call.unknownnumber": "ไม่ทราบหมายเลข",
  "call.unknownnumber_uppercase": "ไม่ทราบหมายเลข",
  "call.endedcall": "จบการสนทนา",
  "call.yourejected": "คุณปฏิเสธการสนทนา",
  "call.noanswer": "ไม่มีการตอบรับ",
  "call.rejected": "ไม่สามารถตอบได้ในขณะนี้",
  "call.notfound": "ไม่สามารถเข้าถึงได้",
  "call.toolongtext": "\n<ตัวอักษรมากเกินไป. สูงสุด 1000.>\n",
  "call.notificationbody": "กดที่นี่เพื่อตอบ",
  "call.videoTextDisabled": "ปิดข้อความและวิดีโอเข้า",
  "call.writeHere": "เขียนที่นี่...",
  "call.receiveHere": "ข้อความที่เข้ามาจะปรากฎที่นี่",
  "call.establishing": "กำลังโทร...",

  /** History screen */
  "history.history": "ประวัติ",

  /** Reset password */
  "resetpassword.processing": "กำลังประมวลผล ...",
  "resetpassword.completed": "การเปลี่ยนรหัสผ่านสำเร็จ",
  "resetpassword.email_or_username": "อีเมล์หรือชื่อผู้ใช้",
  "resetpassword.title": "รหัสผ่านใหม่",
  "resetpassword.explanation": "ใส่อีเมลหรือชื่อผู้ใช้ของคุณ ระบบจะส่งชื่อผู้ใช้และรหัสผ่านไปยังอีเมลคุณ",
  "resetpassword.explanation_complete": "คุณจะได้รับอีเมลพร้อมคำแนะนำสั้น ๆ.",
  "resetpassword.invalid_link": "ลิงก์สำหรับเปลี่ยนรหัสผ่านไม่ถูกต้อง",
  "resetpassword.password_changed": "รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว",
  "resetpassword.enter_new_password": "ใส่รหัสผ่านใหม่",
  "resetpassword.button_reset": "เปลี่ยนรหัสผ่าน",
  "resetpassword.password": "รหัสผ่าน",
  "resetpassword.password_repeat": "ยืนยันรหัสผ่าน",
  "resetpassword.password_requirement": "รหัสผ่านต้องมีความยาว 6 ถึง 20 ตัวอักษร",
  "resetpassword.error": "ไม่สามารถเปลี่ยนรหัสผ่านได้ โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของคุณ",
  "resetpassword.link_too_old": "ลิงก์เก่าเกินไป",

  /** Registration */
  "registration.error": "ไม่สามารถสมัครสมาชิกได้ โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของคุณ",
  "registration.title": "สมัครสมาชิก",
  "registration.processing": "กำลังประมวลผล...",
  "registration.not_enabled": "ยังไม่เปิดใช้งานสมัครสมาชิก",
  "registration.completed_headline": "คุณเป็นสมาชิกแล้ว",
  "registration.completed": "การสมัครสมาชิกของคุณสำเร็จ",
  "registration.completed_admin_approval":
    "บัญชีของคุณต้องได้รับการอนุมัติจากผู้ดูแลระบบก่อน คุณจึงจะสามารถเข้าสู่ระบบได้",
  "registration.completed_verification":
    "บัญชีของคุณต้องได้รับการยืนยันก่อนจึงจะสามารถเข้าสู่ระบบได้ คุณจะได้รับอีเมลพร้อมคำแนะนำในไม่ช้า",
  "registration.completed_link": "/",
  "registration.username_exists": "ชื่อผู้ใช้นี้มีในระบบแล้ว กรุณาใช้ชื่อผู้ใช้อื่น",
  "registration.email_exists": "อีเมลนี้มีในระบบแล้ว กรุณาใช้อีเมลอื่น",
  "registration.sip_exists": "หมายเลข SIP นี้มีในระบบแล้ว กรุณาใช้หมายเลขอื่น",
  "registration.license1": "ใช่, ฉันได้อ่านแล้ว",
  "registration.license_link_text": " ข้อกำหนดเกี่ยวกับการเข้าถึงข้อมูลส่วนบุคุล",
  "registration.license2": " และยอมรับในข้อกำหนด",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "ยอมรับเงื่อนไขหากคุณต้องการลงทะเบียน",
  "registration.register": "ลงทะเบียน",

  /** Verify Account */
  "verifyaccount.completed.title": "บัญชีของคุณเปิดใช้งานแล้ว",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "บางอย่างผิดพลาด! โปรดลองลงทะเบียนบัญชีอีกครั้ง",
  "verifyaccount.error.expired": "ลิงก์การตรวจสอบหมดอายุ โปรดลองลงทะเบียนบัญชีอีกครั้ง",
  "verifyaccount.registration": "ต้องการสมัครสมาชิกหรือไม่?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "แก้ไขบัญชี",
  "changeuser.titlePassword": "เปลี่ยนรหัสผ่าน",
  "changeuser.newPassword": "รหัสผ่านใหม่",
  "changeuser.error": "ไม่สามารถแก้ไขบัญชีได้ โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของคุณ. ",
  "changeuser.complete": "แก้ไขบัญชี",
  "changeuser.processing": "กำลังประมวลผล...",
  "changeuser.username_exists": "ชื่อผู้ใช้นี้มีในระบบแล้ว กรุณาใช้ชื่อผู้ใช้อื่น.",
  "changeuser.email_exists": "อีเมลนี้มีในระบบแล้ว กรุณาใช้อีเมลอื่น.",
  "changeuser.email_empty": "ต้องระบุอีเมล กรุณากรอกอีเมล",
  "changeuser.sip_exists": "หมายเลข SIP นี้มีในระบบแล้ว กรุณาใช้หมายเลขอื่น.",

  /** Network */
  "network.networkError": "เกิดปัญหาการเชื่อมต่อกับเซิร์ฟเวอร์",
  "network.establishing": "กำลังพยายามสร้างการเชื่อมต่อกับเซิร์ฟเวอร์",
  "network.offline": "ออฟไลน์",

  /** Accessibility */
  "accessibility.darktheme": "ธีมสีเข้ม",
  "accessibility.originaltheme": "ธีมสีอ่อน",

  /** About */
  "about.user": "ชื่อผู้ใช้",
  "about.sip": "หมายเลข SIP",
  "about.server": "เซิร์ฟเวอร์ IP",
  "about.version": "เวอร์ชัน",
  "about.browser": "เบราว์เซอร์",
  "about.browserversion": "เบราว์เซอร์ เวอร์ชัน",
  "about.os": "ระบบปฏิบัติการ",

  /** Aria */
  "aria.mainmenu": "เมนูหลัก",
  "aria.submenu_contacts": "รายชื่อผู้ติดต่อ",
  "aria.submenu_account": "บัญชี",
  "aria.selected_page": "เลือกหน้า",
  "aria.back_button": "ลบอักษรตัวสุดท้าย",
  "aria.accept_terms": "ยอมรับเงื่อนไข",
  "aria.link_sound_video": "ไปที่การตั้งค่าสำหรับเสียงและวิดีโอ",
  "aria.selfview": "ดูวิดีโอด้วยตนเอง",
  "aria.in-call": "อยู่ระหว่างการโทร",

  /** Kiosk */
  "kiosk.call_ended": "การโทรสิ้นสุดลงคุณสามารถปิดหน้านี้ได้ในขณะนี้",

  /** Modal */
  "modal.close": "ปิด",
  "modal.cancel": "ยกเลิก",
  "modal.confirm": "ตกลง",
  "modal.logout_confirmation.title": "คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ?",
  "modal.logout_confirmation.confirm": "ใช่",
  "modal.logout_confirmation.cancel": "ไม่ ยกเลิก",
};

export default translations;
