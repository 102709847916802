import React from "react";
import { withRouter } from "react-router-dom";
import "./Button.css";

class Button extends React.Component {
  btn = (props, click) => {
    var typeNew = props.type ? props.type : "button";
    var size = props.size ? props.size : "24";
    var btnSize = props.btnSize ? props.btnSize : "";
    var disabled = props.disabled ? props.disabled : false;
    var classes = props.className !== undefined ? props.className : "";

    classes = classes + (props.color === "green" ? " green-button" : props.color === "red" ? " red-button" : "");
    var ariaLabel = props.ariaLabelText ? props.ariaLabelText : null;

    var title;
    switch (props.title) {
      case " ":
        title = "";
        break;
      case "":
        title = props.title;
        break;
      default:
        title = props.text;
    }

    if (props.inverted && props.inverted === true) {
      return (
        <button
          title={title}
          className={"btn button " + btnSize + " " + classes}
          type={typeNew}
          onClick={click}
          aria-label={ariaLabel}
          disabled={disabled}
        >
          {props.icon && (
            <i
              aria-hidden="true"
              className={
                "material-icons button-icon button-icon md-" + size + (props.text ? "  margin-right-0_4em" : "")
              }
            >
              {props.icon}
            </i>
          )}
          {props.text && <span className="button-text">{props.text}</span>}
        </button>
      );
    } else {
      return (
        <button
          title={title}
          className={"btn button " + btnSize + " " + classes}
          type={typeNew}
          onClick={click}
          aria-label={ariaLabel}
          disabled={disabled}
        >
          {props.icon && (
            <i
              aria-hidden="true"
              className={"material-icons button-icon md-" + size + (props.text ? "  margin-right-0_4em" : "")}
            >
              {props.icon}
            </i>
          )}
          {props.text && <span className="button-text">{props.text}</span>}
        </button>
      );
    }
  };

  render() {
    if (this.props.to) {
      return this.btn(this.props, () => {
        this.props.history.push(this.props.to);
      });
    }

    if (this.props.onClick) {
      return this.btn(this.props, () => {
        this.props.onClick();
      });
    }

    return this.btn(this.props);
  }
}

export default withRouter(Button);
