const findContactFromSip = (services, contacts, sip) => {
  for (var i = 0; i < contacts.length; i++) {
    if (contacts[i].sipaddress === sip) {
      if (contacts[i].fname && contacts[i].sname) {
        return contacts[i].fname + " " + contacts[i].sname;
      } else if (contacts[i].fname) {
        return contacts[i].fname;
      } else if (contacts[i].sname) {
        return contacts[i].sname;
      }
    }
  }
  for (i = 0; i < services.length; i++) {
    if (services[i].sipaddress === sip) {
      return services[i].name;
    }
  }
  return sip;
};

export default findContactFromSip;
