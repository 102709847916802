// System State Actions
export enum APP {
  SET_LOCALE = "SET_LOCALE",
  LOG = "LOG",
  DEBUG = "DEBUG",
  DEBUGVIEW = "DEBUGVIEW",
  SET_LOADING = "SET_LOADING",
  SHOW_PERMISSIONS_OVERLAY = "SHOW_PERMISSIONS_OVERLAY",
  HIDE_PERMISSIONS_OVERLAY = "HIDE_PERMISSIONS_OVERLAY",
  UNSUPPORTED_BROWSER = "UNSUPPORTED_BROWSER",
  TOGGLE_CONTRAST = "TOGGLE_CONTRAST",
  TOGGLE_NAVBAR = "TOGGLE_NAVBAR",
  SCREEN_IS_SMALL = "SCREEN_IS_SMALL",
  IS_IN_BACKGROUND = "IS_IN_BACKGROUND",
  UPDATE_PAGE_TITLE = "UPDATE_PAGE_TITLE",
}

// Alert Actions
export enum ALERT {
  ADD = "ALERT_ADD",
  REMOVE = "ALERT_REMOVE",
  CLEAR_ALL = "ALERT_CLEAR_ALL",
}

// Modal Actions
export enum MODAL {
  SHOW = "MODAL_SHOW",
  HIDE = "MODAL_HIDE",
}

// Notification Actions
export enum NOTIFICATION {
  TRIGGER = "NOTIFICATION_TRIGGER",
  REMOVE = "NOTIFICATION_REMOVE",
}

// Contact Actions
export enum CONTACTS {
  LOADING = "CONTACTS_LOADING",
  LOADED = "CONTACTS_LOADED",
  UPDATE_LOCALLY = "UPDATE_CONTACT_LOCALLY",
  DELETE_LOCALLY = "DELETE_CONTACT_LOCALLY",
  REMOVE_LOADING = "CONTACT_REMOVE_LOADING",
  NO_SIP_GIVEN = "CONTACT_NO_SIP_GIVEN",
  CLEAR_ALERT = "CONTACT_CLEAR_ALERT",
}

// Business Directory Actions
export enum DIRECTORY {
  LOADING = "DIRECTORY_LOADING",
  LOADED = "DIRECTORY_LOADED",
}

// Message Actions
export enum MESSAGES {
  LOADING = "MESSAGES_LOADING",
  LOADED = "MESSAGES_LOADED",
  DELETED_ALERT = "MESSAGES_DELETED_ALERT",
  CLEAR_ALERT = "MESSAGES_CLEAR_ALERT",
}

// History and Call Actions
export enum CALLS {
  LOADING = "CALLS_LOADING",
  LOADED = "CALLS_LOADED",
  CALL_TO_UNREGISTERED = "CALL_TO_UNREGISTERED",
  CHANGE_VIEW = "CHANGE_VIEW",
  ANSWER = "CALL_ANSWER",
  CALL = "CALL_CALL",
  HANGUP = "CALL_HANGUP",
  DTMF = "CALL_DTMF",
  REJECT = "CALL_REJECT",
  TEXT_MSG_IN = "CALL_TEXT_MSG_IN",
  TEXT_MSG_IN_CLEAR = "CALL_TEXT_MSG_IN_CLEAR",
  TEXT_MSG_IN_DEL = "CALL_TEXT_MSG_IN_DEL",
  TEXT_MSG_OUT = "CALL_TEXT_MSG_OUT",
  TEXT_MSG_OUT_CLEAR = "CALL_TEXT_MSG_OUT_CLEAR",
  TEXT_MSG_OUT_DEL = "CALL_TEXT_MSG_OUT_DEL",
  MUTE_AUDIO = "CALL_MUTE_AUDIO",
  MUTE_REMOTE_AUDIO = "CALL_MUTE_REMOTE_AUDIO",
  MUTE_VIDEO = "CALL_MUTE_VIDEO",
  HIDE_VIDEO = "CALL_HIDE_VIDEO",
  TOGGLE_TEXT = "CALL_TOGGLE_TEXT",
  HIDE_DIALER = "CALL_HIDE_DIALER",
  HIDE_SELFVIEW = "CALL_HIDE_SELFVIEW",
  SHOW_MORE = "CALL_SHOW_MORE",
  TOGGLE_VIDEO_MODE = "CALL_TOGGLE_VIDEO_MODE",
  TOGGLE_FULLSCREEN = "CALL_TOGGLE_FULLSCREEN",
  KNOCK_KNOCK = "CALL_KNOCK_KNOCK",
}

// Service Actions
export enum SERVICES {
  LOADING = "SERVICES_LOADING",
  LOADED = "SERVICES_LOADED",
}

// Config Actions
export enum CONFIG {
  SET = "CONFIG_SET",
  DEBUG_ENABLE = "CONFIG_DEBUG_ENABLE",
  SYSTEM_LOADING = "CONFIG_SYSTEM_LOADING",
  SYSTEM_LOADED = "CONFIG_SYSTEM_LOADED",
  INIT_REGISTRATION = "CONFIG_INIT_REGISTRATION",
  INIT_COMPLETED = "CONFIG_INIT_COMPLETED",
  REQUIREMEDIA_STARTED = "CONFIG_REQUIREMEDIA_STARTED",
  REQUIREMEDIA_SUCCESS = "CONFIG_REQUIREMEDIA_SUCCESS",
  REQUIREMEDIA_FAILURE = "CONFIG_REQUIREMEDIA_FAILURE",
  SET_BROWSER_SUPPORT = "CONFIG_SET_BROWSER_SUPPORT",
  LOCAL_IP_AQUIRED = "LOCAL_IP_AQUIRED",
  BIND_LOCAL_STREAM = "BIND_LOCAL_STREAM",
  UNBIND_LOCAL_STREAM = "UNBIND_LOCAL_STREAM",
}

// User Actions
export enum USER {
  LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  REFRESH = "USER_REFRESH",
  LOGIN_FAILED = "USER_LOGIN_FAILED",
  LOGIN_FAILED_TOKEN_INVALID = "USER_LOGIN_FAILED_TOKEN_INVALID",
  LOGIN_FAILED_TOKEN_EXPIRED = "USER_LOGIN_FAILED_TOKEN_EXPIRED",
  LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS",
  LOGINFORM_VALIDATE = "USER_LOGINFORM_VALIDATE",
}

// Change User Actions
export enum CHANGE_USER {
  LOADING = "CHANGE_USER_LOADING",
  INIT = "CHANGE_USER_INIT",
  CLEAR = "CHANGE_USER_CLEAR",
  SUCCESS = "CHANGE_USER_SUCCESS",
  ERROR = "CHANGE_USER_ERROR",
}

// Registration Actions
export enum REGISTRATION {
  REGISTRATION_INIT = "REGISTRATION_INIT",
  REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS",
  REGISTRATION_ERROR = "REGISTRATION_ERROR",
  REGISTRATION_RECAPTCHA_VALID = "REGISTRATION_RECAPTCHA_VALID",
  REGISTRATION_RECAPTCHA_INVALID = "REGISTRATION_RECAPTCHA_INVALID",
  REGISTRATION_CLEAR = "REGISTRATION_CLEAR",
  CONFIG_INIT_REGISTRATION = "CONFIG_INIT_REGISTRATION",
}

// Password Actions
export enum PASSWORD {
  RESET_INIT = "RESETPASSWORD_INIT",
  RESET_CLEAR = "RESETPASSWORD_CLEAR",
  RESET_SUCCESS = "RESETPASSWORD_SUCCESS",
  RESET_ERROR = "RESETPASSWORD_ERROR",
  RESET_EMAIL_USERNAME_VALID = "RESETPASSWORD_EMAILUSERNAME_VALID",
  RESET_EMAIL_USERNAME_INVALID = "RESET_EMAIL_USERNAME_INVALID",
  RESET_RECAPTCHA_VALID = "RESETPASSWORD_RECAPTCHA_VALID",
  RESET_RECAPTCHA_INVALID = "RESETPASSWORD_RECAPTCHA_INVALID",
  NEW_INIT = "NEWPASSWORD_INIT",
  NEW_SUCCESS = "NEWPASSWORD_SUCCESS",
  NEW_ERROR = "NEWPASSWORD_ERROR",
  NEW_RESET = "NEWPASSWORD_RESET",
  RESETPASSWORD_RECAPTCHA_VALID = "RESETPASSWORD_RECAPTCHA_VALID",
  RESETPASSWORD_EMAILUSERNAME_VALID = "RESETPASSWORD_EMAILUSERNAME_VALID",
}

// Sip Actions
export enum SIP_ACTION {
  REGISTER_SUCCESS = "SIP_REGISTER_SUCCESS",
  CALLING = "SIP_CALLING",
  REJECTED_REMOTE = "SIP_CALL_REJECTED_REMOTE",
  CALL_ACCEPTED_REMOTE = "SIP_CALL_ACCEPTED_REMOTE",
  CALL_COMPLETED = "SIP_CALL_COMPLETED",
  CALL_TERMINATED_REMOTE = "SIP_CALL_TERMINATED_REMOTE",
  CALL_TERMINATED_LOCAL = "SIP_CALL_TERMINATED_LOCAL",
  REGISTER_STARTED = "SIP_REGISTER_STARTED",
  CALL_CANCELED_REMOTE = "SIP_CALL_CANCELED_REMOTE",
  CALL_FAILED = "SIP_CALL_FAILED",
  CALL_FAILED_REMOTE = "SIP_CALL_FAILED_REMOTE",
  INVITE_RECIEVED = "SIP_INVITE_RECIEVED",
  REGISTER_FAILED = "SIP_REGISTER_FAILED",
  UNREGISTERED = "SIP_UNREGISTERED",
  CALL_ACCEPTED_LOCAL = "SIP_CALL_ACCEPTED_LOCAL",
  CALL_SET_NEGOTIATED = "SIP_CALL_SET_NEGOTIATED",
  ONLINE = "SIP_ONLINE",
  MESSAGE_SENT = "SIP_MESSAGE_SENT",
  WS_PING = "SIP_WS_PING",
  CALL_REJECTED_LOCAL = "SIP_CALL_REJECTED_LOCAL",
  CALL_CANCELED_LOCAL = "SIP_CALL_CANCELED_LOCAL",
  SIP_ERROR_ALERT = "SIP_ERROR_ALERT",
  SIP_CLEAR_ALERT = "SIP_CLEAR_ALERT",
}

// Error Actions
export enum ERROR {
  NETWORK_ERROR_ALERT = "NETWORK_ERROR_ALERT",
  NETWORK_CLEAR_ALERT = "NETWORK_CLEAR_ALERT",
  SIP_ERROR_ALERT = "SIP_ERROR_ALERT",
  SIP_CLEAR_ALERT = "SIP_CLEAR_ALERT",
}

// Setting Actions
export enum SETTINGS {
  USER_LOADING = "SETTINGS_USER_LOADING",
  USER_LOADED = "SETTINGS_USER_LOADED",
  USER_LOADED_DEFAULT = "SETTINGS_USER_LOADED_DEFAULT",
  SAVE = "SETTINGS_SAVE",
  SAVED_RESET = "SETTINGS_SAVED_RESET",
}

// CONNECTION_WEBSOCKET Actions
export enum CONNECTION_WEBSOCKET {
  LOADED_FROM_SESSION = "CONNECTION_WEBSOCKET_LOADED_FROM_SESSION",
  LOGIN_SUCCESS = "CONNECTION_WEBSOCKET_LOGIN_SUCCESS",
  ONLINE = "CONNECTION_WEBSOCKET_ONLINE",
  OFFLINE = "CONNECTION_WEBSOCKET_OFFLINE",
  MSG = "CONNECTION_WEBSOCKET_MSG",
}
