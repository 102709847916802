import React from "react";

const filterSelect = ({ items, selected, onSelect }) => {
  const buttons = items.map(item => (
    <button
      key={item.id}
      className={"btn button button-secondary m-1" + (selected === item.id ? " button-selected" : "")}
      onClick={onSelect(item.id)}
    >
      {item.name}
    </button>
  ));

  return buttons;
};

export default filterSelect;
