import Storage from "./storage";

const userSession = {
  /**
   * Check if the user is established or not.
   */
  isEstablished: (): boolean => {
    return Storage.session.get("user") && Storage.session.get("systemConfig");
  },

  /**
   * Get the current User.
   */
  getUser: (): string | null => {
    if (Storage.session.get("user")) {
      return JSON.parse(Storage.session.get("user"));
    }
  },

  /**
   * Get the system config.
   */
  getSystemConfig: (): string | null => {
    if (Storage.session.get("systemConfig")) {
      return JSON.parse(Storage.session.get("systemConfig"));
    }
  },

  /**
   * Get the local (actually the external) IP address for the client.
   */
  getLocalIpAddress: (): string | null => {
    if (Storage.session.get("localIpAddress") && Storage.session.get("localIpAddress") !== "undefined") {
      return JSON.parse(Storage.session.get("localIpAddress"));
    }
  },

  /**
   * Save the user to session storage.
   *
   * @param user The User object to save.
   */
  setUser: (user: unknown): void => {
    Storage.session.set("user", JSON.stringify(user));
  },

  /**
   * Save the system config to session storage.
   *
   * @param systemConfig The System Config object to save.
   */
  setSystemConfig: (systemConfig: unknown): void => {
    Storage.session.set("systemConfig", JSON.stringify(systemConfig));
  },

  /**
   * Save the clients IP address to session storage.
   *
   * @param localIpAddress The IP address.
   */
  setLocalIpAddress: (localIpAddress: string): void => {
    Storage.session.set("localIpAddress", JSON.stringify(localIpAddress));
  },

  /**
   * Remove the user from session storage.
   */
  removeUser: (): void => {
    Storage.session.set("user", null);
  },
};

export default userSession;
