import ILanguage from "../models/Language/ILanguage";

const translations: ILanguage = {
  /** Common */
  "common.appOwner": "MMX",
  "common.copyright": "© nWise %YEAR%",
  "common.appName": "Web applicatie",
  "common.loading": "Bezig met laden",
  "common.call": "Telefoontje",
  "common.answer": "Antwoord",
  "common.reject": "Afwijzen",
  "common.callback": "Bel terug",
  "common.callagain": "Bel opnieuw",
  "common.yes": "Ja",
  "common.no": "Nee",
  "common.on": "Aan",
  "common.off": "Uit",
  "common.text": "Tekst",
  "common.abort": "Abortus",
  "common.delete": "Verwijder",
  "common.cancel": "Annuleren",
  "common.missedcall": "Gemiste oproep van",
  "common.save": "Opslaan",
  "common.hangup": "Ophangen",
  "common.add": "Toevoegen",
  "common.firstname": "Voornaam",
  "common.lastname": "Achternaam",
  "common.sipaddress": "SIP-adres",
  "common.email": "E-mail",
  "common.address": "Adres",
  "common.city": "Stad",
  "common.postcode": "Postcode",
  "common.calling": "Roeping",
  "common.dialer": "Dialer",
  "common.back": "Terug",
  "common.hide": "Verbergen",
  "common.online": "Online",
  "common.video": "Video",
  "common.audio": "Audio",
  "common.reset": "Reset",
  "common.here": "hier",
  "common.username": "Gebruikersnaam",
  "common.password": "Wachtwoord",
  "common.send": "Sturen",
  "common.password1": "Wachtwoord",
  "common.password2": "Herhaal wachtwoord",
  "common.phonenumber": "Telefoon",
  "common.sip": "SIP-adres",
  "common.mobilenumber": "Mobiel",
  "common.isdn": "ISDN-nummer",
  "common.3gnumber": "3G-nummer",
  "common.fax": "Fax",
  "common.username_helptext":
    "Tussen 5 en 100 tekens. Alleen 0-9,. (Punt), - (streepje) en a-z (kleine waarden) zijn toegestaan.",
  "common.email_helptext": "",
  "common.password1_helptext": "Tussen 6 en 20 tekens",
  "common.password2_helptext": "Wachtwoorden zijn niet gelijk",
  "common.firstname_helptext": "",
  "common.lastname_helptext": "",
  "common.address_helptext": "",
  "common.postcode_helptext": "",
  "common.city_helptext": "",
  "common.phonenumber_helptext": "",
  "common.sip_helptext": "",
  "common.mobilenumber_helptext": "",
  "common.isdn_helptext": "",
  "common.3gnumber_helptext": "",
  "common.fax_helptext": "",
  "common.invalid_characters": "Het veld bevat een of meer ongeldige tekens",
  "common.link": "link",
  "common.Link": "Link",
  "common.anonymous": "Anoniem",
  "common.edit": "Bewerk",
  "common.contact_information": "Contactgegevens",
  "common.view_message": "Bekijk bericht",
  "common.close_message": "Bericht sluiten",
  "common.required": "* Verplicht veld",
  "common.to_startpage": "Om de pagina te starten",
  "common.organisation": "Organisatie",
  "common.permissions.heading": "De dienst heeft toestemming nodig om je camera en / of microfoon te gebruiken.",
  "common.permissions.subheading":
    'U kunt later voor elk gesprek beslissen of u video / audio in het gesprek wilt gebruiken of niet.<br>Firefox-gebruikers wordt aangeraden "Onthoud deze beslissing" om soortgelijke vragen later te vermijden.<br>Als u de toestemmingen accepteert, wordt de camera / microfoon enkele seconden geactiveerd en vervolgens uitgeschakeld.',
  "common.app_for_os": "%APPNAME% voor %OS%",

  /** Titles */
  "titles.login": "Log in",
  "titles.services": "Diensten",
  "titles.contacts": "Mijn contacten",
  "titles.call": "Telefoontje",
  "titles.directory": "Business Directory",
  "titles.history": "Geschiedenis",
  "titles.messages": "Berichten",
  "titles.settings": "Instellingen",
  "titles.userinformation": "Mijn profiel",
  "titles.log": "Debug",
  "titles.about": "Informatie over systemen",
  "titles.registration": "Registratie",
  "titles.newpassword": "Krijg een nieuw wachtwoord",
  "titles.resetpassword": "Wachtwoord opnieuw instellen",
  "titles.verifyaccount": "Activeer account",

  /** Toolbar */
  "toolbar.hidetext": "Tekst is UIT",
  "toolbar.showtext": "Tekst is AAN",
  "toolbar.micoff": "Microfoon is UIT",
  "toolbar.micon": "Microfoon is AAN",
  "toolbar.audiooff": "Audio is UIT",
  "toolbar.audioon": "Audio is ingeschakeld",
  "toolbar.videooff": "Inkomende video is UIT",
  "toolbar.videoon": "Inkomende video is AAN",
  "toolbar.cameraoff": "Mijn video staat UIT",
  "toolbar.fullscreenoff": "Volledig scherm is UIT",
  "toolbar.fullscreenon": "Volledig scherm is AAN",
  "toolbar.cameraon": "Mijn video staat AAN",
  "toolbar.selfviewoff": "Selfview is UIT",
  "toolbar.selfviewon": "Selfview is AAN",
  "toolbar.hidedialpad": "Toetsenblok is AAN",
  "toolbar.showdialpad": "Het toetsenblok is UIT",
  "toolbar.useaudioon": "Bellen met geluid is AAN",
  "toolbar.useaudiooff": "Oproep met geluid is UIT",
  "toolbar.usevideoon": "Oproep met video is AAN",
  "toolbar.usevideooff": "Bellen met video is UIT",
  "toolbar.usetexton": "Bellen met tekst is AAN",
  "toolbar.usetextoff": "Bellen met tekst is UIT",
  "toolbar.more": "Meer",
  "toolbar.hide": "Verbergen",
  "toolbar.togglevideo": "Show video",
  "toolbar.toggletext": "Toon tekst",
  "toolbar.toSettings": "Ga naar %-instellingen",

  /** Menu */
  "menu.logo": "Naar de startpagina",
  "menu.services": "Diensten",
  "menu.servicesHeadline": "Diensten",
  "menu.contacts": "Contacten",
  "menu.mycontacts": "Mijn contacten",
  "menu.call": "Telefoontje",
  "menu.directory": "Business Directory",
  "menu.history": "Geschiedenis",
  "menu.messages": "Berichten",
  "menu.settings": "Instellingen",
  "menu.logout": "Uitloggen",
  "menu.userinformation": "Account",
  "menu.userprofile": "Mijn profiel",
  "menu.log": "Debug",
  "menu.about": "Informatie over systemen",

  /** Login screen */
  "login.loginanonymous.headline": "Klik hier voor anoniem bellen",
  "login.loginanonymous": "Anonieme login",
  "login.headline": "Log in",
  "login.login": "Log in",
  "login.loginfailed": "Aanmelden mislukt",
  "login.resetpassword": "Ben je je wachtwoord vergeten?",
  "login.registration": "Wilt u zich aanmelden?",
  "login.nowebrtc": "Uw browser ondersteunt geen real-time communicatie met video en audio (WebRTC).",
  "login.debug": "Foutopsporingsmodus",
  "login.optionalLoginInfo": "",
  "login.optionalLoginInfoLink": "",
  "login.optionalLogin": "",
  "login.optionalLoginLink": "",
  "login.optionalLogin2": "",
  "login.optionalLoginLink2": "",
  "login.optionalLogin3": "",
  "login.optionalLoginLink3": "",
  "login.handleData": "",
  "login.handleDataLink": "",
  "login.logo.href": "https://nwise.se/",
  "login.loggedin.logo.href": "/",

  /** Errors */
  "error.usernameorpassword": "Ongeldige gebruikersnaam of wachtwoord",
  "error.usernameNotExists": "De gebruikersnaam bestaat niet",
  "error.noservercontact": "Geen contact met de server",
  "error.accountnotactive": "Het account is niet actief",
  "error.accountexpired": "Account verlopen",
  "error.tokenexpired": "De authenticatiesessie is verlopen, log opnieuw in",
  "error.tokeninvalid": "Het verificatietoken is ongeldig",
  "error.unknown": "Onbekende fout",
  "error.unknownsender": "Onbekende afzender",
  "error.unknownsip": "Onbekende SIP",
  "error.notextsent": "Geen tekst verzonden",
  "error.acquiremedia":
    "U kunt geen audio- of videogesprek voeren als u geen toegang tot media toestaat. Als u zonder audio / video wilt bellen, haal deze dan uit de instellingen.",
  "error.mediaNotReadable":
    "Het audio- of videoapparaat is niet toegankelijk. Zorg ervoor dat ze niet door een andere applicatie worden gebruikt en vernieuw de pagina.",
  "error.requestedDeviceNotFound":
    "Het gevraagde media-apparaat is niet gevonden. Zorg ervoor dat het goed is aangesloten.",
  "error.cantcall": "U kunt niet bellen als u geen contact hebt met het systeem",
  "error.cantcall_without_media": "U kunt niet bellen zonder geluid, video of tekst",
  "error.404": "404 - De pagina waarnaar u op zoek bent, bestaat niet",
  "error.unsupportedbrowser": "Uw webbrowser wordt momenteel niet ondersteund. Gebruik een andere webbrowser",
  "error.browseroutofdate":
    "Uw webbrowser is verouderd. Werk het bij naar de nieuwste versie om de applicatie te gebruiken.",
  "error.browseroutofdate.ios.15.1": "Deze iOS-versie wordt niet ondersteund, update naar minimaal iOS 15.2.",
  "error.browserwarning.closeAndDontShowAgain": "Sluiten en niet meer weergeven",
  "error.browserwarning.macos":
    "Als u problemen ondervindt bij het bellen vanaf uw iPad en momenteel iOS-versie 15.1 gebruikt, upgrade dan naar iOS 15.2 of hoger.",

  /** User settings */
  "settings.white": "Wit",
  "settings.lightgrey": "Lichtgrijs",
  "settings.darkgrey": "Donker grijs",
  "settings.black": "Zwart",
  "settings.yellow": "Geel",
  "settings.blue": "Blauw",
  "settings.red": "Rood",
  "settings.green": "Groen",
  "settings.purple": "Paars",
  "settings.orange": "Oranje",
  "settings.pink": "Roze",
  "settings.helvetica": "Helvetica",
  "settings.times": "Times New Roman",
  "settings.arial": "Arial",
  "settings.trebuchet": "Trebuchet MS",
  "settings.lucida": "Lucida zonder Unicode",
  "settings.verdana": "Verdana",
  "settings.comicsans": "Comic sans",
  "settings.couriernew": "Courier New",
  "settings.sidebyside": "Zij aan zij",
  "settings.overunder": "Over en onder",
  "settings.one": "Gedeeld tekstvenster",
  "settings.smartphone": "Smartphone",
  "settings.bell": "Klok",
  "settings.marimba": "Marimba",
  "settings.glas": "Glas",
  "settings.classic": "Klassiek",
  "settings.settings": "Instellingen",
  "settings.text": "Tekst",
  "settings.font": "Lettertype",
  "settings.fontsize": "Lettertypegrootte",
  "settings.textboxlayout": "Tekst venster lay-out",
  "settings.mycolors": "Mijn tekstvenster",
  "settings.incomingcolors": "Inkomende tekst venster",
  "settings.sharedcolors": "Gedeeld tekstvenster",
  "settings.textcolors": "Tekst kleur",
  "settings.backgroundcolors": "Achtergrond kleur",
  "settings.preview": "Voorbeeld",
  "settings.loremipsum":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Tempor en vitaliteit, zodat de moeite en verdriet, een aantal belangrijke dingen te eiusmod doen.",
  "settings.previewmytext": "Mijn tekst",
  "settings.previewincomingtext": "Inkomende tekst",
  "settings.previewbothtext": "Mijn tekst, inkomende tekst",
  "settings.incomingcalls": "Inkomende oproepen",
  "settings.messagebox": "Berichten box",
  "settings.autoanswer": "Gebruik een sms-bericht als je niet antwoordt",
  "settings.autoanswermessage": "Het bericht",
  "settings.duringcalls": "Tijdens gesprek",
  "settings.colors": "Kleur",
  "settings.textformat": "Tekst",
  "settings.media": "Media",
  "settings.audio": "Geluid",
  "settings.video": "Video",
  "settings.ringtone": "Beltoon",
  "settings.mediamail": "Berichten",
  "settings.about": "Systeem informatie",
  "settings.logout": "Uitloggen",
  "settings.logout.anonymous": "Uitloggen",
  "settings.saved": "Instellingen zijn opgeslagen",
  "settings.media_explanation":
    "Met deze instellingen kunt u bepalen wat je tijdens de oproep kan inschakelen.  Als één van deze instellingen ingeschakeld is, kunt U deze tijdens een gesprek altijd tijdelijk uitschakelen, maar als deze ingesteld is op uit, kan je deze tijdens een gesprek niet worden ingeschakeld.",
  "settings.useaudio": "Bellen met geluid",
  "settings.usevideo": "Bellen met video",
  "settings.bandwidth.label": "Bandbreedte",
  "settings.bandwidth.high": "Hoog",
  "settings.bandwidth.medium": "Medium",
  "settings.bandwidth.low": "Laag",

  /** Messages */
  "messages.messages": "Berichten",
  "messages.unreadmessages": "Ongelezen",
  "messages.readmessages": "Lezen",
  "messages.nomessages": "Je hebt geen berichten",
  "messages.deleteallread": "Verwijder alle gelezen berichten",
  "messages.deleteallreadconfirm": "Alle gelezen berichten verwijderen?",
  "messages.messageAlert": "Bericht verwijderd",

  /** Contacts */
  "contacts.contacts": "Mijn contacten",
  "contacts.addcontact": "Contact toevoegen",
  "contacts.deletecontact": "Verwijder contact",
  "contacts.nosipgiven": "Geef het contact een SIP-adres",

  /** Business Directory */
  "directory.title": "Business Directory",
  "directory.featured": "Featured",
  "directory.search": "Search...",

  /** Call screen */
  "call.placeholder": "Voer hier nummer / naam in",
  "call.incomingtext": "Inkomende tekst",
  "call.outgoingtext": "Uitgaande tekst",
  "call.incoming": "Inkomende oproep van",
  "call.unknownnumber": "onbekend nummer",
  "call.unknownnumber_uppercase": "Onbekend nummer",
  "call.endedcall": "Beëindigde de oproep",
  "call.yourejected": "Je hebt de oproep afgewezen",
  "call.noanswer": "Geen antwoord",
  "call.rejected": "kon op dit moment geen antwoord geven",
  "call.notfound": "is niet bereikbaar",
  "call.toolongtext": "\n <Te veel tekens ingevoerd. Max. 1000.> \n",
  "call.notificationbody": "Klik hier om te antwoorden",
  "call.videoTextDisabled": "Tekst en inkomende video zijn UIT",
  "call.writeHere": "Schrijf hier...",
  "call.receiveHere": "Dit is waar de inkomende tekst zal verschijnen",
  "call.establishing": "Oproep starten...",

  /** History screen */
  "history.history": "Geschiedenis",

  /** Reset password */
  "resetpassword.processing": "Verwerken ...",
  "resetpassword.completed": "Wachtwoordreset is voltooid",
  "resetpassword.email_or_username": "E-mail of gebruikersnaam",
  "resetpassword.title": "Nieuw paswoord",
  "resetpassword.explanation":
    "Voer uw e-mailadres of gebruikersnaam in en een link wordt verzonden naar de e-mail of het e-mailadres dat is gekoppeld aan de gebruikersnaam. Volg de link om uw wachtwoord opnieuw in te stellen.",
  "resetpassword.explanation_complete": "U ontvangt binnenkort een e-mail met verdere instructies.",
  "resetpassword.invalid_link": "Ongeldige reset wachtwoordlink",
  "resetpassword.password_changed": "Uw wachtwoord is veranderd",
  "resetpassword.enter_new_password": "Voer een nieuw wachtwoord in",
  "resetpassword.button_reset": "Reset",
  "resetpassword.password": "Wachtwoord",
  "resetpassword.password_repeat": "Herhaal wachtwoord",
  "resetpassword.password_requirement": "Wachtwoord moet tussen de 6 en 20 tekens lang zijn",
  "resetpassword.error":
    "Het was niet mogelijk om het wachtwoord opnieuw in te stellen. Probeer het opnieuw of neem contact op met uw beheerder.",
  "resetpassword.link_too_old": "link is te oud",

  /** Registration */
  "registration.error":
    "Het was niet mogelijk om de registratie te voltooien. Probeer het opnieuw of neem contact op met uw beheerder.",
  "registration.title": "Registratie",
  "registration.processing": "Verwerken ...",
  "registration.not_enabled": "Registratie is niet ingeschakeld.",
  "registration.completed_headline": "U bent geregistreerd",
  "registration.completed": "Uw registratie is voltooid.",
  "registration.completed_admin_approval":
    "Uw account moet worden goedgekeurd door een beheerder voordat u kunt inloggen.",
  "registration.completed_verification":
    "Uw account moet worden bevestigd voordat u kunt inloggen. U ontvangt binnenkort een e-mail met instructies.",
  "registration.completed_link": "/",
  "registration.username_exists": "Gebruikersnaam bestaat. Selecteer een andere gebruikersnaam.",
  "registration.email_exists": "Email bestaat. Selecteer een andere e-mail.",
  "registration.sip_exists": "SIP-adres bestaat. Selecteer een ander slokadres.",
  "registration.license1": "Ja, ik heb gelezen",
  "registration.license_link_text": "de informatie over hoe wij omgaan met persoonlijke informatie",
  "registration.license2": "en accepteer de voorwaarden",
  "registration.licenselink": "https://nwise.se/om-oss/",
  "registration.info": " ",
  "registration.check_checkbox": "Accepteer de voorwaarden als u zich wilt laten registreren",
  "registration.register": "Registreren",

  /** Verify Account */
  "verifyaccount.completed.title": "Uw account is geactiveerd!",
  "verifyaccount.completed.body": "",
  "verifyaccount.error.generic": "Er is iets fout gegaan! Probeer het account opnieuw te registreren.",
  "verifyaccount.error.expired": "De verificatie link is verlopen. Probeer het account opnieuw te registreren.",
  "verifyaccount.registration": "Wilt u zich aanmelden?",
  "verifyaccount.optionalLink": "",
  "verifyaccount.optionalLinkInfo": "",

  /** Change user */
  "changeuser.title": "Bewerk account",
  "changeuser.titlePassword": "Wachtwoord wijzigen",
  "changeuser.newPassword": "Nieuw paswoord",
  "changeuser.error":
    "Het was niet mogelijk om bij te werken. Probeer het opnieuw of neem contact op met uw beheerder.",
  "changeuser.complete": "Account bijgewerkt",
  "changeuser.processing": "Verwerken ...",
  "changeuser.username_exists": "Gebruikersnaam bestaat. Selecteer een andere gebruikersnaam.",
  "changeuser.email_exists": "Email bestaat. Selecteer een andere e-mail.",
  "changeuser.email_empty": "E-mail is verplicht. Schrijf alstublieft een e-mail.",
  "changeuser.sip_exists": "SIP-adres bestaat. Selecteer een ander slokadres.",

  /** Network */
  "network.networkError": "Er is een probleem opgetreden bij het verbinden met de server",
  "network.establishing": "Proberen verbinding te maken met de server...",
  "network.offline": "Offline",

  /** Accessibility */
  "accessibility.darktheme": "Donker Thema",
  "accessibility.originaltheme": "Origineel Thema",

  /** About */
  "about.user": "Gebruikersnaam",
  "about.sip": "SIP-adres",
  "about.server": "Server IP",
  "about.version": "Versie",
  "about.browser": "Browser",
  "about.browserversion": "Browser versie",
  "about.os": "DE",

  /** Aria */
  "aria.mainmenu": "Hoofdmenu",
  "aria.submenu_contacts": "Submenu van contacten",
  "aria.submenu_account": "Submenu van account",
  "aria.selected_page": "Geselecteerde pagina",
  "aria.back_button": "Laatste karakter verwijderen",
  "aria.accept_terms": "Voorwaarden accepteren",
  "aria.link_sound_video": "Ga naar instellingen voor geluid en video",
  "aria.selfview": "Zelfbeeldvideo",
  "aria.in-call": "In gesprek",

  /** Kiosk */
  "kiosk.call_ended": "Het gesprek is beëindigd, u kunt deze pagina nu sluiten.",

  /** Modal */
  "modal.close": "Dicht",
  "modal.cancel": "Annuleren",
  "modal.confirm": "OK",
  "modal.logout_confirmation.title": "Weet u zeker dat u wilt uitloggen?",
  "modal.logout_confirmation.confirm": "Ja",
  "modal.logout_confirmation.cancel": "Nee, annuleer",
};

export default translations;
