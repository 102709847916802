import {
  Info,
  Invitation,
  InvitationAcceptOptions,
  Inviter,
  InviterInviteOptions,
  InviterOptions,
  Message,
  Registerer,
  RegistererOptions,
  RegistererState,
  RegistererUnregisterOptions,
  Session,
  SessionDescriptionHandlerOptions,
  SessionState,
  URI,
  UserAgent,
  UserAgentOptions,
  UserAgentState,
  Web,
} from "sip.js";
import { getSessionId, getUserAgentString, randomString } from "../../misc/helpers";
import { IncomingMessage, Logger, OutgoingRegisterRequest, OutgoingRequestMessage } from "sip.js/lib/core";
import { SessionDescriptionHandler } from "sip.js/lib/platform/web/session-description-handler";
import { PhoneDelegate, PhoneUserAgentOptions, PhoneOptions, PhoneMedia, PhoneInviteOptions, SafetextSender } from ".";
import store from "../../store";
import { IRootState } from "../../models/reducerStates";
import addBandwidthRestriction from "./modifiers/bandwidthModifier";
import moment from "moment";
import { stripAudio, stripVideo } from "./modifiers/stripMediaModifiers";

export class Phone {
  public debug: boolean = false;
  public delegate: PhoneDelegate;
  public session: Session;
  public user: URI;
  public safetextSender: SafetextSender;

  private options: PhoneOptions;
  private logger: Logger;
  private attemptingReconnection = false;
  private connectRequested = false;
  private registerRequested = false;
  private userAgent: UserAgent;

  private registerer: Registerer;
  private registerAttempts = 0;

  private sessionId: string;

  public audio: boolean;
  public video: boolean;
  public text: boolean;
  public trackPosition: boolean = false;
  public lastPosition: GeolocationPosition = null;

  private emptySdp =
    "v=0\n" +
    "o=fake 0 0 IN IP4 127.0.0.1\n" +
    "s=0\n" +
    "b=CT:64000\n" +
    "t=0 0\n" +
    "m=audio 1 RTP/AVP 0\n" +
    "c=IN IP4 127.0.0.1\n" +
    `a=ice-ufrag:${randomString(4)}\n` +
    `a=ice-pwd:${randomString(22)}\n` +
    "a=fingerprint:sha-1 42:89:c5:c6:55:9d:6e:c8:e8:83:55:2a:39:f9:b6:eb:e9:a3:a9:e7\n" +
    "a=rtcp-mux\n" +
    "a=rtpmap:0 PCMU/8000\n";

  constructor(options: PhoneOptions = {}) {
    this.audio = options.supportedMedia.audio;
    this.video = options.supportedMedia.video;
    this.text = options.supportedMedia.text;

    this.sessionId = getSessionId();

    this.delegate = options.delegate;
    this.debug = options.debug;
    this.options = { ...options };

    this.user = UserAgent.makeURI(`sip:${this.options.userAgentOptions.uri}`);

    this.safetextSender = new SafetextSender(this.user);

    const userAgentOptions = this.getUserAgentOptions(options.userAgentOptions);
    this.userAgent = new UserAgent(userAgentOptions);

    this.userAgent.delegate = {
      onConnect: (): void => {
        this.logger.log(`[${this.id}] Connected`);
        if (this.delegate && this.delegate.onServerConnect) {
          this.delegate.onServerConnect();
        }

        if (this.registerer && this.registerRequested) {
          this.logger.log(`[${this.id}] Registering...`);
          this.registerer.register().catch((e: Error) => {
            this.logger.error(`[${this.id}] Error occurred registering after connection with server was obtained.`);
            this.logger.error(e.toString());
          });
        }
      },
      // Handle connection with server lost
      onDisconnect: (error?: Error): void => {
        this.logger.log(`[${this.id}] Disconnected`);
        if (this.delegate && this.delegate.onServerDisconnect) {
          this.delegate.onServerDisconnect(error);
        }
        if (this.session) {
          this.logger.log(`[${this.id}] Hanging up...`);
          this.hangup() // cleanup hung calls
            .catch((e: Error) => {
              this.logger.error(`[${this.id}] Error occurred hanging up call after connection with server was lost.`);
              this.logger.error(e.toString());
            });
        }
        if (this.registerer) {
          this.logger.log(`[${this.id}] Unregistering...`);
          this.registerer
            .unregister() // cleanup invalid registrations
            .catch((e: Error) => {
              this.logger.error(`[${this.id}] Error occurred unregistering after connection with server was lost.`);
              this.logger.error(e.toString());
            });
        }
        // Only attempt to reconnect if network/server dropped the connection.
        if (error) {
          this.attemptReconnection();
        }
      },
      onInvite: (invitation: Invitation): void => {
        this.logger.log(`[${this.id}] Received INVITE`);

        // Guard against a pre-existing session. This implementation only supports one session at a time.
        // However an incoming INVITE request may be received at any time and/or while in the process
        // of sending an outgoing INVITE request. So we reject any incoming INVITE in those cases.
        if (this.session) {
          this.logger.warn(`[${this.id}] Session already in progress, rejecting INVITE...`);
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
          return;
        }

        // invitation.stateChange.addListener((newState: SessionState) => {
        //   switch (newState) {
        //     case SessionState.Established:
        //       const sessionDescriptionHandler = invitation.sessionDescriptionHandler as Web.SessionDescriptionHandler;
        //       if (this.delegate && this.delegate.onCallAnswered) {
        //         this.delegate.onCallAnswered();
        //       }
        //       // assignStream(sessionDescriptionHandler.remoteMediaStream, audioElement);
        //       sessionDescriptionHandler.peerConnectionDelegate = {
        //         // NOTE:: SB - Allowing to get onTrack events to know when a new track added to the peer connection.
        //         // When we get a new track event, we'll assign the last new remote media stream to HTML audio element source.
        //         // Mostly will occur when RE-INVITEs will happen.
        //         ontrack(e: Event) {
        //           console.log(">>> GOT NEW TRACK EVENT!", e);
        //           // if (this.delegate && this.delegate.onCallAnswered) {
        //           //   this.delegate.onCallAnswered();
        //           // }
        //           // self.assignStream(sessionDescriptionHandler.remoteMediaStream, self.audioElement);
        //         },
        //       };
        //       break;
        //   }
        // });

        // Initialize our session
        // TODO This is probably not needed, since we won't handle referrals
        // const referralInviterOptions: InviterOptions = {
        //   sessionDescriptionHandlerOptions: this.getSessionDescriptionHandlerOptions(),
        // };

        // this.initSession(invitation, referralInviterOptions);
        this.initSession(invitation);

        // Delegate
        if (this.delegate && this.delegate.onCallReceived) {
          this.delegate.onCallReceived();
        } else {
          this.logger.warn(`[${this.id}] No handler available, rejecting INVITE...`);
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
        }
      },
      onMessage: message => {
        if (this.delegate && this.delegate.onMessageReceived) {
          this.delegate.onMessageReceived(message);
        }
      },
    };

    this.logger = this.userAgent.getLogger("sip.mmx.Phone");

    window.addEventListener("online", () => {
      this.logger.log(`[${this.id}] Online`);
      this.attemptReconnection();
    });

    window.addEventListener("pagehide", async () => {
      if (this.session) {
        await this.terminate();
        await this.unregister();
      }
    });
  }

  get noMedia(): boolean {
    return !this.audio && !this.video;
  }

  get isConnected(): boolean {
    return this.userAgent.isConnected();
  }

  /**
   * Instance identifier.
   * @internal
   */
  get id(): string {
    return (
      (this.options.userAgentOptions &&
        this.options.userAgentOptions.user &&
        this.options.userAgentOptions.user.displayName) ||
      "Anonymous"
    );
  }

  private getUserAgentOptions(options: PhoneUserAgentOptions): UserAgentOptions {
    const userAgentString = getUserAgentString();

    const sessionDescriptionHandlerFactoryOptions: Web.SessionDescriptionHandlerFactoryOptions = {
      iceGatheringTimeout: 1000,
      peerConnectionConfiguration: {
        iceServers: [{ urls: `stun:${options.server.host}:40000` }],
      },
    };

    const userAgentOptions: UserAgentOptions = {
      uri: this.user,
      displayName: options.user.displayName,
      contactName: this.user.user,
      contactParams: {
        transport: "wss",
      },
      instanceId: this.sessionId,
      authorizationUsername: options.user.authorizationUsername,
      authorizationPassword: options.user.authorizationPassword,
      logLevel: options.logLevel,
      logBuiltinEnabled: this.debug,
      logConnector: options.onLog,
      sessionDescriptionHandlerFactoryOptions,
      userAgentString,
      transportOptions: {
        server: options.server.href,
        traceSip: this.debug,
        keepAliveInterval: 20,
      },
    };

    return userAgentOptions;
  }

  private getSessionDescriptionHandlerOptions(): SessionDescriptionHandlerOptions {
    const state: IRootState = store.getState();

    let audio = state.call.audio && state.setting.user.WEB_AUDIO === "true";
    let video = state.call.video && state.setting.user.WEB_VIDEO === "true";

    if (!audio && !video) {
      audio = true;
      video = true;
    }

    const sessionDescriptionHandlerOptions: SessionDescriptionHandlerOptions = {
      constraints: {
        audio,
        video,
      },
    };

    return sessionDescriptionHandlerOptions;
  }

  /**
   * Setup session delegate and state change handler.
   * @param session - Session to setup
   */
  private initSession(session: Inviter | Invitation): Session {
    this.session = session;

    const state: IRootState = store.getState();
    const emergencyNumbers = state.setting.user.GEO_EMERGENCY_NUMBERS;
    this.trackPosition = emergencyNumbers.indexOf(this.session.remoteIdentity.friendlyName) !== -1;

    session.sessionDescriptionHandlerModifiers = session.sessionDescriptionHandlerModifiers || [];
    session.sessionDescriptionHandlerModifiers.push(addBandwidthRestriction);

    // @ts-ignore
    this.session.data = {};

    session.delegate = session.delegate || {};
    if (this.noMedia) {
      session.delegate.onSessionDescriptionHandler = (sdh: SessionDescriptionHandler) => {
        sdh.setDescription(this.emptySdp);
      };
    }

    session.delegate.onInfo = (info: Info) => {
      if (this.delegate && this.delegate.onInfoReceived) {
        this.delegate.onInfoReceived(info);
      }
    };

    session.delegate.onMessage = (message: Message) => {
      if (this.delegate && this.delegate.onMessageReceived) {
        this.delegate.onMessageReceived(message);
      }
    };

    session.delegate.onInvite = request => {
      if (this.delegate && this.delegate.onReinviteReceived) {
        this.delegate.onReinviteReceived(request);
      }
    };

    // Setup session state change handler
    this.session.stateChange.addListener((state: SessionState) => {
      // if our session has changed, just return
      if (this.session !== session) {
        return;
      }

      this.logger.log(`[${this.id}] session state changed to ${state}`);

      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          // @ts-ignore
          this.session.data.negotiatedMedia = this.getNegotiatedMedia(session.request);

          if (this.trackPosition) {
            this.sendPosition();
          }

          if (this.session instanceof Invitation) {
            // const sessionDescriptionHandler = this.session.sessionDescriptionHandler as Web.SessionDescriptionHandler;
            // // assignStream(sessionDescriptionHandler.remoteMediaStream, audioElement);
            // sessionDescriptionHandler.peerConnectionDelegate = {
            //   // NOTE:: SB - Allowing to get onTrack events to know when a new track added to the peer connection.
            //   // When we get a new track event, we'll assign the last new remote media stream to HTML audio element source.
            //   // Mostly will occur when RE-INVITEs will happen.
            //   ontrack(event: Event) {
            //     console.log(">>>>> ONTRACK", event);
            //     // self.assignStream(sessionDescriptionHandler.remoteMediaStream, self.audioElement);
            //   },
            // };

            if (this.delegate && this.delegate.onCallAnswered) {
              this.delegate.onCallAnswered();
            }
          }
          break;
        case SessionState.Terminating:
          break;
        case SessionState.Terminated:
          this.session = undefined;
          this.trackPosition = false;
          this.lastPosition = null;
          if (this.delegate && this.delegate.onCallHangup) {
            this.delegate.onCallHangup();
          }
          break;
        default:
          throw new Error("Unknown session state.");
      }
    });

    // Call session created callback
    if (this.delegate && this.delegate.onCallCreated) {
      this.delegate.onCallCreated();
    }

    return session;
  }

  /**
   * Send an INVITE request.
   *
   * @param options - The options for the call.
   */
  public async sendInvite(options: PhoneInviteOptions): Promise<void> {
    const inviterOptions: InviterOptions = {
      extraHeaders: [],
      sessionDescriptionHandlerOptions: this.getSessionDescriptionHandlerOptions(),
    };

    if (this.text) {
      inviterOptions.extraHeaders.push("P-MMX-Text-Offer: 1");
      inviterOptions.extraHeaders.push("P-Safe-Text: 1");
    }

    if (options.extraHeaders) {
      inviterOptions.extraHeaders = [].concat(inviterOptions.extraHeaders, options.extraHeaders);
    }

    const inviter = new Inviter(this.userAgent, options.to, inviterOptions);

    // An Inviter is a Session
    // this.initSession(inviter, inviterOptions);
    this.initSession(inviter);

    const inviteOptions: InviterInviteOptions = {
      sessionDescriptionHandlerOptions: this.getSessionDescriptionHandlerOptions(),
      requestDelegate: {
        onAccept: (response): void => {
          let negotiatedMedia = this.getNegotiatedMedia(response.message);

          this.session.data = {
            negotiatedMedia: negotiatedMedia,
          };

          this.setupSafetextSender();

          if (options.ivr) {
            this.sendIVRinput(options.ivr);
          }

          if (this.delegate && this.delegate.onCallAnswered) {
            this.delegate.onCallAnswered();
          }
        },
      },
    };

    return this.session.invite(inviteOptions).then(() => {
      // Maybe log for debug purposes?
    });
  }

  /**
   * Send DTMF.
   * @remarks
   * Send an INFO request with content type application/dtmf-relay.
   * @param tone - Tone to send.
   */
  public sendDTMF(tone: string): Promise<void> {
    this.logger.log(`[${this.id}] sending DTMF...`);

    // As RFC 6086 states, sending DTMF via INFO is not standardized...
    //
    // Companies have been using INFO messages in order to transport
    // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
    // proprietary and have not been standardized.
    // https://tools.ietf.org/html/rfc6086#section-2
    //
    // It is however widely supported based on this draft:
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

    // Validate tone
    if (!/^[0-9A-D#*,]$/.exec(tone)) {
      return Promise.reject(new Error("Invalid DTMF tone."));
    }

    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }

    // The UA MUST populate the "application/dtmf-relay" body, as defined
    // earlier, with the button pressed and the duration it was pressed
    // for.  Technically, this actually requires the INFO to be generated
    // when the user *releases* the button, however if the user has still
    // not released a button after 5 seconds, which is the maximum duration
    // supported by this mechanism, the UA should generate the INFO at that
    // time.
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
    this.logger.log(`[${this.id}] Sending DTMF tone: ${tone}`);
    const dtmf = tone;
    const duration = 2000;
    const body = {
      contentDisposition: "render",
      contentType: "application/dtmf-relay",
      content: "Signal=" + dtmf + "\r\nDuration=" + duration,
    };
    const requestOptions = { body };

    return this.session.info({ requestOptions }).then(() => {
      return;
    });
  }

  /**
   * Send position.
   * @remarks
   * Send an INFO request with positional data.
   */
  public sendPosition(): Promise<void> {
    if (!this.session) {
      this.trackPosition = false;
      return;
    }

    this.logger.log(`[${this.id}] Sending position...`);

    const state: IRootState = store.getState();
    const entity = this.session.localIdentity.friendlyName;
    const sleep = 10000;

    navigator.geolocation.getCurrentPosition(position => {
      if (this.lastPosition !== null) {
        const positionChangeInMeters = this.getDistanceFromLatLonInMeters(position.coords, this.lastPosition.coords);
        if (positionChangeInMeters < state.setting.user.GEO_UPDATE_LIMIT) {
          this.logger.log(`[${this.id}] Position unchanged, do nothing...`);
          if (this.trackPosition) {
            setTimeout(() => {
              this.sendPosition();
            }, sleep);
          }
          return;
        }
      }

      const timestamp = moment().format();
      let content = `<?xml version="1.0" encoding="UTF-8"?>
<presence xmlns="urn:ietf:params:xml:ns:pidf" xmlns:gp="urn:ietf:params:xml:ns:pidf:geopriv10" xmlns:gml="http://www.opengis.net/gml" xmlns:gs="http://www.opengis.net/pidflo/1.0" entity="pres:${entity}">
  <tuple id="circle">
    <status>
      <gp:geopriv>
        <gp:location-info>
          <gs:Circle srsName="urn:ogc:def:crs:EPSG::4326">
            <gml:pos>${position.coords.latitude} ${position.coords.longitude}</gml:pos>
            <gs:radius uom="urn:ogc:def:uom:EPSG::9001">${position.coords.accuracy}</gs:radius>
          </gs:Circle>
        </gp:location-info>
        <gp:usage-rules/>
        <gp:method>OTDOA</gp:method>
      </gp:geopriv>
    </status>
    <timestamp>${timestamp}</timestamp>
  </tuple>
</presence>`;

      const body = {
        contentDisposition: "render",
        contentType: "application/pidf+xml",
        content,
      };
      const requestOptions = { body };

      if (!this.session) return;
      return this.session.info({ requestOptions }).then(() => {
        this.lastPosition = position;
        if (this.trackPosition) {
          setTimeout(() => {
            this.sendPosition();
          }, sleep);
        }
        return;
      });
    });
  }

  private getDistanceFromLatLonInMeters(position1: GeolocationCoordinates, position2: GeolocationCoordinates) {
    var r = 6371; // Radius of the earth in km
    var distanceLatitude = this.deg2rad(position2.latitude - position1.latitude); // deg2rad below
    var distanceLongitude = this.deg2rad(position2.longitude - position1.longitude);
    var a =
      Math.sin(distanceLatitude / 2) * Math.sin(distanceLatitude / 2) +
      Math.cos(this.deg2rad(position1.latitude)) *
        Math.cos(this.deg2rad(position2.latitude)) *
        Math.sin(distanceLongitude / 2) *
        Math.sin(distanceLongitude / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = r * c; // Distance in km
    return Math.round(d * 1000);
  }

  private deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  /**
   * Answer an incoming call.
   * @remarks
   * Accept an incoming INVITE request creating a new Session.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if and when call is established.
   * @param media - Optional media options for Inviter.accept().
   */
  public answer(media: PhoneMedia): Promise<void> {
    this.logger.log(`[${this.id}] Accepting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error("Session not instance of Invitation."));
    }

    const invitationAcceptOptions: InvitationAcceptOptions = {};

    if (media) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions = {
        constraints: {
          audio: media.audio,
          video: media.video,
        },
      };
    }

    invitationAcceptOptions.sessionDescriptionHandlerModifiers =
      invitationAcceptOptions.sessionDescriptionHandlerModifiers || [];
    if (!media.video) invitationAcceptOptions.sessionDescriptionHandlerModifiers.push(stripVideo);
    if (!media.audio) invitationAcceptOptions.sessionDescriptionHandlerModifiers.push(stripAudio);

    if (!invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints = {
        audio: media.audio,
        video: media.video,
      };
    }

    if (!invitationAcceptOptions.extraHeaders) {
      invitationAcceptOptions.extraHeaders = [];
    }

    if (
      this.session.request.getHeader("P-MMX-Text-Offer") === "1" &&
      this.session.request.getHeader("P-Safe-Text") === "1"
    ) {
      invitationAcceptOptions.extraHeaders.push("P-MMX-Text-Response: 1");
      invitationAcceptOptions.extraHeaders.push("P-Safe-Text: 1");
    }

    this.setupSafetextSender();

    return this.session.accept(invitationAcceptOptions);
  }

  /**
   * Decline an incoming call.
   * @remarks
   * Reject an incoming INVITE request.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when call is ended.
   */
  public decline(): Promise<void> {
    this.logger.log(`[${this.id}] rejecting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error("Session not instance of Invitation."));
    }

    return this.session.reject({
      statusCode: 403,
    });

    // return this.session.reject();
  }

  /**
   * Connect.
   * @remarks
   * Start the UserAgent's WebSocket Transport.
   */
  public connect(): Promise<void> {
    this.logger.log(`[${this.id}] Connecting UserAgent...`);
    this.connectRequested = true;

    if (this.userAgent.state !== UserAgentState.Started) {
      return this.userAgent
        .start()
        .then(() => {
          this.register();
        })
        .catch(e => {
          if (this.delegate && this.delegate.onServerDisconnect) {
            this.delegate.onServerDisconnect(e);
          }
        });
    }

    return this.userAgent.reconnect();
  }

  private register(): Promise<OutgoingRegisterRequest> {
    this.logger.log(`[${this.id}] Registering UserAgent...`);
    this.registerRequested = true;

    const registererOptions: RegistererOptions = {
      expires: 600,
      params: {
        toDisplayName: this.options.userAgentOptions.user.displayName,
      },
    };

    this.registerer = new Registerer(this.userAgent, registererOptions);
    this.registerer.stateChange.addListener((newState: RegistererState) => {
      switch (newState) {
        case RegistererState.Unregistered:
          if (this.delegate && this.delegate.onUnregistered) {
            this.delegate.onUnregistered();
          }
          break;
        case RegistererState.Registered:
          if (this.delegate && this.delegate.onRegistered) {
            this.delegate.onRegistered();
          }
          break;
        default:
          break;
      }
    });

    return this.registerer.register({
      requestDelegate: {
        onReject: response => {
          if (response.message.method === "REGISTER" && response.message.statusCode === 403) {
            if (this.registerAttempts < 5) {
              if (this.delegate && this.delegate.onServerConnectionInProgress) {
                this.delegate.onServerConnectionInProgress();
              }

              this.registerAttempts++;
              setTimeout(() => {
                this.register();
              }, 2500);
            } else {
              if (this.delegate && this.delegate.onUnregistered) {
                this.delegate.onUnregistered();
              }

              if (this.delegate && this.delegate.onServerConnectionFailed) {
                this.delegate.onServerConnectionFailed();
              }
            }
          }
        },
      },
    });
  }

  /**
   * Stop receiving incoming calls.
   * @remarks
   * Send an un-REGISTER request for the UserAgent's AOR.
   * Resolves when the un-REGISTER request is sent, otherwise rejects.
   */
  public unregister(registererUnregisterOptions?: RegistererUnregisterOptions): Promise<void> {
    this.logger.log(`[${this.id}] Unregistering UserAgent...`);
    this.registerRequested = false;

    if (!this.registerer) {
      return Promise.resolve();
    }

    return this.registerer.unregister(registererUnregisterOptions).then(() => {
      return;
    });
  }

  /**
   * Disconnect.
   * @remarks
   * Stop the UserAgent's WebSocket Transport.
   */
  public disconnect(): Promise<void> {
    this.logger.log(`[${this.id}] Disconnecting UserAgent...`);
    this.connectRequested = false;
    return this.userAgent.stop();
  }

  /**
   * Hangup a call.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when call is ended.
   */
  public hangup(): Promise<void> {
    this.logger.log(`[${this.id}] Hangup...`);
    return this.terminate();
  }

  /**
   * End a session.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when Session is terminated.
   */
  private terminate(): Promise<void> {
    this.logger.log(`[${this.id}] Terminating...`);

    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }

    switch (this.session.state) {
      case SessionState.Initial:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            this.logger.log(`[${this.id}] Inviter never sent INVITE (canceled)`);
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            this.logger.log(`[${this.id}] Inviter canceled (sent CANCEL)`);
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Established:
        return this.session.bye().then(() => {
          this.logger.log(`[${this.id}] Session ended (sent BYE)`);
        });
      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;
      default:
        throw new Error("Unknown state");
    }

    this.logger.log(`[${this.id}] Terminating in state ${this.session.state}, no action taken`);
    return Promise.resolve();
  }

  /** Helper function to enable/disable media tracks. */
  private enableReceiverTracks(kind: "audio" | "video", enable: boolean): void {
    const peerConnection = this.getPeerConnection();
    const receivers = peerConnection.getReceivers();

    if (!receivers.length) return;

    receivers.forEach(receiver => {
      if (receiver.track && receiver.track.kind === kind) {
        receiver.track.enabled = enable;
      }
    });
  }

  /** Helper function to enable/disable media tracks. */
  private enableSenderTracks(kind: "audio" | "video", enable: boolean): void {
    const peerConnection = this.getPeerConnection();
    const senders = peerConnection.getSenders();

    if (!senders.length) return;

    senders.forEach(sender => {
      if (sender.track && sender.track.kind === kind) {
        sender.track.enabled = enable;
      }
    });
  }

  /**
   * Puts Session on mute.
   * @param mute - Mute on if true, off if false.
   */
  private setMute(direction: "local" | "remote", kind: "audio" | "video", mute: boolean): void {
    if (!this.session) {
      this.logger.warn(`[${this.id}] A session is required to enabled/disable media tracks`);
      return;
    }

    if (this.session.state !== SessionState.Established) {
      this.logger.warn(`[${this.id}] An established session is required to enable/disable media tracks`);
      return;
    }

    this.logger.log(`[${this.id}] ${mute ? "Disable" : "Enable"} ${direction} ${kind} tracks.`);

    switch (direction) {
      case "local":
        this.enableSenderTracks(kind, !mute);
        break;
      case "remote":
        this.enableReceiverTracks(kind, !mute);
        break;
    }
  }

  /**
   * Attempt to get the Peer Connection from the current sessions SessionDescriptionHandler.
   */
  private getPeerConnection(): RTCPeerConnection {
    if (!this.session) {
      throw new Error("Session does not exist.");
    }

    const sessionDescriptionHandler = this.session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error("Peer connection closed.");
    }

    return peerConnection;
  }

  public muteAudio(direction: "local" | "remote"): void {
    this.setMute(direction, "audio", true);
  }

  public unmuteAudio(direction: "local" | "remote"): void {
    this.setMute(direction, "audio", false);
  }

  public muteVideo(direction: "local" | "remote"): void {
    this.setMute(direction, "video", true);
  }

  public unmuteVideo(direction: "local" | "remote"): void {
    this.setMute(direction, "video", false);
  }

  public stopMediaTracks(direction: "local" | "remote", kind: "audio" | "video"): void {
    this.logger.log(`[${this.id}] stopping local media tracks...`);

    if (!this.session) {
      throw new Error("Session does not exist.");
    }

    const sessionDescriptionHandler = this.session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error("Peer connection closed.");
    }

    if (direction === "local") {
      peerConnection.getSenders().forEach(sender => {
        if (sender.track && sender.track.kind === kind) {
          sender.track.stop();
        }
      });
    }

    if (direction === "remote") {
      peerConnection.getReceivers().forEach(receiver => {
        if (receiver.track && receiver.track.kind === kind) {
          receiver.track.stop();
        }
      });
    }
  }

  private getNegotiatedMedia(request: IncomingMessage | OutgoingRequestMessage): PhoneMedia {
    const body = request instanceof OutgoingRequestMessage ? request.body.body : request.body;
    const video = body.indexOf("m=video 0 ") > -1 || body.indexOf("m=video") === -1 ? false : true;
    const audio = body.indexOf("m=audio 0 ") > -1 || body.indexOf("m=audio") === -1 ? false : true;
    const text = typeof request.getHeader("P-Mmx-Text-Dst") !== "undefined";
    return { audio, video, text };
  }

  private sendIVRinput(inputString: string): void {
    const input = inputString.split(",");
    this.sendInput(input);
  }

  private async sendInput(input: string[], index: number = 0) {
    if (index < input.length) {
      for (let i = 0; i < input[index].length; i++) {
        await this.sendDTMF(input[index][i]);
      }

      setTimeout(() => this.sendInput(input, index + 1), 100);
    }
  }

  /**
   * Attempt reconnection up to `maxReconnectionAttempts` times.
   * @param reconnectionAttempt - Current attempt number.
   */
  private attemptReconnection(reconnectionAttempt = 1): void {
    const reconnectionAttempts = this.options.reconnectionAttempts || 10;
    const reconnectionDelay = this.options.reconnectionDelay || 4;

    if (!this.connectRequested) {
      this.logger.log(`[${this.id}] Reconnection not currently desired`);
      return; // If intentionally disconnected, don't reconnect.
    }

    if (this.attemptingReconnection) {
      this.logger.log(`[${this.id}] Reconnection attempt already in progress`);
    }

    if (reconnectionAttempt > reconnectionAttempts) {
      this.logger.log(`[${this.id}] Reconnection maximum attempts reached`);

      if (this.delegate && this.delegate.onServerConnectionFailed) {
        this.delegate.onServerConnectionFailed();
      }

      return;
    }

    if (reconnectionAttempt === 1) {
      this.logger.log(`[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying`);
    } else {
      this.logger.log(
        `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying in ${reconnectionDelay} seconds`
      );
    }

    this.attemptingReconnection = true;

    if (this.delegate && this.delegate.onServerConnectionInProgress) {
      this.delegate.onServerConnectionInProgress();
    }

    setTimeout(
      () => {
        if (!this.connectRequested) {
          this.logger.log(
            `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - aborted`
          );
          this.attemptingReconnection = false;
          return; // If intentionally disconnected, don't reconnect.
        }
        this.userAgent
          .reconnect()
          .then(() => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - succeeded`
            );

            if (this.delegate && this.delegate.onServerConnect) {
              this.delegate.onServerConnect();
            }

            this.attemptingReconnection = false;
          })
          .catch((error: Error) => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - failed`
            );
            this.logger.error(error.message);
            this.attemptingReconnection = false;
            this.attemptReconnection(++reconnectionAttempt);
          });
      },
      reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000
    );
  }

  private setupSafetextSender() {
    if (this.safetextSender) {
      this.safetextSender.reset();
      this.safetextSender.uri = this.session.remoteIdentity.uri;
      this.safetextSender.session = this.session;
    }
  }
}
