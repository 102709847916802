import React from "react";
import Button from "./Button";
import "./HangupButton.css";

const HangupButton = ({ text, onClick, kioskmode }) => (
  <Button
    className={"hangup-button" + (kioskmode === true ? " hangup-button-kiosk" : "")}
    text={text}
    title={text}
    icon="call_end"
    onClick={onClick}
  />
);

export default HangupButton;
