import React, { useState, useCallback } from "react";
import debounce from "lodash/debounce";

type SearchProps = {
  onSearch: Function;
  placeholder: string;
};

const Search = (props: SearchProps) => {
  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((value: string) => search(value), 400),
    []
  );

  const search = (value: string) => {
    props.onSearch(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-sm-8 col-md-4 col-lg-3 p-0">
        <input
          value={value}
          type="text"
          className="form-control"
          placeholder={props.placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Search;
