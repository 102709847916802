import { SERVICES } from "../actions/actionTypes";
import { IServiceState } from "../models/reducerStates";

const initialState: IServiceState = {
  services: [],
  loading: false,
};

const serviceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SERVICES.LOADED:
      state = {
        loading: false,
        services: action.payload.services,
      };
      break;
    case SERVICES.LOADING:
      state = {
        loading: true,
        services: [],
      };
      break;
    default:
      break;
  }
  return state;
};

export default serviceReducer;
